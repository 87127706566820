<template>
  <v-textarea v-model="text" v-bind="$attrs"></v-textarea>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
const props = defineProps({
  value: {
    type: Array as PropType<string[]>,
    required: true,
  },
});
const emits = defineEmits(["input"]);

const text = computed({
  get(previous) {
    return props.value.join("\n");
  },
  set(newValue: string) {
    const trimmedValue = newValue.trim();
    emits(
      "input",
      trimmedValue ? trimmedValue.split("\n").filter((text) => !!text) : [],
    );
  },
});
</script>
