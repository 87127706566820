<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <div class="article-maintenance">
          <v-autocomplete
            v-model="selectedCustomer"
            class="article-maintenance__filter"
            :items="customers"
            label="Customers"
            clearable
            @change="onFilterSelected"
          ></v-autocomplete>
          <SenderAutocomplete
            v-model="selectedSender"
            :is-export="false"
            type="fulfilled"
            class="article-maintenance__filter"
            @change="onFilterSelected"
          ></SenderAutocomplete>
          <v-select
            v-model="selectedIncoterm"
            :items="incoterms"
            item-value="id"
            item-text="name"
            label="DAP/DDP"
            clearable
            :disabled="loading"
            class="article-maintenance__filter"
            @change="onFilterSelected"
          >
          </v-select>
          <v-text-field
            v-model="search"
            class="article-maintenance__filter"
            label="Search"
            @keyup.enter="onFilterSelected"
          >
          </v-text-field>
        </div>
      </template>

      <template #item.useGeneralCustomerForAutomatedProcess="{ item }">
        <v-icon
          :color="
            item.useGeneralCustomerForAutomatedProcess ? 'success' : 'error'
          "
          >{{
            item.useGeneralCustomerForAutomatedProcess
              ? "mdi-check"
              : "mdi-close"
          }}
        </v-icon>
      </template>

      <template #item.useSenderForAutomatedProcess="{ item }">
        <v-icon :color="item.useSenderForAutomatedProcess ? 'success' : 'error'"
          >{{ item.useSenderForAutomatedProcess ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>

      <template #item.documents="{ item }">
        <v-icon :color="item.documents ? 'success' : 'error'"
          >{{ item.documents ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>

      <template #item.ezaHsCode="{ item }">
        <span>{{ getEzaHsCode(item) }}</span>
      </template>

      <template #item.impostHsCode="{ item }">
        <span>{{ getImpostHsCode(item) }}</span>
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex flex-column">
          <v-btn color="primary" class="ma-2" @click="onEdit(item)"
            ><v-icon left>mdi-pencil</v-icon>Edit</v-btn
          >
          <v-btn color="error" class="ma-2" @click="onDelete(item)"
            ><v-icon left>mdi-delete</v-icon>Delete</v-btn
          >
        </div>
      </template>
    </v-data-table>
    <ArticleMaintenanceDialog
      v-if="showDialog"
      v-model="showDialog"
      title="Edit"
      :is-loading="dialogLoading"
      :initial-data="initialData"
      @confirm="onEditConfirmed"
    ></ArticleMaintenanceDialog>

    <ConfirmDialog
      v-if="showDeleteDialog"
      v-model="showDeleteDialog"
      :is-loading="deleteDialogLoading"
      title="Confirm delete"
      @confirm="onDeleteConfirmed"
    >
      Are you sure you want to delete this fulfilled article?
    </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback } from "@/event-bus";
import {
  CustomsArticleMaintenanceApi,
  FulfilledArticle,
  IncotermViewModel,
} from "@/openapi";
import { DefaultDataTableItem } from "@/types/types";
import { ref, watch, computed, onBeforeMount, PropType } from "vue";
import { DataOptions } from "vuetify";

import ArticleMaintenanceDialog, {
  ArticleMaintenanceFulfillmentForm,
} from "./dialogs/ArticleMaintenanceDialog.vue";
import SenderAutocomplete from "./editors/SenderAutocomplete.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { DataAction } from "@/store/dataModule";
import { useArticles } from "@/composables/articles";
import store from "@/store";
const { ezaArticles, impostArticles } = useArticles();
const api = new CustomsArticleMaintenanceApi(undefined, "");

const props = defineProps({
  customers: {
    type: Array as PropType<DefaultDataTableItem<string>[]>,
    default: () => [],
  },
  senders: {
    type: Array as PropType<DefaultDataTableItem<string>[]>,
    default: () => [],
  },
  incotermIds: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
});

const loading = ref(false);
const dialogLoading = ref(false);

const showDeleteDialog = ref(false);
const deleteDialogLoading = ref(false);

const headers = ref([
  { text: "Customer", value: "customer", sortable: false },
  { text: "Sender", value: "sender", sortable: false },
  { text: "HS Code", value: "hsCode", sortable: false },
  { text: "Content description", value: "description", sortable: false },
  { text: "EZA HS Code", value: "ezaHsCode", sortable: false },
  { text: "EZA Description", value: "ezaDescription", sortable: false },
  { text: "IMPOST HS Code", value: "impostHsCode", sortable: false },
  {
    text: "IMPOST Description",
    value: "impostDescription",
    sortable: false,
  },
  { text: "DAP/DDP", value: "incoterm", sortable: false },
  {
    text: "Use general customer for automated process",
    value: "useGeneralCustomerForAutomatedProcess",
    sortable: false,
  },
  {
    text: "Use sender for automated process",
    value: "useSenderForAutomatedProcess",
    sortable: false,
  },
  {
    text: "Documents",
    value: "documents",
    sortable: false,
  },
  { text: " ", value: "actions", sortable: false, width: "6em" },
]);

const selectedCustomer = ref<string | null>(null);
const selectedSender = ref<string | null>(null);
const selectedIncoterm = ref<number | null>(null);

const search = ref<string | null>(null);

const items = ref<FulfilledArticle[]>([]);
const currentItem = ref<FulfilledArticle | null>(null);
const initialData = ref<ArticleMaintenanceFulfillmentForm | null>(null);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);

const showDialog = ref(false);

onBeforeMount(() => {
  store.dispatch(DataAction.FetchIncoterms);
});

let timeoutDelay = 0;

watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getFulfilledArticles(options.value.page);
    }, 250);
  },
  { deep: true },
);

const getFulfilledArticles = async (page: number) => {
  loading.value = true;
  try {
    const response = await api.getFulfilledArticles(
      selectedCustomer.value ? parseInt(selectedCustomer.value) : undefined,
      selectedSender.value ?? undefined,
      search.value ?? undefined,
      selectedIncoterm.value ?? undefined,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the fulfilled articles.");
  }

  loading.value = false;
};

const onFilterSelected = async () => {
  await getFulfilledArticles(1);
};

const onEdit = (item: FulfilledArticle) => {
  currentItem.value = item;
  initialData.value = {
    eza: {
      hsCode: item.ezaHsCode,
      description: item.ezaDescription,
    },
    impost: {
      description: item.impostDescription,
      hsCode: item.impostHsCode,
    },
    useGeneralCustomerForAutomatedProcess:
      item.useGeneralCustomerForAutomatedProcess!,
    useSenderForAutomatedProcess: item.useSenderForAutomatedProcess!,
    documents: item.documents!,
    senderName: item.sender,
    customer: item.customer,
  };
  showDialog.value = true;
};

const onDelete = (item: FulfilledArticle) => {
  currentItem.value = item;
  showDeleteDialog.value = true;
};

const onEditConfirmed = async (
  eventData: ArticleMaintenanceFulfillmentForm,
) => {
  dialogLoading.value = true;
  try {
    await api.editFulfilledArticle({
      fulfilledArticleId: currentItem.value!.id,
      ezaHsCode: eventData.eza.hsCode!,
      ezaDescription: eventData.eza.description,
      impostHsCode: eventData.impost.hsCode,
      impostDescription: eventData.impost.description,
      useGeneralCustomerForAutomatedProcess:
        eventData.useGeneralCustomerForAutomatedProcess,
      useSenderForAutomatedProcess: eventData.useSenderForAutomatedProcess,
      documents: eventData.documents,
    });
    dialogLoading.value = false;
    showDialog.value = false;
    currentItem.value = null;
    initialData.value = null;
    await getFulfilledArticles(options.value.page);
  } catch (e: any) {
    dialogLoading.value = false;
    let errorMessages: string[] = [
      "Something went wrong while editing the fulfilled article.",
    ];

    if (e?.response?.data?.errorMessages?.length) {
      errorMessages = e.response.data.errorMessages;
    }
    emitError(...errorMessages);
  }
};

const onDeleteConfirmed = async () => {
  deleteDialogLoading.value = true;

  try {
    await api.deleteFullfilledArticle({
      id: currentItem.value!.id,
    });

    showDeleteDialog.value = false;
    currentItem.value = null;

    await getFulfilledArticles(options.value.page);
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while deleting the fulfilled article.",
    );
  }

  deleteDialogLoading.value = false;
};

const getEzaHsCode = (item: FulfilledArticle) => {
  return ezaArticles.value.find((c) => c.id === item.ezaHsCode)?.hsCode ?? "";
};

const getImpostHsCode = (item: FulfilledArticle) => {
  return (
    impostArticles.value.find((c) => c.id === item.impostHsCode)?.hsCode ?? ""
  );
};

const incoterms = computed(() => {
  return store.getters.incoterms.filter((c: IncotermViewModel) =>
    props.incotermIds.includes(c.id!),
  );
});
</script>
