<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalAmountOfItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1 stickyColumn"
          :loading="loading"
          @update:options="onUpdateOptions"
        >
          <template v-slot:header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              @click="addNewItem"
              :disabled="disableNewItemButton"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td valign="top">
                    <v-icon
                      :disabled="!dirty && invalid"
                      small
                      class="mr-2 warning--text"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'routeId-' + item.id"
                  >
                    <ValidationProvider
                      name="routeId"
                      :vid="'routeId-' + item.id"
                      ref="providers"
                      item-name="routeId"
                      :item-id="item.id"
                      rules="required"
                      v-slot="{ errors, failed, validate }"
                      slim
                    >
                      <div
                        @click="makeEditable"
                        :class="{ error: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ routeDisplayName(item.routeId) }}
                        </div>
                        <v-autocomplete
                          v-else
                          v-model="item.routeId"
                          :items="routes"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          item-value="key"
                          item-text="value"
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'tarif-' + item.id"
                  >
                    <ValidationProvider
                      name="tarif"
                      :vid="'tarif-' + item.id"
                      ref="providers"
                      item-name="tarif"
                      :item-id="item.id"
                      rules="required"
                      v-slot="{ errors, failed, validate }"
                      slim
                    >
                      <div
                        @click="makeEditable"
                        :class="{ error: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.tarif }}</div>
                        <v-text-field
                          v-else
                          v-model="item.tarif"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import {
  CustomsForbiddenTarifApi,
  CustomsForbiddenTarifViewModel,
  DataApi,
  KeyValueItem,
} from "@/openapi";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ref, computed, onBeforeMount } from "vue";
import { DataOptions } from "vuetify";
import { useCrudPage } from "@/composables/crudPage";

const {
  disableNewItemButton,
  items,
  totalAmountOfItems,
  isLoading,
  isNewItem,
  mapItem,
} = useCrudPage<CustomsForbiddenTarifViewModel>({ id: 0, routeId: 0 }, "id", 0);

const forbiddenTarifApi = new CustomsForbiddenTarifApi(undefined, "");
const dataApi = new DataApi(undefined, "");
const emits = defineEmits(["errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);
const loading = ref(false);

const routes = ref<KeyValueItem[]>([]);

const headers = ref([
  { text: " ", value: "actions", sortable: false, width: "6em" },
  { text: "Route", value: "route", width: "30%" },
  { text: "Tarif", value: "tarif" },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

onBeforeMount(() => {
  getRoutes();
});

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({ id: 0 } as CustomsForbiddenTarifViewModel);
  totalAmountOfItems.value += 1;
};

const getRoutes = async () => {
  try {
    const response = await dataApi.getRoutes();
    routes.value = response.data;
  } catch {
    displayError("Something went wrong while retrieving the routes.");
  }
};

const getForbiddenTarifs = async () => {
  try {
    const response = await forbiddenTarifApi.getForbiddenTarifs(
      sortBy.value,
      sortDesc.value,
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    options.value.page = response.data.page ?? 1;
  } catch {
    displayError("Something went wrong while retrieving the forbidden tarifs.");
  }
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const onUpdateOptions = (options: DataOptions) => {
  getForbiddenTarifs();
};

const routeDisplayName = (routeId: number | null) => {
  return routeId === null
    ? ""
    : routes.value.find((c) => c.key == routeId)?.value;
};

const deleteItem = async (item: any) => {
  loading.value = true;
  const index = items.value.findIndex((x) => x.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    loading.value = false;
    disableNewItemButton.value = false;
    totalAmountOfItems.value -= 1;
    return;
  }

  try {
    await forbiddenTarifApi.deleteForbiddenTarif(item.id);
    await getForbiddenTarifs();
  } catch {
    displayError("Something went wrong while deleting the item.");
  }

  loading.value = false;
};

const saveItem = async (item: CustomsForbiddenTarifViewModel) => {
  if (loading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<any>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(
          item[propName as keyof CustomsForbiddenTarifViewModel],
        ),
      );
    });

  await Promise.all(promises);

  validationObserver.validate().then(async (isValid) => {
    if (!isValid) {
      return;
    }

    loading.value = true;
    const isNew = isNewItem(item);

    let promise: Promise<any>;

    if (isNew) {
      promise = forbiddenTarifApi.createForbiddenTarif({
        routeId: item.routeId,
        tarif: item.tarif,
      });
    } else {
      promise = forbiddenTarifApi.updateForbiddenTarif({
        id: item.id,
        routeId: item.routeId,
        tarif: item.tarif,
      });
    }
    try {
      await promise;
      disableNewItemButton.value = false;
    } catch {
      displayError("Something went wrong while creating/updating the entity.");
    }

    await getForbiddenTarifs();
    loading.value = false;
  });
};

const sortBy = computed(() => {
  return options.value.sortBy?.[0] ?? "";
});

const sortDesc = computed(() => {
  return options.value.sortDesc?.[0] ?? false;
});
</script>
