<template>
  <Fragment>
    <v-btn
      v-if="exportFlowStatus != 21"
      color="error"
      :class="cssClasses"
      @click="showDialog = true"
    >
      <span class="white--text"> Return shipment </span>
    </v-btn>

    <SetReturnReasonsDialog
      v-if="showDialog"
      v-model="showDialog"
      :is-loading="loading"
      @confirm="onConfirmedReturnReasons"
    ></SetReturnReasonsDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { emitError } from "@/event-bus";
import { ShipmentStatusApi } from "@/openapi";
import { getCurrentInstance } from "vue";
const instance = getCurrentInstance();
interface IProps {
  shipmentId: number;
  exportFlowStatus?: number;
}
interface Successful {
  success: boolean;
}

import SetReturnReasonsDialog, {
  ReturnShipmentReasonsForm,
} from "../dialogs/SetReturnReasonsDialog.vue";

const emits = defineEmits(["shipmentStatusChanged"]);

const shipmentStatusApi = new ShipmentStatusApi(undefined, "");
const props = withDefaults(defineProps<IProps>(), {
  shipmentId: 0,
  exportFlowStatus: 0,
});

const loading = ref(false);
const showDialog = ref(false);

const onConfirmedReturnReasons = async (value: ReturnShipmentReasonsForm) => {
  loading.value = true;
  let success = false;
  try {
    await shipmentStatusApi.setReturnStatus({
      shipmentId: props.shipmentId,
      returnReasonFirstId: value.shipmentReasonFirst,
      returnReasonSecondId: value.shipmentReasonSecond,
    });

    success = true;
    showDialog.value = false;
  } catch {
    emitError("Something went wrong while returning the shipment.");
  }

  loading.value = false;
  emits("shipmentStatusChanged", { success } as Successful);
};

const cssClasses = computed(() => {
  return instance?.proxy?.$vnode?.data?.staticClass ?? "";
});
</script>
