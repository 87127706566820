<template>
  <v-dialog :value="value" persistent max-width="600px">
    <v-card>
      <v-toolbar color="yellow darken-3" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-stepper alt-labels :value="confirmLoadingStep">
            <v-stepper-header>
              <v-stepper-step
                :step="assignedToEmployeeStep"
                :color="flowSteps[checkShipmentStep][3]"
                complete
              >
                <span class="text-caption text-center flowExample">{{
                  checkShipmentStep
                }}</span>
                <span class="text-caption text-center">{{
                  flowSteps[checkShipmentStep][4]
                }}</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :step="checkShipmentStep"
                :color="flowSteps[checkShipmentStep][3]"
                complete
              >
                <span class="text-caption text-center">Loaded</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :step="valueBreaksStep"
                :color="flowSteps[valueBreaksStep][3]"
                complete
              >
                <span class="text-caption text-center flowExample">{{
                  valueBreaksStep
                }}</span>
                <span class="text-caption text-center">{{
                  flowSteps[valueBreaksStep][4]
                }}</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :step="confirmLoadingStep"
                :color="flowSteps[confirmLoadingStep][3]"
                complete
              >
                <span class="text-caption text-center flowExample">{{
                  confirmLoadingStep
                }}</span>
                <span class="text-caption text-center">{{
                  flowSteps[confirmLoadingStep][4]
                }}</span>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-container>

        <div class="mx-5">
          <span class="black--text">
            Click CONFIRM to confirm that the shipment data is checked. If the
            shipment is loaded then the status will change to '4'.
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          color="light-blue darken-1"
          text
          :loading="isLoading"
          @click="emits('confirm')"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
interface IProps {
  value?: boolean | null | undefined;
  isLoading?: boolean | null | undefined;
  title?: string | null | undefined;
  confirmButtonText?: string | null | undefined;
  closeButtonText?: string | null | undefined;
}

const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Confirm shipments",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});
const emits = defineEmits(["errorOccured", "input", "close", "confirm"]);

const assignedToEmployeeStep = ref(1);
const checkShipmentStep = ref(2);
const valueBreaksStep = ref(3);
const confirmLoadingStep = ref(4);

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

onBeforeMount(() => {
  customsFlowStepsOnBeforeMount();
});
</script>
