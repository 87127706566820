<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>
      <div class="white">
        <v-card-text>
          <ValidationObserver ref="hawbObserver" v-slot="{ valid }" slim>
            <v-form :disabled="loading">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="HAWB numbers"
                tag="div"
                class="mb-4"
              >
                <TextAreaCollection
                  v-model="form.hawbNumbers"
                  label="HAWB numbers"
                  :error-messages="errors"
                  hint="Place each parcel number on a new line"
                  @input="hasValidHawbNumbers = false"
                ></TextAreaCollection>
                <div v-if="invalidHawbNumbers.length" class="error--text">
                  The following HAWB numbers are invalid:
                  {{ invalidHawbNumbers.join(", ") }}
                </div>
              </ValidationProvider>

              <v-btn
                :disabled="!valid"
                :loading="!hasValidHawbNumbers && loading"
                :color="hasValidHawbNumbers ? 'success' : ''"
                :depressed="hasValidHawbNumbers"
                @click="validateHawbNumbers"
              >
                Validate
                <v-icon v-if="hasValidHawbNumbers" class="ps-1"
                  >mdi-check-circle-outline
                </v-icon>
              </v-btn>
            </v-form>
          </ValidationObserver>

          <v-divider class="my-8"></v-divider>

          <ValidationObserver
            v-if="hasValidHawbNumbers"
            v-slot="{ valid }"
            ref="createObserver"
            slim
            tag="div"
          >
            <div class="even-columns">
              <v-form :disabled="loading">
                <span class="t2-creation-form__title">T2</span>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|mrn"
                  name="Transit MRN"
                  mode="eager"
                >
                  <v-text-field
                    v-model="form.transitMrn"
                    type="text"
                    label="Transit MRN"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Art"
                >
                  <v-select
                    v-model="form.type"
                    :items="['P', 'R']"
                    label="Art"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Anzahl"
                >
                  <v-text-field
                    v-model.number="form.amount"
                    type="number"
                    label="Anzahl"
                    min="0"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Kenzeichen"
                >
                  <v-text-field
                    v-model="form.registration"
                    label="Kenzeichen"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Staat"
                >
                  <v-autocomplete
                    v-model="form.country"
                    :items="customsCountries"
                    item-text="description"
                    item-value="code"
                    label="Staat"
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Zeichen"
                >
                  <v-textarea
                    v-model="form.sign"
                    label="Zeichen"
                    outlined
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-form>

              <v-form :disabled="loading">
                <span class="t2-creation-form__title">Final WAB</span>

                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Final WAB number"
                >
                  <v-text-field
                    v-model="form.finalWabNumber"
                    label="Final WAB number"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-form>
            </div>

            <v-divider class="my-4"></v-divider>

            <v-btn :disabled="!valid" :loading="loading" @click="createT2">
              Create
            </v-btn>
          </ValidationObserver>
        </v-card-text>
      </div>
    </v-card>
    <v-snackbar v-model="showSnackbar" color="success"
      >Successfully created the T2 with the given information.</v-snackbar
    >
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import TextAreaCollection from "./editors/TextAreaCollection.vue";
import {
  CustomsCountryViewModel,
  CustomsT2CreationApi,
  CustomsCountriesApi,
} from "@/openapi";
import { emitError } from "@/event-bus";
import { ValidationObserver } from "vee-validate";

interface T2CreationForm {
  hawbNumbers: string[];
  transitMrn: string;
  type: string;
  amount: number;
  registration: string;
  country: string;
  sign: string;
  finalWabNumber: string;
}

const customsT2CreationApi = new CustomsT2CreationApi(undefined, "");
const customsCountryApi = new CustomsCountriesApi(undefined, "");

const props = defineProps({
  color: {
    type: String,
    default: "",
  },
});

const initializeForm = () => {
  return {
    hawbNumbers: [],
    transitMrn: "",
    type: "",
    amount: 0,
    registration: "",
    country: "",
    sign: "",
    finalWabNumber: "",
  };
};

const hawbObserver = ref<InstanceType<typeof ValidationObserver>>();
const createObserver = ref<InstanceType<typeof ValidationObserver>>();

const customsCountries = ref<CustomsCountryViewModel[] | null>([]);

const loading = ref(false);

const hasValidHawbNumbers = ref(false);
const invalidHawbNumbers = ref<string[]>([]);

const form = ref(initializeForm());

const showSnackbar = ref(false);

onBeforeMount(async () => {
  await getCustomsCountries();
});

const getCustomsCountries = async () => {
  customsCountries.value = [];
  try {
    var response = await customsCountryApi.getCustomsCountries();
    customsCountries.value = response.data;
    customsCountries.value!.forEach(function (item) {
      item.description = item.code + " - " + item.description;
    });
  } catch {
    emitError(
      "Something went wrong while retrieving the country options for the dropdown",
    );
  }
};

const validateHawbNumbers = async () => {
  loading.value = true;
  try {
    const response = await customsT2CreationApi.validateHawbNumbers(
      form.value.hawbNumbers,
    );

    invalidHawbNumbers.value = response.data.invalidHawbNumbers ?? [];
    hasValidHawbNumbers.value = !invalidHawbNumbers.value.length;
  } catch {
    emitError(
      "Something went wrong while trying to validte the enter HAWB numbers",
    );
  }

  loading.value = false;
};

const createT2 = async () => {
  loading.value = true;

  try {
    await customsT2CreationApi.createT2({
      ...form.value,
    });

    resetForm();
    loading.value = false;
    showSnackbar.value = true;
  } catch (e: any) {
    let errorMessages: string[] = [
      "Something went wrong while trying to create the T2",
    ];

    if (e?.response?.data?.errorMessages?.length) {
      errorMessages = e.response.data.errorMessages;
    }
    emitError(...errorMessages);
  }

  loading.value = false;
};

const resetForm = () => {
  form.value = initializeForm();
  hawbObserver.value?.reset();
  createObserver.value?.reset();
  hasValidHawbNumbers.value = false;
};
</script>
