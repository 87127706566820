<template>
  <div
    class="container fill-height container--fluid email-template"
    :class="{ disabled: isLoading }"
  >
    <div class="row">
      <div class="col col-7 email-template__selection">
        <div class="arrow-pointer"></div>
        <v-card>
          <v-toolbar flat class="grey darken-3" color="white" dark>
            <v-toolbar-title class="grow">Email template</v-toolbar-title>
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
          </v-toolbar>
          <v-tabs v-model="selectedEmailType" vertical>
            <v-tab class="justify-start">
              <v-icon left> mdi-crown </v-icon>
              Master template
            </v-tab>
            <v-tab
              v-for="(type, index) in emailTypes"
              :key="type.id"
              class="justify-start"
            >
              <v-icon left> {{ type.icon }} </v-icon>
              {{ type.name }}
            </v-tab>

            <v-tab-item class="grey lighten-4 pa-5">
              <MasterTemplates
                :current-tab="selectedEmailType"
                @getSelectedEmailTemplate="setSelectedEmailTemplate"
                @getLoadingStatus="setLoadingStatus"
                @pushSuccessMessage="showSuccessMessage = true"
              ></MasterTemplates>
            </v-tab-item>

            <v-tab-item
              v-for="(template, index) in emailTypes"
              :key="template.id"
              class="grey lighten-4 pa-5"
            >
              <DetailTemplates
                v-if="selectedEmailType === index + 1"
                :key="selectedEmailType"
                :current-tab="selectedEmailType"
                :template-data="emailTypes[selectedEmailType - 1]"
                @getSelectedEmailTemplate="setSelectedEmailTemplate"
                @getLoadingStatus="setLoadingStatus"
                @pushSuccessMessage="showSuccessMessage = true"
              ></DetailTemplates>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
      <div class="col col-5">
        <EmailPreview
          v-if="selectedEmailType !== 1"
          :selected-email-template="selectedEmailTemplate"
        ></EmailPreview>
        <EmailPreviewAvis
          v-else
          :selected-email-template="selectedEmailTemplate"
        >
        </EmailPreviewAvis>
      </div>
    </div>
    <v-snackbar v-model="showSuccessMessage" :timeout="5000">
      Template has been added

      <template #action="{ attrs }">
        <v-btn
          color="success"
          v-bind="attrs"
          @click="showSuccessMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup lang="ts">
import MasterTemplates from "./components/MasterTemplates.vue";
import DetailTemplates from "./components/DetailTemplates.vue";
import EmailPreview from "./components/EmailPreview.vue";
import EmailPreviewAvis from "./components/EmailPreviewAvis.vue";
import {
  EmailTemplateApi,
  EmailTemplateSimple,
  EmailTemplateType,
} from "@/openapi";
import { emitError } from "@/event-bus";
import { onBeforeMount, ref } from "vue";
import { EnrichedEmailTemplate } from "@/types/email";

const api = new EmailTemplateApi(undefined, "");

const isLoading = ref(false);
const selectedEmailTemplate = ref<EnrichedEmailTemplate>();
const selectedEmailType = ref(0);
const emailTemplates = ref<EmailTemplateSimple[]>([]);
const emailTypes = ref<EmailTemplateType[]>([]);
const showSuccessMessage = ref(false);

const setSelectedEmailTemplate = (item: EnrichedEmailTemplate) => {
  selectedEmailTemplate.value = item;
};

const setLoadingStatus = (status: boolean) => {
  isLoading.value = status;
};

onBeforeMount(async () => {
  await getEmailTemplates();
  await getEmailTemplateTypes();
});

const getEmailTemplates = async () => {
  try {
    const response = await api.getEmailTemplates(undefined, true);
    emailTemplates.value = response.data.emailTemplates ?? [];
  } catch (e) {
    emitError("Something went wrong");
  }
};

const getEmailTemplateTypes = async () => {
  try {
    const response = await api.getEmailTemplateTypes();
    emailTypes.value = response.data.emailTemplateTypes ?? [];
  } catch (e) {
    emitError("Something went wrong");
  }
};
</script>
