<template>
  <div>
    <v-flex class="px-3" white>
      <v-container fluid>
        <v-row class="align-center">
          <v-icon>mdi-filter</v-icon>
          <v-col md="1">
            <v-checkbox
              v-model="manifestFilter.showAllManifests"
              class="mt-0 d-inline-block"
              hide-details
              @change="onManifestFilterEntered()"
            >
              <span slot="label" class="primary--text">Show all manifests</span>
            </v-checkbox>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="manifestFilter.manifestName"
              clearable
              class="mt-0 pt-0 d-inline-block"
              hide-details
              @keyup.enter="onManifestFilterEntered()"
              @click:clear="
                (manifestFilter.manifestName = ''), onManifestFilterEntered()
              "
            >
              <span slot="label" class="primary--text"
                >Enter manifest number</span
              >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="true"
      item-key="id"
      height="calc(100vh - 345px)"
      fixed-header
      fixed-footer
      show-expand
      class="elevation-1"
      :loading="isLoading"
      @item-expanded="onExpandedItem"
    >
      <template #item.confirmFlow="{ item }">
        <v-btn
          :disabled="item.status === 3 || isLoading"
          small
          color="primary"
          class="v-btn--flexible w-100"
          @click="onConfirmFlowChange(item)"
          >{{ getConfirmFlowButtonName(item.status) }}</v-btn
        >
      </template>

      <template #item.tempManifest="{ item }">
        <v-btn
          v-if="item.status >= 1 && item.status <= 3"
          :disabled="isLoading"
          :href="
            '/api/customsManifest/downloadTempManifest?manifestId=' + item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center w-100"
          ><v-icon left> mdi-download </v-icon>TEMP MANIFEST</v-btn
        >
      </template>

      <template #item.tempManifestResponse="{ item }">
        <v-btn
          v-if="
            item.status >= 1 &&
            item.status <= 3 &&
            !item.hasTempResponseFile &&
            !item.tempNoResponse.active
          "
          :disabled="isLoading"
          small
          color="info"
          class="text-center w-100"
          @click="uploadResponse(item, 1)"
          ><v-icon left> mdi-upload </v-icon>UPLOAD RESPONSE</v-btn
        >
        <v-btn
          v-else-if="
            item.status >= 1 && item.status <= 3 && item.hasTempResponseFile
          "
          :disabled="isLoading"
          :href="
            '/api/customsManifest/downloadTempManifestResponse?manifestId=' +
            item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center w-100"
          ><v-icon left> mdi-download </v-icon>DOWNLOAD RESPONSE</v-btn
        >
      </template>

      <template #item.tempManifestNoResponse="{ item }">
        <v-btn
          v-if="
            item.status >= 1 &&
            item.status <= 3 &&
            !item.hasTempResponseFile &&
            !item.tempNoResponse.active
          "
          :disabled="isLoading"
          small
          color="error"
          class="v-btn--flexible text-center w-100"
          @click="setNoResponse(item, 1)"
          ><v-icon left> mdi-file-cancel </v-icon>NO RESPONSE</v-btn
        >
        <span v-else-if="item.tempNoResponse.active">
          <strong>Name:</strong> <br />{{ item.tempNoResponse.employee }} <br />
          <strong>Date:</strong> <br />{{
            moment(item.tempNoResponse.activationDate).format(
              "DD-MM-YYYY HH:mm:ss",
            )
          }}
        </span>
      </template>

      <template #item.finalManifest="{ item }">
        <v-btn
          v-if="item.status === 3"
          :disabled="isLoading"
          :href="
            '/api/customsManifest/downloadFinalManifest?manifestId=' + item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center w-100"
        >
          <v-icon left> mdi-download </v-icon>FINAL MANIFEST</v-btn
        >
      </template>

      <template #item.finalManifestResponse="{ item }">
        <v-btn
          v-if="
            item.status === 3 &&
            !item.hasFinalResponseFile &&
            !item.finalNoResponse.active
          "
          :disabled="isLoading"
          small
          color="info"
          class="v-btn--flexible text-center w-100"
          @click="uploadResponse(item, 2)"
          ><v-icon left> mdi-upload </v-icon>UPLOAD RESPONSE</v-btn
        >
        <v-btn
          v-if="item.status === 3 && item.hasFinalResponseFile"
          :disabled="isLoading"
          :href="
            '/api/customsManifest/downloadFinalManifestResponse?manifestId=' +
            item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center w-100"
          ><v-icon left> mdi-download </v-icon>DOWNLOAD RESPONSE</v-btn
        >
      </template>

      <template #item.finalManifestNoResponse="{ item }">
        <v-btn
          v-if="
            item.status === 3 &&
            !item.hasFinalResponseFile &&
            !item.finalNoResponse.active
          "
          :disabled="isLoading"
          small
          color="error"
          class="v-btn--flexible text-center w-100"
          @click="setNoResponse(item, 2)"
          ><v-icon left> mdi-file-cancel </v-icon>NO RESPONSE</v-btn
        >
        <span v-else-if="item.finalNoResponse.active">
          <strong>Name:</strong> <br />{{ item.finalNoResponse.employee }}
          <br />
          <strong>Date:</strong> <br />{{
            moment(item.finalNoResponse.activationDate).format(
              "DD-MM-YYYY                    HH:mm:ss",
            )
          }}
        </span>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-flex>
            <v-container fluid>
              <v-row class="light-blue lighten-5">
                <v-col cols="12">
                  <v-flex class="px-3" white>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-data-table
                            :headers="customsHandlingItemsHeaders"
                            :items="customsHandlingItems"
                            :server-items-length="totalShipmentItems"
                            :options.sync="shipmentOptions"
                            :footer-props="footerOptions"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            item-key="id"
                            dense
                            fixed-header
                            fixed-footer
                            show-expand
                            class="elevation-1"
                            :loading="isLoading"
                          >
                            <template #body.prepend>
                              <tr>
                                <td>
                                  <v-icon small>mdi-filter</v-icon>
                                </td>
                                <td>
                                  <v-select
                                    v-model="filter.step"
                                    :items="statusFilter"
                                    clearable
                                    chips
                                    dense
                                    single-line
                                    @change="filterSearchEntered"
                                  ></v-select>
                                </td>
                                <td>
                                  <v-select
                                    v-model="filter.shipmentStatusDescription"
                                    :items="shipmentStatusFilter"
                                    clearable
                                    dense
                                    single-line
                                    @change="filterSearchEntered"
                                  ></v-select>
                                </td>
                                <td>
                                  <FilterParcels
                                    v-model="filter.parcels"
                                    @input="filterSearchEntered"
                                  ></FilterParcels>
                                </td>
                                <td>
                                  <v-text-field
                                    v-model="filter.exportMrn"
                                    clearable
                                    dense
                                    @keyup.enter="filterSearchEntered"
                                    @click:clear="
                                      (filter.exportMrn = ''),
                                        filterSearchEntered()
                                    "
                                  ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <v-autocomplete
                                    v-model="filter.shipperCountry"
                                    :items="dataStore.originCountries"
                                    item-key="code"
                                    item-text="code"
                                    clearable
                                    dense
                                    single-line
                                    @change="filterSearchEntered"
                                  ></v-autocomplete>
                                </td>
                                <td>
                                  <v-autocomplete
                                    v-model="filter.consigneeCountry"
                                    :items="dataStore.originCountries"
                                    item-key="code"
                                    item-text="code"
                                    clearable
                                    dense
                                    single-line
                                    @change="filterSearchEntered"
                                  ></v-autocomplete>
                                </td>
                              </tr>
                            </template>

                            <template #item.exportFlowStatus="{ item }">
                              <v-avatar
                                class="flow-status-avatar"
                                size="22"
                                :color="flowSteps[item.exportFlowStatus][3]"
                              >
                                {{ item.exportFlowStatus }}
                              </v-avatar>
                            </template>

                            <template #item.exportMrn="{ item }">
                              <v-icon
                                v-if="!item.isMaster && item.exportMrn == null"
                                :disabled="!isMrnEditable"
                                class="error--text"
                                @click="openDialogShipmentAddMrn(item)"
                              >
                                mdi-plus-circle
                              </v-icon>
                              <span v-else>
                                {{
                                  item.isMaster
                                    ? item.exportMrns.join(",")
                                    : item.exportMrn
                                }}
                              </span>
                            </template>

                            <template #item.shipper="{ item }">
                              <v-row justify="center">
                                <v-col>
                                  {{ item.shipper.name }}<br />
                                  {{ item.shipper.address }}<br />
                                  {{ item.shipper.zipcode }}
                                  {{ item.shipper.city }}<br />
                                  {{ item.shipper.country }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.consignee="{ item }">
                              <v-row justify="center">
                                <v-col>
                                  {{ item.consignee.name }}<br />
                                  {{ item.consignee.address }}<br />
                                  {{ item.consignee.zipcode }}
                                  {{ item.consignee.city }}<br />
                                  {{ item.consignee.country }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.value="{ item }">
                              <v-row>
                                <v-col>
                                  {{ item.value.toFixed(2) }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.articles="{ item }">
                              <v-row
                                v-for="article in item.articles.slice(0, 4)"
                                no-gutters
                              >
                                <v-col cols="9">
                                  <span class="articleDescription">{{
                                    article.description
                                  }}</span>
                                </v-col>
                                <v-col>
                                  <span class="articleDescription pl-4">{{
                                    article.rcTarif
                                  }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="item.articles.length > 4" no-gutters>
                                <v-col cols="9">
                                  <span class="articleDescription"
                                    >more...</span
                                  >
                                </v-col>
                              </v-row>
                            </template>

                            <template #expanded-item="{ headers, item }">
                              <td
                                v-if="currentShipmentDetail"
                                :colspan="headers.length"
                              >
                                <v-flex class="pa-3 pb-5">
                                  <v-container fluid>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-card>
                                          <v-card-title
                                            >Shipment status</v-card-title
                                          >
                                          <ShipmentPaperwork
                                            class="shipment-paperwork"
                                            :shipment-id="
                                              currentShipmentDetail.id
                                            "
                                          ></ShipmentPaperwork>
                                          <CustomsExportShipmentDetailViewNew
                                            :current-handling-item="
                                              currentShipmentDetail
                                            "
                                          />

                                          <div class="ms-6">
                                            <HoldShipmentButton
                                              :shipment-id="
                                                currentShipmentDetail.id
                                              "
                                              :export-flow-status="
                                                currentShipmentDetail.exportFlowStatus
                                              "
                                              class="ml-3 mb-5"
                                              @shipmentStatusChanged="
                                                onShipmentStatusChanged
                                              "
                                            >
                                            </HoldShipmentButton>

                                            <ReturnShipmentButton
                                              :shipment-id="
                                                currentShipmentDetail.id
                                              "
                                              class="ml-3 mb-5"
                                              @shipmentStatusChanged="
                                                onShipmentStatusChanged
                                              "
                                            >
                                            </ReturnShipmentButton>

                                            <CustomsInspectionButton
                                              :shipment-id="
                                                currentShipmentDetail.id
                                              "
                                              class="ml-3 mb-5"
                                              @shipmentStatusChanged="
                                                onShipmentStatusChanged
                                              "
                                            >
                                            </CustomsInspectionButton>
                                          </div>

                                          <ShipmentStatusFlow
                                            :shipment-id="
                                              currentShipmentDetail.id
                                            "
                                            :active-status="
                                              currentShipmentDetail.exportFlowStatus
                                            "
                                          ></ShipmentStatusFlow>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>

    <AddMrnDialog
      v-if="currentHandlingItem"
      v-model="showDialogAddMrn"
      :parcel-number="currentHandlingItem.hawb"
      :is-loading="dialogAddMrnLoading"
      @confirm="onMrnEntered"
    ></AddMrnDialog>
    <UploadFileDialog
      v-model="showDialogUploadFile"
      :is-loading="dialogUploadFileLoading"
      @confirm="onFileUploaded"
      @cancel="onFileUploadCanceled"
    ></UploadFileDialog>

    <ConfirmDialog
      v-model="showConfirmAcceptanceFromCustomsDialog"
      :is-loading="dialogConfirmAcceptanceFromCustomsLoading"
      title="bestätigen"
      confirm-button-text="Ja"
      close-button-text="Nein"
      @confirm="onConfirmAcceptanceFromCustoms"
    >
      Dieser Schritt bestätigt unwiderruflich das Manifest und gibt alle jetzt
      auf diesem Manifest enthaltenen Sendungen frei. Bist Du sicher, dass Du
      fortfahren möchtest?</ConfirmDialog
    >
  </div>
</template>

<script setup lang="ts">
interface IProps {
  color?: string;
}
import { ref, computed, watch, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import ConfirmShipmentReasonDialog from "@/components/dialogs/ConfirmShipmentReasonDialog.vue";
import { getStatuses } from "@/helpers/statusHelper";
import UploadFileDialog from "@/components/dialogs/UploadFileDialog.vue";
import {
  CustomsManifestApi,
  CustomsManifestItemViewModel,
  CustomsShipmentItemViewModel,
  ManifestStatusUpdate,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import { UploadFileForm } from "@/components/dialogs/UploadFileDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useDataStore } from "@/stores/data-store";
import moment from "moment";

enum UploadResponseAction {
  Temp = 1,
  Final = 2,
}
const props = withDefaults(defineProps<IProps>(), {
  color: "",
});
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const customsManifestApi = new CustomsManifestApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const shipmentOptions = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const manifestFilter = ref({
  manifestName: "",
  showAllManifests: false,
});

const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const totalShipmentItems = ref(0);
const expanded = ref<CustomsShipmentItemViewModel[]>([]);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const items = ref<CustomsManifestItemViewModel[]>([]);
const currentManifestItem = ref<CustomsManifestItemViewModel | null>(null);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const customsHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);
const selectedHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);

const showDialogAddMrn = ref(false);
const dialogAddMrnLoading = ref(false);

const showDialogUploadFile = ref(false);
const dialogUploadFileLoading = ref(false);
const currentManifestItemFileUpload = ref<CustomsManifestItemViewModel | null>(
  null,
);
const currentUploadResponseAction = ref<UploadResponseAction | null>(null);

const showConfirmAcceptanceFromCustomsDialog = ref(false);
const dialogConfirmAcceptanceFromCustomsLoading = ref(false);
const confirmAcceptanceFromCustomsItem =
  ref<CustomsManifestItemViewModel | null>(null);

const headers = ref([
  {
    text: "Manifest-No.",
    value: "manifestNumber",
    align: "start",
    sortable: false,
  },
  {
    text: "Number of shipments",
    value: "numberOfShipments",
    align: "start",
    sortable: false,
  },
  {
    text: "Status",
    value: "shipmentStatus",
    align: "start",
    sortable: false,
  },
  {
    text: "Manifest flow",
    value: "confirmFlow",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Download temp manifest",
    value: "tempManifest",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Upload/download temp manifest response",
    value: "tempManifestResponse",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Temp manifest no response",
    value: "tempManifestNoResponse",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Download final manifest",
    value: "finalManifest",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Upload/download final manifest response",
    value: "finalManifestResponse",
    align: "left",
    width: "10%",
    sortable: false,
  },
  {
    text: "Final manifest no response",
    value: "finalManifestNoResponse",
    align: "left",
    width: "10%",
    sortable: false,
  },
]);

const customsHandlingItemsHeaders = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);
const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
  mrnnumber: (value: string) => {
    const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
    return pattern.test(value) || "Invalid MRN number";
  },
  email: (value: string) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail";
  },
};

onBeforeMount(() => {
  customsFlowStepsOnBeforeMount();
  dataStore.fetchOriginCountries();
  statusFilter.value = getStatuses();

  emits("PageInfoReceived", [
    {
      callback: reloadManifests,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ]);
});

const loadManifests = async (page: number) => {
  isLoading.value = true;
  try {
    var response = await customsManifestApi.getManifests(
      page,
      options.value.itemsPerPage,
      manifestFilter.value.manifestName ?? "",
      manifestFilter.value.showAllManifests,
    );
    items.value = response.data.items ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the manifests");
  }
  isLoading.value = false;
};

const reloadManifests = async () => {
  options.value.page == 1
    ? await loadManifests(options.value.page)
    : (options.value.page = 1);
};

const loadShipmentItems = async (manifestId: number, page: number) => {
  isLoading.value = true;
  try {
    const response = await customsManifestApi.getShipments({
      manifestId,
      page,
      itemsPerPage: shipmentOptions.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });
    customsHandlingItems.value = response.data.shipments ?? [];
    totalShipmentItems.value = response.data.totalAmount ?? 0;
    currentManifestItem.value!.numberOfShipments = response.data.totalAmount;
    shipmentOptions.value.page = page;
  } catch (error) {
    displayError(
      "Something went wrong while retrieving the corresponding shipments.",
    );
  }
  isLoading.value = false;
};

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadManifests(newVal.page);
    }, 250);
  },
);

const timeoutShipmentsDelay = ref(0);
watch(
  () => shipmentOptions.value,
  (newVal) => {
    clearTimeout(timeoutShipmentsDelay.value);
    timeoutShipmentsDelay.value = setTimeout(() => {
      loadShipmentItems(
        currentManifestItem.value!.id!,
        shipmentOptions.value.page,
      );
    }, 250);
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value!.id!)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);

const onExpandedItem = ({
  item,
  value,
}: {
  item: CustomsManifestItemViewModel;
  value: boolean;
}) => {
  if (value) {
    currentManifestItem.value = item;
    loadShipmentItems(item!.id!, shipmentOptions.value.page);
  } else {
    currentManifestItem.value = null;
    customsHandlingItems.value = [];
  }
};

const openDialogShipmentAddMrn = (item: CustomsShipmentItemViewModel) => {
  currentHandlingItem.value = item;
  showDialogAddMrn.value = true;
};

const filterSearchEntered = () => {
  loadShipmentItems(currentManifestItem.value!.id!, shipmentOptions.value.page);
};

const closeAllDialogs = () => {
  showDialogAddMrn.value = false;
};

const onConfirmFlowChange = async (item: CustomsManifestItemViewModel) => {
  if (item.status === 2) {
    // Force the user to confirm the last status change
    showConfirmAcceptanceFromCustomsDialog.value = true;
    confirmAcceptanceFromCustomsItem.value = item;
    return;
  }
  await changeManifestStatus(item);
};

const changeManifestStatus = async (item: CustomsManifestItemViewModel) => {
  try {
    isLoading.value = true;
    await customsManifestApi.setManifestStatus({
      manifestId: item.id,
      manifestStatus: item.status! + 1,
    } as ManifestStatusUpdate);

    item.status!++;
    if (currentManifestItem.value?.id === item.id) {
      customsHandlingItems.value = [];
      await loadShipmentItems(item.id!, 1);
    }
  } catch {
    displayError(
      "Something went wrong while changing the status of the manifest.",
    );
  }
  isLoading.value = false;
};

const getConfirmFlowButtonName = (status: number) => {
  switch (status) {
    case 0:
      return "CREATE MANIFEST";
    case 1:
      return "CONFIRM MANIFEST IS SENT TO CUSTOMS";
    case 2:
    case 3:
      return "CONFIRM ACCEPTANCE FROM CUSTOMS";
    default:
      return "ERROR";
  }
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const isMrnEditable = computed(() => {
  return (
    currentManifestItem.value != null && currentManifestItem.value.status! < 2
  );
});

const onMrnEntered = async ({ mrn }: { mrn: string }) => {
  dialogAddMrnLoading.value = true;
  try {
    await customsManifestApi.manifestSetMrn({
      mrn: mrn,
      shipmentId: currentHandlingItem.value!.id,
    });

    await loadShipmentItems(currentManifestItem.value!.id!, options.value.page);
  } catch {
    displayError("Something went wrong while setting the MRN of the shipment");
  }

  dialogAddMrnLoading.value = false;
  showDialogAddMrn.value = false;
};

const onManifestFilterEntered = () => {
  reloadManifests();
};

const setNoResponse = async (
  item: CustomsManifestItemViewModel,
  uploadResponseAction: UploadResponseAction,
) => {
  isLoading.value = true;
  try {
    if (uploadResponseAction == UploadResponseAction.Temp) {
      await customsManifestApi.setTempManifestNoResponse(item.id);
      await loadManifests(options.value.page);
    } else if (uploadResponseAction == UploadResponseAction.Final) {
      await customsManifestApi.setFinalManifestNoResponse(item.id);
      await loadManifests(options.value.page);
    } else {
      displayError(
        "Something went wrong while uploading the manifest response.",
      );
      return;
    }
  } catch {
    displayError("Something went wrong while uploading the manifest response.");
  }
  isLoading.value = false;
};

const uploadResponse = (
  item: CustomsManifestItemViewModel,
  uploadResponseAction: UploadResponseAction,
) => {
  currentManifestItemFileUpload.value = item;
  currentUploadResponseAction.value = uploadResponseAction;
  showDialogUploadFile.value = true;
};

const onFileUploaded = async ({ files }: UploadFileForm) => {
  dialogUploadFileLoading.value = true;

  try {
    if (currentUploadResponseAction.value == UploadResponseAction.Temp) {
      await customsManifestApi.uploadTempManifestResponse(
        currentManifestItemFileUpload.value?.id,
        files![0],
      );
      await loadManifests(options.value.page);
      showDialogUploadFile.value = false;
    } else if (
      currentUploadResponseAction.value == UploadResponseAction.Final
    ) {
      await customsManifestApi.uploadFinalManifestResponse(
        currentManifestItemFileUpload.value?.id,
        files![0],
      );
      await loadManifests(options.value.page);
      showDialogUploadFile.value = false;
    } else {
      displayError(
        "Something went wrong while uploading the manifest response.",
      );
    }
  } catch {
    displayError("Something went wrong while uploading the manifest response.");
  }

  dialogUploadFileLoading.value = false;
};

const onFileUploadCanceled = () => {
  currentManifestItemFileUpload.value = null;
  currentUploadResponseAction.value = null;
};

const onConfirmAcceptanceFromCustoms = async () => {
  dialogConfirmAcceptanceFromCustomsLoading.value = true;
  await changeManifestStatus(confirmAcceptanceFromCustomsItem.value!);
  dialogConfirmAcceptanceFromCustomsLoading.value = false;
  confirmAcceptanceFromCustomsItem.value = null;
  showConfirmAcceptanceFromCustomsDialog.value = false;
};

const onShipmentStatusChanged = ({ success }: any) => {
  if (success) {
    selectedHandlingItems.value = [];
    loadShipmentItems(currentManifestItem.value!.id!, options.value.page);
  }
};
</script>
