<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>

      <template>
        <v-flex class="px-3" white>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <span class="font-weight-bold">Manage shipments</span>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="8">
                      <v-file-input
                        v-model="uploadExcelLTSFile"
                        outlined
                        dense
                        label="Upload LTS Excel file"
                        placeholder="Please upload a valid Excel LTS file"
                      ></v-file-input>
                    </v-col>
                    <v-col>
                      <v-btn
                        color="primary"
                        outlined
                        :disabled="!uploadExcelLTSFile"
                        :loading="loadingReadExcelLts"
                        @click="readExcelLTS()"
                      >
                        Process file
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="3">
                    <v-row>
                      <v-btn
                        color="primary"
                        :loading="loadingAssignShipmentsToEmployee"
                        :disabled="
                          !selectedHandlingItems.length || !selectedEmployee
                        "
                        @click="assignShipmentsToEmployee()"
                      >
                        Assign shipments to
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-autocomplete
                        v-model="selectedEmployee"
                        :items="employees"
                        item-text="name"
                        item-value="id"
                        return-object
                        label="Employee"
                        clearable
                      ></v-autocomplete>
                    </v-row>
                  </v-col>

                  <v-col cols="2"></v-col>

                  <v-col cols="2">
                    <v-row>
                      <v-btn
                        color="primary"
                        :disabled="
                          !selectedHandlingItems.length || !selectedStatus
                        "
                        :loading="loadingChangeStatusOfSelected"
                        @click="saveStatusToSelectedShipments()"
                      >
                        Change status to
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-select
                        v-model.number="selectedStatus"
                        :items="statusFilter"
                        clearable
                        chips
                        dense
                        single-line
                      ></v-select>
                    </v-row>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col>
                    <v-row>
                      <v-btn
                        color="primary"
                        @click="showShipmentValueChangeDialog = true"
                        >Export change of values</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="2"></v-col>
              <v-col cols="2">
                <v-select
                  v-model="filter.loadedConfirmed"
                  :items="loadedConfirmedFilter"
                  class="primary--text"
                  :value="selectedLoadedConfirmed"
                  dense
                  solo
                  @change="onFilterSelected"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="filter.showAssigned"
                  class="mt-0 ml-4"
                  @change="onFilterSelected"
                >
                  <span slot="label" class="primary--text">Show assigned</span>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-flex>

        <v-data-table
          v-model="selectedHandlingItems"
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="id"
          height="calc(100vh - 430px)"
          dense
          fixed-header
          fixed-footer
          show-expand
          show-select
          class="elevation-1"
          :loading="isLoading"
          @input="selectedHandlingItems"
        >
          <template #body.prepend>
            <tr>
              <td><v-icon small>mdi-filter</v-icon></td>
              <td></td>
              <td></td>
              <td>
                <v-autocomplete
                  v-model="filter.employee"
                  :items="employees"
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  @change="onFilterSelected"
                ></v-autocomplete>
              </td>
              <td>
                <v-select
                  v-model="filter.step"
                  :items="statusFilter"
                  clearable
                  chips
                  dense
                  single-line
                  @change="onFilterSelected"
                ></v-select>
              </td>
              <td>
                <v-select
                  v-model="filter.shipmentLoaded"
                  :items="booleanFilter"
                  clearable
                  dense
                  single-line
                  @change="onFilterSelected"
                >
                </v-select>
              </td>
              <td>
                <v-select
                  v-model="filter.shipmentConfirmed"
                  :items="booleanFilter"
                  clearable
                  dense
                  single-line
                  @change="onFilterSelected"
                >
                </v-select>
              </td>
              <td>
                <v-select
                  v-model="filter.shipmentStatusDescription"
                  :items="shipmentStatusFilter"
                  clearable
                  dense
                  single-line
                  @change="onFilterSelected"
                ></v-select>
              </td>
              <td>
                <FilterParcels
                  v-model="filter.parcels"
                  @input="onFilterSelected"
                ></FilterParcels>
              </td>
              <td>
                <v-text-field
                  v-model="filter.exportMrn"
                  clearable
                  dense
                  @keyup.enter="onFilterSelected()"
                  @click:clear="(filter.exportMrn = ''), onFilterSelected()"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <v-autocomplete
                  v-model="filter.shipperCountry"
                  :items="dataStore.originCountries"
                  item-key="code"
                  item-text="code"
                  clearable
                  dense
                  single-line
                  @change="onFilterSelected"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  v-model="filter.consigneeCountry"
                  :items="dataStore.originCountries"
                  item-key="code"
                  item-text="code"
                  clearable
                  dense
                  single-line
                  @change="onFilterSelected"
                ></v-autocomplete>
              </td>
            </tr>
          </template>

          <template #item.exportFlowStatus="{ item }">
            <v-avatar
              class="flow-status-avatar"
              size="22"
              :color="flowSteps[item.exportFlowStatus][3]"
            >
              {{ item.exportFlowStatus }}
            </v-avatar>
          </template>

          <template #item.createDate="{ item }">
            <v-row>
              <v-col>
                {{
                  item.createDate
                    ? moment(item.createDate).format("YYYY-MM-DD HH:mm:ss")
                    : ""
                }}
              </v-col>
            </v-row>
          </template>

          <template #item.isLoaded="{ item }">
            <v-row justify="center">
              <v-col>
                <v-icon v-if="item.isLoaded == true"> mdi-check-bold </v-icon>
              </v-col>
            </v-row>
          </template>

          <template #item.isConfirmed="{ item }">
            <v-row justify="center">
              <v-col>
                <v-icon v-if="item.isConfirmed == true">
                  mdi-check-bold
                </v-icon>
              </v-col>
            </v-row>
          </template>

          <template #item.exportMrn="{ item }">
            <v-row justify="center">
              <v-col>
                {{
                  item.isMaster ? item.exportMrns.join(", ") : item.exportMrn
                }}
              </v-col>
            </v-row>
          </template>

          <template #item.shipper="{ item }">
            <v-row justify="center">
              <v-col>
                {{ item.shipper.name }}<br />
                {{ item.shipper.address }}<br />
                {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
                {{ item.shipper.country }}
              </v-col>
            </v-row>
          </template>

          <template #item.consignee="{ item }">
            <v-row justify="center">
              <v-col>
                {{ item.consignee.name }}<br />
                {{ item.consignee.address }}<br />
                {{ item.consignee.zipcode }} {{ item.consignee.city }}<br />
                {{ item.consignee.country }}
              </v-col>
            </v-row>
          </template>

          <template #item.value="{ item }">
            <v-row>
              <v-col> {{ item.value.toFixed(2) }} </v-col>
            </v-row>
          </template>

          <template #item.articles="{ item }">
            <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
              <v-col cols="9">
                <span class="articleDescription">{{
                  article.description
                }}</span>
              </v-col>
              <v-col>
                <span class="articleDescription pl-4">{{
                  article.rcTarif
                }}</span>
              </v-col>
              <v-row v-if="item.articles.length > 4" no-gutters>
                <v-col cols="9">
                  <span class="articleDescription">more...</span>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template #item.documents="{ item }">
            <v-row>
              <v-col>
                <UploadPaperworkButton
                  :shipment-id="item.id"
                  :parcel-number="item.hawb"
                ></UploadPaperworkButton>
              </v-col>
            </v-row>
          </template>

          <template #expanded-item="{ headers, item }">
            <td v-if="currentShipmentDetail" :colspan="headers.length">
              <v-flex class="pa-3 pb-5">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <span> Shipment status</span>
                        </v-card-title>
                        <ShipmentPaperwork
                          class="shipment-paperwork"
                          :shipment-id="currentShipmentDetail.id"
                        ></ShipmentPaperwork>
                        <CustomsExportShipmentDetailViewNew
                          v-if="currentShipmentDetail"
                          :admin-view="true"
                          :current-handling-item="currentShipmentDetail"
                          :readonly="
                            currentShipmentDetail.exportFlowStatus >=
                            confirmDocumentOrMRN
                          "
                          @updated-details="onShipmentDetailsUpdated"
                        />
                        <div class="ms-6">
                          <HoldShipmentButton
                            :shipment-id="currentShipmentDetail.id"
                            :export-flow-status="
                              currentShipmentDetail.exportFlowStatus
                            "
                            :disabled="
                              currentShipmentDetail.exportFlowStatus >=
                              confirmDocumentOrMRN
                            "
                            class="ml-3 mb-5"
                            @shipmentStatusChanged="onShipmentStatusChanged"
                          >
                          </HoldShipmentButton>

                          <ReturnShipmentButton
                            :shipment-id="currentShipmentDetail.id"
                            :disabled="
                              currentShipmentDetail.exportFlowStatus >=
                              confirmDocumentOrMRN
                            "
                            class="ml-3 mb-5"
                            @shipmentStatusChanged="onShipmentStatusChanged"
                          >
                          </ReturnShipmentButton>

                          <CustomsInspectionButton
                            :shipment-id="currentShipmentDetail.id"
                            :disabled="
                              currentShipmentDetail.exportFlowStatus >=
                              confirmDocumentOrMRN
                            "
                            class="ml-3 mb-5"
                            @shipmentStatusChanged="onShipmentStatusChanged"
                          >
                          </CustomsInspectionButton>
                        </div>
                        <ShipmentStatusFlow
                          :shipment-id="currentShipmentDetail.id"
                          :active-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                        ></ShipmentStatusFlow>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>
            </td>
          </template>
        </v-data-table>
      </template>
    </v-card>
    <v-snackbar v-model="saveSuccessful" timeout="4000" top color="success">
      Saved successful
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="saveSuccessful = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noEmployeeSelected" timeout="2000" top color="warning">
      No employee selected
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="noEmployeeSelected = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <ShipmentValueChangesExportDialog
      v-model="showShipmentValueChangeDialog"
    ></ShipmentValueChangesExportDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import ConfirmShipmentsDialog from "./dialogs/ConfirmShipmentsDialog.vue";
import UploadPaperworkButton from "./buttons/UploadPaperworkButton.vue";
import {
  CustomsAdminApi,
  CustomsExportApi,
  DataApi,
  CustomsPaperworkHandlingApi,
  CustomsShipmentItemViewModel,
  CustomsShipmentDetailViewModel,
  ExportStep,
  EmployeeViewModel,
} from "@/openapi";
import { ConfirmShipmentReasonForm } from "./dialogs/ConfirmShipmentReasonDialog.vue";
import ShipmentValueChangesExportDialog from "./dialogs/ShipmentValueChangesExportDialog.vue";
import { getStatuses } from "@/helpers/statusHelper";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { emitError } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";
import moment from "moment";
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();

interface IProps {
  color?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  color: "",
});
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const customsAdminApi = new CustomsAdminApi(undefined, "");

const api: CustomsExportApi = new CustomsExportApi(undefined, "");
const customsPaperworkHandlingApi: CustomsPaperworkHandlingApi =
  new CustomsPaperworkHandlingApi(undefined, "");
const apiData: DataApi = new DataApi(undefined, "");
const form = ref(null);

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  loadedConfirmed: 0,
  shipmentConfirmed: null,
  shipmentLoaded: null,
  weight: null,
  showAssigned: false,
  employee: null,
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref([]);

const assignedCustomsHandlingItems = ref(0);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const loadedConfirmedFilter = ref([
  { text: "Show all", value: 0 },
  { text: "Show Confirmed but not loaded", value: 1 },
  { text: "Show Loaded but not confirmed", value: 2 },
]);
const selectedLoadedConfirmed = ref("");

const booleanFilter = ref(["Yes", "No"]);

const items = ref<CustomsShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const selectedHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);

const showShipmentValueChangeDialog = ref(false);

const employees = ref<EmployeeViewModel[]>([]);

const confirmDocumentOrMRN = ref(5);

const uploadExcelLTSFile = ref<string | Blob | null>(null);

const loadingReadExcelLts = ref(false);
const saveSuccessful = ref(false);

const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
};

const selectedEmployee = ref<EmployeeViewModel | null>(null);
const noEmployeeSelected = ref(false);
const loadingAssignShipmentsToEmployee = ref(false);

const selectedStatus = ref<ExportStep | null>(null);
const noStatusSelected = ref(false);
const loadingChangeStatusOfSelected = ref(false);

const valid = ref(false);

const headers = ref([
  { text: "Create date", value: "createDate", width: "10em" },
  { text: "Employee", value: "employee", width: "15em" },
  { text: "Step", value: "exportFlowStatus", align: "center" },
  { text: "Loaded", value: "isLoaded" },
  { text: "Confirmed", value: "isConfirmed" },
  { text: "Shipment status", value: "shipmentStatusDescription" },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  { text: "MRN", value: "exportMrn", width: "18em" },
  { text: "Temp. WAB Number", value: "tempWabNumber", width: "15em" },
  { text: "Final WAB Number", value: "finalWabNumber", width: "15em" },
  { text: "Anzahl ", value: "pieces", sortable: false, align: "end" },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false, width: "15em" },
  { text: "Versender", value: "shipper", sortable: false, width: "15em" },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  { text: "Warenwert", value: "value", sortable: false, align: "end" },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung",
    value: "articles",
    sortable: false,
    width: "25em",
  },
  {
    text: "Upload document(s)",
    value: "documents",
    sortable: false,
  },
]);

const hasSelectedHandlingItems = computed(() => {
  return !!selectedHandlingItems.value?.length;
});

const dataTableItems = computed(() => {
  return items.value.map((c) => ({
    ...c,
    isSelectable: !c.isConfirmed && !isLoading.value,
  }));
});

onBeforeMount(() => {
  customsFlowStepsOnBeforeMount();
  dataStore.fetchOriginCountries();
  selectedLoadedConfirmed.value = loadedConfirmedFilter.value[0].text;
  getEmployees();
  statusFilter.value = getStatuses(true, true, true, true, true);

  emits("PageInfoReceived", [
    {
      callback: reloadShipments,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
});

const loadShipments = async (page: number) => {
  isLoading.value = true;
  try {
    const response = await customsAdminApi.getAdminShipments({
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      loadedConfirmed: filter.value.loadedConfirmed ?? undefined,
      shipmentLoaded: filter.value.shipmentLoaded ?? undefined,
      shipmentConfirmed: filter.value.shipmentConfirmed ?? undefined,
      weight: filter.value.weight ?? undefined,
      showAssigned: filter.value.showAssigned ?? undefined,
      employee: filter.value.employee ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });

    items.value = response.data.shipments ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the shipments");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1
    ? await loadShipments(options.value.page)
    : (options.value.page = 1);
};

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadShipments(options.value.page);
    }, 250);
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    currentShipmentDetail.value = null;

    if (newVal.length === 0) {
      currentHandlingItem.value = null;
    } else {
      currentHandlingItem.value = newVal[0];
      getShipmentDetail();
    }
  },
);

const onFilterSelected = () => {
  reloadShipments();
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const getShipmentDetail = async () => {
  isLoading.value = true;
  return api
    .getCustomsShipmentDetail(currentHandlingItem.value!.id!)
    .then((response) => {
      currentShipmentDetail.value = response.data;
    })
    .catch((error) => {
      displayError("Something went wrong while retrieving the detail data.");
    })
    .finally(() => (isLoading.value = false));
};

const onShipmentDetailsUpdated = async () => {
  isLoading.value = true;
  currentShipmentDetail.value = null;
  try {
    const response = await api.getCustomsShipmentDetail(
      currentHandlingItem.value!.id!,
    );
    currentShipmentDetail.value = response.data;
  } catch {
    displayError(
      "Something went wrong while re-retrieving the shipment details",
    );
  }

  isLoading.value = false;
};

const readExcelLTS = async () => {
  if (!(form.value! as Vue & { validate: () => boolean }).validate()) {
    return;
  }

  loadingReadExcelLts.value = true;
  var castedFile = uploadExcelLTSFile.value as File;
  try {
    var response = await api.readExcelLTS([castedFile]);

    var returnString = response.data;
    if (returnString) {
      alert(returnString);
    } else {
      saveSuccessful.value = true;
      uploadExcelLTSFile.value = null;
    }
  } catch {
    displayError("Something went wrong while processing the LTS file.");
  }
  loadingReadExcelLts.value = false;
};

const assignShipmentsToEmployee = async () => {
  if (selectedEmployee.value == null) {
    noEmployeeSelected.value = true;
    return;
  }
  loadingAssignShipmentsToEmployee.value = true;

  try {
    await customsAdminApi.assignShipmentsToEmployee({
      employeeId: selectedEmployee.value.id,
      shipmentIds: selectedHandlingItems.value.map((c) => c.id!),
    });

    selectedHandlingItems.value = [];
    selectedEmployee.value = null;
    await loadShipments(options.value.page);
  } catch {
    displayError(
      "Something went wrong while assigning the shipments to the given employee.",
    );
  }

  loadingAssignShipmentsToEmployee.value = false;
};

const saveStatusToSelectedShipments = async () => {
  loadingChangeStatusOfSelected.value = true;

  try {
    if (selectedStatus.value === null) {
      emitError(...["Selected status is null."]);
      return;
    }

    await customsAdminApi.setStatusOfShipments({
      shipmentIds: selectedHandlingItems.value.map((c) => c.id!),
      status: selectedStatus.value,
    });
    selectedHandlingItems.value = [];
    selectedStatus.value = null;
    await loadShipments(options.value.page);
  } catch {
    displayError(
      "Something went wrong while setting the status of the given shipments",
    );
  }

  loadingChangeStatusOfSelected.value = false;
};

const getEmployees = async () => {
  employees.value = [];
  try {
    var response = await apiData.getCustomsEmployees();
    employees.value = response.data;
  } catch {
    displayError("Something went wrong while retrieving the employees");
  }
};

const onShipmentStatusChanged = ({ success }: any) => {
  if (success) {
    selectedHandlingItems.value = [];
    loadShipments(options.value.page);
  }
};
</script>
