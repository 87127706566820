<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        :disabled="disabled"
        class="tiptap-vuetify-editor__action-render-btn"
        :class="{ 'v-btn--active': active }"
        small
        icon
        v-on="on"
        @click="action"
      >
        <v-icon v-if="!textIcon" class="tiptap-vuetify-editor__btn-icon">
          {{ icon }}
        </v-icon>
        <b v-else>
          {{ textIconText }}
        </b>
      </v-btn>
    </template>
    <template>
      {{ tooltipText }}
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { ref } from "vue";

const disabled = ref(false);
const props = defineProps({
  tooltipText: { type: String },
  icon: { type: String },
  action: { type: Function },
  active: { type: Boolean },
  textIcon: { type: Boolean },
  textIconText: { type: String },
});
</script>
