<template>
  <div class="d-flex atb-info">
    <ValidationProvider
      name="ATB-number"
      rules="required|atbNumber"
      v-slot="{ errors }"
      slim
    >
      <v-text-field
        v-model="info.number"
        label="ATB-number"
        class="atb-info__number"
        :error-messages="errors"
      ></v-text-field>
    </ValidationProvider>
    <ValidationProvider
      v-if="!singlePosition"
      name="Position from"
      rules="required|atbPosition"
      v-slot="{ errors }"
      slim
    >
      <v-text-field
        v-model="info.from"
        label="Position from"
        class="atb-info__from"
        :error-messages="errors"
      ></v-text-field>
    </ValidationProvider>
    <ValidationProvider
      v-if="!singlePosition"
      name="Position to"
      rules="required|atbPosition"
      v-slot="{ errors }"
      slim
    >
      <v-text-field
        v-model="info.to"
        label="Position to"
        class="atb-info__to"
        :error-messages="errors"
      ></v-text-field>
    </ValidationProvider>

    <ValidationProvider
      v-if="singlePosition"
      name="Position"
      rules="required|atbPosition"
      v-slot="{ errors }"
      slim
    >
      <v-text-field
        v-model="info.position"
        label="Position"
        class="atb-info__to"
        :error-messages="errors"
      ></v-text-field>
    </ValidationProvider>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, watch, computed, PropType } from "vue";

export interface AtbInfo {
  number: string;
  from: string;
  to: string;
}

export interface AtbInfoSingle {
  number: string;
  position: string;
}

const props = defineProps({
  value: { type: Object as PropType<AtbInfo | AtbInfoSingle | undefined> },
});
const emits = defineEmits(["input"]);

onBeforeMount(() => {
  info.value = props.value;
});

watch(
  () => info.value,
  (newVal) => {
    emits("input", newVal);
  },
);

const info = computed({
  get(): AtbInfo | AtbInfoSingle | undefined {
    return props.value;
  },
  set(value: AtbInfo | AtbInfoSingle | undefined) {
    emits("input", value);
  },
});

const singlePosition = computed(() => {
  return Object.keys(props.value).includes("position");
});
</script>
