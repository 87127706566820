<template>
  <v-card color="lighten-3" class="ma-6">
    <v-card-title>Flight info</v-card-title>
    <v-card-text>
      <ValidationObserver ref="observer" slim v-slot="{ changed }">
        <v-form :disabled="loading">
          <v-row>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-autocomplete
                  :items="generalCustomers"
                  item-value="id"
                  item-text="shortName"
                  hide-details
                  dense
                  label="Kunde"
                  outlined
                  v-model="editableItem.customerId"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  hide-details
                  dense
                  label="Flug / Straße"
                  outlined
                  v-model="editableItem.flight"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  hide-details
                  dense
                  label="MAWB"
                  outlined
                  v-model="editableItem.mawb"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-menu
                v-model="showDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider>
                    <v-text-field
                      :value="
                        editableItem.eta
                          ? moment(editableItem.eta).format('YYYY-MM-DD')
                          : ''
                      "
                      label="ETA"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="editableItem.eta"
                  no-title
                  scrollable
                  @input="showDatePicker = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  type="number"
                  min="0"
                  hide-details
                  dense
                  label="Packstücke"
                  outlined
                  v-model.number="packages"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  hide-details
                  dense
                  label="Gewicht (real)"
                  outlined
                  v-model.number="netWeight"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  hide-details
                  dense
                  label="Gewicht (chrg.)"
                  outlined
                  v-model.number="brutoWeight"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ValidationProvider>
                <v-text-field
                  hide-details
                  dense
                  label="Handlingpartner"
                  outlined
                  v-model="editableItem.handlingPartner"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn v-if="isNew" @click="create" :loading="loading" primary
                >Create</v-btn
              >
              <v-btn
                v-else
                @click="update"
                :disabled="!changed"
                :loading="loading"
                primary
                >Update</v-btn
              >
              <v-btn
                v-if="isNew"
                @click="cancel"
                class="ms-4"
                :loading="loading"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  CustomerViewModel,
  CustomsFlightHandlingApi,
  MAWBDetailItem,
} from "@/openapi";
import { ValidationObserver } from "vee-validate";
import { ref, watch, computed } from "vue";
import moment from "moment";

const consolidationApi = new CustomsFlightHandlingApi(undefined, "");

const props = defineProps({
  item: {
    type: Object as () => MAWBDetailItem | null,
    required: true,
  },
  generalCustomers: {
    type: Array as () => CustomerViewModel[],
    default: () => [],
  },
});
const emits = defineEmits(["updated", "created", "canceled"]);
const observer = ref<InstanceType<typeof ValidationObserver>>();
const loading = ref(false);
const showDatePicker = ref(false);

const editableItem = ref<MAWBDetailItem | null>(null);
const copyEditableItem = ref<MAWBDetailItem | null>(null);

watch(
  () => props.item,
  (newVal) => {
    if (newVal === null) {
      editableItem.value = null;
      return;
    }

    editableItem.value = JSON.parse(JSON.stringify(newVal));
    copyEditableItem.value = JSON.parse(JSON.stringify(newVal));
  },
  { immediate: true },
);

const update = async () => {
  if (!editableItem.value) {
    return;
  }

  const entityId = await save(editableItem.value);
  emits("updated", { entityId });
};

const create = async () => {
  if (!editableItem.value) {
    return;
  }
  const entityId = await save(editableItem.value);
  emits("created", { entityId });
};

const cancel = () => {
  emits("canceled", { entityId: editableItem.value?.id });
};

const save = async (detailItem: MAWBDetailItem): Promise<number | null> => {
  loading.value = true;
  let entityId: number | null = null;
  try {
    const response = await consolidationApi.saveMawb(detailItem);
    entityId = response.data.entityId ?? null;
    observer.value?.reset();
  } catch {
    emitError("Something went wrong while saving the MAWB information");
  }
  loading.value = false;
  return entityId;
};

const netWeight = computed({
  get() {
    return editableItem.value?.netWeight;
  },
  set(newValue) {
    editableItem.value!.netWeight = !newValue && newValue !== 0 ? 0 : newValue;
  },
});

const packages = computed({
  get() {
    return editableItem.value?.packages;
  },
  set(newValue) {
    editableItem.value!.packages = !newValue && newValue !== 0 ? 0 : newValue;
  },
});

const brutoWeight = computed({
  get() {
    return editableItem.value?.grossWeight;
  },
  set(newValue) {
    editableItem.value!.grossWeight =
      !newValue && newValue !== 0 ? 0 : newValue;
  },
});

const isNew = computed(() => {
  return props.item?.id === 0;
});
</script>
