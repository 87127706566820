<template>
  <Fragment>
    <v-data-table
      :items="items"
      :headers="headers"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <v-toolbar flat class="pe-4">
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="isDeleting" @click="onCreate"
            ><v-icon left>mdi-plus-thick</v-icon>Create</v-btn
          >
        </v-toolbar>
      </template>

      <template #item.actions="{ item }">
        <div class="actions">
          <v-btn
            color="success"
            small
            :disabled="
              isDeleting &&
              (!item.isDeleting ||
                (item.isDeleting && item.id == currentItem?.id))
            "
            @click="onEdit(item)"
          >
            <v-icon small left>mdi-pencil</v-icon>Edit</v-btn
          >
          <v-btn
            color="error"
            small
            :loading="item.isDeleting"
            :disabled="isDeleting && !item.isDeleting"
            @click="onDelete(item)"
            ><v-icon small left>mdi-delete</v-icon>Delete</v-btn
          >
        </div>
      </template>
    </v-data-table>
    <HawbDefinitionDialog
      v-model="showDialog"
      :initial-data="initialData"
      :dialog-loading="isProcessing"
      @cancel="currentItem = null"
      @confirm="onConfirm"
    ></HawbDefinitionDialog>
  </Fragment>
</template>
<script setup lang="ts">
import { emitError, emitSuccess } from "@/event-bus";
import { HawbDefinitionsApi, HawbDefinition } from "@/openapi";
import { FooterOptions } from "@/types/types";
import { computed, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import HawbDefinitionDialog, {
  HawbDefinitionForm,
} from "./components/dialogs/HawbDefinitionDialog.vue";

interface EnrichedHawbDefinition extends HawbDefinition {
  isDeleting: boolean;
}

const hawbDefinitionsApi = new HawbDefinitionsApi(undefined, "");
const headers = ref<DataTableHeader[]>([
  {
    text: "General customer",
    value: "customer",
    align: "start",
    width: 300,
    sortable: false,
  },
  {
    text: "First digit to scan",
    value: "firstDigitToScan",
    align: "start",
    width: 150,
    sortable: false,
  },
  {
    text: "Last digit to scan",
    value: "lastDigitToScan",
    align: "start",
    width: 150,
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    align: "end",
    sortable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<EnrichedHawbDefinition[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const currentItem = ref<EnrichedHawbDefinition | null>();

const showDialog = ref(false);
const isProcessing = ref(false);
const isDeleting = ref(false);

const getHawbDefinitions = async () => {
  loading.value = true;
  try {
    const response = await hawbDefinitionsApi.getHawbDefinitions(
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value =
      response.data.items?.map((item) => ({
        ...item,
        isDeleting: false,
      })) ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the HAWB definitions");
  }
  loading.value = false;
};

const onCreate = () => {
  currentItem.value = null;
  showDialog.value = true;
};

const onEdit = (item: EnrichedHawbDefinition) => {
  currentItem.value = item;
  showDialog.value = true;
};

const onDelete = async (item: EnrichedHawbDefinition) => {
  currentItem.value = item;
  isDeleting.value = true;
  item.isDeleting = true;
  try {
    await hawbDefinitionsApi.deleteHawbDefinition({
      id: item.id!,
    });
    emitSuccess("Succesfully deleted the HAWB definition");
    await getHawbDefinitions();
  } catch {
    emitError("Something went wrong while deleting the HAWB definition");
  }
  currentItem.value = null;
  isDeleting.value = false;
  item.isDeleting = false;
};

const onConfirm = async (eventData: HawbDefinitionForm) => {
  const isNew = !eventData.id;
  isProcessing.value = true;
  try {
    if (!isNew) {
      await hawbDefinitionsApi.editHawbDefinition({
        id: eventData.id!,
        customerId: eventData.customerId!,
        firstDigitToScan: eventData.firstDigitToScan!,
        lastDigitToScan: eventData.lastDigitToScan
          ? parseInt(eventData.lastDigitToScan.toString())
          : undefined,
      });
    } else {
      await hawbDefinitionsApi.createHawbDefinition({
        customerId: eventData.customerId!,
        firstDigitToScan: eventData.firstDigitToScan!,
        lastDigitToScan: eventData.lastDigitToScan
          ? parseInt(eventData.lastDigitToScan.toString())
          : undefined,
      });
    }
    showDialog.value = false;
    isProcessing.value = false;

    emitSuccess(
      `Succesfully ${isNew ? "created" : "edited"} the HAWB definition`,
    );
    await getHawbDefinitions();
  } catch {
    emitError(
      `Something went wrong while ${isNew ? "creating" : "editing"} the item`,
    );
    showDialog.value = false;
    isProcessing.value = false;
  }
  currentItem.value = null;
};

const initialData = computed((): HawbDefinitionForm | null => {
  return currentItem.value
    ? ({
        id: currentItem.value.id,
        customerId: currentItem.value.customerId,
        firstDigitToScan: currentItem.value.firstDigitToScan,
        lastDigitToScan: currentItem.value.lastDigitToScan,
      } as HawbDefinitionForm)
    : null;
});

watch(
  () => options.value,
  async () => {
    await getHawbDefinitions();
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  justify-content: end;
  gap: 15px;
}
</style>
