<template>
  <div>
    <v-flex class="px-3" white>
      <v-container fluid>
        <v-row class="align-center">
          <v-icon>mdi-filter</v-icon>
          <v-col md="2">
            <v-menu
              v-model="showDatepicker"
              transition="scale-transition"
              offset-y
              :close-on-content-click="false"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="dateDisplayText"
                  class="ml-5"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="(dates = null), onFilterSelected()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :range="true"
                @change="onFilterSelected"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      dense
      fixed-header
      fixed-footer
      show-expand
      class="elevation-1"
      :loading="loading"
    >
      <template #body.prepend>
        <tr height="65px">
          <td>
            <v-icon small>mdi-filter</v-icon>
          </td>
          <td></td>

          <td>
            <v-select
              v-model="filter.shipmentStatusDescription"
              :items="shipmentStatusFilter"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-select>
          </td>
          <td>
            <FilterParcels
              v-model="filter.parcels"
              @input="onFilterSelected"
            ></FilterParcels>
          </td>
          <td>
            <v-text-field
              v-model="filter.transitMrn"
              clearable
              dense
              @keyup.enter="onFilterSelected"
              @click:clear="(filter.transitMrn = ''), onFilterSelected()"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-autocomplete
              v-model="filter.shipperCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
          <td>
            <v-autocomplete
              v-model="filter.consigneeCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
        </tr>
      </template>

      <template #item.exportFlowStatus="{ item }">
        <v-avatar
          class="flow-status-avatar"
          size="22"
          :color="flowSteps[item.exportFlowStatus][3]"
        >
          {{ item.exportFlowStatus }}
        </v-avatar>
      </template>

      <template #item.shipper="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.shipper.name }}<br />
            {{ item.shipper.address }}<br />
            {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
            {{ item.shipper.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.consignee="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.consignee.name }}<br />
            {{ item.consignee.address }}<br />
            {{ item.consignee.zipcode }} {{ item.consignee.city }}<br />
            {{ item.consignee.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.value="{ item }">
        <v-row>
          <v-col>
            {{ item.value.toFixed(2) }}
          </v-col>
        </v-row>
      </template>

      <template #item.articles="{ item }">
        <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">{{ article.description }}</span>
          </v-col>
          <v-col>
            <span class="articleDescription pl-4">{{ article.rcTarif }}</span>
          </v-col>
        </v-row>
        <v-row v-if="item.articles.length > 4" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">more...</span>
          </v-col>
        </v-row>
      </template>

      <template #expanded-item="{ headers, item }">
        <td v-if="currentShipmentDetail" :colspan="headers.length">
          <v-flex class="pa-3 pb-5">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Shipment status</v-card-title>
                    <ShipmentPaperwork
                      class="shipment-paperwork"
                      :shipment-id="currentShipmentDetail.id"
                    ></ShipmentPaperwork>
                    <CustomsExportShipmentDetailViewNew
                      v-if="currentShipmentDetail"
                      :current-handling-item="currentShipmentDetail"
                    >
                    </CustomsExportShipmentDetailViewNew>

                    <ShipmentStatusFlow
                      :shipment-id="currentShipmentDetail.id"
                      :active-status="currentShipmentDetail.exportFlowStatus"
                    ></ShipmentStatusFlow>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import {
  CustomsShipmentItemViewModel,
  CustomsTransitApi,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import { ref, computed, watch, onBeforeMount } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";
import { ToolbarItem } from "@/models/ToolbarItem";

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const loading = ref(false);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [1, 5, 25, 50, 100],
  disablePagination: false,
});

const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const filter = ref({
  shipmentStatusDescription: "",
  parcels: [],
  transitMrn: null as string | null,
  shipperCountry: null,
  consigneeCountry: null,
});

const showDatepicker = ref(false);
const dates = ref<string[] | null>([]);

const items = ref<CustomsShipmentItemViewModel[]>([]);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const totalAmountOfItems = ref(0);

const expanded = ref<CustomsShipmentItemViewModel[]>([]);

const headers = ref<DataTableHeader[]>([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "Transit MRN ",
    value: "transitMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eoriNumber", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

onBeforeMount(() => {
  dataStore.fetchOriginCountries();
  addRefreshToolbarOption(reloadShipments);
});

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

watch(
  () => options.value,
  (newValue: DataOptions, oldValue: DataOptions) => {
    loadShipments();
  },
);

watch(
  () => expanded.value,
  (
    newValue: CustomsShipmentItemViewModel[],
    oldValue: CustomsShipmentItemViewModel[],
  ) => {
    if (newValue.length === 0) {
      currentShipmentDetail.value = null;
    } else {
      currentShipmentDetail.value = null;
      loading.value = true;
      customsExportApi
        .getCustomsShipmentDetail(newValue[0].id!)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (loading.value = false));
    }
  },
);

const loadShipments = async (page: number = options.value.page) => {
  loading.value = true;
  try {
    let fromDate;
    let tillDate;

    if (dates.value?.length === 2) {
      fromDate = dates.value[0];
      tillDate = dates.value[1];
    }

    const response = await customsTransitApi.getHistoryShipments({
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      transitMrn: filter.value.transitMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
      fromDate,
      tillDate,
      page,
      itemsPerPage: options.value.itemsPerPage,
    });
    items.value = response.data.items ?? [];
    options.value.page = response.data.page!;
    options.value.itemsPerPage = response.data.itemsPerPage!;
    totalAmountOfItems.value = response.data.totalAmountOfItems!;
  } catch {
    displayError("Something went wrong while retrieve the shipments");
  }

  loading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1
    ? await loadShipments(options.value.page)
    : (options.value.page = 1);
};

const onFilterSelected = () => {
  reloadShipments();
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const dateDisplayText = computed(() => {
  return dates.value?.join(" - ");
});
</script>
