import { ref } from "vue";

export function useCustomsFlowSteps() {
  const flowSteps = ref([
    [
      "Shipment data received",
      "Shipment data received",
      "Shipment data received",
      "yellow darken-3",
      "Shipment data received",
    ],
    [
      "Assigned to employee",
      "Assigned to employee",
      "Assigned to employee",
      "yellow darken-3",
      "Assigned to employee",
    ],

    // CHECK MODUS per Shipment
    [
      "Check shipment",
      "Check shipment",
      "Check shipment",
      "yellow darken-3",
      "Check shipment",
    ],

    // EXPORT MODUS per WAB
    [
      "< 1.000",
      "1.000 - 3.000",
      "> 3.000 or has MRN",
      "light-blue darken-1",
      "Value breaks",
    ],
    [
      "Confirm loading",
      "Confirm loading",
      "Confirm loading",
      "light-blue darken-1",
      "Confirm loading",
    ],
    [
      "Excel sheet created",
      "Declaration by Dakosy",
      "Movement Reference Number (MRN) entered",
      "light-blue darken-1",
      "Files/MRN created",
    ],
    [
      "Pending customs release",
      "Pending customs release",
      "Movement Reference Number (MRN) entered",
      "light-blue darken-1",
      "Pending customs release",
    ],
    [
      "Confirm reply from customs",
      "Confirm reply from Dakosy",
      "Movement Reference Number (MRN) entered",
      "light-blue darken-1",
      "Customs EXIT released",
    ],

    // TRANSIT MODUS per WAB
    [
      "WAB is ready to depart",
      "WAB is ready to depart",
      "WAB is ready to depart",
      "purple",
      "WAB is ready to depart",
    ],
    [
      "T1/T2 creation",
      "T1/T2 creation",
      "T1/T2 creation",
      "purple",
      "T1/T2 creation",
    ],
    [
      "T1/T2 released",
      "T1/T2 released",
      "T1/T2 released",
      "purple",
      "T1/T2 released",
    ],
    [
      "CONSO file created",
      "CONSO file created",
      "CONSO file created",
      "purple",
      "CONSO file created",
    ],
    [
      // Will be further defined in the future.
      "",
      "",
      "",
      "purple",
      "",
    ],
    [
      "T1/T2 discharged",
      "T1/T2 discharged",
      "T1/T2 discharged",
      "purple",
      "T1/T2 discharged",
    ],

    // FULLFILLED
    [
      "Fullfilled",
      "Fullfilled",
      "Fullfilled",
      "light-green darken-2",
      "Fullfilled",
    ],
  ]);

  const customsFlowStepsOnBeforeMount = () => {
    flowSteps.value[20] = ["Hold", "Hold", "Hold", "error", "Hold"];

    flowSteps.value[21] = ["Return", "Return", "Return", "error", "Return"];

    flowSteps.value[22] = [
      "Customs inspection",
      "Customs inspection",
      "Customs inspection",
      "error",
      "Customs inspection",
    ];

    flowSteps.value[31] = [
      "Manual PostNord data transfer",
      "Manual PostNord data transfer",
      "Manual PostNord data transfer",
      "purple",
      "Manual PostNord data transfer",
    ];

    flowSteps.value[50] = [
      "Consolidated shipment",
      "Consolidated shipment",
      "Consolidated shipment",
      "purple",
      "Consolidated shipment",
    ];

    flowSteps.value[98] = ["Ignore", "Ignore", "Ignore", "error", "Ignore"];
  };

  return { flowSteps, customsFlowStepsOnBeforeMount };
}
