<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs
          v-model="currentTab"
          background-color="grey darken-3"
          center-active
          dark
        >
          <v-tab>Open articles</v-tab>
          <v-tab>Fulfilled articles</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <CustomsArticleMaintenanceOpen
              v-if="currentTab == 0"
              :customers="customers"
              :incoterm-ids="filteredIncotermIds"
            ></CustomsArticleMaintenanceOpen>
          </v-tab-item>
          <v-tab-item>
            <CustomsArticleMaintenanceFulfilled
              v-if="currentTab == 1"
              :customers="customers"
              :incoterm-ids="filteredIncotermIds"
            ></CustomsArticleMaintenanceFulfilled>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";

import CustomsArticleMaintenanceOpen from "./components/CustomsArticleMaintenanceOpen.vue";
import CustomsArticleMaintenanceFulfilled from "./components/CustomsArticleMaintenanceFulfilled.vue";

import { CustomsArticleMaintenanceApi, DataApi } from "@/openapi";
import { emitError } from "@/event-bus";
import { DefaultDataTableItem } from "@/types/types";

const api = new CustomsArticleMaintenanceApi(undefined, "");
const dataApi = new DataApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);

const currentTab = ref(0);

const filteredIncotermIds = ref<number[]>([]);
const customers = ref<DefaultDataTableItem<string>[]>([]);

const getCustomers = async () => {
  try {
    const response = await dataApi.getCustomers(true);
    customers.value = response.data.map((customer: any) => ({
      value: customer.id,
      text: customer.shortName,
    }));
  } catch (ex) {
    emitError(
      'Something went wrong while retrieving the "Customers" filter options.',
    );
  }
};

const getDAPAndDDP = async () => {
  try {
    const response = await api.getDAPAndDDP();
    filteredIncotermIds.value = response.data ?? [];
  } catch {
    emitError("Failed to retrieve the DAP/DDP filter options");
  }
};

onBeforeMount(async () => {
  emits("PageInfoReceived", "Article Maintenance", []);
  await getCustomers();
  await getDAPAndDDP();
});
</script>
