<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    transition="dialog-bottom-transition"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="error" dark>
        <span class="ml-3 text-button">{{ displayTitle }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-3 mt-2 mb-8" elevation="0" outlined>
                  <v-row>
                    <v-col>Beschau</v-col>
                  </v-row>
                </v-card>
                <v-select
                  v-model="form.reason"
                  :items="returnReasonsBeschau"
                  label="Please select a reason"
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="!valid"
          @click="emits('confirm', form)"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { Actions } from "@/config";

export interface SetInspectionReasonForm {
  reason?: string;
}

const props = defineProps({
  value: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  title: { type: String, default: "" },
  confirmButtonText: { type: String, default: "Confirm" },
  closeButtonText: { type: String, default: "Close" },
});
const emits = defineEmits(["input", "close", "confirm"]);

const initializeForm = (): SetInspectionReasonForm => {
  return {
    reason: "",
  };
};

const form = ref();
const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
};
const valid = ref(true);

const formData = ref(initializeForm());

const returnReasonsBeschau = ref<string[]>([
  "Customs inspection documents",
  "Customs inspection parcel",
]);

const displayTitle = computed(() => {
  return !props.title ? Actions.CUSTOMS_INSPECTION : props.title;
});

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      formData.value = initializeForm();
      (
        form.value! as Vue & { resetValidation: () => boolean }
      ).resetValidation();
    }
  },
);
</script>
