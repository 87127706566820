<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          fixed-header
          fixed-footer
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="claimId"
          dense
          show-expand
          class="elevation-1"
          :loading="isLoading"
        >
          <template #item.claimDateOpened="{ item }">
            {{ formatDateTime(item.claimDateOpened) }}
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-flex class="pa-3 pb-5">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>Claim details</v-card-title>
                        <v-card-text v-if="currentClaim">
                          <v-row no-gutters class="ml-5">
                            <v-col cols="6">
                              <ClaimDetailView :claim="currentClaim" />
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="ml-5">
                            <v-col cols="4">
                              <v-autocomplete
                                :items="claimTypes"
                                label="Dropdown example"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="ml-5">
                            <v-col>
                              <v-btn
                                color="primary"
                                class="mb-3"
                                @click="proceedClaim"
                              >
                                Proceed
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text v-else> Could not find claim </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ClaimViewModel, CustomsClaimApi } from "@/openapi";
import { ToolbarItem } from "@/models/ToolbarItem";
import ClaimDetailView from "../components/ClaimDetailView.vue";
import moment from "moment";

const CcustomsClaimApi = new CustomsClaimApi(undefined, "");

const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const showClaimDialog = ref(false);
const openClaimId = ref(0);
const isLoading = ref(false);
const items = ref<ClaimViewModel[]>([]);
const currentClaim = ref<ClaimViewModel | null>(null);

const expanded = ref<ClaimViewModel[]>([]);

const claimTypes = ref(["A", "B", "C"]);

const headers = ref([
  { text: "Date", value: "claimDateOpened" },
  { text: "Category", value: "claimCategory" },
  { text: "Invoice number", value: "invoiceNumber" },
  { text: "Name", value: "invoiceName" },
]);

watch(
  () => expanded.value,
  (newVal: ClaimViewModel[], oldVal: ClaimViewModel[]) => {
    if (newVal.length === 0) {
      currentClaim.value = null;
    } else {
      let claimId = newVal[0].claimId;
      isLoading.value = true;

      CcustomsClaimApi.getClaimById(claimId)
        .then((response) => {
          currentClaim.value = response.data;
        })
        .catch((error) => {
          emits("errorOccured", [error.message]);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
  },
);

onBeforeMount(() => {
  let toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];

  emits("PageInfoReceived", "Open Claims", toolbarButtons);
  loadData();
});

const refresh = () => {
  loadData();
};

const loadData = () => {
  isLoading.value = true;

  CcustomsClaimApi.listClaims()
    .then((response) => {
      items.value = response.data;
      isLoading.value = false;
    })
    .catch((errors) => {
      isLoading.value = false;
    });
};

const openClaimDialog = (id: number) => {
  showClaimDialog.value = true;
  openClaimId.value = id;
};

const closeClaimDialog = () => {
  showClaimDialog.value = false;
  openClaimId.value = 0;
};

const proceedClaim = () => {
  //TODO SOVC-311: (mvl) - No information yet what this should do
};

const formatDateTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("DD-MM-YYYY");
};
</script>
