<template>
  <div>
    <v-flex class="px-3" white>
      <v-container fluid>
        <v-row class="align-start">
          <v-col md="2">
            <v-checkbox
              v-model="declarationFilter.showFulfilled"
              class="mt-0 d-inline-block"
              @change="(showDateRangeMenu = false), reloadFinalWabs()"
            >
              <span slot="label" class="primary--text"
                >Show also fullfilled</span
              >
            </v-checkbox>

            <v-menu
              v-if="declarationFilter.showFulfilled"
              ref="dateRangeMenu"
              v-model="showDateRangeMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="ml-4 d-block"
            >
              <template #activator="menuSlotProps">
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-badge color="primary">
                      <template #badge>
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-information-variant</v-icon
                        >
                      </template>
                      <v-text-field
                        v-model="declarationFilter.fulfilledDateRange"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="menuSlotProps.attrs"
                        v-on="menuSlotProps.on"
                        @input="reloadFinalWabs"
                      ></v-text-field>
                    </v-badge>
                  </template>
                  <span
                    >Select 2 dates to select a range of dates. Select the same
                    date twice to filter on a single date.</span
                  >
                </v-tooltip>
              </template>

              <v-date-picker
                v-model="declarationFilter.fulfilledDateRange"
                :range="true"
                @change="reloadFinalWabs"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="declarationFilter.search"
              label="Search"
              clearable
              dense
              class="ms-4"
              @keyup.enter="reloadFinalWabs"
              @click:clear="(declarationFilter.search = ''), reloadFinalWabs()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="false"
      :expanded.sync="expanded"
      item-key="id"
      height="calc(100vh - 235px)"
      dense
      fixed-header
      fixed-footer
      show-expand
      class="elevation-1"
      :loading="isLoading"
      @item-expanded="onExpandedFinalWabItem"
    >
      <template #item.data-table-expand="{ expand, isExpanded, item }">
        <v-btn
          v-if="!item.t2Declarations.length"
          icon
          class="v-data-table__expand-icon"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
          @click="customExpand(isExpanded, expand)"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <template #item.shipmentsAddedWarning="{ item }">
        <div class="d-flex">
          <div
            v-if="!item.hasShipmentsAddedRecently"
            class="export-warning-sign"
          ></div>
          <div
            v-else
            class="export-warning-sign export-warning-sign--active"
          ></div>
        </div>
      </template>

      <template #item.createT2="{ item }">
        <v-btn
          small
          :disabled="!!item.t2Declarations.length"
          @click="onCreateT2(item)"
          >Create T2 ({{ item.t2Declarations.length }})</v-btn
        >
        <v-btn
          small
          class="ml-3"
          :disabled="!!item.t2Declarations.length"
          @click="openMoveShipmentsDialog(item)"
          >Move shipments</v-btn
        >
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-flex class="ps-10" grey lighten-3>
            <CustomsExportInTransitDeclarations
              :t2-declarations="item.t2Declarations"
              @reloadDeclarations="reloadFinalWabs"
            ></CustomsExportInTransitDeclarations>
          </v-flex>

          <v-flex v-if="!item.t2Declarations.length">
            <v-container fluid>
              <v-row class="light-blue lighten-5">
                <v-col cols="12">
                  <v-flex class="px-3" white>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-data-table
                            :headers="customsHandlingItemsHeaders"
                            :items="customsHandlingItems"
                            :server-items-length="totalShipmentItems"
                            :options.sync="shipmentOptions"
                            :footer-props="footerOptions"
                            :single-expand="true"
                            :expanded.sync="expandedShipments"
                            item-key="id"
                            dense
                            fixed-header
                            fixed-footer
                            show-expand
                            class="elevation-1"
                            :loading="isLoading"
                          >
                            <template #body.prepend>
                              <tr>
                                <td>
                                  <v-icon small>mdi-filter</v-icon>
                                </td>
                                <td>
                                  <v-select
                                    v-model="filter.step"
                                    :items="statusFilter"
                                    clearable
                                    chips
                                    dense
                                    single-line
                                    @change="onFilterSelected"
                                  ></v-select>
                                </td>
                                <td>
                                  <v-select
                                    v-model="filter.shipmentStatusDescription"
                                    :items="shipmentStatusFilter"
                                    clearable
                                    dense
                                    single-line
                                    @change="onFilterSelected"
                                  ></v-select>
                                </td>
                                <td>
                                  <FilterParcels
                                    v-model="filter.parcels"
                                    @input="onFilterSelected"
                                  ></FilterParcels>
                                </td>
                                <td>
                                  <v-text-field
                                    v-model="filter.exportMrn"
                                    clearable
                                    dense
                                    @keyup.enter="onFilterSelected"
                                    @click:clear="
                                      (filter.exportMrn = ''),
                                        onFilterSelected()
                                    "
                                  ></v-text-field>
                                </td>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <v-autocomplete
                                    v-model="filter.shipperCountry"
                                    :items="dataStore.originCountries"
                                    item-key="code"
                                    item-text="code"
                                    clearable
                                    dense
                                    single-line
                                    @change="onFilterSelected"
                                  ></v-autocomplete>
                                </td>
                                <td>
                                  <v-autocomplete
                                    v-model="filter.consigneeCountry"
                                    :items="dataStore.originCountries"
                                    item-key="code"
                                    item-text="code"
                                    clearable
                                    dense
                                    single-line
                                    @change="onFilterSelected"
                                  ></v-autocomplete>
                                </td>
                              </tr>
                            </template>

                            <template #item.exportFlowStatus="{ item }">
                              <v-avatar
                                class="flow-status-avatar"
                                size="22"
                                :color="flowSteps[item.exportFlowStatus][3]"
                              >
                                {{ item.exportFlowStatus }}
                              </v-avatar>
                            </template>

                            <template #item.exportMrn="{ item }">
                              <v-row justify="center">
                                <v-col>
                                  {{
                                    item.isMaster
                                      ? item.exportMrns.join(", ")
                                      : item.exportMrn
                                  }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.shipper="{ item }">
                              <v-row justify="center">
                                <v-col>
                                  {{ item.shipper.name }}<br />
                                  {{ item.shipper.address }}<br />
                                  {{ item.shipper.zipcode }}
                                  {{ item.shipper.city }}<br />
                                  {{ item.shipper.country }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.consignee="{ item }">
                              <v-row justify="center">
                                <v-col>
                                  {{ item.consignee.name }}<br />
                                  {{ item.consignee.address }}<br />
                                  {{ item.consignee.zipcode }}
                                  {{ item.consignee.city }}<br />
                                  {{ item.consignee.country }}
                                </v-col>
                              </v-row>
                            </template>

                            <template #item.value="{ item }">
                              <v-row>
                                <v-col> {{ item.value.toFixed(2) }} </v-col>
                              </v-row>
                            </template>

                            <template #item.articles="{ item }">
                              <v-row
                                v-for="article in item.articles.slice(0, 4)"
                                no-gutters
                              >
                                <v-col cols="9">
                                  <span class="articleDescription">{{
                                    article.description
                                  }}</span>
                                </v-col>
                                <v-col>
                                  <span class="articleDescription pl-4">{{
                                    article.rcTarif
                                  }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="item.articles.length > 4" no-gutters>
                                <v-col cols="9">
                                  <span class="articleDescription"
                                    >more...</span
                                  >
                                </v-col>
                              </v-row>
                            </template>

                            <template #expanded-item="{ headers, item }">
                              <td
                                v-if="currentShipmentDetail"
                                :colspan="headers.length"
                              >
                                <v-flex class="pa-3 pb-5">
                                  <v-container fluid>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-card>
                                          <v-card-title
                                            >Shipment status</v-card-title
                                          >
                                          <ShipmentPaperwork
                                            class="shipment-paperwork"
                                            :shipment-id="
                                              currentShipmentDetail.id
                                            "
                                          ></ShipmentPaperwork>
                                          <CustomsExportShipmentDetailViewNew
                                            :current-handling-item="
                                              currentShipmentDetail
                                            "
                                          />
                                          <v-btn
                                            class="ms-6 mb-4"
                                            :disabled="item.status == 8"
                                            color="error"
                                            @click="removeFromFinalWab"
                                          >
                                            Remove from final WAB
                                          </v-btn>
                                          <ShipmentStatusFlow
                                            :shipment-id="
                                              currentShipmentDetail.id
                                            "
                                            :active-status="
                                              currentShipmentDetail.exportFlowStatus
                                            "
                                          ></ShipmentStatusFlow>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>

    <CreateTransitDeclarationDialog
      v-model="showDialog"
      :create-transit-declaration-loading="isDialogLoading"
      @confirm="onDialogConfirm"
      @close="onDialogClose"
    >
      <template #header>Confirm WAB ready to depart</template>
    </CreateTransitDeclarationDialog>
    <CustomsMoveShipmentDialog
      :model-id="selectedWabItemId"
      :model-open="showMoveShipmentDialog"
      @close="closeMoveShipment"
      @moved="movedMoveShipment"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import CustomsExportInTransitDeclarations from "./CustomsExportInTransitDeclarations.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CreateT2DeclarationsViewModel,
  CustomsFinalWabItem,
  CustomsShipmentItemViewModel,
  CustomsTransitApi,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import moment from "moment";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import CreateTransitDeclarationDialog, {
  CreateTransitDeclarationForm,
} from "@/components/dialogs/CreateTransitDeclarationDialog.vue";
import CustomsMoveShipmentDialog from "./dialogs/CustomsMoveShipmentDialog.vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useDataStore } from "@/stores/data-store";

const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const props = defineProps({
  color: {
    type: String,
    default: "",
  },
});

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const shipmentOptions = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});

const declarationFilter = ref({
  showFulfilled: false,
  fulfilledDateRange: [] as string[],
  search: "",
});
const showDateRangeMenu = ref(false);

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalItems = ref(0);
const expanded = ref<CustomsFinalWabItem[]>([]);
const totalShipmentItems = ref(0);
const expandedShipments = ref([]);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const items = ref<CustomsFinalWabItem[]>([]);
const currentFinalWabItem = ref<CustomsFinalWabItem | null>(null);
const customsHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);

const showDialog = ref(false);
const isDialogLoading = ref(false);
const selectedFinalWabDialogItem = ref<CustomsFinalWabItem | null>(null);

const showMoveShipmentDialog = ref(false);
const selectedWabItemId = ref(0);

const headers = ref([
  {
    text: "LTS-Number",
    value: "ltsNumber",
    align: "start",
    width: "15%",
  },
  {
    text: "Number of shipments",
    value: "numberOfShipments",
    align: "start",
    width: "15%",
  },
  {
    text: "Active",
    value: "shipmentsAddedWarning",
    align: "start",
    width: "5%",
  },
  { text: "", value: "createT2", align: "start" },
  { text: "Status", value: "status" },
]);

const customsHandlingItemsHeaders = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);
const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeMount(() => {
  dataStore.fetchOriginCountries();
  statusFilter.value = getStatuses();
  setDefaultDateRange();

  addRefreshToolbarOption(reloadFinalWabs);
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadFinalWabs(newVal.page, newVal.itemsPerPage);
    }, 250);
  },
);

let timeoutShipmentsDelay = 0;
watch(
  () => shipmentOptions.value,
  () => {
    clearTimeout(timeoutShipmentsDelay);
    timeoutShipmentsDelay = setTimeout(() => {
      loadFinalWabShipments(
        currentFinalWabItem.value!.id!,
        shipmentOptions.value.page,
      );
    }, 250);
  },
);

watch(
  () => expandedShipments.value,
  (
    newVal: CustomsShipmentItemViewModel[],
    oldVal: CustomsShipmentItemViewModel[],
  ) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value.id!)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);

const onExpandedFinalWabItem = (item: CustomsFinalWabItem, value: boolean) => {
  currentShipmentDetail.value = null;
  expandedShipments.value = [];
  customsHandlingItems.value = [];

  if (value) {
    currentFinalWabItem.value = item;
    loadFinalWabShipments(item.id!, 1);
  } else {
    currentFinalWabItem.value = null;
  }
};

const onFilterSelected = () => {
  loadFinalWabShipments(currentFinalWabItem.value!.id!, 1);
};

const loadFinalWabs = async (page: number, itemsPerPage: number) => {
  try {
    isLoading.value = true;
    let fromDate;
    let tillDate;

    if (declarationFilter.value.fulfilledDateRange?.length === 2) {
      fromDate = declarationFilter.value.fulfilledDateRange[0];
      tillDate = declarationFilter.value.fulfilledDateRange[1];
    }

    const response = await customsTransitApi.getFinalWabs(
      declarationFilter.value.showFulfilled,
      fromDate,
      tillDate,
      declarationFilter.value.search ?? "",
      page,
      itemsPerPage,
    );

    items.value = response.data.finalWabItems ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
    expanded.value = items.value.filter((c) => !!c.t2Declarations!.length);
  } catch {
    displayError("Something went wrong while retrieving the final WABs");
  }

  isLoading.value = false;
};

const reloadFinalWabs = async () => {
  options.value.page == 1
    ? await loadFinalWabs(options.value.page, options.value.itemsPerPage)
    : (options.value.page = 1);
};

const loadFinalWabShipments = async (finalWabItemId: number, page: number) => {
  try {
    isLoading.value = true;
    const response = await customsTransitApi.getFinalWabShipments({
      page,
      finalWabId: finalWabItemId,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });

    customsHandlingItems.value = response.data.shipments ?? [];
    totalShipmentItems.value = response.data.totalAmount ?? 0;
  } catch {
    displayError(
      "Something went wrong while retrieving the shipments of the selected final WAB.",
    );
  }

  isLoading.value = false;
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const onCreateT2 = (item: CustomsFinalWabItem) => {
  selectedFinalWabDialogItem.value = item;
  showDialog.value = true;
};

const openMoveShipmentsDialog = (item: CustomsFinalWabItem) => {
  selectedWabItemId.value = item.id;
  showMoveShipmentDialog.value = true;
};

const closeMoveShipment = () => {
  selectedWabItemId.value = 0;
  showMoveShipmentDialog.value = false;
};

const movedMoveShipment = () => {
  reloadFinalWabs();
};

const removeFromFinalWab = async () => {
  try {
    isLoading.value = true;
    await customsTransitApi.removeShipmentsFromFinalWab({
      shipmentIds: [currentShipmentDetail.value!.id],
    });

    await loadFinalWabShipments(
      currentFinalWabItem.value!.id!,
      options.value.page,
    );
    currentFinalWabItem.value!.numberOfShipments!--;
  } catch {
    displayError(
      "Something went wrong while removing the shipment from the final WAB.",
    );
  }
  isLoading.value = false;
};

const onDialogClose = () => {
  selectedFinalWabDialogItem.value = null;
};

const onDialogConfirm = async (formData: CreateTransitDeclarationForm) => {
  isDialogLoading.value = true;

  const postData: CreateT2DeclarationsViewModel = {
    amount: formData.amount,
    country: formData.country,
    countryTruck: formData.countryTruck,
    finalWabId: selectedFinalWabDialogItem.value!.id,
    registration: formData.registration,
    registrationTruck: formData.registrationTruck,
    transportAcrossEuBorder: formData.transportAcrossEuBorder,
    signs: formData.signs.map((c) => c.value).filter((c) => !!c),
    routeId: formData.routeId,
    templateId: formData.templateId,
  };

  try {
    await customsTransitApi.createT2Declarations(postData);

    isLoading.value = true;
    try {
      await loadFinalWabs(options.value.page, options.value.itemsPerPage);
    } catch {
      displayError(
        "Something went wrong while refreshing the final WABs after creating the T2 declarations.",
      );
    }
    isLoading.value = false;
  } catch {
    displayError("Something went wrong while creating the T2 declarations.");
  }
  isDialogLoading.value = false;
  showDialog.value = false;
};

const customExpand = (isExpanded: boolean, expandFn: Function) => {
  expanded.value = expanded.value.filter(
    (expandedItem) => expandedItem.t2Declarations!.length,
  );
  nextTick(() => {
    expandFn(!isExpanded);
  });
};

const reloadDeclarations = () => {
  loadFinalWabs(options.value.page, options.value.itemsPerPage);
};

const setDefaultDateRange = () => {
  const from = moment().subtract(1, "months");
  const till = moment();

  declarationFilter.value.fulfilledDateRange[0] = from.format("YYYY-MM-DD");
  declarationFilter.value.fulfilledDateRange[1] = till.format("YYYY-MM-DD");
};
</script>
