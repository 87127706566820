<template>
  <div
    class="container fill-height container--fluid email-template"
    :class="{ disabled: isLoading }"
  >
    <div class="row">
      <div class="col col-6 email-template__selection">
        <v-card>
          <v-toolbar flat class="grey darken-3" color="white" dark>
            <v-toolbar-title class="grow">Ratecards</v-toolbar-title>
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
          </v-toolbar>
          <v-tabs v-model="selectedEmailType" vertical>
            <v-tab class="justify-start">
              <v-icon left> mdi-download </v-icon>
              Import
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-exit-to-app </v-icon>
              Export
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-crown </v-icon>
              Single Positions
            </v-tab>
            <v-tab-item class="grey lighten-4 pa-5">
              <ImportRatecard></ImportRatecard>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5">
              <ExportRatecard></ExportRatecard>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5">
              <SinglePositionsRatecard></SinglePositionsRatecard>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
      <div class="col col-6">
        <v-card>
          <v-toolbar flat class="grey darken-3" color="white" dark>
            <v-toolbar-title>Price history Import</v-toolbar-title>
          </v-toolbar>
          <v-simple-table fixed-header>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">Price change</th>
                  <th class="text-left">Valid from</th>
                  <th class="text-left">Valid to</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(price, index) in priceHistory" :key="index">
                  <td>{{ price.changeDate }}</td>
                  <td>{{ price.validDate }}</td>
                  <td>{{ price.validTo }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ImportRatecard from "./components/ImportRatecard.vue";
import ExportRatecard from "./components/ExportRatecard.vue";
import SinglePositionsRatecard from "./components/SinglePositionsRatecard.vue";

const currentTab = ref(0);
const isLoading = ref(false);
const selectedEmailTemplate = ref({});
const selectedPreviewImage = ref("");
const selectedEmailType = ref(0);
const emailTemplates = ref([]);
const emailTypes = ref([]);
const priceHistory = ref([
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
  {
    changeDate: "01.07.2023",
    validDate: "01.09.2023",
    validTo: "01.11.2023",
  },
]);

const setLoadingStatus = (status: boolean) => {
  isLoading.value = status;
};
</script>
