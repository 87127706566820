<template>
  <v-dialog
    :value="value"
    @click:outside="emits('input', false)"
    width="500"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="ml-3 text-button">Choose export option</span>
      </v-toolbar>

      <v-card-text class="mt-4">
        Do you want to export the items currently visible in the overview or do
        you want to export all items? Both options are affected by the selected
        filters.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :href="visibleExportUrl"
          :download="visibleExportDownloadName"
          @click="emits('input', false)"
        >
          Visible items
        </v-btn>
        <v-btn
          color="primary"
          text
          :href="allExportUrl"
          :download="allExportDownloadName"
          @click="emits('input', false)"
        >
          All items
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import moment from "moment";
import { ref, computed, PropType } from "vue";

export interface HoldReturnExportDialogDateFilter {
  fromDate: string;
  tillDate: string;
}
const emits = defineEmits(["input"]);
const props = defineProps({
  value: { type: Boolean, default: false },
  includeReturn: { type: Boolean, default: false },
  shipmentIds: { type: Array as PropType<number[]>, default: () => [] },
  returnDateFilter: {
    type: Object as PropType<HoldReturnExportDialogDateFilter>,
    default: null,
  },
});

const setDefaultExportUrlParameters = (searchParams: URLSearchParams) => {
  searchParams.append("includeReturn", props.includeReturn.toString());
  if (!!props.returnDateFilter) {
    searchParams.append("fromDate", props.returnDateFilter.fromDate);
    searchParams.append("tillDate", props.returnDateFilter.tillDate);
  }
};

const visibleExportUrl = computed(() => {
  var searchParams = new URLSearchParams();
  setDefaultExportUrlParameters(searchParams);
  if (!!props.shipmentIds?.length)
    for (const shipmentId of props.shipmentIds) {
      searchParams.append("ids", shipmentId.toString());
    }

  return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
});

const allExportUrl = computed(() => {
  var searchParams = new URLSearchParams();
  setDefaultExportUrlParameters(searchParams);
  return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
});

const visibleExportDownloadName = computed(() => {
  var now = moment();
  return `visible-export-${now.format("YYYY-MM-DD")}`;
});

const allExportDownloadName = computed(() => {
  var now = moment();
  return `all-export-${now.format("YYYY-MM-DD")}`;
});
</script>
