<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :single-expand="true"
      item-key="shipmentHawb"
      height="calc(100vh - 295px)"
      fixed-header
      :hide-default-footer="true"
      :disable-pagination="true"
      show-expand
      :loading="isLoading"
      class="elevation-1"
      @item-expanded="onExpandedItem"
    >
      <template #item.documents="{ item }">
        <v-avatar class="flow-status-avatar" size="24" color="error">
          {{ item.documents.length }}
        </v-avatar>
      </template>
      <template #expanded-item="{ headers, item }">
        <td></td>
        <td class="text-center" style="width: 15%">
          {{ item.shipmentHawb }}
        </td>
        <td>
          <div v-if="item.documents.length" class="documents-wrapper">
            <div
              v-for="(document, index) in item.documents"
              class="documents-wrapper__item mb-3"
            >
              <v-btn
                :href="
                  '/CustomsPaperworkHandling/DownloadDocument?paperworkId=' +
                  document.id
                "
                class="v-btn--flexible text-center mb-2"
              >
                {{ document.documentNr }}
              </v-btn>

              <v-select
                v-model="document.category"
                :items="categories"
                clearable
                chips
                dense
                single-line
                label="Choose category"
                item-value="key"
                item-text="value"
              ></v-select>
            </div>
          </div>
        </td>
        <td class="text-center" style="width: 15%">
          <div class="d-flex align-center justify-center">
            <span v-if="item.mrn || (item.mrns && item.mrns.length)">
              {{ item.isMaster ? item.mrns.join(", ") : item.mrn }}
            </span>
            <v-icon
              v-if="!item.isMaster && !item.mrn"
              class="error--text"
              @click="openDialogShipmentAddMrn(item)"
            >
              mdi-plus-circle
            </v-icon>
            <v-icon
              v-else-if="!item.isMaster && item.mrn"
              class="ms-2"
              @click="openDialogShipmentAddMrn(item)"
            >
              mdi-pencil
            </v-icon>
          </div>
        </td>
        <td class="text-center" style="width: 15%">
          <v-btn
            :disabled="!isAllowedToSave || isSaving"
            :loading="isSaving"
            class="v-btn--flexible text-center"
            @click="save"
          >
            Save
            <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
          >
        </td>
      </template>
    </v-data-table>

    <AddMrnDialog
      v-if="currentPaperworkItem && showDialogAddMrn"
      v-model="showDialogAddMrn"
      :parcel-number="currentPaperworkItem.shipmentHawb"
      :is-loading="dialogAddMrnLoading"
      :initial-mrn="currentPaperworkItem.mrn"
      @confirm="onMrnEntered"
    ></AddMrnDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import {
  KeyValueItem,
  PaperworkItem,
  SavePaperworkDocumentsRequest,
} from "@/openapi";

import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { emitError } from "@/event-bus";
import { DataApi, CustomsPaperworkHandlingApi } from "@/openapi";
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const dataApi = new DataApi(undefined, "");
const customsPaperworkHandlingApi = new CustomsPaperworkHandlingApi(
  undefined,
  "",
);
const color = ref("");
const showAddMrnField = ref(false);
const allCategoriesChosen = ref(false);

const isLoading = ref(false);
const isSaving = ref(false);

const categories = ref<KeyValueItem[]>([]);
const expanded = ref([]);

const items = ref<PaperworkItem[]>([]);
const currentPaperworkItem = ref<PaperworkItem | null>(null);
const showDialogAddMrn = ref(false);
const dialogAddMrnLoading = ref(false);

const headers = ref([
  {
    text: "HAWB",
    value: "shipmentHawb",
    align: "center",
    sortable: false,
  },
  {
    text: "Documents",
    value: "documents",
    align: "start",
    sortable: false,
  },
  {
    text: "MRN",
    value: "mrn",
    align: "center",
    sortable: false,
  },
  {
    text: "",
    value: "",
    align: "end",
    sortable: false,
  },
]);

onBeforeMount(async () => {
  isLoading.value = true;
  await Promise.all([getPaperwork(), getCategories()]);
  isLoading.value = false;

  addRefreshToolbarOption(getPaperwork);
});

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

const onExpandedItem = ({
  item,
  value,
}: {
  item: PaperworkItem;
  value: boolean;
}) => {
  if (value) {
    currentPaperworkItem.value = item;
  } else {
    currentPaperworkItem.value = null;
  }
};

const getPaperwork = async () => {
  try {
    const response = await customsPaperworkHandlingApi.getPaperwork();
    items.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the paperwork");
  }
};

const getCategories = async () => {
  try {
    const response = await dataApi.getPaperworkCategories();
    categories.value = response.data as KeyValueItem[];
  } catch {
    emitError("Something went wrong while retrieving the categories");
  }
};

const onMrnEntered = async ({ mrn }: { mrn: string }) => {
  dialogAddMrnLoading.value = true;
  try {
    const data = {
      customsHandlingId: currentPaperworkItem.value?.shipmentId!,
      mrn: mrn,
    };
    await customsPaperworkHandlingApi.setMrn(data);
    currentPaperworkItem.value!.mrn = mrn;
  } catch {
    emitError("Something went wrong while setting the mrn");
  }
  dialogAddMrnLoading.value = false;
  showDialogAddMrn.value = false;
};

const isAllowedToSave = computed(() => {
  return !!currentPaperworkItem.value?.documents?.every(
    (doc) => doc.category !== null,
  );
});

const save = async () => {
  const data: SavePaperworkDocumentsRequest = {
    documents: currentPaperworkItem.value?.documents,
  };

  try {
    isSaving.value = true;
    await customsPaperworkHandlingApi.savePaperworkDocuments(data);

    isLoading.value = true;
    await getPaperwork();
    isLoading.value = false;
  } catch {
    emitError(
      "Something went wrong while saving the categories to the corresponding documents",
    );
  }
  isSaving.value = false;
};

const openDialogShipmentAddMrn = (item: PaperworkItem) => {
  currentPaperworkItem.value = item;
  showDialogAddMrn.value = true;
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};
</script>
