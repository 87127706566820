<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="true"
      :loading="isLoading"
      height="calc(100vh - 325px)"
      fixed-header
      fixed-footer
      class="elevation-1"
    >
      <template #item.parcelNumbers="{ item }">
        <div class="d-flex flex-column align-center py-1">
          <span v-for="parcelNumber in item.parcelNumbers">
            {{ parcelNumber }}
          </span>
        </div>
      </template>

      <template #item.reportedToCustomer="{ item }">
        <div class="d-flex align-center">
          <v-text-field
            v-model="item.reportedToCustomer"
            :disabled="item.loading"
            @input="item.success = false"
          ></v-text-field>
          <v-btn
            color="teal"
            class="ml-4 white--text"
            :disabled="
              item.reportedToCustomer == item.initialReportedToCustomer
            "
            :loading="item.loading"
            @click="setReportedToCustomer(item)"
            >Save
            <v-icon v-if="item.success" right>mdi-check-circle</v-icon></v-btn
          >
        </div>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          class="v-btn--flexible text-center"
          :loading="isLoadingReturn"
          @click="onReturn(item)"
        >
          Return
          <v-icon class="ml-2">mdi-keyboard-return</v-icon></v-btn
        >
        <v-btn
          color="teal"
          class="white--text v-btn--flexible text-center ml-2"
          :loading="isLoadingRelease"
          @click="onRelease(item)"
        >
          Complete
          <v-icon class="ml-2">mdi-check</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <UploadPaperworkDialog
      v-if="currentItem"
      v-model="showDialog"
      :is-loading="dialogIsLoading"
      :parcel-number="currentItem.parcelNumbers?.[0]"
      @confirm="onFilesSelected"
    ></UploadPaperworkDialog>
  </div>
</template>

<script setup lang="ts">
import {
  CustomsDiscrepanciesApi,
  MissingPaperworkShipment,
  MissingPaperworkShipmentPagedResult,
} from "@/openapi";
import axios from "axios";
import { ref, watch, onBeforeMount } from "vue";
import {
  PaperworkGrouping,
  UploadPaperworkFormConfirmed,
} from "./dialogs/UploadPaperworkDialog.vue";
import UploadPaperworkDialog from "./dialogs/UploadPaperworkDialog.vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { serialize } from "object-to-formdata";
import { emitError } from "@/event-bus";

const api = new CustomsDiscrepanciesApi(undefined, "");

interface Enrichment {
  loading: boolean;
  success: boolean;
  initialReportedToCustomer: string;
}

type EnrichedMissingPaperworkShipment = MissingPaperworkShipment & Enrichment;

const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const isLoading = ref(false);
const isLoadingReturn = ref(false);
const isLoadingRelease = ref(false);

const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<EnrichedMissingPaperworkShipment[]>([]);
const totalAmountOfItems = ref(0);
const currentItem = ref<EnrichedMissingPaperworkShipment | null>(null);

const showDialog = ref(false);
const dialogIsLoading = ref(false);

const headers = ref([
  {
    text: "General customer",
    value: "customer",
    align: "center",
    sortable: false,
  },
  {
    text: "HAWB",
    value: "hawbNumber",
    align: "center",
    sortable: false,
  },
  {
    text: "Parcel numbers",
    value: "parcelNumbers",
    align: "center",
    sortable: false,
  },
  {
    text: "Destination country",
    value: "destinationCountry",
    align: "center",
    sortable: false,
  },
  {
    text: "Date, reported to customer",
    value: "reportedToCustomer",
    align: "center",
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    align: "end",
    sortable: false,
  },
]);

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeMount(() => {
  addRefreshToolbarOption(reloadMissingPaperworkShipments);
});

watch(
  () => options.value,
  (newVal) => {
    getMissingPaperworkShipments(options.value.page);
  },
);

const getMissingPaperworkShipments = async (page: number) => {
  isLoading.value = true;
  items.value = [];
  try {
    const response = await api.getShipmentsWithMissingPaperwork(
      page,
      options.value.itemsPerPage,
    );
    const data: MissingPaperworkShipmentPagedResult = response.data;
    items.value =
      data.items?.map((item) => ({
        ...item,
        loading: false,
        success: false,
        initialReportedToCustomer: item.reportedToCustomer ?? "",
      })) ?? [];
    totalAmountOfItems.value = data.totalAmountOfItems ?? 0;
  } catch {
    emitError(
      "Something went wrong while retrieving the missing paperwork shipments",
    );
  }
  isLoading.value = false;
};

const reloadMissingPaperworkShipments = async () => {
  options.value.page == 1
    ? await getMissingPaperworkShipments(options.value.page)
    : (options.value.page = 1);
};

const onReturn = async (item: MissingPaperworkShipment) => {
  isLoadingReturn.value = true;
  await returnShipment(item)
    .then(() => {
      isLoadingReturn.value = false;
      getMissingPaperworkShipments(options.value.page);
    })
    .catch(() => (isLoadingReturn.value = false));
};

const onRelease = async (item: EnrichedMissingPaperworkShipment) => {
  currentItem.value = item;
  showDialog.value = true;
};

const onFilesSelected = async (eventData: UploadPaperworkFormConfirmed) => {
  dialogIsLoading.value = true;

  await releaseShipment(
    currentItem.value!.shipmentId as number,
    eventData.paperworkGroupings,
  )
    .then(() => {
      dialogIsLoading.value = false;
      showDialog.value = false;
      getMissingPaperworkShipments(options.value.page);
    })
    .catch(() => (dialogIsLoading.value = false));
};

const returnShipment = async (item: MissingPaperworkShipment) => {
  try {
    await api.returnShipment({ shipmentId: item.shipmentId });
  } catch (e) {
    emitError(
      `Something went wrong during the "Return" action of the item with HawbNumber "${item.hawbNumber}"`,
    );
    throw e;
  }
};

const releaseShipment = async (
  shipmentId: number,
  paperworkGroupings: PaperworkGrouping[],
) => {
  try {
    const formData = serialize(
      {
        shipmentId: shipmentId,
        paperworkGroupings: paperworkGroupings.map((grouping) => ({
          paperworkCategory: grouping.category,
          documents: grouping.files,
        })),
      },
      {
        indices: true,
        dotsForObjectNotation: true,
        noFilesWithArrayNotation: true,
      },
    );
    await axios.post("/api/CustomsDiscrepancies/ReleaseShipment", formData);
  } catch (e) {
    emitError(
      `Something went wrong during the "Release" action of the item with id "${shipmentId}"`,
    );
    throw e;
  }
};

const setReportedToCustomer = async (
  item: EnrichedMissingPaperworkShipment,
) => {
  item.loading = true;
  try {
    await api.setReportedToCustomer({
      shipmentId: item.shipmentId,
      reportedToCustomer: item.reportedToCustomer,
    });
    item.success = true;
    item.initialReportedToCustomer = item.reportedToCustomer ?? "";
  } catch {
    emitError(
      'Something went wrong while setting the "Reported to customer" value.',
    );
  }

  item.loading = false;
};
</script>
