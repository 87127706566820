<template>
  <Fragment>
    <div class="d-flex">
      <div class="text-left mb-4 shipments-stock mr-auto">
        <v-autocomplete
          v-model="filters.customsReturnLocation"
          :items="dataStore.warehouseLocations"
          item-value="key"
          item-text="value"
          label="Warehouse location"
          clearable
          class="shipments-stock__filter"
          @change="onFilterSelected"
        >
        </v-autocomplete>

        <v-autocomplete
          v-model="filters.category"
          :items="dataStore.customsHandlingCategories"
          item-value="key"
          item-text="value"
          label="Category"
          clearable
          class="shipments-stock__filter"
          @change="onFilterSelected"
        >
        </v-autocomplete>
      </div>
      <div class="text-right mb-4">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-badge
              overlap
              offset-x="25"
              offset-y="10"
              color="error"
              :value="!!notAllowedShipmentsForConsolidationCreation.length"
            >
              <template #badge>
                <v-icon v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
              </template>
              <v-btn
                color="primary"
                class="mr-3"
                :class="
                  notAllowedShipmentsForConsolidationCreation.length
                    ? 'border border--error'
                    : ''
                "
                :disabled="!canCreateConsolidation"
                @click="openCreateConsolidationModal"
              >
                <v-icon color="black">mdi-plus</v-icon>
                Create consolidation
              </v-btn>
            </v-badge>
          </template>
          <span
            >The following shipments can't be used to create a
            consolidation:</span
          >
          <div>{{ notAllowedShipmentsForConsolidationCreation.join(",") }}</div>
        </v-tooltip>
        <v-btn
          color="primary"
          :disabled="
            $store.getters.disableByUserRights(!hasSelectedItems, 'Hide')
          "
          :loading="loadingHideShipments"
          class="mr-3"
          @click="showHideConfirmDialog = true"
        >
          <v-icon color="black">mdi-cancel</v-icon> Hide
        </v-btn>
        <v-btn
          :disabled="!hasItems"
          color="primary"
          class="mr-3"
          @click="exportShipments"
        >
          <v-icon color="black">mdi-file-export</v-icon> Export
        </v-btn>
      </div>
    </div>
    <CreateConsolidationDialog
      v-if="showCreateConsolidationDialog"
      v-model="showCreateConsolidationDialog"
      :shipment-ids="selectedShipmentIds"
      :only-t1="hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories"
      @created-consolidation="onCreatedConsolidation"
    ></CreateConsolidationDialog>
    <ConfirmDialog
      v-if="showHideConfirmDialog"
      v-model="showHideConfirmDialog"
      :is-loading="loadingHideShipments"
      @confirm="hideShipment"
    >
      <span>Confirm that you want to hide the following shipment(s):</span>
      <ul>
        <li v-for="selectedItem in selectedItems">
          <strong>{{ selectedItem.hawb }}</strong>
        </li>
      </ul>
    </ConfirmDialog>
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      item-key="hawb"
      height="calc(100vh - 281px)"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
      show-select
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
    >
      <template #item.multiPieceComplete="{ item }">
        <v-icon :color="item.multiPieceComplete ? 'success' : 'error'"
          >{{ item.multiPieceComplete ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>

      <template #item.inboundScanDate="{ item }">
        <span
          >{{
            item.inboundScanDate != null
              ? moment(item.inboundScanDate).format("DD-MM-YYYY")
              : ""
          }}
        </span>
      </template>

      <template #item.category="{ item }">
        <span>{{ getCategoryName(item.category) }} </span>
      </template>
    </v-data-table>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import moment from "moment";
import { CustomsHandlingConsolidationViewModel, ShipmentApi } from "@/openapi";
import { ref, watch, computed, onBeforeMount } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import CreateConsolidationDialog from "./dialogs/CreateConsolidationDialog.vue";
import { downloadFile } from "@/helpers/downloadHelper";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { useDataStore } from "@/stores/data-store";

interface Filters {
  customsReturnLocation: string | null;
  category: number | null;
}

const DapHoldNoInvoiceCreated = ref(3);
const DapHoldNotCustomsCleared = ref(4);

const api: ShipmentApi = new ShipmentApi(undefined, "");
const dataStore = useDataStore();
const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    align: "start",
    value: "hawb",
    groupable: false,
    sortable: false,
  },
  { text: "Pieces", value: "pieces", groupable: false, sortable: false },
  { text: "Weight", value: "weight", groupable: false, sortable: false },
  {
    text: "Multi piece complete",
    value: "multiPieceComplete",
    groupable: false,
    sortable: false,
  },
  { text: "Value", value: "value", groupable: false, sortable: false },
  {
    text: "Value currency",
    value: "valueCurrency",
    groupable: false,
    sortable: false,
  },
  {
    text: "Warehouse location",
    value: "warehouseLocation",
    groupable: false,
    sortable: false,
  },
  {
    text: "Incoterm",
    value: "incoterm",
    groupable: false,
    sortable: false,
  },
  {
    text: "Category",
    value: "category",
    groupable: false,
    sortable: false,
  },
  {
    text: "Inbound scan date",
    value: "inboundScanDate",
    groupable: false,
    sortable: false,
  },
  { text: "", value: "actions", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100, -1],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);

const items = ref<CustomsHandlingConsolidationViewModel[]>([]);

const selectedItems = ref<CustomsHandlingConsolidationViewModel[]>([]);
const isLoading = ref(false);
const loadingHideShipments = ref(false);

const showCreateConsolidationDialog = ref(false);
const showHideConfirmDialog = ref(false);
const filters = ref<Filters>({
  customsReturnLocation: null,
  category: null,
});

onBeforeMount(async () => {
  await Promise.all([
    dataStore.fetchWarehouseLocations(),
    dataStore.fetchCustomsHandlingCategories(),
  ]);
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getShipments(options.value.page);
    }, 250);
  },
);

const openCreateConsolidationModal = () => {
  showCreateConsolidationDialog.value = true;
};

const cancelCreateConsolidation = () => {
  showCreateConsolidationDialog.value = false;
};

const getShipments = async (page: number) => {
  isLoading.value = true;
  try {
    const response = await api.getShipmentsOnStock(
      filters.value.customsReturnLocation ?? undefined,
      filters.value.category ?? undefined,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    options.value.page = response.data.page ?? 0;
    options.value.itemsPerPage = response.data.itemsPerPage ?? 0;
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    selectedItems.value = [];
  } catch {
    emitError("Someting went wrong while retrieving the items.");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1 ? await getShipments(1) : (options.value.page = 1);
};

const hideShipment = async () => {
  loadingHideShipments.value = true;
  try {
    await api.hideShipment(selectedShipmentIds.value);
    selectedItems.value = [];
    await reloadShipments();
  } catch {
    emitError("Something went wrong while hiding the items.");
  }
  loadingHideShipments.value = false;
  showHideConfirmDialog.value = false;
};

const exportShipments = async () => {
  if (!items.value.length) {
    return;
  }
  try {
    const response = await api.exportShipment(
      {
        hasConsolidation: false,
        shipmentIds: items.value.map((item) => item.id!),
      },
      {
        responseType: "blob",
      },
    );
    downloadFile(response.data, "OnStock-" + Date.now() + ".xlsx");
  } catch {
    emitError("Something went wrong during the export");
  }
};

const onCreatedConsolidation = async () => {
  selectedItems.value = [];
  await reloadShipments();
};

const onFilterSelected = async () => {
  await reloadShipments();
};

const getCategoryName = (category: number) => {
  return (
    dataStore.customsHandlingCategories.find((c) => c.key === category)
      ?.value ?? ""
  );
};

const hasItems = computed(() => {
  return !!items.value?.length;
});

const hasSelectedItems = computed(() => {
  return !!selectedItems.value?.length;
});

const selectedShipmentIds = computed(() => {
  return selectedItems.value?.map((c) => c.id!) ?? [];
});

const hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories = computed(() => {
  if (!selectedItems.value.length) {
    return false;
  }
  return selectedItems.value.every(
    (c) =>
      c.category == DapHoldNoInvoiceCreated.value ||
      c.category == DapHoldNotCustomsCleared.value,
  );
});

const notAllowedShipmentsForConsolidationCreation = computed(() => {
  if (hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories.value) {
    return [];
  }
  return selectedItems.value
    .filter(
      (c) =>
        c.category == DapHoldNoInvoiceCreated.value ||
        c.category == DapHoldNotCustomsCleared.value,
    )
    .map((c) => c.hawb);
});

const canCreateConsolidation = computed(() => {
  if (hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories.value) {
    return true;
  }
  return (
    hasSelectedItems.value &&
    !selectedItems.value.some(
      (c) =>
        c.category === DapHoldNoInvoiceCreated.value ||
        c.category === DapHoldNotCustomsCleared.value,
    )
  );
});
</script>
