<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="tab" dark background-color="grey darken-3">
          <v-tab>Shipments on stock</v-tab>
          <v-tab>Shipments with consolidation</v-tab>
          <v-tab>History</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-3">
          <v-tab-item>
            <ShipmentsOnStock v-if="tab === 0"></ShipmentsOnStock>
          </v-tab-item>
          <v-tab-item>
            <ShipmentConsolidations v-if="tab === 1"></ShipmentConsolidations>
          </v-tab-item>
          <v-tab-item>
            <ShipmentConsolidationsHistory
              v-if="tab === 2"
            ></ShipmentConsolidationsHistory>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ShipmentsOnStock from "./components/ShipmentsOnStock.vue";
import ShipmentConsolidations from "./components/ShipmentConsolidations.vue";
import ShipmentConsolidationsHistory from "./components/ShipmentConsolidationsHistory.vue";

const isLoading = ref(false);
const tab = ref(null);
</script>
