<template>
  <td class="editable-field">
    <slot
      :make-editable="makeEditable"
      :remove-editable="removeEditable"
      :is-editable="isEditable"
    ></slot>
  </td>
</template>

<script setup lang="ts">
import { ref } from "vue";

const isEditable = ref(false);

const makeEditable = () => {
  isEditable.value = true;
};

const removeEditable = () => {
  setTimeout(() => {
    isEditable.value = false;
  }, 125);
};
</script>
