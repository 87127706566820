<template>
  <div class="container fill-height container" :class="{ disabled: isLoading }">
    <div class="row">
      <div class="col col-12">
        <v-card>
          <v-toolbar flat class="grey darken-3" color="white" dark>
            <v-toolbar-title class="grow">Services</v-toolbar-title>
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="services"
              sort-by="calories"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="grey lighten-4">
                  <v-select
                    class="mt-5 mr-3 ml-3"
                    :items="categories"
                    label="Categories"
                  ></v-select>
                  <v-select
                    class="mt-5"
                    :items="subCategories"
                    label="Sub Categories"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        elevation="2"
                        ><v-icon color="white">mdi-plus</v-icon> New</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5" v-html="formTitle"></span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.name"
                                label="Service name"
                              ></v-text-field>
                              <v-select
                                :items="categories"
                                label="Categories"
                              ></v-select>
                              <v-select
                                :items="subCategories"
                                label="Sub Categories"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch, nextTick } from "vue";

const currentTab = ref<number | null>(0);

const dialog = ref(false);

const dialogDelete = ref(false);
const isLoading: boolean = false;
const selectedTemplate = ref<number | null>(null);
const priceIsManually = ref(false);
const editedIndex = ref(-1);

const categories = ref([
  { text: "Import", value: "import" },
  { text: "Export", value: "export" },
  { text: "Single positions", value: "singlePositions" },
]);

const subCategories = ref([
  { text: "Fixed Values", value: "fixedValues" },
  { text: "System Values", value: "systemValues" },
  { text: "Flexible Values", value: "flexibleValues" },
]);

const editedItem = ref({
  name: "",
  category: "",
  subCategory: "",
});
const defaultItem = ref({
  name: "",
  category: "",
  subCategory: "",
});

const canEditRatecardName = ref(false);

const headers = ref([
  { text: "Name", value: "name", align: "start" },
  { text: "Category", value: "category", align: "start" },
  { text: "Sub Category", value: "subCategory", align: "start" },
  { text: "Actions", value: "actions", align: "end", sortable: false },
]);

const services = ref([
  { name: "Service 1A", category: "Import", subCategory: "Fixed Values" },
  {
    name: "Service 2A",
    category: "Export",
    subCategory: "System Values",
  },
  { name: "Service 3A", category: "Import", subCategory: "Fixed Values" },
  { name: "Service 4A", category: "Import", subCategory: "Fixed Values" },
]);

watch(
  () => dialog.value,
  (newValue) => {
    if (!newValue) {
      close();
    }
  },
);

const formTitle = computed(() => {
  return editedIndex.value === -1 ? "New Item" : "Edit Item";
});

const initialize = () => {};

const editItem = (item: any) => {
  editedIndex.value = services.value.indexOf(item);
  editedItem.value = Object.assign({}, item);
  dialog.value = true;
};

const deleteItem = (item: any) => {
  editedIndex.value = services.value.indexOf(item);
  editedItem.value = Object.assign({}, item);
  dialogDelete.value = true;
};

const deleteItemConfirm = () => {
  services.value.splice(editedIndex.value, 1);
  closeDelete();
};

const close = () => {
  dialog.value = false;
  nextTick(() => {
    editedItem.value = Object.assign({}, defaultItem.value);
    editedIndex.value = -1;
  });
};

const closeDelete = () => {
  dialogDelete.value = false;
  nextTick(() => {
    editedItem.value = Object.assign({}, defaultItem.value);
    editedIndex.value = -1;
  });
};

const save = () => {
  if (editedIndex.value > -1) {
    Object.assign(services.value[editedIndex.value], editedItem.value);
  } else {
    services.value.push(editedItem.value);
  }
  close();
};
</script>
