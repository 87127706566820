<template>
  <Fragment>
    <v-btn
      v-if="exportFlowStatus != 22"
      color="error"
      :class="cssClasses"
      @click="showDialog = true"
    >
      <span class="white--text"> Customs inspection </span>
    </v-btn>

    <SetInspectionReasonDialog
      v-if="showDialog"
      v-model="showDialog"
      :is-loading="loading"
      @confirm="onConfirmedInspectionReason"
    ></SetInspectionReasonDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { emitError } from "@/event-bus";
import { ShipmentStatusApi } from "@/openapi";

import SetInspectionReasonDialog, {
  SetInspectionReasonForm,
} from "../dialogs/SetInspectionReasonDialog.vue";
import { getCurrentInstance } from "vue";

interface IProps {
  shipmentId: number;
  exportFlowStatus?: number | null | undefined;
}

interface Successful {
  success: boolean;
}

const instance = getCurrentInstance();
const emits = defineEmits(["shipmentStatusChanged"]);
const props = defineProps<IProps>();
const shipmentStatusApi = new ShipmentStatusApi(undefined, "");
const loading = ref(false);
const showDialog = ref(false);

const onConfirmedInspectionReason = async (value: SetInspectionReasonForm) => {
  loading.value = true;
  let success = false;
  try {
    await shipmentStatusApi.setCustomsInspectionStatus({
      shipmentId: props.shipmentId,
      shipmentReason: value.reason,
    });

    success = true;
    showDialog.value = false;
  } catch {
    emitError(
      "Something went wrong while setting the Customs Inspection status.",
    );
  }

  loading.value = false;
  emits("shipmentStatusChanged", { success } as Successful);
};

const cssClasses = computed(() => {
  return instance?.proxy?.$vnode?.data?.staticClass ?? "";
});
</script>
