<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        IOSS Number: {{ item.iossNumber }}
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.customerId"
                hide-details
                dense
                item-text="shortName"
                :items="dataStore.generalCustomers"
                item-value="id"
                label="General customer*"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.iossNumber"
                hide-details
                dense
                label="IOSS Number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="form.enabled" label="Enabled"></v-checkbox>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!isFormValid || loading" text @click="save()">
          Save</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from "vue";
import { CustomsIossNumbersModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
interface IProps {
  show: boolean;
  loading: boolean;
  item: CustomsIossNumbersModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();
const emit = defineEmits(["close", "save"]);

const defaultForm: CustomsIossNumbersModel = {
  id: 0,
  customerId: 0,
  iossNumber: "",
  enabled: false,
};

const form = ref<CustomsIossNumbersModel>({ ...defaultForm });
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  emit("close");
};

const isFormValid = computed(() => {
  return form.value.customerId > 0 && form.value.iossNumber;
});

const save = () => {
  emit("save", form.value);
};

watch(
  () => props.item,
  (newVal) => {
    form.value = { ...newVal };
  },
);
onBeforeMount(() => {
  dataStore.fetchGeneralCustomers();
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
