import { render, staticRenderFns } from "./CustomsCountriesEU.vue?vue&type=template&id=8fd678f0"
import script from "./CustomsCountriesEU.vue?vue&type=script&setup=true&lang=ts"
export * from "./CustomsCountriesEU.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports