<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 error white--text">
        Remove IOSS number
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col>
              Are you sure you want to delete
              <strong>"{{ item.iossNumber }}"</strong>
            </v-col>
          </v-row>
        </v-container></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" color="error" text @click="deleteItem()">
          Delete</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { CustomsIossNumbersModel } from "@/openapi";
interface IProps {
  item: CustomsIossNumbersModel;
  loading: boolean;
  show: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["close", "delete"]);

const defaultForm: CustomsIossNumbersModel = {
  id: 0,
  customerId: 0,
  iossNumber: "",
  enabled: false,
};

const form = ref<CustomsIossNumbersModel>({ ...defaultForm });
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const deleteItem = () => {
  emit("delete", form.value);
};

const closeDialog = () => {
  emit("close");
};

watch(
  () => props.item,
  (newVal) => {
    form.value = { ...newVal };
  },
);
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
