<template>
  <div>
    <v-data-table
      fixed-header
      :height="height"
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      class="elevation-1"
      dense
      scrollable
      :loading="loading"
      @update:options="onUpdateOptions"
    >
      <template v-if="adminView" #item.action="{ item }">
        <v-btn icon small @click="openEditDialog(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon small @click="openDeleteDialog(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-if="adminView" #footer.prepend>
        <v-btn @click="openEditDialog(null)">
          <v-icon>mdi-plus</v-icon> ADD
        </v-btn>
      </template>
    </v-data-table>

    <CustomsParcelDialog
      :key="timeStampKey"
      :model-open="showEditDialog"
      :model-value="selectedItem"
      :model-saving="saving"
      @cancel="showEditDialog = false"
      @save="saveItem"
      @update:model-open="(value) => (showEditDialog = value)"
    ></CustomsParcelDialog>
    <DeleteDialog
      :model-open="showDeleteDialog"
      :name="selectedItem?.parcelNumber"
      :deleting="deleting"
      @cancel="showDeleteDialog = false"
      @delete="deleteItem"
      @update:model-open="(value) => (showDeleteDialog = value)"
    />
  </div>
</template>

<script setup lang="ts">
import { CustomsParcelApi, ShipmentDetailParcelViewModel } from "@/openapi";
import { ref, onMounted } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { getSortBy, getSortDesc } from "@/helpers/dataTableSortHelper";
import CustomsParcelDialog from "./dialogs/CustomsParcelDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { emitError, emitSuccess } from "@/event-bus";
interface IProps {
  shipmentId: number;
  height?: string;
  readonly?: boolean;
  adminView?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  height: "",
  readonly: false,
  adminView: false,
});

const parcelsApi = new CustomsParcelApi(undefined, "");

const items = ref<ShipmentDetailParcelViewModel[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions: any = {
  showFirstLastPage: true,
  itemsPerPageOptions: [1, 5, 25, 50, 100],
  disablePagination: false,
};
const headers = ref<DataTableHeader[]>([
  { text: "Parcelnumber", value: "parcelNumber", width: "30%" },
  { text: "Weight", value: "weight" },
  { text: "Dimensions", value: "dimensions" },
]);

const defaultModel = ref({
  id: 0,
  parcelNumber: null,
  weight: null,
  dimensions: null,
  costs: null,
  currency: null,
});

const selectedItem = ref<ShipmentDetailParcelViewModel>({
  ...defaultModel.value,
});
const timeStampKey = ref(Date.now());
const showEditDialog = ref(false);
const saving = ref(false);

const showDeleteDialog = ref(false);
const deleting = ref(false);

const openEditDialog = (item: ShipmentDetailParcelViewModel | null) => {
  timeStampKey.value = Date.now();
  selectedItem.value = item ? { ...item } : { ...defaultModel.value };
  showEditDialog.value = true;
};

const openDeleteDialog = (item: ShipmentDetailParcelViewModel) => {
  selectedItem.value = item;
  showDeleteDialog.value = true;
};

const saveItem = async () => {
  try {
    saving.value = true;
    const response = await parcelsApi.saveParcel(
      props.shipmentId,
      selectedItem.value,
    );

    saving.value = false;
    showEditDialog.value = false;
    selectedItem.value = { ...defaultModel.value };
    emitSuccess("Parcel saved.");
    await getParcels();
  } catch {
    emitError("Something went wrong while saving parcel.");
    saving.value = false;
  }
};

const deleteItem = async () => {
  try {
    await parcelsApi.deleteParcel(selectedItem.value.id);
    showEditDialog.value = false;
    selectedItem.value = { ...defaultModel.value };
    emitSuccess("Parcel deleted.");
  } catch {
    emitError("Something went wrong while deleting parcel.");
  }
  await getParcels();
  showDeleteDialog.value = false;
  selectedItem.value = { ...defaultModel.value };
};

onMounted(async () => {
  if (props.adminView) {
    headers.value.push({
      text: "",
      sortable: false,
      value: "action",
      align: "end",
    });
  }
  await getParcels();
});

const getParcels = async () => {
  try {
    loading.value = true;
    const response = await parcelsApi.getParcels(
      props.shipmentId,
      getSortBy(options.value),
      getSortDesc(options.value),
      options.value.page,
      options.value.itemsPerPage,
    );

    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError(
      "Something went wrong while retrieving the parcels of the shipment.",
    );
  }

  loading.value = false;
};

const onUpdateOptions = (options: DataOptions) => {
  getParcels();
};
</script>
