<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      :loading="loading"
      height="calc(100vh - 475px)"
    >
      <template #item.remark="{ item }">
        <div class="remark">
          <v-textarea
            v-model="item.remark"
            class="remark__editor"
            placeholder="Remark"
            :disabled="item.isSolved || item.isSaving"
            hide-details
          >
          </v-textarea>

          <v-btn
            v-if="!item.isSolved"
            color="success"
            class="remark__save"
            :loading="item.isSaving"
            @click="saveRemark(item)"
            >Save</v-btn
          >
        </div>
      </template>
      <template #item.isSolved="{ item }">
        <v-checkbox
          v-model="item.isSolved"
          :disabled="item.isSolved"
          @change="onSolved($event, item)"
        ></v-checkbox
      ></template>
    </v-data-table>
    <ConfirmDialog
      v-if="selectedItem"
      v-model="showConfirmSolvedDialog"
      title="Confirm action"
      close-button-text="Cancel"
      :is-loading="selectedItem.isMarking"
      @close="onClose"
      @confirm="onSolvedConfirm"
      >Are you sure you want to mark this remark as solved?</ConfirmDialog
    >
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitSuccess } from "@/event-bus";
import { DutiesAndTaxesApi, NotInBundeskasseDiscrepancyItem } from "@/openapi";
import { FooterOptions } from "@/types/types";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

interface DutiesAndTaxesdiscrepanciesProps {
  id: number;
}

interface EnrichedNotInBundeskasseDiscrepancyItem
  extends NotInBundeskasseDiscrepancyItem {
  isSaving: boolean;
  isMarking: boolean;
}

const api = new DutiesAndTaxesApi(undefined, "");

const props = defineProps<DutiesAndTaxesdiscrepanciesProps>();

const headers: DataTableHeader[] = [
  { text: "ATC", value: "atcNumber", sortable: false, width: "50px" },
  {
    text: "Charges Zöll",
    value: "chargesZoll",
    sortable: false,
    width: "115px",
  },
  {
    text: "Charges EUST",
    value: "chargesEust",
    sortable: false,
    width: "115px",
  },
  { text: "Remark", value: "remark", sortable: false },
  { text: "Solved", value: "isSolved", sortable: false, width: "75px" },
];

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [100, 200, 500],
  disablePagination: false,
});

const items = ref<EnrichedNotInBundeskasseDiscrepancyItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const selectedItem = ref<EnrichedNotInBundeskasseDiscrepancyItem | null>();

const showConfirmSolvedDialog = ref(false);

let timeoutDelay = 0;
watch(options, (newValue: DataOptions, oldValue: DataOptions) => {
  clearTimeout(timeoutDelay);
  timeoutDelay = setTimeout(async () => {
    await getItems();
  }, 250);
});

async function getItems(page?: number) {
  page ??= options.value.page;
  loading.value = true;
  try {
    const response = await api.getNotInBundeskasseDiscrepancies(
      props.id,
      page,
      options.value.itemsPerPage,
    );
    items.value =
      response.data.items?.map((item) => ({
        ...item,
        isSaving: false,
        isMarking: false,
      })) ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the items");
  }
  loading.value = false;
}

function onSolved(
  eventData: boolean,
  item: EnrichedNotInBundeskasseDiscrepancyItem,
) {
  if (eventData) {
    showConfirmSolvedDialog.value = true;
    selectedItem.value = item;
  }
}

function onClose() {
  selectedItem.value!.isSolved = false;
  selectedItem.value = null;
}

async function onSolvedConfirm() {
  try {
    selectedItem.value!.isMarking = true;
    await api.markAsSolved({
      dutiesTaxesId: props.id,
      discrepancyId: selectedItem.value!.id,
    });
    showConfirmSolvedDialog.value = false;
    emitSuccess("Successfully marked as solved");
  } catch {
    emitError("Something went wrong while marking this as solved");
    selectedItem.value!.isSolved = false;
  }
  selectedItem.value!.isMarking = false;
}

async function saveRemark(item: EnrichedNotInBundeskasseDiscrepancyItem) {
  try {
    item.isSaving = true;
    await api.saveRemark({
      dutiesTaxesId: props.id,
      discrepancyId: item.id,
      remark: item.remark,
    });
    emitSuccess("Successfully saved the remark");
  } catch {
    emitError("Something went wrong while saving the remark");
  }
  item.isSaving = false;
}
</script>

<style scoped lang="scss">
.remark {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;

  &__editor {
    width: 100%;
  }
}
</style>
