<template>
  <v-card
    :color="errorWithArticles ? 'error' : undefined"
    :outlined="errorWithArticles"
  >
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <v-tabs v-model="tab" dark background-color="grey darken-3">
        <v-tab>Articles to be worked on</v-tab>
        <v-tab>All articles</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pa-3">
        <v-tab-item>
          <v-data-table
            :headers="articleHeaders"
            calculate-widths
            :items="groupableArticles"
            item-key="id"
            hide-default-footer
            sort-by="rcTarif"
            group-by=""
            :custom-group="groupFunction"
            :disable-pagination="true"
            :disable-filtering="true"
            class="elevation-1"
          >
            <template #group.header="{ group, items, isOpen, toggle }">
              <th
                v-if="group !== 'dontGroup' && items && items.length > 0"
                colspan="9"
              >
                <v-row align-content="center">
                  <v-col class="d-flex align-center">
                    <h2 v-if="group">
                      <v-icon @click="toggle">
                        {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                      </v-icon>
                      RC Tarif: {{ group }}
                    </h2>
                  </v-col>
                  <v-col v-if="items.length > 1" cols="auto">
                    <v-btn
                      color="primary"
                      @click.native="groupSelectedArticlesDialog(items)"
                      >Group articles</v-btn
                    >
                  </v-col>
                  <v-col v-if="items.length === 1" cols="auto">
                    <v-btn
                      color="primary"
                      @click.native="groupSelectedArticlesDialog(items)"
                      >Set article info</v-btn
                    >
                  </v-col>
                </v-row>
              </th>
            </template>
            <template #item.groupArticle="{ item }">
              <v-checkbox
                v-model="item.groupArticle"
                hide-details
                color="primary"
                class="mt-0"
              ></v-checkbox>
            </template>
            <template #item.googleLinks="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :href="`https://www.google.com/search?q=${item.description}`"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-cloud-search-outline</v-icon></v-btn
                  >
                </template>
                <span>Search on Google</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :href="`https://translate.google.com/?sl=auto&tl=de&text=${item.description}&op=translate`"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-google-translate</v-icon></v-btn
                  >
                </template>
                <span>Translate using Google Translate</span>
              </v-tooltip>
            </template>
            <template #item.description="{ item }">
              <v-edit-dialog>
                {{ item.description }}
                <template #input>
                  <v-text-field
                    v-model="item.description"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.rcTarif="{ item }">
              <v-edit-dialog>
                {{ item.rcTarif }}
                <template #input>
                  <v-text-field
                    v-model="item.rcTarif"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.scTarif="{ item }">
              <v-edit-dialog>
                {{ item.scTarif }}
                <template #input>
                  <v-text-field
                    v-model="item.scTarif"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.eigenmasse="{ item }">
              <v-edit-dialog>
                {{ item.eigenmasse }}
                <template #input>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Eigenmasse"
                    :vid="`${item.id}-eigenmasse`"
                    rules="min_value:0.1"
                  >
                    <v-text-field
                      v-model="item.eigenmasse"
                      label="Edit"
                      single-line
                      type="number"
                      step="0.01"
                      min="0.1"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.rohmasse="{ item }">
              <v-edit-dialog>
                {{ item.rohmasse }}
                <template #input>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Rohmasse"
                    :vid="`${item.id}-rohmasse`"
                    rules="min_value:0.1"
                  >
                    <v-text-field
                      v-model="item.rohmasse"
                      label="Edit"
                      single-line
                      type="number"
                      step="0.01"
                      min="0.1"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.rechnungspreis="{ item }">
              <v-edit-dialog>
                {{ item.rechnungspreis }}
                <template #input>
                  <v-text-field
                    v-model="item.rechnungspreis"
                    label="Edit"
                    single-line
                    type="number"
                    step="0.01"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.rechnungswahrung="{ item }">
              <v-edit-dialog>
                {{ item.rechnungswahrung }}
                <template #input>
                  <v-autocomplete
                    v-model="item.rechnungswahrung"
                    :items="valuesCurrency"
                    item-text="name"
                    item-value="name"
                    single-line
                    class="mt-3"
                    label="Rechnungswahrung"
                  ></v-autocomplete>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            dense
            :headers="articleHeadersFullOverview"
            :items="articles"
            :items-per-page="10"
            class="elevation-1"
          >
            <template #item="{ item, headers }">
              <tr>
                <td v-for="header in headers" class="text-start">
                  <v-edit-dialog v-if="['artikel'].includes(header.value)">
                    {{ item[header.value] }}
                    <template #input>
                      <v-autocomplete
                        v-model="item[header.value]"
                        :items="articleTypes"
                        :item-text="getArticleTypeText"
                        item-value="name"
                        single-line
                        class="mt-3"
                        :label="header.text"
                        @input="setArticleFieldsBasedOnArticleType(item)"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                  <v-edit-dialog
                    v-else-if="
                      [
                        'wtnNumber',
                        'additionalCode',
                        'description',
                        'rcTarif',
                        'scTarif',
                      ].includes(header.value)
                    "
                  >
                    {{ item[header.value] }}
                    <template #input>
                      <v-text-field
                        v-model="item[header.value]"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <v-edit-dialog
                    v-else-if="
                      ['eigenmasse', 'rohmasse'].includes(header.value)
                    "
                  >
                    {{ getRoundedTo2DigitsValue(item[header.value]) }}
                    <template #input>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="header.value"
                        :vid="`${item.id}-${header.value}`"
                        rules="min_value:0.1"
                      >
                        <v-text-field
                          v-model="item[header.value]"
                          label="Edit"
                          single-line
                          type="number"
                          step="0.01"
                          min="0.1"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                  </v-edit-dialog>
                  <v-edit-dialog
                    v-else-if="
                      ['rechnungspreis', 'frachtkosten'].includes(header.value)
                    "
                  >
                    {{ getRoundedTo2DigitsValue(item[header.value]) }}
                    <template #input>
                      <v-text-field
                        v-model="item[header.value]"
                        label="Edit"
                        type="number"
                        step="0.01"
                        min="0"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <v-edit-dialog
                    v-else-if="
                      ['rechnungswahrung', 'frachtkostenWahrung'].includes(
                        header.value,
                      )
                    "
                  >
                    {{ item[header.value] }}
                    <template #input>
                      <v-autocomplete
                        v-model="item[header.value]"
                        :items="valuesCurrency"
                        item-text="name"
                        item-value="name"
                        single-line
                        class="mt-3"
                        :label="header.text"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                  <div
                    v-else-if="
                      [
                        'artikelPreis',
                        'zollwert',
                        'statWert',
                        'statMenge',
                        'zollMenge',
                      ].includes(header.value)
                    "
                  >
                    {{ getRoundedTo2DigitsValue(item[header.value]) }}
                  </div>
                  <v-edit-dialog
                    v-else-if="['massEinheit'].includes(header.value)"
                  >
                    {{ item[header.value] }}
                    <template #input>
                      <v-autocomplete
                        v-model="item[header.value]"
                        :items="unitsOfMeasurement"
                        :item-text="getMassEinheitText"
                        item-value="code"
                        single-line
                        class="mt-3"
                        :label="header.text"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                  <div v-else>{{ item[header.value] }}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <GroupArticlesDialog
        :group-article-dialog="groupArticleDialog"
        @updateGroupArticleDialog="(val) => (groupArticleDialog = val)"
      >
        <template #1>
          <v-col cols="12" sm="6" md="4">
            <ValidationProvider v-slot="{ errors }" name="Rc Tarif">
              <v-text-field
                v-model="groupArticleVm.rcTarif"
                label="Rc Tarif"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="groupArticleVm.scTarif"
              label="Sc Tarif"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="Article"
              rules="required"
            >
              <v-autocomplete
                v-model="groupArticleVm.artikel"
                label="Article"
                :items="articleTypes"
                :item-text="getArticleTypeText"
                item-value="name"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Description"
              rules="required"
            >
              <v-text-field
                v-model="groupArticleVm.description"
                label="Description"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </template>
        <template #2>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="Eigenmasse"
              rules="min_value:0.1"
            >
              <v-text-field
                v-model="groupArticleVm.eigenmasse"
                label="Eigenmasse"
                type="number"
                step="0.01"
                min="0.1"
                :error-messages="errors"
                @blur="
                  groupArticleVm.eigenmasse = getRoundedTo2DigitsValue(
                    groupArticleVm.eigenmasse,
                  )
                "
                @change="
                  groupArticleVm.eigenmasse = getRoundedTo2DigitsValue(
                    groupArticleVm.eigenmasse,
                  )
                "
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="Rohmasse"
              rules="min_value:0.1"
            >
              <v-text-field
                v-model="groupArticleVm.rohmasse"
                label="Rohmasse"
                type="number"
                step="0.01"
                min="0.1"
                :error-messages="errors"
                @blur="
                  groupArticleVm.rohmasse = getRoundedTo2DigitsValue(
                    groupArticleVm.rohmasse,
                  )
                "
                @change="
                  groupArticleVm.rohmasse = getRoundedTo2DigitsValue(
                    groupArticleVm.rohmasse,
                  )
                "
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="groupArticleVm.frachtkosten"
              label="Frachtkosten"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.frachtkosten = getRoundedTo2DigitsValue(
                  groupArticleVm.frachtkosten,
                )
              "
              @change="
                groupArticleVm.frachtkosten = getRoundedTo2DigitsValue(
                  groupArticleVm.frachtkosten,
                )
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="groupArticleVm.frachtkostenWahrung"
              label="Frachtkostenwährung"
              :items="valuesCurrency"
              item-text="name"
              item-value="name"
            ></v-autocomplete>
          </v-col>
        </template>
        <template #3>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="groupArticleVm.rechnungspreis"
              label="Rechnungspreis"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.rechnungspreis = getRoundedTo2DigitsValue(
                  groupArticleVm.rechnungspreis,
                )
              "
              @change="
                groupArticleVm.rechnungspreis = getRoundedTo2DigitsValue(
                  groupArticleVm.rechnungspreis,
                )
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="groupArticleVm.rechnungswahrung"
              label="Rechnungswährung"
              :items="valuesCurrency"
              item-text="name"
              item-value="name"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="groupArticleVm.additionalCode"
              label="Zusatzcode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="groupArticleVm.wtnNumber"
              label="WTN"
            ></v-text-field>
          </v-col>
        </template>
        <template #4="{ alignTop }">
          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <v-text-field
              v-model="groupArticleVm.statWert"
              label="Stat. Wert (EUR)"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.statWert = getRoundedTo2DigitsValue(
                  groupArticleVm.statWert,
                )
              "
              @change="
                groupArticleVm.statWert = getRoundedTo2DigitsValue(
                  groupArticleVm.statWert,
                )
              "
            ></v-text-field>
          </v-col>
          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <v-text-field
              v-model="groupArticleVm.zollwert"
              label="Zollwert (EUR)"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.zollwert = getRoundedTo2DigitsValue(
                  groupArticleVm.zollwert,
                )
              "
              @change="
                groupArticleVm.zollwert = getRoundedTo2DigitsValue(
                  groupArticleVm.zollwert,
                )
              "
            ></v-text-field>
          </v-col>
          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <v-text-field
              v-model="groupArticleVm.artikelPreis"
              label="Artikelpreis (EUR)"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.artikelPreis = getRoundedTo2DigitsValue(
                  groupArticleVm.artikelPreis,
                )
              "
              @change="
                groupArticleVm.artikelPreis = getRoundedTo2DigitsValue(
                  groupArticleVm.artikelPreis,
                )
              "
            ></v-text-field>
          </v-col>
          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <v-text-field
              v-model="groupArticleVm.statMenge"
              label="Stat. Menge"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.statMenge = getRoundedTo2DigitsValue(
                  groupArticleVm.statMenge,
                )
              "
              @change="
                groupArticleVm.statMenge = getRoundedTo2DigitsValue(
                  groupArticleVm.statMenge,
                )
              "
            ></v-text-field>
          </v-col>

          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <ValidationProvider
              v-slot="{ errors }"
              name="Statistische maßeinheit"
            >
              <v-autocomplete
                v-model="groupArticleVm.massEinheit"
                label="Statistische maßeinheit"
                :items="unitsOfMeasurement"
                :item-text="getMassEinheitText"
                item-value="code"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <ValidationProvider v-slot="{ errors }" name="Zollmenge Maßeinheit">
              <v-autocomplete
                v-model="groupArticleVm.zollMengeMassEinheit"
                label="Zollmenge Maßeinheit"
                :items="unitsOfMeasurement"
                :item-text="getMassEinheitText"
                item-value="code"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col :cols="!alignTop ? 12 : false" :sm="!alignTop ? 4 : false">
            <v-text-field
              v-model="groupArticleVm.zollMenge"
              label="Zollmenge"
              type="number"
              step="0.01"
              min="0"
              @blur="
                groupArticleVm.zollMenge = getRoundedTo2DigitsValue(
                  groupArticleVm.zollMenge,
                )
              "
              @change="
                groupArticleVm.zollMenge = getRoundedTo2DigitsValue(
                  groupArticleVm.zollMenge,
                )
              "
            ></v-text-field>
          </v-col>
        </template>
        <template #groupArticlesButton>
          <v-btn
            v-if="
              Array.isArray(groupArticleVm.articleIds) &&
              groupArticleVm.articleIds.length > 1
            "
            color="primary"
            @click="groupSelectedArticles"
          >
            Group articles
          </v-btn>
        </template>
        <template #setArticlesInfoButton>
          <v-btn
            v-if="
              Array.isArray(groupArticleVm.articleIds) &&
              groupArticleVm.articleIds.length === 1
            "
            color="primary"
            @click="groupSelectedArticles"
          >
            Set article info
          </v-btn>
        </template>
      </GroupArticlesDialog>
      <v-dialog v-model="showWtnNumberIsNot11Characters" width="500">
        <v-card>
          <v-card-text> The WTN field should have 11 digits </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="showWtnNumberIsNot11Characters = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import {
  ArticleViewModel,
  CurrencyViewModel,
  ArticleTypeViewModel,
  UnitOfMeasurementViewModel,
  GroupArticlesViewModel,
} from "@/openapi";
import { ValidationObserver } from "vee-validate";
import GroupArticlesDialog from "./dialogs/GroupArticlesDialog.vue";

class GroupFunctionItem {
  name: string = "";
  items: ArticleViewModel[] = [];
}
interface IProps {
  articles: ArticleViewModel[];
  valuesCurrency: CurrencyViewModel[];
  errorWithArticles: boolean;
  exchangeRateCurrencies: CurrencyViewModel[];
  frachtkostenPercentage: number | null | undefined;
  getRoundedTo2DigitsValue: Function;
  updateArticlePrices: boolean;
  articleTypes: ArticleTypeViewModel[];
  unitsOfMeasurement: UnitOfMeasurementViewModel[];
}

const props = withDefaults(defineProps<IProps>(), {
  errorWithArticles: true,
});
const emits = defineEmits([
  "update-zollwert",
  "update:group-by",
  "update:updateArticlePrices",
  "group-articles",
]);

const alignTop = ref(false);
const observer = ref<InstanceType<typeof ValidationObserver>>();

const groupArticleDialog = ref(false);
const groupArticleVm = ref({} as GroupArticlesViewModel);
const showWtnNumberIsNot11Characters = ref(false);

const groupableArticles = computed(() => {
  return props.articles.filter((a) => a.canBeGrouped);
});

const updateArticleCalculations = (articles: ArticleViewModel[]) => {
  for (const article of props.articles) {
    const articleCurrency = props.exchangeRateCurrencies.find(
      (cer) => cer.name === article.rechnungswahrung,
    );
    const freightCostsCurrency = props.exchangeRateCurrencies.find(
      (cer) => cer.name === article.frachtkostenWahrung,
    );
    const artikelPreis =
      articleCurrency != undefined
        ? article.rechnungspreis! / articleCurrency.exchangeRate!
        : 0;
    const zollWertInEuros =
      freightCostsCurrency != undefined
        ? article.frachtkosten! / freightCostsCurrency.exchangeRate!
        : 0;
    const zollwert =
      artikelPreis +
        ((props.frachtkostenPercentage !== undefined &&
        props.frachtkostenPercentage !== null
          ? props.frachtkostenPercentage
          : 0) /
          100) *
          zollWertInEuros ?? 0;

    article.artikelPreis = artikelPreis;
    article.zollwert = zollwert;
    article.statWert = Math.round(article.zollwert);
  }

  emits("update-zollwert");
};

const groupFunction = (
  items: ArticleViewModel[],
  groupBy: string[],
  groupDesc: boolean[],
) => {
  const groupedArray = items.reduce((arr, item) => {
    const notGroupedArticlesArr = arr.findIndex(
      (item) => item.name === "dontGroup",
    );
    let nonGroupedItems: ArticleViewModel[] = [];
    if (notGroupedArticlesArr !== -1) {
      nonGroupedItems = arr[notGroupedArticlesArr].items;
    } else {
      const newArrayItem = {
        name: "dontGroup",
        items: nonGroupedItems,
      } as GroupFunctionItem;
      arr.push(newArrayItem);
    }

    if (!item.groupArticle) {
      nonGroupedItems.push(item);
      return arr;
    }

    if (!item.rcTarif) {
      const individualItemGroup = {
        items: [item],
      } as GroupFunctionItem;
      arr.push(individualItemGroup);
      return arr;
    }

    const existingArrIndex = arr.findIndex(
      (arrItem, index) => arrItem.name === item.rcTarif,
    );
    if (existingArrIndex !== -1) {
      arr[existingArrIndex].items.push(item);
    } else {
      const newArrayItem = {
        name: item.rcTarif,
        items: [item],
      } as GroupFunctionItem;
      arr.push(newArrayItem);
    }

    return arr;
  }, [] as GroupFunctionItem[]);

  return groupedArray;
};

const groupSelectedArticles = () => {
  observer.value!.validateWithInfo().then((x) => {
    if (!x.isValid) {
      return;
    }

    if (
      groupArticleVm.value.wtnNumber !== null &&
      groupArticleVm.value.wtnNumber !== undefined &&
      groupArticleVm.value.wtnNumber.length !== 11
    ) {
      showWtnNumberIsNot11Characters.value = true;
      return;
    }

    groupArticleDialog.value = false;
    emits("group-articles", groupArticleVm.value);
  });
};

const groupSelectedArticlesDialog = (articles: ArticleViewModel[]) => {
  const articlesToGroup = articles.filter(
    (a) => a.groupArticle && a.canBeGrouped,
  );
  const articleIds = articlesToGroup.map((item) => item.id);
  const firstArticle = articlesToGroup[0];

  groupArticleDialog.value = true;
  groupArticleVm.value = {
    articleIds: articleIds,
    customsHandlingId: props.articles[0].customsHandlingId,
    rcTarif: firstArticle.rcTarif,
    scTarif: firstArticle.scTarif,
    additionalCode: firstArticle.additionalCode,
    artikel: firstArticle.artikel,
    description: firstArticle.description,
    frachtkostenWahrung: firstArticle.frachtkostenWahrung,
    frachtkosten: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat(
            (currentItem.frachtkosten ?? 0).toString(),
          )),
        0,
      ),
    ),
    rechnungswahrung: firstArticle.rechnungswahrung,
    wtnNumber: firstArticle.wtnNumber,
    artikelPreis: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat(
            (currentItem.artikelPreis ?? 0).toString(),
          )),
        0,
      ),
    ),
    eigenmasse: props.getRoundedTo2DigitsValue(
      getMinimumValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.eigenmasse ?? 0).toString(),
            )),
          0,
        ),
      ),
    ),
    freightcostPercentage: props.frachtkostenPercentage,
    rechnungspreis: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat(
            (currentItem.rechnungspreis ?? 0).toString(),
          )),
        0,
      ),
    ),
    rohmasse: props.getRoundedTo2DigitsValue(
      getMinimumValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.rohmasse ?? 0).toString(),
            )),
          0,
        ),
      ),
    ),
    statWert: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat((currentItem.statWert ?? 0).toString())),
        0,
      ),
    ),
    zollwert: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat((currentItem.zollwert ?? 0).toString())),
        0,
      ),
    ),
    massEinheit: firstArticle.massEinheit,
    zollMengeMassEinheit: firstArticle.zollMengeMassEinheit,
    statMenge: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat((currentItem.statMenge ?? 0).toString())),
        0,
      ),
    ),
    zollMenge: props.getRoundedTo2DigitsValue(
      articlesToGroup.reduce(
        (currentValue, currentItem) =>
          (currentValue += parseFloat((currentItem.zollMenge ?? 0).toString())),
        0,
      ),
    ),
  } as GroupArticlesViewModel;
};

const tab = ref(null);
const articleHeaders = ref([
  { text: "Group?", value: "groupArticle", sortable: false },
  { text: "", value: "googleLinks", sortable: false, width: "115px" },
  { text: "Warenbeschreibung", value: "description", width: "40%" },
  { text: "RC Tarif", value: "rcTarif" },
  { text: "SC Tarif", value: "scTarif" },
  { text: "Eigenmasse", value: "eigenmasse" },
  { text: "Rohmasse", value: "rohmasse" },
  { text: "Rechnungspreis", value: "rechnungspreis" },
  { text: "Rechnungswahrung", value: "rechnungswahrung" },
]);

const articleHeadersFullOverview = ref([
  {
    text: "Article",
    value: "artikel",
  },
  { text: "WTN", value: "wtnNumber" },
  { text: "Zusatzcode", value: "additionalCode" },
  { text: "Warenbeschreibung", value: "description" },
  { text: "RcTarif", value: "rcTarif" },
  { text: "ScTarif", value: "scTarif" },
  { text: "Eigenmasse", value: "eigenmasse" },
  { text: "Rohmasse", value: "rohmasse" },
  { text: "Rechnungspreis", value: "rechnungspreis" },
  { text: "Rechnungswahrung", value: "rechnungswahrung" },
  { text: "Artikelpreis", value: "artikelPreis" },
  { text: "Frachtkosten", value: "frachtkosten" },
  { text: "Frachtkostenwahrung", value: "frachtkostenWahrung" },
  { text: "Zollwert", value: "zollwert" },
  { text: "Stat. Wert", value: "statWert" },
  { text: "Stat. Menge", value: "statMenge" },
  { text: "Maßeinheit", value: "massEinheit" },
  { text: "Zollmenge", value: "zollMenge" },
]);

const getArticleTypeText = (item: ArticleTypeViewModel) => {
  return `${item.name} (${item.wtnNumber})`;
};

const getMassEinheitText = (item: UnitOfMeasurementViewModel) => {
  return `${item.code} - ${item.description}`;
};

const setArticleFieldsBasedOnArticleType = (item: ArticleViewModel) => {
  const articleType = props.articleTypes.find((a) => a.name === item.artikel);
  if (articleType !== undefined && articleType !== null) {
    item.wtnNumber = articleType.wtnNumber;
    item.description = articleType.description;
    item.additionalCode = articleType.additionalCode;
    item.massEinheit = articleType.unitOfMeasurement;
    item.zollMengeMassEinheit = articleType.zollMengeUnitOfMeasurement;
  }
};

const getMinimumValue = (value: number) => {
  return value > 0.1 ? value : 0.1;
};

watch(
  () => props.articles,
  (newValue) => {
    updateArticleCalculations(newValue);
    emits("update-zollwert");
    emits("update:group-by", "rcTarif");
  },
  { deep: true },
);

watch(
  () => props.updateArticlePrices,
  () => {
    updateArticleCalculations(props.articles);
    emits("update:updateArticlePrices", false);
  },
  { immediate: true },
);

watch(
  () => groupArticleVm.value.artikel,
  (newValue) => {
    const article = props.articleTypes.find((a) => a.name === newValue);
    if (article !== undefined && article !== null) {
      groupArticleVm.value.wtnNumber = article.wtnNumber;
      groupArticleVm.value.description = article.description;
      groupArticleVm.value.additionalCode = article.additionalCode;
      groupArticleVm.value.massEinheit = article.unitOfMeasurement;
      groupArticleVm.value.zollMengeMassEinheit =
        article.zollMengeUnitOfMeasurement;
    }
  },
);
</script>
