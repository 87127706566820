<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
    >
      <template v-slot:item.multiPieceComplete="{ item }">
        <v-icon :color="item.multiPieceComplete ? 'success' : 'error'"
          >{{ item.multiPieceComplete ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>

      <template v-slot:item.category="{ item }">
        <span>{{ getCategoryName(item.category) }} </span>
      </template>
    </v-data-table>
    <v-dialog
      v-if="showExportMrnModal"
      v-model="showExportMrnModal"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5"> Export MRN </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="exportMrn.mrnNumber"
            label="Export MRN number"
            single-line
          ></v-text-field>
          <v-text-field
            v-model="exportMrn.mawb"
            label="MAWB"
            single-line
          ></v-text-field>
          <v-text-field
            v-model="exportMrn.gatewayExport"
            label="Gateway Export"
            single-line
          ></v-text-field>
          <v-text-field
            v-model="exportMrn.sealNumber"
            label="Sealnumber"
            single-line
          ></v-text-field>
          <v-text-field
            v-model="exportMrn.registrationNumber"
            label="Registration number"
            single-line
          ></v-text-field>
          <v-checkbox
            class="mt-0 pt-0"
            label="Set return shipment loaded (outbound) milestone"
            v-model="exportMrn.returnShipmentLoaded"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancelExportMrn"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createExportMrn"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  CustomsHandlingConsolidationViewModel,
  Int32StringKeyValueItem,
  ShipmentApi,
} from "@/openapi";
import { DataAction } from "@/store/dataModule";
import { ref, watch, computed, onBeforeMount } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import store from "@/store";

const api = new ShipmentApi(undefined, "");
const props = defineProps({
  consolidation: { type: String, required: true, default: "" },
  dakosyReference: { type: String, required: true, default: "" },
});

const isLoading = ref(false);
const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    align: "start",
    value: "hawb",
    groupable: false,
    sortable: false,
  },
  { text: "Pieces", value: "pieces", groupable: false, sortable: false },
  { text: "Weight", value: "weight", groupable: false, sortable: false },
  {
    text: "Multi piece complete",
    value: "multiPieceComplete",
    groupable: false,
    sortable: false,
  },
  {
    text: "Export MRN",
    value: "exportMrn",
    groupable: false,
    sortable: false,
  },
  { text: "Value", value: "value", groupable: false, sortable: false },
  {
    text: "Value currency",
    value: "valueCurrency",
    groupable: false,
    sortable: false,
  },
  {
    text: "Warehouse location",
    value: "warehouseLocation",
    groupable: false,
    sortable: false,
  },
  {
    text: "Incoterm",
    value: "incoterm",
    groupable: false,
    sortable: false,
  },
  {
    text: "Category",
    value: "category",
    groupable: false,
    sortable: false,
  },
  { text: "", value: "actions", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);
const items = ref<CustomsHandlingConsolidationViewModel[]>([]);

const showExportMrnModal = ref(false);

watch(
  () => props.consolidation,
  (newVal) => {
    if (!!newVal) {
      getShipmentHistory(1);
    }
  },
);

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getShipmentHistory();
    }, 250);
  },
);

onBeforeMount(async () => {
  await store.dispatch(DataAction.FetchCustomsHandlingCategories);
});

const getShipmentHistory = async (page?: number) => {
  page ??= options.value.page;
  isLoading.value = true;
  try {
    const response = await api.getShipmentsOfConsolidationHistory(
      props.consolidation,
      props.dakosyReference,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    options.value.page = response.data.page ?? 1;
    options.value.itemsPerPage = response.data.itemsPerPage ?? 5;
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the items.");
  }
  isLoading.value = false;
};

const getCategoryName = (category: number) => {
  return categories.value.find((c) => c.key === category)?.value ?? "";
};

const categories = computed((): Int32StringKeyValueItem[] => {
  return store.getters.customsHandlingCategories;
});
</script>
