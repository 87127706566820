<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>
      <v-tabs
        v-model="discrepanciesTab"
        class="pt-3 pb-8 export-modus-inner-tabs"
        align-with-title
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Incomplete multipiece shipments</strong>
        </v-tab>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Shipments with missing paperwork scan</strong>
        </v-tab>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Damaged shipments</strong>
        </v-tab>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Paperwork without allocation</strong>
        </v-tab>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Consolidation</strong>
        </v-tab>
        <v-tab class="mr-3 discrepancies-inner-tabs__tab">
          <strong>Return shipment without data</strong>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="discrepanciesTab">
        <v-tab-item>
          <CustomsDiscrepanciesIncomplete
            v-if="discrepanciesTab === 0"
            @PageInfoReceived="$emit('PageInfoReceived', $event)"
          ></CustomsDiscrepanciesIncomplete>
        </v-tab-item>
        <v-tab-item>
          <CustomsDiscrepanciesMissing
            v-if="discrepanciesTab === 1"
            @PageInfoReceived="$emit('PageInfoReceived', $event)"
          ></CustomsDiscrepanciesMissing>
        </v-tab-item>
        <v-tab-item>
          <CustomsDiscrepanciesDamaged
            v-if="discrepanciesTab === 2"
            @PageInfoReceived="$emit('PageInfoReceived', $event)"
          ></CustomsDiscrepanciesDamaged>
        </v-tab-item>
        <v-tab-item>
          <CustomsDiscrepanciesUnallocated
            v-if="discrepanciesTab === 3"
            @PageInfoReceived="$emit('PageInfoReceived', $event)"
          ></CustomsDiscrepanciesUnallocated>
        </v-tab-item>
        <v-tab-item>
          <CustomsConsolidation
            v-if="discrepanciesTab === 4"
          ></CustomsConsolidation>
        </v-tab-item>
        <v-tab-item>
          <CustomsDiscrepanciesReturn
            v-if="discrepanciesTab === 5"
          ></CustomsDiscrepanciesReturn>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import CustomsDiscrepanciesIncomplete from "./CustomsDiscrepanciesIncomplete.vue";
import CustomsDiscrepanciesMissing from "./CustomsDiscrepanciesMissing.vue";
import CustomsDiscrepanciesDamaged from "./CustomsDiscrepanciesDamaged.vue";
import CustomsDiscrepanciesUnallocated from "./CustomsDiscrepanciesUnallocated.vue";
import CustomsConsolidation from "./CustomsConsolidation.vue";
import CustomsDiscrepanciesReturn from "./CustomsDiscrepanciesReturn.vue";
import { ref, watch } from "vue";
import { useConsolidationStore } from "@/stores/consolidation-store";

interface CustomsDiscrepanciesProps {
  color: string;
}

const consolidationStore = useConsolidationStore();
const props = withDefaults(defineProps<CustomsDiscrepanciesProps>(), {
  color: "",
});

const discrepanciesTab = ref(0);

watch(
  () => discrepanciesTab.value,
  (newValue: number, oldValue: number) => {
    if (oldValue === 4) {
      consolidationStore.resetState();
    }
  },
);
</script>
