<template>
  <Fragment>
    <div class="mb-4">
      <v-btn
        color="primary"
        :disabled="!hasSelectedItems || loading"
        @click="showDialog = true"
      >
        Move shipments from airport
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        :disabled="!hasSelectedItems || loading"
        @click="showNotMovedDialog = true"
      >
        Set not move reason
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        :disabled="!hasSelectedItems || loading"
        @click="toggleShowInHawbOverview"
      >
        Move to HAWB overview
      </v-btn>
    </div>
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="-1"
      height="500"
      hide-default-footer
      fixed-header
      show-select
    >
      <template #body.prepend>
        <tr>
          <td></td>
          <td>
            <v-text-field
              v-model="filters.hawb"
              clearable
              placeholder="Filter on paketscheinnummer"
              dense
              single-line
              @keyup.enter="onFilterSelected"
              @click:clear="(filters.hawb = ''), onFilterSelected()"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>

      <template #item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="!item.isSelectable"
          @input="select"
        ></v-simple-checkbox>
      </template>

      <template #item.showInHawbOverview="{ item }">
        <v-icon :color="item.showInHawbOverview ? 'success' : 'error'">{{
          item.showInHawbOverview ? "mdi-check" : "mdi-close"
        }}</v-icon>
      </template>
    </v-data-table>

    <CreateTransitDeclarationDialog
      v-if="showDialog"
      v-model="showDialog"
      :create-transit-declaration-loading="dialogLoading"
      @confirm="moveShipments"
    >
      <template #header
        >Move shipments</template
      ></CreateTransitDeclarationDialog
    >

    <ValidationObserver v-slot="{ invalid }">
      <DefaultDialog v-if="showNotMovedDialog" v-model="showNotMovedDialog">
        <template #header> Not moved reason </template>
        <template #content>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|max:200"
            name="Reason"
            slim
          >
            <v-textarea
              v-model="notMovedReason"
              label="Reason"
              :counter="200"
              :error-messages="errors"
            ></v-textarea>
          </ValidationProvider>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="notMovedDialogLoading"
            text
            @click="showNotMovedDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="invalid"
            :loading="notMovedDialogLoading"
            color="primary"
            text
            @click="setNotMovedReason"
            >Confirm</v-btn
          >
        </template>
      </DefaultDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  ConsolidationMoveableShipment,
  CustomsFlightHandlingApi,
} from "@/openapi";
import { ref, watch, computed, onBeforeMount } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import CreateTransitDeclarationDialog, {
  CreateTransitDeclarationForm,
} from "@/components/dialogs/CreateTransitDeclarationDialog.vue";

interface Filters {
  hawb?: string;
}

const api = new CustomsFlightHandlingApi(undefined, "");

interface Enrichment {
  isSelectable: boolean;
}

type EnrichedConsolidationMoveableShipment = ConsolidationMoveableShipment &
  Enrichment;

const props = defineProps({
  consolidationId: {
    type: Number,
    required: true,
  },
});

const loading = ref(false);
const items = ref<EnrichedConsolidationMoveableShipment[]>([]);
const currentItem = ref<EnrichedConsolidationMoveableShipment | null>(null);
const selectedItems = ref<EnrichedConsolidationMoveableShipment[]>([]);

const showDialog = ref(false);
const dialogLoading = ref(false);

const showNotMovedDialog = ref(false);
const notMovedDialogLoading = ref(false);
const notMovedReason = ref("");

const filters = ref<Filters>({
  hawb: "",
});

const headers = ref([
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
  },
  {
    text: "Gewicht ",
    value: "weight",
    sortable: false,
  },
  {
    text: "Show in HAWB overview",
    value: "showInHawbOverview",
    sortable: false,
  },
  {
    text: "Dakosy reference",
    value: "dakosyReference",
    sortable: false,
  },
  {
    text: "MRN",
    value: "mrn",
    sortable: false,
  },
  {
    text: "Not moved reason",
    value: "notMovedReason",
    sortable: false,
  },
]);

watch(
  () => showNotMovedDialog.value,
  (newVal) => {
    if (!newVal) {
      notMovedReason.value = "";
    }
  },
);

onBeforeMount(() => {
  getShipments();
});

const getShipments = async () => {
  loading.value = true;
  try {
    const response = await api.getConsolidationMoveableShipments(
      props.consolidationId,
      filters.value.hawb ?? "",
    );
    items.value = response.data
      .shipments!.map((item) => ({
        ...item,
        isSelectable:
          !item.notMovedReason && !item.dakosyReference && !item.mrn,
      }))
      .sort((a, b) => (a.isSelectable ? -1 : 1));
    selectedItems.value = [];
  } catch {
    emitError("Something went wrong while retrieving the moveable shipments.");
  }
  loading.value = false;
};

let timeout = 0;
const onFilterSelected = async () => {
  clearTimeout(timeout);
  timeout = setTimeout(async () => {
    await getShipments();
  }, 250);
};

const moveShipments = async (eventData: CreateTransitDeclarationForm) => {
  dialogLoading.value = true;

  try {
    await api.moveShipmentsFromAirport({
      amount: eventData.amount,
      country: eventData.country,
      ids: selectedItems.value.map((c) => c.id!),
      registration: eventData.registration,
      signs: eventData.signs.map((c) => c.value).filter((c) => !!c),
      routeId: eventData.routeId ?? undefined,
      templateId: eventData.templateId ?? undefined,
      transportAcrossEuBorder: eventData.transportAcrossEuBorder,
    });
    showDialog.value = false;
    selectedItems.value = [];
    await getShipments();
  } catch {
    emitError("Something went wrong while moving the shipment(s).");
  }

  dialogLoading.value = false;
};

const setNotMovedReason = async () => {
  try {
    notMovedDialogLoading.value = true;
    await api.setNotMovedReason({
      shipmentIds: selectedItems.value.map((c) => c.id!),
      reason: notMovedReason.value,
    });

    showNotMovedDialog.value = false;
    getShipments();
  } catch {
    emitError(
      "Something went wrong while setting the reason for not moving the shipment",
    );
  }

  notMovedDialogLoading.value = false;
};

const toggleShowInHawbOverview = async () => {
  try {
    loading.value = true;
    await api.toggleShowInHawbOverview(
      selectedItems.value.map((item) => item.id!),
    );
    for (const selectedItem of selectedItems.value) {
      selectedItem.showInHawbOverview = !selectedItem.showInHawbOverview;
    }
    selectedItems.value = [];
  } catch {
    emitError(
      'Something went wrong while changing the "Show in HAWB overview" status.',
    );
  }

  loading.value = false;
};

const hasSelectedItems = computed(() => {
  return !!selectedItems.value?.length;
});
</script>
