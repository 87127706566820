<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>
      <v-data-table
        :headers="headers"
        :items="loadableItems"
        :server-items-length="totalItems"
        :options.sync="options"
        :footer-props="footerOptions"
        :single-expand="true"
        item-key="id"
        height="calc(100vh - 235px)"
        dense
        fixed-header
        fixed-footer
        show-expand
        class="elevation-1"
        :loading="isLoading"
        @item-expanded="onExpandedWABItem"
      >
        <template #item.shipmentsAddedWarning="{ item }">
          <div class="d-flex">
            <div
              v-if="!item.hasShipmentsAddedRecently"
              class="export-warning-sign"
            ></div>
            <div
              v-else
              class="export-warning-sign export-warning-sign--active"
            ></div>
          </div>
        </template>

        <template #item.proceedToExport="{ item }">
          <v-btn
            small
            :loading="item.isLoading"
            :disabled="item.isLoading"
            @click="onProceedToExport(item)"
            >Proceed to export</v-btn
          >
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-flex>
              <v-container fluid>
                <v-row class="light-blue lighten-5">
                  <v-col cols="12">
                    <v-flex class="px-3" white>
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <v-data-table
                              :headers="customsHandlingItemsHeaders"
                              :items="customsHandlingItems"
                              :server-items-length="totalShipmentItems"
                              :options.sync="shipmentOptions"
                              :footer-props="footerOptions"
                              :single-expand="true"
                              :expanded.sync="expanded"
                              item-key="id"
                              dense
                              fixed-header
                              fixed-footer
                              show-expand
                              class="elevation-1"
                              :loading="isLoading"
                            >
                              <template #body.prepend>
                                <tr>
                                  <td>
                                    <v-icon small>mdi-filter</v-icon>
                                  </td>
                                  <td>
                                    <v-select
                                      v-model="filter.step"
                                      :items="statusFilter"
                                      clearable
                                      chips
                                      dense
                                      single-line
                                      @change="onFilterSelected"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-select
                                      v-model="filter.shipmentStatusDescription"
                                      :items="shipmentStatusFilter"
                                      clearable
                                      dense
                                      single-line
                                      @change="onFilterSelected"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <FilterParcels
                                      v-model="filter.parcels"
                                      @input="onFilterSelected"
                                    ></FilterParcels>
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="filter.exportMrn"
                                      clearable
                                      dense
                                      @keyup.enter="onFilterSelected"
                                      @click:clear="
                                        (filter.exportMrn = ''),
                                          onFilterSelected()
                                      "
                                    ></v-text-field>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <v-autocomplete
                                      v-model="filter.shipperCountry"
                                      :items="dataStore.originCountries"
                                      item-key="code"
                                      item-text="code"
                                      clearable
                                      dense
                                      single-line
                                      @change="onFilterSelected"
                                    ></v-autocomplete>
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      v-model="filter.consigneeCountry"
                                      :items="dataStore.originCountries"
                                      item-key="code"
                                      item-text="code"
                                      clearable
                                      dense
                                      single-line
                                      @change="onFilterSelected"
                                    ></v-autocomplete>
                                  </td>
                                </tr>
                              </template>

                              <template #item.exportFlowStatus="{ item }">
                                <v-avatar
                                  class="flow-status-avatar"
                                  size="22"
                                  :color="flowSteps[item.exportFlowStatus][3]"
                                >
                                  {{ item.exportFlowStatus }}
                                </v-avatar>
                              </template>

                              <template #item.exportMrn="{ item }">
                                <v-icon
                                  v-if="
                                    !item.isMaster && item.exportMrn == null
                                  "
                                  class="error--text"
                                  @click="openDialogShipmentAddMrn(item)"
                                >
                                  mdi-plus-circle
                                </v-icon>
                                <span v-else>
                                  {{
                                    item.isMaster
                                      ? item.exportMrns.join(",")
                                      : item.exportMrn
                                  }}
                                </span>
                              </template>

                              <template #item.shipper="{ item }">
                                <v-row justify="center">
                                  <v-col>
                                    {{ item.shipper.name }}<br />
                                    {{ item.shipper.address }}<br />
                                    {{ item.shipper.zipcode }}
                                    {{ item.shipper.city }}<br />
                                    {{ item.shipper.country }}
                                  </v-col>
                                </v-row>
                              </template>

                              <template #item.consignee="{ item }">
                                <v-row justify="center">
                                  <v-col>
                                    {{ item.consignee.name }}<br />
                                    {{ item.consignee.address }}<br />
                                    {{ item.consignee.zipcode }}
                                    {{ item.consignee.city }}<br />
                                    {{ item.consignee.country }}
                                  </v-col>
                                </v-row>
                              </template>

                              <template #item.value="{ item }">
                                <v-row>
                                  <v-col>
                                    {{ item.value.toFixed(2) }}
                                  </v-col>
                                </v-row>
                              </template>

                              <template #item.articles="{ item }">
                                <v-row
                                  v-for="article in item.articles.slice(0, 4)"
                                  no-gutters
                                >
                                  <v-col cols="9">
                                    <span class="articleDescription">{{
                                      article.description
                                    }}</span>
                                  </v-col>
                                  <v-col>
                                    <span class="articleDescription pl-4">{{
                                      article.rcTarif
                                    }}</span>
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-if="item.articles.length > 4"
                                  no-gutters
                                >
                                  <v-col cols="9">
                                    <span class="articleDescription"
                                      >more...</span
                                    >
                                  </v-col>
                                </v-row>
                              </template>

                              <template #expanded-item="{ headers, item }">
                                <td
                                  v-if="currentShipmentDetail"
                                  :colspan="headers.length"
                                >
                                  <v-flex class="pa-3 pb-5">
                                    <v-container fluid>
                                      <v-row>
                                        <v-col cols="12">
                                          <v-card>
                                            <v-card-title
                                              >Shipment status</v-card-title
                                            >
                                            <ShipmentPaperwork
                                              class="shipment-paperwork"
                                              :shipment-id="
                                                currentShipmentDetail.id
                                              "
                                            ></ShipmentPaperwork>
                                            <CustomsExportShipmentDetailViewNew
                                              :current-handling-item="
                                                currentShipmentDetail
                                              "
                                            />

                                            <div class="ms-6">
                                              <HoldShipmentButton
                                                :shipment-id="
                                                  currentShipmentDetail.id
                                                "
                                                :export-flow-status="
                                                  currentShipmentDetail.exportFlowStatus
                                                "
                                                class="ml-3 mb-5"
                                                @shipmentStatusChanged="
                                                  onShipmentStatusChanged
                                                "
                                              >
                                              </HoldShipmentButton>

                                              <ReturnShipmentButton
                                                :shipment-id="
                                                  currentShipmentDetail.id
                                                "
                                                class="ml-3 mb-5"
                                                @shipmentStatusChanged="
                                                  onShipmentStatusChanged
                                                "
                                              >
                                              </ReturnShipmentButton>

                                              <CustomsInspectionButton
                                                :shipment-id="
                                                  currentShipmentDetail.id
                                                "
                                                :export-flow-status="
                                                  currentShipmentDetail.exportFlowStatus
                                                "
                                                class="ml-3 mb-5"
                                                :on-shipment-status-changed="
                                                  onShipmentStatusChanged
                                                "
                                              >
                                              </CustomsInspectionButton>
                                            </div>
                                            <ShipmentStatusFlow
                                              :shipment-id="
                                                currentShipmentDetail.id
                                              "
                                              :active-status="
                                                currentShipmentDetail.exportFlowStatus
                                              "
                                            ></ShipmentStatusFlow>
                                            <template
                                              v-if="
                                                automatedSteps.indexOf(
                                                  currentShipmentDetail.exportFlowStatus,
                                                ) !== -1
                                              "
                                            >
                                              <v-btn
                                                :color="
                                                  flowSteps[
                                                    checkShipmentStep
                                                  ][3]
                                                "
                                                outlined
                                                :loading="
                                                  simulateAutomatedStepButton
                                                "
                                                class="mr-3"
                                                @click="
                                                  simulateAutomatedStepButton = true
                                                "
                                              >
                                                Simulate automated step
                                              </v-btn>
                                            </template>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-flex>
                                </td>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-col>
                </v-row>
              </v-container>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <AddMrnDialog
      v-if="currentHandlingItem"
      v-model="showDialogAddMrn"
      :parcel-number="currentHandlingItem.hawb"
      :is-loading="dialogAddMrnLoading"
      @confirm="onMrnEntered"
    ></AddMrnDialog>

    <AddRouteDialog
      v-model="showDialogAddRoute"
      :is-loading="dialogAddRouteLoading"
      title="Add route to shipments"
      @confirm="onRouteSelected"
    ></AddRouteDialog>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  color: string;
}

interface Loadable {
  isLoading: boolean;
}
import { ref, computed, watch, onBeforeMount, onBeforeUnmount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import AddRouteDialog from "./dialogs/AddRouteDialog.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import { getStatuses } from "@/helpers/statusHelper";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import {
  CustomsShipmentItemViewModel,
  CustomsWabApi,
  CustomsWabItem,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { useDataStore } from "@/stores/data-store";
const dataStore = useDataStore();
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const customsWabApi = new CustomsWabApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

const props = withDefaults(defineProps<IProps>(), {
  color: "",
});
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const shipmentOptions = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const totalShipmentItems = ref(0);
const expanded = ref([]);

const simulateAutomatedStepButton = ref(false);

const checkShipmentStep = ref(2);
const automatedSteps = ref([6, 9, 10, 11, 13]); // for testing

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const currentHandlingWABItem = ref<CustomsWabItem | null>(null);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const customsHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);

const showDialogAddMrn = ref(false);
const dialogAddMrnLoading = ref(false);

const showDialogAddRoute = ref(false);
const dialogAddRouteLoading = ref(false);

const items = ref<CustomsWabItem[]>([]);

const headers = ref([
  {
    text: "WAB Number",
    value: "wabNumber",
    align: "start",
    width: "15%",
  },
  {
    text: "Number of shipments in WAB",
    value: "numberOfShipmentsInWab",
    align: "start",
    width: "15%",
  },
  {
    text: "Active",
    value: "shipmentsAddedWarning",
    align: "start",
    width: "5%",
  },
  { text: "", value: "proceedToExport", align: "start" },
]);

const customsHandlingItemsHeaders = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

const timeInterval = ref(0);
onBeforeMount(async () => {
  customsFlowStepsOnBeforeMount();
  dataStore.fetchOriginCountries();
  statusFilter.value = getStatuses();

  // Update/retrieve the data every minute
  timeInterval.value = setInterval(() => {
    loadWabs(
      options.value.page,
      options.value.itemsPerPage,
      getSortField(options.value.sortBy),
      getSort(options.value.sortDesc),
    );
  }, 60000);

  emits("PageInfoReceived", [
    {
      callback: reloadWabs,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
});

onBeforeUnmount(() => {
  clearInterval(timeInterval.value);
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadWabs(
        newVal.page,
        newVal.itemsPerPage,
        getSortField(newVal.sortBy),
        getSort(newVal.sortDesc),
      );
    }, 250);
  },
  { deep: true },
);

const timeoutShipmentsDelay = ref(0);
watch(
  () => shipmentOptions.value,
  (newVal) => {
    clearTimeout(timeoutShipmentsDelay.value);
    timeoutShipmentsDelay.value = setTimeout(() => {
      loadShipmentItems(
        currentHandlingWABItem.value!.id!,
        shipmentOptions.value.page,
      );
    }, 250);
  },
  { deep: true },
);

watch(
  () => expanded.value,
  (newVal: CustomsShipmentItemViewModel[]) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value.id)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
  { deep: true },
);

const onExpandedWABItem = ({
  item,
  value,
}: {
  item: CustomsWabItem;
  value: boolean;
}) => {
  if (value) {
    const isDifferentWab =
      currentHandlingWABItem.value &&
      item?.id != currentHandlingWABItem.value?.id;
    currentHandlingWABItem.value = item;
    if (isDifferentWab) {
      customsHandlingItems.value = [];
      loadShipmentItems(item.id!, 1);
    }
    // Retrieval of the related shipments is otherwise done in `onShipmentOptionsChanged`.
  } else {
    currentHandlingWABItem.value = null;
    customsHandlingItems.value = [];
  }
};

const onProceedToExport = (item: CustomsWabItem & Loadable) => {
  currentHandlingWABItem.value = item;
  if (item.hasRoute) {
    item.isLoading = true;
    proceedToExport()
      .then(async () => {
        await loadWabs(
          options.value.page,
          options.value.itemsPerPage,
          getSortField(options.value.sortBy),
          getSort(options.value.sortDesc),
        );
      })
      .finally(() => (item.isLoading = false));
  } else {
    showDialogAddRoute.value = true;
  }
};

const openDialogShipmentAddMrn = (item: CustomsShipmentItemViewModel) => {
  stopLoadingButtons();
  currentHandlingItem.value = item;
  showDialogAddMrn.value = true;
};

const onFilterSelected = () => {
  loadShipmentItems(currentHandlingWABItem.value!.id!, 1);
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  const isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const loadableItems = computed(() => {
  return items.value.map((item) => ({ ...item, isLoading: false }));
});

const loadWabs = async (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  return customsWabApi
    .getWabs(page, itemsPerPage, orderByField, orderBy)
    .then((response) => {
      if (response.status != 200) {
        displayError("Something went wrong while retrieving the WABs.");
      }

      items.value = response.data.wabItems ?? [];
      totalItems.value = response.data.totalAmount ?? 0;
    })
    .catch((error) => {
      displayError("Something went wrong while retrieving the WABs.");
    })
    .finally(() => (isLoading.value = false));
};

const reloadWabs = async () => {
  options.value.page == 1
    ? await loadWabs(
        options.value.page,
        options.value.itemsPerPage,
        getSortField(options.value.sortBy),
        getSort(options.value.sortDesc),
      )
    : (options.value.page = 1);
};

const loadShipmentItems = async (wabId: number, page: number) => {
  isLoading.value = true;
  try {
    const response = await customsWabApi.getWabShipments({
      wabId,
      page,
      itemsPerPage: shipmentOptions.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });
    customsHandlingItems.value = response.data.shipments ?? [];
    totalShipmentItems.value = response.data.totalAmount ?? 0;
    currentHandlingWABItem.value!.numberOfShipmentsInWab =
      response.data.totalAmount ?? 0;
    shipmentOptions.value.page = page;
  } catch (error) {
    displayError(
      "Something went wrong while retrieving the corresponding shipments.",
    );
  }
  isLoading.value = false;
};

const stopLoadingButtons = () => {
  simulateAutomatedStepButton.value = false;
};

const closeAllDialogs = () => {
  showDialogAddMrn.value = false;
  showDialogAddRoute.value = false;
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const proceedToExport = async (routeId?: number) => {
  try {
    const response = await customsWabApi.proceedToExport({
      wabId: currentHandlingWABItem.value!.id,
      routeId: routeId,
    });
    currentHandlingWABItem.value = null;
    return response;
  } catch (e) {
    displayError('Something went wrong during the "Proceed to export" action.');
    throw e;
  }
};

const onRouteSelected = async (value: any) => {
  dialogAddRouteLoading.value = true;
  proceedToExport(value.routeId)
    .then(async () => {
      await loadWabs(
        options.value.page,
        options.value.itemsPerPage,
        getSortField(options.value.sortBy),
        getSort(options.value.sortDesc),
      );
    })
    .finally(() => {
      dialogAddRouteLoading.value = false;
      showDialogAddRoute.value = false;
    });
};

const onMrnEntered = async ({ mrn }: { mrn: string }) => {
  dialogAddMrnLoading.value = true;
  try {
    await customsWabApi.setMrnAndRemoveShipmentFromWab(
      currentHandlingItem.value!.id,
      mrn,
    );
    if (customsHandlingItems.value.length == 1) {
      await loadWabs(
        options.value.page,
        options.value.itemsPerPage,
        getSortField(options.value.sortBy),
        getSort(options.value.sortDesc),
      );
    } else {
      await loadShipmentItems(
        currentHandlingWABItem.value!.id!,
        shipmentOptions.value.page,
      );
    }
  } catch (error) {
    displayError(
      "Something went wrong while setting the MRN and removing the shipment from the WAB.",
    );
  }

  dialogAddMrnLoading.value = false;
  showDialogAddMrn.value = false;
};

const onShipmentStatusChanged = ({ success }: any) => {
  if (success) {
    loadShipmentItems(currentHandlingWABItem.value!.id!, options.value.page);
  }
};
</script>
