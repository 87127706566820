<template>
  <ValidationProvider rules="required">
    <v-select
      :value="value"
      :items="computedItems"
      :loading="loading"
      @change="emits('input', $event)"
    ></v-select>
  </ValidationProvider>
</template>
<script setup lang="ts">
import { emitError } from "@/event-bus";
import { DataApi } from "@/openapi";
import { ValidationProvider } from "vee-validate";
import { computed, ref, watch } from "vue";
import { VSelect } from "vuetify/lib";

interface TransitTemplateEditorProps {
  value?: number | null;
  definitionRouteId?: number;
}

interface SelectItem {
  value: number;
  text: string;
  disabled: boolean;
}

const dataApi = new DataApi(undefined, "");

const props = defineProps<TransitTemplateEditorProps>();
const emits = defineEmits(["input"]);
const items = ref<SelectItem[]>([]);
const loading = ref(false);

watch(
  () => props.definitionRouteId,
  async (
    newValue: number | null | undefined,
    oldValue: number | null | undefined,
  ) => {
    if (!newValue) {
      items.value = [];
      return;
    }

    loading.value = true;
    items.value = [];
    try {
      const response = await dataApi.getTransitDeclarationTemplateValues(
        props.definitionRouteId,
      );
      items.value =
        response.data?.map(
          (c) =>
            ({
              value: c.key,
              text: c.value,
              disabled: false,
            }) as SelectItem,
        ) ?? [];
    } catch {
      emitError("Something went wrong while retrieving the templates");
    }
    loading.value = false;
  },
);

const computedItems = computed(() => {
  const itemsCopy = items.value?.slice(0);
  if (
    props.value &&
    itemsCopy?.length &&
    !itemsCopy?.some((c) => c.value === props.value)
  ) {
    itemsCopy?.unshift({
      value: props.value,
      text: "UNLINKED ITEM",
      disabled: true,
    } as SelectItem);
  }

  return itemsCopy;
});
</script>
