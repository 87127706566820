<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :footer-props="footerOptions"
    :server-items-length="totalAmountOfItems"
    :loading="loading"
    height="calc(100vh - 475px)"
  ></v-data-table>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  BundeskasseDiscrepancyItem,
  DutiesAndTaxesApi,
  NotInSovexDiscrepancyItem,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";

interface DutiesAndTaxesdiscrepanciesProps {
  id: number;
}

interface GenericDiscrepancyItem {
  atcNumber: string;
}

const api = new DutiesAndTaxesApi(undefined, "");

const props = defineProps<DutiesAndTaxesdiscrepanciesProps>();

const headers: DataTableHeader[] = [
  { text: "ATC", value: "atcNumber", sortable: false },
  { text: "Value", value: "value", sortable: false },
];

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [100, 200, 500],
  disablePagination: false,
});

const items = ref<BundeskasseDiscrepancyItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

let timeoutDelay = 0;
watch(options, (newValue: DataOptions, oldValue: DataOptions) => {
  clearTimeout(timeoutDelay);
  timeoutDelay = setTimeout(async () => {
    await getItems();
  }, 250);
});

async function getItems(page?: number) {
  page ??= options.value.page;
  loading.value = true;
  try {
    const response = await api.getAllDiscrepancies(
      props.id,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the items");
  }
  loading.value = false;
}
</script>
