<template>
  <Fragment>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <ConfirmDialog
        v-model="localValue"
        title="Set keywords"
        :is-confirm-disabled="invalid"
        @confirm="onConfirm"
      >
        <MultipleItemsEditor
          v-model="form.keywords"
          :constructor-fn="createItem"
        >
          <template #editor="{ item }">
            <div :key="item.id" class="article-keyword-editor">
              <ValidationProvider
                v-slot="{ errors }"
                slim
                rules="max:100"
                name="Keyword"
              >
                <v-text-field
                  v-model="item.keyword"
                  label="Keyword"
                  :counter="100"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                slim
                rules="max:200"
                name="Remarks"
              >
                <v-text-field
                  v-model="item.remarks"
                  label="Remarks"
                  :counter="200"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                slim
                rules="max:200"
                name="Customs description"
              >
                <v-text-field
                  v-model="item.customsDescription"
                  label="Customs description"
                  :counter="200"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </template>
        </MultipleItemsEditor>
      </ConfirmDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { useFormReset } from "@/composables/formReset";
import { CustomsArticleKeywordDto } from "@/openapi";
import { ValidationObserver } from "vee-validate";
import { computed, ref, watch } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import MultipleItemsEditor from "@/components/editors/MultipleItemsEditor.vue";

interface KeywordsDialogFormData {
  keywords: CustomsArticleKeywordDto[];
}

interface KeywordsDialogProps {
  value: boolean;
  keywords: CustomsArticleKeywordDto[] | null | undefined;
}

const defaultFormValues: KeywordsDialogFormData = {
  keywords: [
    {
      id: null,
      keyword: "",
      remarks: "",
      customsDescription: "",
    },
  ],
};

const props = defineProps<KeywordsDialogProps>();
const emits = defineEmits(["input", "close", "confirm"]);

const observer = ref<InstanceType<typeof ValidationObserver>>();
const form = ref<KeywordsDialogFormData>({ ...defaultFormValues });

watch(
  () => props.keywords,
  (
    newValue: CustomsArticleKeywordDto[] | null | undefined,
    oldValue: CustomsArticleKeywordDto[] | null | undefined,
  ) => {
    if (!newValue?.length) {
      form.value = { ...defaultFormValues };
    } else {
      form.value = { keywords: structuredClone(newValue) };
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const createItem = () => {
  return {
    keyword: "",
    remarks: "",
    customsDescription: "",
  };
};

const localValue = computed({
  get() {
    return props.value;
  },
  set(value: boolean) {
    emits("input", value);
  },
});

useFormReset(localValue, form, defaultFormValues, observer);

const onConfirm = () => {
  emits("confirm", structuredClone(form.value.keywords));
};
</script>

<style scoped lang="scss">
.article-keyword-editor {
  display: flex;
  gap: 15px;
}
</style>
