<template>
  <div>
    <v-card class="mr-4 mb-4 pa-6" color="#FFF8E1">
      <v-dialog v-model="showParcelsDialog" width="1200">
        <template #activator="{ on, attrs }">
          <v-btn class="mb-4" color="black" dark v-bind="attrs" v-on="on">
            Parcels
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Parcels </v-card-title>
          <v-card-text style="height: 500px">
            <CustomsParcels
              class="mt-4"
              height="410px"
              :shipment-id="currentHandlingItem.id"
              :admin-view="adminView && exportFlowStatusIs7OrLower"
            ></CustomsParcels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showParcelsDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <CustomsExportMrnDialog
        :id="currentHandlingItem.id"
        :is-master="currentHandlingItem.isMaster"
        :model-open="showCustomsExportMrnDialog"
        @cancel="closeEditExportMrnsDialog"
        @saved="exportMrnsSaved"
      ></CustomsExportMrnDialog>

      <v-form v-model="isFormValid" @submit.prevent>
        <v-row>
          <v-col cols="4">
            <v-row v-if="currentHandlingItem.shipmentStatusDescription">
              <v-col>
                <p class="text-h5 font-weight-bold red--text">
                  {{ currentHandlingItem.shipmentStatusDescription }}
                </p>
                <p class="font-weight-bold red--text">
                  {{ currentHandlingItem.shipmentReason }}
                </p>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium"
                >Paketscheinnummer</v-col
              >
              <v-col>{{ currentHandlingItem.hawb }}</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Anzahl</v-col>
              <v-col>{{ currentHandlingItem.pieces }}</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Gewicht</v-col>
              <v-col>
                <EditableTextField
                  v-model.number="currentHandlingItem.grossWeight"
                  :readonly="readonly"
                  type="number"
                  :text-field-attr="{ min: 0, step: 0.1 }"
                ></EditableTextField>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Incoterm</v-col>
              <v-col>
                <EditableDropdown
                  v-if="incoTerms && incoTerms.length"
                  :value="getIncoterm(currentHandlingItem.incoTerm)"
                  :readonly="readonly || !currentHandlingItem.isNorway"
                  :items="incoTerms"
                  @input="currentHandlingItem.incoTerm = $event.key"
                ></EditableDropdown>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Invoice number</v-col>
              <v-col>
                <EditableTextField
                  v-if="!currentHandlingItem.isMaster"
                  v-model="currentHandlingItem.invoiceNumber"
                  :readonly="readonly || !currentHandlingItem.isNorway"
                ></EditableTextField>
                <span v-else>{{
                  !!invoiceNumbers ? invoiceNumbers : "x"
                }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium"
                >Reason for export</v-col
              >
              <v-col>
                <EditableTextField
                  v-model="currentHandlingItem.reasonForExport"
                  :readonly="readonly || !currentHandlingItem.isNorway"
                ></EditableTextField>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium"
                >Country of origin</v-col
              >
              <v-col>
                <EditableDropdown
                  v-if="originCountries && originCountries.length"
                  :value="
                    getCountryOfOrigin(currentHandlingItem.countryOfOrigin)
                  "
                  :items="originCountries"
                  :readonly="readonly || !currentHandlingItem.isNorway"
                  @input="currentHandlingItem.countryOfOrigin = $event.value"
                ></EditableDropdown>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Warenwert</v-col>
              <v-col>
                <EditableTextField
                  v-model.number="currentHandlingItem.value"
                  wrapper="span"
                  type="number"
                  class="pe-1"
                  :text-field-attr="{ min: 0, step: 0.1 }"
                  :readonly="readonly"
                ></EditableTextField>
                <EditableDropdown
                  v-if="currencies && currencies.length"
                  :value="getCurrency(currentHandlingItem.valueCurrency ?? '')"
                  :items="currencies"
                  :readonly="readonly"
                  wrapper="span"
                  @input="currentHandlingItem.valueCurrency = $event.value"
                ></EditableDropdown>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">EORI-Nummer</v-col>
              <v-col>
                <EditableTextField
                  v-model="currentHandlingItem.eori"
                  :readonly="readonly"
                ></EditableTextField>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Versender</v-col>
              <v-col>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipper.name"
                    :readonly="readonly"
                    placeholder="Name"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipper.address"
                    :readonly="readonly"
                    placeholder="Address"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipper.zipcode"
                    :readonly="readonly"
                    placeholder="Zipcode"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipper.city"
                    wrapper="span"
                    class="pe-1"
                    :readonly="readonly"
                    placeholder="City"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipper.country"
                    wrapper="span"
                    :readonly="readonly"
                    placeholder="Country"
                  ></EditableTextField>
                  <br />
                </span>
                <span v-if="currentHandlingItem.shipperContact.contact"
                  >{{ currentHandlingItem.shipperContact.contact }}<br
                /></span>
                <span v-if="currentHandlingItem.shipperContact.phone"
                  >{{ currentHandlingItem.shipperContact.phone }}<br
                /></span>
                <span v-if="currentHandlingItem.shipperContact.email"
                  >{{ currentHandlingItem.shipperContact.email }}<br
                /></span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.shipperVat"
                    wrapper="span"
                    class="pe-1"
                    :readonly="readonly || !currentHandlingItem.isNorway"
                    placeholder="VatNo."
                  ></EditableTextField>
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Empfaenger</v-col>
              <v-col>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consignee.name"
                    :readonly="readonly"
                    placeholder="Name"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consignee.address"
                    :readonly="readonly"
                    placeholder="Address"
                  ></EditableTextField
                ></span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consignee.zipcode"
                    :readonly="readonly"
                    placeholder="Zipcode"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consignee.city"
                    wrapper="span"
                    class="pe-1"
                    :readonly="readonly"
                    placeholder="City"
                  ></EditableTextField>
                </span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consignee.country"
                    wrapper="span"
                    :readonly="readonly"
                    placeholder="Country"
                  ></EditableTextField>
                  <br />
                </span>
                <span v-if="currentHandlingItem.consigneeContact.contact"
                  >{{ currentHandlingItem.consigneeContact.contact }}<br
                /></span>
                <span v-if="currentHandlingItem.consigneeContact.phone"
                  >{{ currentHandlingItem.consigneeContact.phone }}<br
                /></span>
                <span v-if="currentHandlingItem.consigneeContact.email"
                  >{{ currentHandlingItem.consigneeContact.email }}<br
                /></span>
                <span>
                  <EditableTextField
                    v-model="currentHandlingItem.consigneeVat"
                    wrapper="span"
                    class="pe-1"
                    :readonly="readonly || !currentHandlingItem.isNorway"
                    placeholder="VatNo."
                  ></EditableTextField>
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4" class="font-weight-medium">Remarks</v-col>
              <v-col cols="8">
                <div class="remark-wrapper">
                  <EditableTextField v-model="currentHandlingItem.remarks">
                    <template #editor="{ on }">
                      <v-textarea
                        :value="currentHandlingItem.remarks"
                        append-icon="mdi-arrow-u-left-top"
                        placeholder="Remarks"
                        :autofocus="true"
                        v-on="on"
                      >
                      </v-textarea>
                    </template>
                  </EditableTextField></div
              ></v-col>
            </v-row>
          </v-col>

          <v-col cols="8">
            <v-row no-gutters>
              <v-col cols="3" class="pl-4 font-weight-medium"
                >Warenbeschreibung</v-col
              >
              <v-col class="font-weight-medium pl-4">Goods item number </v-col>
              <v-col class="font-weight-medium pl-4">Warentarifnummer </v-col>
              <v-col class="font-weight-medium pl-4">Net weight </v-col>
              <v-col class="font-weight-medium pl-4">Gross weight </v-col>
              <v-col class="font-weight-medium pl-4">Value </v-col>
              <v-col class="font-weight-medium pl-4">Currency </v-col>
              <v-col class="font-weight-medium pl-4">Country of Origin </v-col>
              <v-col class="font-weight-medium pl-4">Action </v-col>
            </v-row>

            <v-row
              v-for="item in currentHandlingItem.articles"
              :key="item.id"
              no-gutters
              class="pl-4"
            >
              <v-col cols="3" class="overflow-x-auto">
                <EditableTextField
                  v-model="item.description"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-1">
                <EditableTextField
                  v-model.number="item.goodsItemNumber"
                  type="number"
                  step="1"
                  min="1"
                  max="999"
                  :rules="[rules.goodsItemNumber]"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-1">
                <EditableTextField
                  v-model="item.rcTarif"
                  :rules="[rules.rcTarif]"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-4">
                <EditableTextField
                  type="number"
                  :value="item.eigenmasse"
                  :text-field-attr="{ min: 0, step: 0.01 }"
                  :rules="[rules.maxWeight]"
                  @input="item.eigenmasse = parseFloat($event)"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-4">
                <EditableTextField
                  :value="item.rohmasse"
                  type="number"
                  :text-field-attr="{ min: 0, step: 0.01 }"
                  :rules="[rules.maxWeight]"
                  @input="item.rohmasse = parseFloat($event)"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-4">
                <EditableTextField
                  type="number"
                  :value="item.rechnungspreis"
                  :text-field-attr="{ min: 0, step: 0.01 }"
                  @input="item.rechnungspreis = parseFloat($event)"
                ></EditableTextField>
              </v-col>
              <v-col class="pl-4">
                <EditableDropdown
                  v-if="currencies && currencies.length"
                  :value="getCurrency(item.rechnungswahrung ?? '')"
                  :items="currencies"
                  @input="item.rechnungswahrung = $event.value"
                ></EditableDropdown>
              </v-col>
              <v-col class="pl-4">
                <EditableDropdown
                  v-if="originCountries && originCountries.length"
                  :value="getCountryOfOrigin(item.countryOfOrigin)"
                  :items="originCountries"
                  @input="item.countryOfOrigin = $event.value"
                ></EditableDropdown>
              </v-col>
              <v-col class="pl-4">
                <v-icon
                  :disabled="currentHandlingItem.exportFlowStatus >= 5"
                  left
                  color="error"
                  @click="
                    (currentArticle = item), (showConfirmDeleteDialog = true)
                  "
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>

            <v-icon class="error--text mt-1 pl-4" @click="addEmptyArticle">
              mdi-plus-circle
            </v-icon>
            <v-row no-gutters class="pl-4 mt-6 article-total">
              <v-col class="article-total__title" cols="4">Total</v-col>
              <v-col class="article-total__net-weight" cols="1">{{
                articleTotalNetWeight
              }}</v-col>
              <v-col class="article-total__gross-weight" cols="1">{{
                articleTotalGrossWeight
              }}</v-col>
              <v-col class="article-total__value" cols="1">
                {{ articleTotalValue }}</v-col
              >
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" class="pl-4 mb-1 mt-4">
                <span
                  class="font-weight-medium text-decoration-underline text-h6"
                  >Customs information</span
                >
              </v-col>
              <v-col class="pl-4">
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium"
                      >Manifest number(s)</span
                    ></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem?.customsInformation?.manifestNumbers
                        ?.length
                        ? currentHandlingItem.customsInformation.manifestNumbers.join(
                            ", ",
                          )
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium">Export MRN</span></v-col
                  >
                  <v-col cols="9"
                    ><span
                      :class="
                        adminView && exportFlowStatusIs7OrLower
                          ? 'text-decoration-underline editable-text-field'
                          : ''
                      "
                      @click="openEditExportMrnsDialog"
                      >{{ !!exportMrns ? exportMrns : "x" }}</span
                    ></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium">Transit MRN</span></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.transitMrn
                        ? currentHandlingItem.customsInformation.transitMrn
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium"
                      >Transit MRN issue date</span
                    ></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation
                        .transitMrnIssueDate
                        ? currentHandlingItem.customsInformation
                            .transitMrnIssueDate
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium"
                      >Dakosy-Reference(T1/T2)</span
                    ></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.dakosyReference
                        ? currentHandlingItem.customsInformation.dakosyReference
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium">Registration</span></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.registration
                        ? currentHandlingItem.customsInformation.registration
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium">Seal</span></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.seal
                        ? currentHandlingItem.customsInformation.seal
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium"
                      >Temp LTS number</span
                    ></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.tempWabNumber
                        ? currentHandlingItem.customsInformation.tempWabNumber
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium"
                      >Final LTS number</span
                    ></v-col
                  >
                  <v-col cols="9"
                    ><span>{{
                      !!currentHandlingItem.customsInformation.finalLtsNumber
                        ? currentHandlingItem.customsInformation.finalLtsNumber
                        : "x"
                    }}</span></v-col
                  >
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"
                    ><span class="font-weight-medium">Route</span></v-col
                  >
                  <v-col cols="9">
                    <EditableDropdown
                      v-if="routes && routes.length"
                      v-model="selectedRoute"
                      wrapper="span"
                      :readonly="readonly"
                      :items="routes"
                    ></EditableDropdown>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pl-4 mb-1 mt-4">
                <span
                  class="font-weight-medium text-decoration-underline text-h6"
                  >System information</span
                >
              </v-col>
              <v-col class="pl-4">
                <v-row
                  v-if="
                    currentHandlingItem?.systemInformation?.documents?.length
                  "
                  no-gutters
                >
                  <v-col cols="3"
                    ><span class="font-weight-medium">Documents</span></v-col
                  >
                  <v-col cols="9"
                    ><div
                      v-for="groupedDocument in groupedDocuments"
                      :key="groupedDocument[0]"
                      class="system-information__group"
                    >
                      <strong class="text-decoration-underline"
                        >Uploaded by {{ groupedDocument[0] }}</strong
                      >

                      <div
                        v-for="document in groupedDocument[1]"
                        :key="document.id"
                      >
                        <span class="font-italic">{{ document.fileName }}</span>
                        on
                        <span class="font-italic">{{
                          moment(document.date).format("DD-MM-YYYY HH:mm:ss")
                        }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        v-if="hasChangedValues && !initialLoading"
        :loading="isLoading"
        :disabled="!isFormValid"
        class="mt-4"
        color="success"
        @click="submitChanges"
        >Submit changes</v-btn
      >
    </v-card>
    <ConfirmDialog
      v-model="showConfirmDeleteDialog"
      :is-loading="confirmDeleteDialogLoading"
      title="Bestätigen"
      confirm-button-text="Ja"
      close-button-text="Nein"
      @cancel="currentArticle = null"
      @confirm="confirmDelete"
    >
      Soll diese Zeile wirklich gelöscht werden?
    </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  currentHandlingItem: CustomsShipmentDetailViewModel;
  readonly?: boolean;
  adminView?: boolean;
}

import EditableTextField from "@/components/EditableTextField.vue";
import EditableDropdown from "@/components/EditableDropdown.vue";
import { ref, onBeforeMount, computed } from "vue";
import moment from "moment";
import axios from "axios";
import {
  CountryOfOriginViewModel,
  DataApi,
  IncotermViewModel,
  CustomsShipmentDetailViewModel,
  ShipmentDetailUpdateViewModel,
  CurrencyApi,
  KeyValueItem,
  ShipmentUpdateArticleViewModel,
  ArticleViewModel,
  CustomsExportApi,
} from "@/openapi";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import CustomsParcels from "./CustomsParcels.vue";
import CustomsExportMrnDialog from "./dialogs/CustomsExportMrnDialog.vue";
import { KeyValueItem as OpenApiKeyValueItem } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
const dataStore = useDataStore();

const emits = defineEmits(["errorOccured", "updated-details"]);
const dataApi = new DataApi(undefined, "");
const currencyApi = new CurrencyApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

const props = withDefaults(defineProps<IProps>(), {
  readonly: true,
  adminView: false,
});

const localHandlingItem = ref<CustomsShipmentDetailViewModel>(
  JSON.parse(JSON.stringify(props.currentHandlingItem)),
);

const initialLoading = ref(true);
const isFormValid = ref(true);
const copyCurrentHandlingItem = ref<CustomsShipmentDetailViewModel | null>(
  null,
);
const isLoading = ref(false);

const currencies = ref<KeyValueItem[]>([]);
const selectedCurrency = ref<KeyValueItem | null>(null);

const routes = ref<OpenApiKeyValueItem[]>([]);
const selectedRoute = ref<OpenApiKeyValueItem | null>(null);

const incoTerms = ref<KeyValueItem[]>([]);
const originCountries = ref<KeyValueItem[]>([]);
const showConfirmDeleteDialog = ref(false);
const confirmDeleteDialogLoading = ref(false);
const currentArticle = ref<ArticleViewModel | null>(null);

const showParcelsDialog = ref(false);
const showCustomsExportMrnDialog = ref(false);

const rules = {
  rcTarif: (value: string) => {
    const pattern = /^\d{8}$/;
    return pattern.test(value) || "This field should contain 8 digits.";
  },
  maxWeight: (value: number) => {
    return value <= 50000 || "Weight can't be higher than 50000";
  },
  goodsItemNumber: (value: number | string | undefined) => {
    return (
      value === undefined ||
      value === "" ||
      (parseInt(value.toString()) >= 1 && parseInt(value.toString()) <= 999) ||
      "The goods item number must be between 0 and 1000"
    );
  },
};
onBeforeMount(async () => {
  getCurrencies();
  copyCurrentHandlingItem.value = JSON.parse(
    JSON.stringify(props.currentHandlingItem),
  );
  initialLoading.value = false;

  Promise.all([
    getCurrencies(),
    getRoutes(),
    getIncoTerms(),
    getOriginCountries(),
  ]);
});

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const submitChanges = async () => {
  const updateModel: ShipmentDetailUpdateViewModel = {
    shipmentId: props.currentHandlingItem.id,
    consigneeAddress: props.currentHandlingItem.consignee.address,
    consigneeCity: props.currentHandlingItem.consignee.city,
    consigneeCountry: props.currentHandlingItem.consignee.country,
    consigneeName: props.currentHandlingItem.consignee.name,
    consigneeZipcode: props.currentHandlingItem.consignee.zipcode,
    consigneeVat: props.currentHandlingItem.consigneeVat,
    eori: props.currentHandlingItem.eori,
    shipperAddress: props.currentHandlingItem.shipper.address,
    shipperCity: props.currentHandlingItem.shipper.city,
    shipperCountry: props.currentHandlingItem.shipper.country,
    shipperName: props.currentHandlingItem.shipper.name,
    shipperZipcode: props.currentHandlingItem.shipper.zipcode,
    shipperVat: props.currentHandlingItem.shipperVat,
    weight: props.currentHandlingItem.grossWeight,
    valueCurrency: props.currentHandlingItem.valueCurrency,
    value: props.currentHandlingItem.value,
    articles:
      props.currentHandlingItem.articles?.map((c) => {
        return {
          id: c.id,
          description: c.description,
          netWeight:
            c.eigenmasse !== undefined && !isNaN(c.eigenmasse!)
              ? c.eigenmasse
              : undefined,
          grossWeight:
            c.rohmasse !== undefined && !isNaN(c.rohmasse!)
              ? c.rohmasse
              : undefined,
          value:
            c.rechnungspreis !== undefined && !isNaN(c.rechnungspreis!)
              ? c.rechnungspreis
              : undefined,
          currency: c.rechnungswahrung,
          rcTarif: c.rcTarif,
          countryOfOrigin: c.countryOfOrigin,
          goodsItemNumber: parseGoodsItemNumberToNullableNumber(c),
        } as ShipmentUpdateArticleViewModel;
      }) ?? [],
    routeId: selectedRoute.value?.key,
    invoiceNumber: props.currentHandlingItem.invoiceNumber,
    reasonForExport: props.currentHandlingItem.reasonForExport,
    incoTerm: props.currentHandlingItem.incoTerm,
    countryOfOrigin: props.currentHandlingItem.countryOfOrigin,
    remarks: props.currentHandlingItem.remarks,
  };

  try {
    isLoading.value = true;
    await customsExportApi.updateShipmentDetail(updateModel);
    emits("updated-details");
  } catch {
    displayError(
      "Something went wrong while updating the values of the shipment",
    );
  }

  isLoading.value = false;
};

const parseGoodsItemNumberToNullableNumber = (
  articleViewModel: ArticleViewModel,
) => {
  if (
    articleViewModel.goodsItemNumber === undefined ||
    articleViewModel.goodsItemNumber === null
  ) {
    return undefined;
  }

  if (Number.isNaN(articleViewModel.goodsItemNumber)) {
    return undefined;
  }

  return parseInt(articleViewModel.goodsItemNumber.toString());
};

const getCurrency = (currency: string) => {
  return currencies.value?.find((c) => c.value === currency) ?? null;
};

const getIncoterm = (incotermId: number | null | undefined) => {
  return incoTerms.value.find((c) => c.key === incotermId) ?? null;
};

const getCountryOfOrigin = (originCountryCode: string | null | undefined) => {
  return (
    originCountries.value.find((c) => c.value === originCountryCode) ?? null
  );
};

const getCurrencies = async () => {
  try {
    const response = await currencyApi.getCurrencies();
    currencies.value = response.data;
    selectedCurrency.value =
      currencies.value.find(
        (c) => c.value === props.currentHandlingItem.valueCurrency,
      ) ?? null;
  } catch {
    displayError(
      "Something went wrong while retrieving the currencies necessary when changes the currency of the shipment",
    );
  }
};

const getRoutes = async () => {
  try {
    const response = await dataApi.getRoutes();
    routes.value = response.data;
    selectedRoute.value =
      routes.value.find(
        (c) => c.key === props.currentHandlingItem.customsInformation?.routeId,
      ) ?? null;
  } catch {
    displayError("Something went wrong while retrieving the routes");
  }
};

const getIncoTerms = async () => {
  try {
    const response = await axios.get("/api/Data/GetIncoTerms");
    incoTerms.value = response.data.map((c: IncotermViewModel) => ({
      key: c.id,
      value: c.name,
    }));
  } catch {
    displayError("Something went wrong while retrieving the incoterms");
  }
};

const getOriginCountries = async () => {
  try {
    const response = await axios.get("/api/Data/GetOriginCountries");
    originCountries.value = response.data.map(
      (c: CountryOfOriginViewModel) => ({ key: c.id, value: c.code }),
    );
  } catch {
    displayError("Something went wrong while retrieving the origin countries");
  }
};

const addEmptyArticle = () => {
  if (!props.currentHandlingItem.articles) {
    props.currentHandlingItem.articles = [];
  }

  const lowestId = Math.min(
    ...props.currentHandlingItem.articles.map((article) => article.id),
  );
  props.currentHandlingItem.articles.push({
    id: lowestId <= 0 ? lowestId - 1 : 0,
    customsHandlingId: props.currentHandlingItem.id,
    description: "",
    wtnNumber: "",
    eigenmasse: 0,
    rohmasse: 0,
    rechnungspreis: 0,
    rechnungswahrung: "",
    groupArticle: false,
    canBeGrouped: false,
    artikel: "",
    additionalCode: "",
    artikelPreis: undefined,
    frachtkosten: undefined,
    frachtkostenWahrung: "",
    zollwert: undefined,
    statWert: undefined,
    statMenge: undefined,
    massEinheit: "",
    zollMenge: undefined,
    countryOfOrigin: "",
    goodsItemNumber: 0,
  } as ArticleViewModel);
};

const deleteArticle = async (item: ArticleViewModel) => {
  if (item.id! <= 0) {
    deleteFromArticles(item.id!);
    return;
  }

  try {
    await axios.post("/api/CustomsExport/DeleteArticle", {
      articleId: item.id,
    });
    deleteFromArticles(item.id!);
  } catch {
    displayError("Something went wrong while deleting the article");
  }
};

const deleteFromArticles = (articleId: number) => {
  props.currentHandlingItem.articles?.splice(
    props.currentHandlingItem.articles.findIndex((art) => art.id === articleId),
    1,
  );
};

const openEditExportMrnsDialog = () => {
  if (props.adminView) {
    showCustomsExportMrnDialog.value = true;
  }
};

const closeEditExportMrnsDialog = () => {
  showCustomsExportMrnDialog.value = false;
};

const exportMrnsSaved = (list: string[]) => {
  showCustomsExportMrnDialog.value = false;
  props.currentHandlingItem.customsInformation.exportMrns = list;
};

const confirmDelete = async () => {
  confirmDeleteDialogLoading.value = true;
  await deleteArticle(currentArticle.value!);
  confirmDeleteDialogLoading.value = false;
  showConfirmDeleteDialog.value = false;
  currentArticle.value = null;
};

const hasChangedValues = computed(() => {
  return (
    (!initialLoading.value &&
      JSON.stringify(props.currentHandlingItem) !==
        JSON.stringify(copyCurrentHandlingItem.value)) ||
    differentCurrency.value ||
    differentRoute.value
  );
});

const differentCurrency = computed(() => {
  return (
    selectedCurrency.value != null &&
    copyCurrentHandlingItem.value != null &&
    copyCurrentHandlingItem.value.valueCurrency != selectedCurrency.value.value
  );
});

const differentRoute = computed(() => {
  return (
    selectedRoute.value != null &&
    copyCurrentHandlingItem.value != null &&
    copyCurrentHandlingItem.value.customsInformation.routeId !=
      selectedRoute.value.key
  );
});

const exportMrns = computed(() => {
  return (
    props.currentHandlingItem.customsInformation.exportMrns?.join(", ") ?? ""
  );
});

const invoiceNumbers = computed(() => {
  return props.currentHandlingItem.invoiceNumbers?.join(", ") ?? "";
});

const exportFlowStatusIs7OrLower = computed(() => {
  if (!props.currentHandlingItem) {
    return false;
  }

  return props.currentHandlingItem.exportFlowStatus <= 7;
});

const articleTotalNetWeight = computed(() => {
  return props.currentHandlingItem.articles?.reduce((prev, current) => {
    const value =
      current.eigenmasse !== undefined && !isNaN(current.eigenmasse!)
        ? current.eigenmasse
        : 0;
    return prev + value!;
  }, 0);
});

const articleTotalGrossWeight = computed(() => {
  return props.currentHandlingItem.articles?.reduce((prev, current) => {
    const value =
      current.rohmasse !== undefined && !isNaN(current.rohmasse!)
        ? current.rohmasse
        : 0;
    return prev + value!;
  }, 0);
});

const articleTotalValue = computed(() => {
  return props.currentHandlingItem.articles?.reduce((prev, current) => {
    const value =
      current.rechnungspreis !== undefined && !isNaN(current.rechnungspreis!)
        ? current.rechnungspreis
        : 0;
    return prev + value!;
  }, 0);
});

const groupedDocuments = computed(() => {
  const map = new Map();

  for (const document of props.currentHandlingItem.systemInformation
    .documents!) {
    const existingValue = map.get(document.employee);
    if (!existingValue) {
      map.set(document.employee, [document]);
      continue;
    }

    existingValue.push(document);
  }

  return Array.from(map);
});
</script>
