<template>
  <Fragment>
    <DualListBox
      v-model="employeeUserRights"
      :items="userRights"
      item-key="id"
      item-text="name"
      :loading="loadingUserRights || loadingEmployeeUserRights"
    >
      <template #top-left>Available user rights</template>
      <template #top-right>Current user rights</template>
      <template #item="{ item }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
          <span>{{ item.description }}</span>
        </v-tooltip>
      </template>
    </DualListBox>
    <div class="save-section">
      <v-btn color="success" :loading="saving" @click="save">Save</v-btn>
    </div>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { UserRightApi, UserRightDto } from "@/openapi";
import { onBeforeMount, ref, watch } from "vue";
import DualListBox from "./components/DualListBox.vue";

interface EmployeeDetailRightsProps {
  employeeId: number;
}

const api = new UserRightApi(undefined, "");
const props = defineProps<EmployeeDetailRightsProps>();

const userRights = ref<UserRightDto[]>([]);
const employeeUserRights = ref<UserRightDto[]>([]);

const loadingUserRights = ref(false);
const loadingEmployeeUserRights = ref(false);
const saving = ref(false);

const getUserRights = async () => {
  loadingUserRights.value = true;
  try {
    const response = await api.getUserRights();
    userRights.value = response.data.items ?? [];
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Somethin went wrong while retrieving the user rights",
    );
  }
  loadingUserRights.value = false;
};

const getEmpoyeeUserRights = async () => {
  loadingEmployeeUserRights.value = true;
  try {
    const response = await api.getEmployeeUserRights(props.employeeId);
    employeeUserRights.value = response.data.items ?? [];
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Somethin went wrong while retrieving the employee user rights",
    );
  }
  loadingEmployeeUserRights.value = false;
};

const save = async () => {
  saving.value = true;
  try {
    await api.putEmployeeUserRights({
      employeeId: props.employeeId,
      userRightIds: employeeUserRights.value.map((c) => c.id!),
    });
    emitSuccess("Successfully saved the selected user rights");
  } catch {
    emitError("Something went wrong while saving the user rights");
  }
  saving.value = false;
};

watch(
  () => props.employeeId,
  (newValue: number | undefined, oldValue: number | undefined) => {
    if (newValue) {
      getEmpoyeeUserRights();
    }
  },
  {
    immediate: true,
  },
);

onBeforeMount(async () => {
  await getUserRights();
});
</script>

<style scoped lang="scss">
.save-section {
  display: flex;
  justify-content: flex-end;
}
</style>
