import Vue from "vue";
import Vuex from "vuex";
import { CheckUserStillActiveResponse, LoginResultViewModel } from "@/openapi";

import DataModule from "@/store/dataModule";
import { allRoutes, MenuItem } from "@/stores/route-store";

Vue.use(Vuex);

export interface RootState {
  loggedIn: boolean;
  userRights: string[];
  mustConnectMicrosoftAccount: boolean;
  accessLevel: number;
  routes: MenuItem[];
  currentUserRight: string;
  isBpoUser: boolean;
}

const setMenuItemsState = (routes: MenuItem[], userRights: string[]) => {
  const newRoutes = structuredClone(routes) as MenuItem[];
  newRoutes.forEach((menuItem) => {
    setMenuItemState(menuItem, userRights);
  });
  return newRoutes;
};

const setMenuItemState = (menuItem: MenuItem, userRights: string[]) => {
  menuItem.children?.forEach((subItem) => {
    setMenuItemState(subItem, userRights);
  });

  if (menuItem.name == "Login" || menuItem.name == "Home") {
    menuItem.show = true;
  } else {
    if (!menuItem.isPage) {
      menuItem.show = !!menuItem.children?.find(
        (item) => item.showInMenu && item.show,
      );
    } else {
      menuItem.show = userRights.includes(menuItem.userRights ?? "-");
    }
  }
};

const findByUserRight = (
  routes: MenuItem[],
  userRight: string,
): MenuItem | null => {
  let found = routes.find((route) => route.userRights == userRight) ?? null;
  if (found) {
    return found;
  } else {
    for (let i = 0; i < routes.length; i++) {
      found = findByUserRight(routes[i].children ?? [], userRight) ?? null;
      if (found) {
        i = routes.length;
      }
    }
  }
  return found;
};

export default new Vuex.Store<RootState>({
  modules: {
    data: DataModule,
  },
  state: {
    loggedIn: false,
    userRights: [],
    mustConnectMicrosoftAccount: false,
    accessLevel: 0,
    routes: allRoutes,
    currentUserRight: "",
    isBpoUser: false,
  },
  mutations: {
    setLoggedoutState(state) {
      state.mustConnectMicrosoftAccount = false;
      state.loggedIn = false;
      state.userRights = [];
      state.accessLevel = 0;
      state.routes = setMenuItemsState(state.routes, []);
    },
    setCheckUserStillActive(state, response: CheckUserStillActiveResponse) {
      state.mustConnectMicrosoftAccount = response.mustConnectMicrosoftAccount;
      state.accessLevel = response.accessLevel;
      state.userRights = response.userRights ?? [];
      state.loggedIn = true;
      state.routes = setMenuItemsState(state.routes, state.userRights);
    },
    setLoggedinState(state, response: LoginResultViewModel) {
      state.mustConnectMicrosoftAccount = response.mustConnectMicrosoftAccount;
      state.accessLevel = response.accessLevel;
      state.userRights = response.userRights ?? [];
      state.loggedIn = true;
      state.routes = setMenuItemsState(state.routes, state.userRights);
    },
    setCurrentUserRight(state, userRights: string) {
      state.currentUserRight = userRights;
    },
    setIsBpoUser(state, value) {
      state.isBpoUser = value;
    },
  },
  actions: {},
  getters: {
    disableByUserRights: (state) => (disabled: boolean, userRight: string) => {
      if (userRight) {
        const thisUserRight = state.currentUserRight + "." + userRight;
        if (!state.userRights.includes(thisUserRight)) {
          return true;
        }
      }
      return disabled;
    },
    hasUserRight: (state) => (userRight: string) => {
      return state.userRights.includes(userRight);
    },
    hasAnyUserRight: (state) => (userRights: string[]) => {
      return userRights.some((ur) => state.userRights.includes(ur));
    },
    getPageByUserRight: (state) => (userRights: string) => {
      return findByUserRight(state.routes, userRights);
    },
    loggedIn: (state) => () => {
      return state.loggedIn;
    },
    isBpoUser: (state) => {
      return state.isBpoUser;
    },
  },
});
