<template>
  <Fragment>
    <v-data-table
      v-bind="$attrs"
      :headers="headersWithActions"
      :loading="loading"
      v-on="$listeners"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"></slot
      ></template>

      <template #top>
        <slot name="top">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :disabled="loading"
              @click="onCreateItem"
              class="mr-5"
              ><v-icon left>mdi-plus-thick</v-icon>Create</v-btn
            >
          </v-toolbar>
        </slot>
      </template>

      <template #item.crud-actions="props">
        <slot name="item.crud-actions" v-bind="props">
          <v-btn
            small
            color="primary"
            class="ma-2"
            :disabled="loading"
            @click="onEditItem(props.item)"
            ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
          >
          <v-btn
            small
            color="error"
            class="ma-2"
            :disabled="loading"
            @click="onDeleteItem(props.item)"
            ><v-icon small left>mdi-delete</v-icon>Delete</v-btn
          >
        </slot>
      </template>
    </v-data-table>

    <ConfirmDialog
      v-if="showConfirmDeleteDialog"
      v-model="showConfirmDeleteDialog"
      color="error"
      @confirm="onDeleteConfirm"
      @close="currentItem = null"
    >
      <slot name="confirm-delete-text" :default-text="confirmDeleteText">{{
        confirmDeleteText
      }}</slot>
    </ConfirmDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { DataTableHeader } from "vuetify";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

interface CrudDataTableProps {
  headers: DataTableHeader[];
  loading: boolean;
  confirmOnDelete?: boolean;
}

const props = withDefaults(defineProps<CrudDataTableProps>(), {
  headers: () => [],
  loading: false,
  confirmOnDelete: true,
});
const emits = defineEmits(["click:create", "click:edit", "click:delete"]);
const showConfirmDeleteDialog = ref(false);
const currentItem = ref<unknown>();

const confirmDeleteText = "Are you sure you want to delete this item?";

const onCreateItem = () => {
  emits("click:create");
};

const onEditItem = (item: unknown) => {
  emits("click:edit", item);
};

const onDeleteItem = (item: unknown) => {
  if (props.confirmOnDelete) {
    showConfirmDeleteDialog.value = true;
    currentItem.value = item;
  } else {
    emits("click:delete", item);
  }
};

const onDeleteConfirm = () => {
  emits("click:delete", currentItem.value);
  showConfirmDeleteDialog.value = false;
  currentItem.value = null;
};

const headersWithActions = computed(() => {
  if (!props.headers?.length) {
    return [];
  }
  const headers = [...props.headers];
  const actionsHeader = headers.find((c) => c.value === "crud-actions");
  if (!actionsHeader) {
    headers.push({
      text: "",
      value: "crud-actions",
      align: "end",
      sortable: false,
      width: "240px",
    });
  }
  return headers;
});
</script>
