<template>
  <div>
    <v-card>
      <v-card-title> Form for return shipments without data </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ valid }" ref="observer" slim>
          <v-form :disabled="loading" @submit.prevent>
            <ValidationProvider
              v-slot="{ errors }"
              name="Customer"
              rules="required"
            >
              <v-autocomplete
                v-model="form.customer"
                :items="dataStore.generalCustomers"
                name="Customer"
                item-text="shortName"
                item-value="id"
                label="Select a customer"
                outlined
              ></v-autocomplete>
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Hawb"
              rules="required"
            >
              <v-text-field
                v-if="form.customer"
                v-model="form.hawb"
                name="Hawb"
                label="Enter a HAWB"
              >
              </v-text-field>
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="form.hawb" class="d-flex">
              <ValidationProvider
                v-slot="{ errors }"
                name="First return reason"
                rules="required"
                tag="div"
                class="flex-grow-1"
              >
                <v-autocomplete
                  v-model="form.shipmentReasonFirst"
                  :items="returnReasonsFirst"
                  name="First return reason"
                  item-text="reason"
                  item-value="id"
                  label="Please select a return reason"
                  outlined
                  @change="returnReasonsChange"
                ></v-autocomplete>
                <span class="error--text">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-if="hasSelectableSecondReturnReason"
                v-slot="{ errors }"
                name="Second return reason"
                rules="required_if:shipmentReturnReasonFirst"
                tag="div"
                class="flex-grow-1"
              >
                <v-autocomplete
                  v-model="form.shipmentReasonSecond"
                  name="Second return reason"
                  :items="returnReasonsSecond"
                  item-text="reason"
                  item-value="id"
                  label="Please select a second return reason"
                  outlined
                  class="ml-4"
                ></v-autocomplete>
                <span class="error--text">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <ValidationProvider
              v-if="
                form.shipmentReasonFirst &&
                ((hasSelectableSecondReturnReason &&
                  form.shipmentReasonSecond) ||
                  !hasSelectableSecondReturnReason)
              "
              v-slot="{ errors }"
              name="Parcels"
              rules="required"
            >
              <v-textarea
                v-model="form.parcelsText"
                name="Parcels"
              ></v-textarea>
              <span class="error--text">{{ errors[0] }}</span>
              <span v-if="!errors.length"
                >Parcels: {{ parcels.join(", ") }}</span
              >
            </ValidationProvider>

            <ValidationProvider
              v-if="form.parcelsText"
              name="Conso country"
              rules="required"
            >
              <v-radio-group v-model="form.consoFileCountry">
                <template #label>
                  <strong>Conso country</strong>
                </template>
                <v-radio label="UK" :value="1"></v-radio>
                <v-radio label="NO" :value="2"></v-radio>
              </v-radio-group>
            </ValidationProvider>

            <v-btn
              v-if="valid"
              class="mt-4"
              :loading="loading"
              @click="onSubmit"
              >Submit</v-btn
            >
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="showSnackbar" color="success"
      >Successfully created a return shipment.</v-snackbar
    >
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import {
  DataApi,
  CustomsDiscrepanciesApi,
  CustomsExportApi,
  ConsoFileCountry,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
} from "@/openapi";
import { emitError } from "@/event-bus";
import { ValidationObserver } from "vee-validate";

import { useDataStore } from "@/stores/data-store";

const dataApi = new DataApi(undefined, "");
const discrepanciesApi = new CustomsDiscrepanciesApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

interface DiscrepancyReturnForm {
  customer: number;
  hawb: string | null;
  shipmentReasonFirst: number;
  shipmentReasonSecond: number | null;
  parcelsText: string | null;
  consoFileCountry: number | null;
}

const initializeForm = () => {
  return {} as DiscrepancyReturnForm;
};

const observer = ref<InstanceType<typeof ValidationObserver>>();

const dataStore = useDataStore();

const returnReasonsFirst = ref<ReturnReasonFirstViewModel[]>([]);
const returnReasonsSecond = ref<ReturnReasonSecondViewModel[]>([]);

const form = ref<DiscrepancyReturnForm>(initializeForm());
const loading = ref(false);
const showSnackbar = ref(false);

onBeforeMount(async () => {
  await Promise.all([getReturnReasons(), dataStore.fetchGeneralCustomers()]);
});

const getReturnReasons = async () => {
  const response = await customsExportApi.getReturnReasons();
  returnReasonsFirst.value = response.data;
};

const returnReasonsChange = () => {
  form.value.shipmentReasonSecond = null;
  var reasonFirst = returnReasonsFirst.value.find(
    (x) => x.id == form.value.shipmentReasonFirst,
  );
  if (reasonFirst == null) {
    returnReasonsSecond.value = [];
    return;
  }

  returnReasonsSecond.value = reasonFirst.returnReasonsSecond!;
  if (returnReasonsSecond.value.length == 1) {
    form.value.shipmentReasonSecond = returnReasonsSecond.value[0].id;
  }
};

const onSubmit = async () => {
  loading.value = true;
  try {
    await discrepanciesApi.returnShipmentWithoutData({
      customerId: form.value.customer,
      hawb: form.value.hawb,
      returnReasonFirstId: form.value.shipmentReasonFirst,
      returnReasonSecondId: form.value.shipmentReasonSecond,
      parcelNumbers: parcels.value,
      consoFileCountry: form.value.consoFileCountry as ConsoFileCountry,
    });
    form.value = initializeForm();
    observer.value?.reset();
    showSnackbar.value = true;
  } catch {
    emitError("Failed to create a return shipment.");
  }

  loading.value = false;
};

const parcels = computed(() => {
  const trimmedParcelsText = form.value.parcelsText?.trim();
  if (!trimmedParcelsText) {
    return [];
  }

  const parcels = trimmedParcelsText.split("\n").filter((p) => !!p);
  return parcels;
});

const hasSelectableSecondReturnReason = computed(() => {
  return returnReasonsSecond.value?.length;
});
</script>
