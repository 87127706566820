<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text class="justify-center">
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <span class="text-caption"
                  >Paketscheinnummer: {{ parcelNumber }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  :value="formData.paperworkFiles"
                  outlined
                  dense
                  :label="fileInputLabel"
                  :placeholder="fileInputPlaceholder"
                  :multiple="multiple"
                  @change="onFileUpload"
                ></v-file-input>
                <div v-if="hasSelectedFiles">
                  <div>Selected files:</div>
                  <ul class="mb-4 font-weight-bold">
                    <li v-for="fileName in fileNames">
                      {{ fileName }}
                    </li>
                  </ul>
                </div>
                <v-select
                  v-model="formData.paperworkCategory"
                  :items="paperworkCategories"
                  clearable
                  chips
                  dense
                  single-line
                  label="Choose category"
                  item-value="key"
                  item-text="value"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                  v-if="allPaperworkGroupings.length"
                  v-for="paperworkGrouping in allPaperworkGroupings"
                >
                  <span
                    class="interactive interactive--red"
                    @click="removePaperworkGrouping(paperworkGrouping)"
                  >
                    <strong>{{
                      getCategoryName(paperworkGrouping.category)
                    }}</strong
                    >:
                    {{ paperworkGrouping.files?.map((c) => c.name).join(", ") }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text :disabled="isLoading" @click="onClose">{{
          closeButtonText
        }}</v-btn>
        <v-btn
          color="primary"
          text
          :loading="isLoading"
          :disabled="!canConfirm"
          @click="onConfirm"
        >
          {{ confirmButtonText }}
        </v-btn>

        <v-btn
          color="primary"
          text
          :disabled="
            isLoading || !hasSelectedFiles || !formData.paperworkCategory
          "
          @click="uploadAnother"
        >
          Upload another document
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, computed } from "vue";
import { emitError } from "@/event-bus";
import { KeyValueItem, DataApi } from "@/openapi";

interface IProps {
  value?: boolean | null | undefined;
  isLoading?: boolean | null | undefined;
  title?: string | null | undefined;
  confirmButtonText?: string | null | undefined;
  closeButtonText?: string | null | undefined;
  parcelNumber: string;
  multiple?: boolean | null | undefined;
}

export interface UploadPaperworkForm {
  paperworkFiles?: File[] | null;
  paperworkCategory?: number | null;
}

export interface PaperworkGrouping {
  files: File[];
  category: number;
}

export interface UploadPaperworkFormConfirmed {
  paperworkGroupings: PaperworkGrouping[];
}

const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Upload paperwork",
  confirmButtonText: "Save",
  closeButtonText: "Close",
  parcelNumber: "",
  multiple: false,
});
const emits = defineEmits(["input", "confirm", "close"]);
const dataApi = new DataApi(undefined, "");
const form = ref(null);
const valid = ref(true);
const formData = ref<UploadPaperworkForm>({
  paperworkFiles: null,
  paperworkCategory: null,
});

const paperworkGroupings = ref<PaperworkGrouping[]>([]);

const paperworkCategories = ref<KeyValueItem[]>([]);

watch(
  () => props.value,
  (newVal) => {
    if (!newVal) {
      resetForm();
      paperworkGroupings.value = [];
    }
  },
);

onBeforeMount(() => {
  getCategories();
});

const getCategories = async () => {
  try {
    const response = await dataApi.getPaperworkCategories();
    paperworkCategories.value = response.data as KeyValueItem[];
  } catch {
    emitError("Something went wrong while retrieving the categories");
  }
};

const onClose = () => {
  emits("input", false);
  emits("close");
};

const onConfirm = () => {
  emits("confirm", {
    paperworkGroupings: allPaperworkGroupings.value,
  } as UploadPaperworkFormConfirmed);
};

const uploadAnother = () => {
  paperworkGroupings.value.push({
    category: formData.value.paperworkCategory!,
    files: formData.value.paperworkFiles!,
  });
  resetForm();
};

const resetForm = () => {
  (
    form.value! as Vue & {
      resetValidation: () => boolean;
    }
  ).resetValidation();

  formData.value.paperworkCategory = null;
  formData.value.paperworkFiles = [];
};

const getCategoryName = (categoryId: number) => {
  return (
    paperworkCategories.value.find((pc) => pc.key === categoryId)?.value ??
    "ERROR"
  );
};

const removePaperworkGrouping = (paperworkGrouping: PaperworkGrouping) => {
  const index = paperworkGroupings.value.indexOf(paperworkGrouping);
  if (index >= 0) {
    paperworkGroupings.value.splice(index, 1);
  } else {
    resetForm();
  }
};

const onFileUpload = (eventData: File | File[]) => {
  formData.value.paperworkFiles = eventData
    ? Array.isArray(eventData)
      ? eventData
      : [eventData]
    : [];
};

const canConfirm = computed(() => {
  if (
    hasPaperworkGroupings.value &&
    ((!hasSelectedFiles.value && !formData.value.paperworkCategory) ||
      (hasSelectedFiles.value && formData.value.paperworkCategory))
  ) {
    return true;
  } else {
    return (
      !hasPaperworkGroupings.value &&
      hasSelectedFiles.value &&
      formData.value.paperworkCategory
    );
  }
});

const fileInputLabel = computed(() => {
  let label = "Upload paperwork file";
  if (props.multiple) {
    label += "s";
  }
  return label;
});

const fileInputPlaceholder = computed(() => {
  let placeholder = "Please upload the paperwork";
  if (props.multiple) {
    placeholder += "s";
  }
  return placeholder;
});

const hasSelectedFiles = computed(() => {
  return !!formData.value.paperworkFiles?.length;
});

const hasPaperworkGroupings = computed(() => {
  return !!paperworkGroupings.value?.length;
});

const fileNames = computed(() => {
  if (!formData.value.paperworkFiles?.length) {
    return [];
  }

  return formData.value.paperworkFiles.map((c) => c.name);
});

const allPaperworkGroupings = computed(() => {
  let groupings = paperworkGroupings.value.slice();

  if (hasSelectedFiles && formData.value.paperworkCategory) {
    groupings.push({
      category: formData.value.paperworkCategory!,
      files: formData.value.paperworkFiles!,
    });
  }

  return groupings;
});
</script>
