<template>
  <Fragment>
    <v-data-table
      v-if="currentTab === 0"
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :disable-sort="disableFiltering"
      :disable-filtering="disableFiltering"
      item-key="shipmentId"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 257px)"
      class="elevation-1"
      :loading="loading"
      @update:options="onUpdateOptions"
    >
      <template #top>
        <div class="px-4 py-4">
          <div class="py-4 d-flex align-baseline justify-space-between">
            <div class="d-flex align-baseline">
              <v-btn
                :disabled="loading || disableFiltering"
                color="primary"
                @click="openMrnsDialog"
                >MRN Number <v-icon right> mdi-magnify </v-icon></v-btn
              >
              <v-text-field
                v-model="filters.valueFrom"
                type="number"
                class="shipments-search"
                placeholder="Value from"
                append-icon="mdi-arrow-bottom-left"
                :disabled="loading || disableFiltering || !currentItem"
                min="0"
                @blur="onUpdateOptions"
              ></v-text-field>
              <v-text-field
                v-model="filters.valueTo"
                type="number"
                class="shipments-search"
                placeholder="Value to"
                append-icon="mdi-arrow-top-right"
                :disabled="loading || disableFiltering || !currentItem"
                min="0"
                @blur="onUpdateOptions"
              ></v-text-field>
              <v-select
                v-model="filters.incoterm"
                :disabled="loading || disableFiltering || !currentItem"
                class="shipments-search"
                :items="incoterms"
                label="Incoterm"
                item-text="value"
                item-value="key"
                clearable
                @input="onUpdateOptions"
              ></v-select>
              <v-text-field
                v-model="filters.consigneeCity"
                v-refocus-on-enter
                class="shipments-search"
                placeholder="Consignee city"
                append-icon="mdi-magnify"
                :disabled="loading || disableFiltering || !currentItem"
                @blur="onUpdateOptions"
                @keyup.enter="onUpdateOptions"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <p v-if="currentItem">
                <b>MRN number:</b>
                {{ currentItem.mrn }}
              </p>
              <p v-if="currentItem" class="ml-5">
                <b>General customer:</b>
                {{ currentItem.customer }}
              </p>
            </div>
            <v-card
              v-if="!historical && showDataIsChangedMessage"
              class="pt-5 px-5 red white--text font-weight-bold"
              max-width="344"
              mx-auto
            >
              <p>You have unsaved changes. Please save before proceeding.</p>
            </v-card>
            <div v-if="!historical && currentItem">
              <v-btn :disabled="loading" color="grey lighten-2" @click="saveMrn"
                >Save <v-icon right> mdi-floppy </v-icon></v-btn
              >
              <v-btn
                :disabled="loading || disableFiltering"
                color="success"
                class="ml-2"
                @click="showCompleteDialog = true"
                >Complete <v-icon right> mdi-check </v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </template>

      <template v-if="items.length" #body="{ items }">
        <tbody>
          <ValidationObserver
            v-for="item in items"
            :key="item.shipmentId"
            :ref="'observer-' + item.shipmentId"
            slim
          >
            <tr>
              <td>
                <EditableTextField
                  v-model.number="item.hawbNumber"
                  class="my-2"
                  :readonly="true"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model.number="item.pieces"
                  class="my-2"
                  :readonly="true"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model.number="item.value"
                  class="my-2"
                  :readonly="true"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.valueCurrency"
                  class="my-2"
                  :readonly="true"
                ></EditableTextField>
              </td>
              <td>
                <EditableDropdown
                  :value="item.incoterm"
                  :items="incoterms"
                  :readonly="historical"
                  @input="item.incoterm = $event"
                ></EditableDropdown>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.phone"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.email"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model.number="item.weight"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.eoriNumber"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.subsidaryBranchNumber"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.name"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.address"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.postalCode"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableTextField
                  v-model="item.consignee.city"
                  class="my-2"
                  :readonly="historical"
                ></EditableTextField>
              </td>
              <td>
                <EditableDropdown
                  v-model="item.consignee.countryId"
                  class="my-2"
                  :items="countries"
                  :return-object="false"
                  :readonly="historical"
                ></EditableDropdown>
                <span
                  v-if="
                    !historical &&
                    !item.consignee.countryId &&
                    item.consignee.country
                  "
                  >The original selected country '{{ item.consignee.country }}'
                  doesn't seem to exist anymore.</span
                >
              </td>
              <td>
                <v-checkbox
                  v-if="isDDPSelected(item.incoterm?.value)"
                  v-model="item.dontUseInAutomaticClearance"
                  class="my-2"
                  :class="{ 'disable-events': historical }"
                  hide-details
                  :readonly="historical"
                  :disabled="historical"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="item.manualClearance"
                  class="my-2"
                  :class="{ 'disable-events': historical }"
                  hide-details
                  :readonly="historical"
                  :disabled="historical"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="item.vorsteuerabzug"
                  class="my-2"
                  :class="{ 'disable-events': historical }"
                  hide-details
                  :readonly="historical"
                  :disabled="historical"
                ></v-checkbox>
              </td>
            </tr>
          </ValidationObserver>
        </tbody>
      </template>
    </v-data-table>
    <v-snackbar v-model="showSuccessMessage" :timeout="5000">
      {{ successMessage }}
      <template #action="{ attrs }">
        <v-btn
          color="success"
          v-bind="attrs"
          @click="showSuccessMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ShowMrnDialog
      v-if="showDialog"
      v-model="showDialog"
      :pre-check-confirmed="historical"
      :only-show-uncompleted="true"
      @getMrn="getMrnData"
    >
    </ShowMrnDialog>

    <CompletePreCheckDialog
      v-model="showCompleteDialog"
      :loading="loading"
      @confirm="completeMrn($event)"
    ></CompletePreCheckDialog>
  </Fragment>
</template>

<script setup lang="ts">
import {
  PreCheckShipmentApi,
  PreCheckMrn,
  PreCheckShipment,
  IncotermViewModel,
  CountryOfOriginViewModel,
  KeyValueItem,
  MrnDto,
} from "@/openapi";
import { ToolbarItem } from "@/models/ToolbarItem";
import { DataOptions, DataTableHeader } from "vuetify";
import ShowMrnDialog from "@/components/dialogs/ShowMrnDialog.vue";
import EditableTextField from "@/components/EditableTextField.vue";
import EditableDropdown from "@/components/EditableDropdown.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import { FooterOptions } from "@/types/types";
import { emitErrorWithFallback } from "@/event-bus";
import store from "@/store";
import { DataAction } from "@/store/dataModule";
import { ValidationObserver } from "vee-validate";
import { useSorting } from "@/composables/sort";
import CompletePreCheckDialog from "./dialogs/CompletePreCheckDialog.vue";
import { CompletePreCheckDialogFormData } from "./dialogs/CompletePreCheckDialog.vue";

interface TableFilters {
  valueFrom: number | null;
  valueTo: number | null;
  incoterm: number | null;
  consigneeCity: string;
}
interface PreCheckShipmentsOverviewProps {
  historical?: boolean;
}

interface EnrichedPreCheckShipment extends PreCheckShipment {
  countryKvp: KeyValueItem | null;
}

const preCheckShipmentsApi = new PreCheckShipmentApi(undefined, "");

const props = defineProps<PreCheckShipmentsOverviewProps>();
const emits = defineEmits(["PageInfoReceived"]);

const currentTab = ref(0);

const loading = ref(false);
const showSuccessMessage = ref(false);
const successMessage = ref("");
const showDataIsChangedMessage = ref(false);
const disableFiltering = ref(false);

const filters = ref<TableFilters>({
  valueFrom: null,
  valueTo: null,
  incoterm: null,
  consigneeCity: "",
});

const items = ref<EnrichedPreCheckShipment[]>([]);
const copyOfItems = ref<string | null>();
const headers = ref<DataTableHeader[]>([
  { text: "HAWB", value: "hawbNumber", sortable: false },
  { text: "Pieces", value: "pieces", sortable: false },
  { text: "Value", value: "value", sortable: true },
  { text: "Value Currency", value: "valueCurrency", sortable: false },
  { text: "Incoterm", value: "incoterm.key", sortable: true },
  { text: "Consignee Phone", value: "consignee.phone", sortable: false },
  { text: "Consignee Email", value: "consignee.email", sortable: false },
  { text: "Weight", value: "weight", sortable: false },
  { text: "EORI number", value: "eoriNumber", sortable: true },
  {
    text: "subsidary branch number",
    value: "subsidaryBranchNumber",
    sortable: true,
  },
  { text: "Consignee Name", value: "consignee.name", sortable: true },
  {
    text: "Consignee Address",
    value: "consignee.address",
    sortable: true,
  },
  {
    text: "Consignee Post Code",
    value: "consignee.postalCode",
    sortable: true,
  },
  { text: "Consignee City", value: "consignee.city", sortable: true },
  {
    text: "Consignee Country",
    value: "consignee.country",
    sortable: false,
  },
  {
    text: "Don`t use in automatic clearance",
    value: "dontUseInAutomaticClearance",
    sortable: false,
  },
  { text: "Manual clearance", value: "manualClearance", sortable: false },
  { text: "Vorsteuerabzug", value: "vorsteuerabzug", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref<number | undefined>(0);
const currentItem = ref<MrnDto | null>();

const showDialog = ref(false);
const showCompleteDialog = ref(false);

const { sortBy, sortDesc } = useSorting(options);

const incoterms = computed((): KeyValueItem[] => {
  const incoterms = store.getters.incoterms as IncotermViewModel[];
  return (
    incoterms?.map(
      (c) =>
        ({
          key: c.id,
          value: c.name,
        }) as KeyValueItem,
    ) ?? []
  );
});
const countries = computed((): KeyValueItem[] => {
  const countries = store.getters
    .countriesOfOrigin as CountryOfOriginViewModel[];
  return (
    countries?.map(
      (c) =>
        ({
          key: c.id,
          value: c.code,
        }) as KeyValueItem,
    ) ?? []
  );
});

watch(
  () => items.value,
  (items: PreCheckShipment[]) => {
    if (copyOfItems.value !== null && items.length) {
      if (JSON.stringify(items) !== copyOfItems.value) {
        disableFiltering.value = true;
        showDataIsChangedMessage.value = true;
        footerOptions.value.disablePagination = true;
      } else {
        disableFiltering.value = false;
        showDataIsChangedMessage.value = false;
        footerOptions.value.disablePagination = false;
      }
    }
  },
  { deep: true },
);

const openMrnsDialog = () => {
  currentItem.value = null;
  showDialog.value = true;
};

const getShipments = async () => {
  loading.value = true;
  copyOfItems.value = null;
  try {
    const response = await preCheckShipmentsApi.getShipmentsByMrn(
      currentItem.value?.id,
      filters.value.valueFrom ?? undefined,
      filters.value.valueTo ?? undefined,
      filters.value.incoterm ?? undefined,
      filters.value.consigneeCity ?? undefined,
      props.historical,
      sortBy.value,
      sortDesc.value,
      options.value.page,
      options.value.itemsPerPage,
    );

    items.value =
      response.data.items?.map((item) => ({
        ...item,
        countryKvp:
          countries.value.find(
            (country) => country.key === item.consignee?.countryId,
          ) ?? null,
      })) ?? [];
    copyOfItems.value = JSON.stringify(items.value ?? []);
    totalAmountOfItems.value = response.data.totalAmountOfItems;
    loading.value = false;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the shipments",
    );
  }
};

const getMrnData = (mrn: MrnDto) => {
  currentItem.value = mrn;
  showDialog.value = false;
  getShipments();
};

const onUpdateOptions = () => {
  if (currentItem.value?.mrn) {
    getShipments();
  }
};

const saveMrn = async () => {
  loading.value = true;
  try {
    await preCheckShipmentsApi.savePreCheckShipments({
      shipments: items.value.map((c) => ({
        shipmentId: c.shipmentId!,
        incotermId: c.incoterm?.key,
        consigneePhone: c.consignee?.phone,
        consigneeEmail: c.consignee?.email,
        weight: c.weight,
        coriNumber: c.eoriNumber,
        subsidiaryBranchNumber: c.subsidaryBranchNumber,
        consigneeName: c.consignee?.name,
        consigneeAddress: c.consignee?.address,
        consigneePostalCode: c.consignee?.postalCode,
        consigneeCity: c.consignee?.city,
        consigneeCountry: c.consignee?.country,
        consigneeCountryId: c.consignee?.countryId,
        dontUseInAutomaticClearance: c.dontUseInAutomaticClearance,
        isManuallyCleared: c.manualClearance,
        vorsteuerabzug: c.vorsteuerabzug,
        eoriNumber: c.eoriNumber,
      })),
    });
    showSuccessMessage.value = true;
    successMessage.value = "All changes have been saved for this MRN.";
    items.value = [];
    await getShipments();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong");
  } finally {
    loading.value = false;
    disableFiltering.value = false;
    showDataIsChangedMessage.value = false;
    footerOptions.value.disablePagination = false;
  }
};

const completeMrn = async (eventData: CompletePreCheckDialogFormData) => {
  loading.value = true;
  try {
    await preCheckShipmentsApi.confirmMrn({
      mrnId: currentItem.value?.id,
      customerId: eventData.customerId!,
      customsOfficeId: eventData.customsOfficeId!,
      eta: eventData.eta!,
    });
    currentItem.value = null;
    items.value = [];
    copyOfItems.value = "";
    showSuccessMessage.value = true;
    successMessage.value = "The MRN has been confirmed.";
    showCompleteDialog.value = false;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while completing the MRN");
  } finally {
    loading.value = false;
    disableFiltering.value = false;
    showDataIsChangedMessage.value = false;
    footerOptions.value.disablePagination = false;
  }
};

const isDDPSelected = (customer: string) => {
  if (customer) {
    return customer === "DDP" ?? "";
  }
};

onBeforeMount(() => {
  store.dispatch(DataAction.FetchIncoterms);
  store.dispatch(DataAction.FetchCountriesOfOrigin);

  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => {
        if (currentItem.value?.id) {
          getShipments();
        }
      },
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Pre-check shipments", toolbarButtons);
});
</script>
