<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 123px)"
      class="elevation-1"
      :loading="loading"
      @contextmenu:row="onContextMenu"
    >
      <template #item.blockingMilestoneCreateDate="{ item }">{{
        item.blockingMilestoneCreateDate | formatDate
      }}</template>
    </v-data-table>

    <v-menu
      v-model="showContextMenu"
      :position-x="contextMenuXPosition"
      :position-y="contextMenuYPosition"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item
          v-for="(contextMenuOption, index) in contextMenuOptions"
          :key="index"
          :disabled="contextMenuOption.disabled"
          @click="contextMenuOption.callback"
        >
          <v-list-item-title>{{ contextMenuOption.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <RemarkDialog
      v-if="currentItem"
      v-model="showRemarkDialog"
      :loading="processingRemark"
      :readonly="false"
      @confirm="onConfirmRemark"
    ></RemarkDialog>
    <RemarkOverviewDialog
      v-if="currentItem?.blockingMilestoneId && showRemarkOverviewDialog"
      v-model="showRemarkOverviewDialog"
      :blocking-milestone-id="currentItem.blockingMilestoneId"
    ></RemarkOverviewDialog>

    <component
      :is="selectedReleaseComponent"
      v-if="showReleaseDialog && selectedReleaseComponent && currentItem"
      v-model="showReleaseDialog"
      :blocking-milestone-id="currentItem.blockingMilestoneId"
      @confirm="getItems"
    ></component>
  </Fragment>
</template>
<script setup lang="ts">
import { emitError, emitErrorWithFallback } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ProblemShipment, ProblemShipmentsApi } from "@/openapi";
import { FooterOptions } from "@/types/types";
import { nextTick, onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import RemarkDialog, {
  RemarkDialogFormData,
} from "./components/dialogs/RemarkDialog.vue";
import RemarkOverviewDialog from "./components/dialogs/RemarkOverviewDialog.vue";
import InformationRequiredDialog from "./components/dialogs/InformationRequiredDialog.vue";
import EoriNumberInvalidDialog from "./components/dialogs/EoriNumberInvalidDialog.vue";
import InvoiceRequiredDialog from "./components/dialogs/InvoiceRequiredDialog.vue";
interface ContextMenuOption {
  title: string;
  callback: Function;
  disabled: boolean;
}

const api = new ProblemShipmentsApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);

const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    value: "hawbNumber",
    align: "start",
    width: 300,
    sortable: false,
  },
  {
    text: "Blocking milestone",
    value: "blockingMilestone",
    sortable: false,
  },
  {
    text: "Date/time of milestone",
    value: "blockingMilestoneCreateDate",
    sortable: false,
  },
  {
    text: "Receiver name",
    value: "receiverName",
    sortable: false,
  },
  {
    text: "Incoterm",
    value: "incoterm",
    sortable: true,
  },
  {
    text: "Remarks",
    value: "amountOfRemarks",
    sortable: false,
  },
]);
const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<ProblemShipment[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const showContextMenu = ref(false);
const contextMenuXPosition = ref(0);
const contextMenuYPosition = ref(0);
const contextMenuOptions = ref<ContextMenuOption[]>([
  {
    title: "Release block",
    callback: () => {
      switch (currentItem.value?.blockingCategory) {
        case "eoriNumberInvalid":
          selectedReleaseComponent.value = EoriNumberInvalidDialog;
          break;
        case "informationRequired":
          selectedReleaseComponent.value = InformationRequiredDialog;
          break;
        case "invoiceRequired":
          selectedReleaseComponent.value = InvoiceRequiredDialog;
          break;
      }
      showReleaseDialog.value = true;
    },
    disabled: false,
  },
  {
    title: "Return shipment",
    callback: () => {
      // TODO: Will be implemented at a later point in time | SOVC-775
    },
    disabled: true,
  },
  {
    title: "Add remark",
    callback: () => {
      showRemarkDialog.value = true;
    },
    disabled: false,
  },
  {
    title: "View remarks",
    callback: () => {
      showRemarkOverviewDialog.value = true;
    },
    disabled: false,
  },
]);
const currentItem = ref<ProblemShipment | null>();

const showRemarkDialog = ref(false);
const processingRemark = ref(false);

const showRemarkOverviewDialog = ref(false);

const showReleaseDialog = ref(false);
const selectedReleaseComponent = ref<any>();

const refresh = async () => {
  await getItems();
};

const getItems = async (page?: number) => {
  page ??= options.value.page;

  loading.value = true;
  try {
    const response = await api.getProblemShipments(
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went while retrieving the items");
  }
  loading.value = false;
};

const onContextMenu = (e: MouseEvent, { item }: { item: ProblemShipment }) => {
  e.preventDefault();
  contextMenuXPosition.value = e.x;
  contextMenuYPosition.value = e.y;
  currentItem.value = item;
  nextTick(() => {
    showContextMenu.value = true;
  });
};

const onConfirmRemark = async (eventData: RemarkDialogFormData) => {
  processingRemark.value = true;
  try {
    await api.addProblemShipmentRemark({
      blockingMilestoneId: currentItem.value!.id!,
      remark: eventData.remark,
    });
    currentItem.value = null;
    showRemarkDialog.value = false;
    await getItems();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Failed in adding the remark");
  }

  processingRemark.value = false;
};

watch(
  () => options.value,
  async (newValue, oldValue) => {
    await getItems();
  },
  { deep: true },
);

onBeforeMount(async () => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Problematic shipments", toolbarButtons);
});
</script>
