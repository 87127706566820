import { render, staticRenderFns } from "./CustomsMoveShipmentDialog.vue?vue&type=template&id=6c588177&scoped=true"
import script from "./CustomsMoveShipmentDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./CustomsMoveShipmentDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CustomsMoveShipmentDialog.vue?vue&type=style&index=0&id=6c588177&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c588177",
  null
  
)

export default component.exports