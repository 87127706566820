<template>
  <v-card color="grey darken-3">
    <v-toolbar dark color="grey darken-3" height="undefined">
      <v-container fluid>
        <v-row class="mb-3">
          <v-toolbar-title> Shipment {{ handlingItem.hawb }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="handlingItem.canBeEscalated"
            color="error"
            class="mr-3"
            @click="openEscalationModal"
          >
            <v-icon color="white">mdi-exclamation-thick</v-icon>
            Escalate
          </v-btn>
          <v-btn text class="mr-3" @click="emits('fullView-changed', true)"
            >Show full overview</v-btn
          >
          <v-tooltip v-if="handlingItem.dakosyExport" top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="
                  !handlingItem.dakosyExport.hadError &&
                  handlingItem.dakosyExport.exported
                "
                color="success"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-check</v-icon> Create & send to dakosy
              </v-btn>
              <v-btn
                v-else-if="handlingItem.dakosyExport.hadError"
                color="error"
                class="ml-3"
                v-bind="attrs"
                :disabled="isSavingShipment || isCreatingDakosyExport"
                v-on="on"
                @click="emits('exportToDakosy')"
              >
                <v-icon color="white">mdi-exclamation-thick</v-icon>
                Create & send to dakosy
              </v-btn>
              <v-btn v-else color="info" class="ml-3" v-bind="attrs" v-on="on">
                <v-icon color="white">mdi-clock-time-four-outline</v-icon>
                Create & send to dakosy
              </v-btn>
            </template>
            <span>
              <b>Export created:</b>
              {{ formatDateTimeWithTime(handlingItem.dakosyExport.creationDate)
              }}<br />
              <b>Exported yet?:</b> {{ handlingItem.dakosyExport.exported
              }}<br />
              <b>Export date:</b>
              {{ formatDateTimeWithTime(handlingItem.dakosyExport.requestDate)
              }}<br />
              <b>Had errors:</b> {{ handlingItem.dakosyExport.hadError }}<br />
              <b>Error message:</b> {{ handlingItem.dakosyExport.error }}<br />
              <b>Employee:</b> {{ handlingItem.dakosyExport.employee }}<br />
            </span>
          </v-tooltip>
          <v-btn
            v-else
            color="primary"
            :disabled="isSavingShipment || isCreatingDakosyExport"
            @click="emits('exportToDakosy')"
            ><v-icon color="error">mdi-close</v-icon> Create & send to
            dakosy</v-btn
          >
          <v-btn
            color="primary"
            class="ml-3"
            :disabled="isSavingShipment || isCreatingDakosyExport"
            @click="emits('save')"
            >Save</v-btn
          >
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-row>
              <ValidationProvider
                v-slot="{ errors }"
                name="Bundesland"
                vid="LimitedView-Bestimmungsbundesland"
                rules="required"
                class="shrink-grow"
              >
                <v-select
                  v-model="handlingItem.destinationCountry"
                  hide-details
                  dense
                  :items="destinationCountries"
                  :item-text="getDestinationCountryText"
                  item-value="id"
                  label="Bundesland"
                  :error-messages="errors"
                  outlined
                ></v-select>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-text-field
                v-model="handlingItem.invoiceName"
                class="mt-2"
                hide-details
                dense
                label="Consignee name"
                outlined
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row>
              <v-text-field
                v-model="handlingItem.invoiceZipcode"
                hide-details
                dense
                label="Receiver Postcode"
                outlined
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="handlingItem.invoiceEORINumber"
                class="mt-2"
                hide-details
                dense
                label="Consignee EORI number"
                outlined
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row>
              <ValidationProvider
                v-slot="{ errors }"
                name="Code"
                rules="requiredAllowEmpty"
                vid="LimitedView-Code"
                class="shrink-grow"
              >
                <v-select
                  v-model="handlingItem.customsCodeId"
                  hide-details
                  dense
                  :items="codes"
                  :item-text="getCodeText"
                  item-value="id"
                  label="Code"
                  :error-messages="errors"
                  outlined
                ></v-select>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-text-field
                class="mt-2"
                hide-details
                dense
                label="Zollwert (EUR)"
                disabled
                outlined
                :value="getRoundedTo2DigitsValue(handlingItem.zollwert)"
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row>
              <ValidationProvider
                v-slot="{ errors }"
                name="Frachtkosten"
                rules="required|min_value:0.01"
                vid="LimitedView-Frachtkosten"
                class="grow"
              >
                <v-text-field
                  v-model="handlingItem.autoClearanceFreightCosts"
                  hide-details
                  dense
                  label="Frachtkosten"
                  :error-messages="errors"
                  type="number"
                  min="0"
                  step="0.01"
                  outlined
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </v-row>
            <v-row>
              <ValidationProvider
                v-slot="{ errors }"
                name="Geschäfsart"
                rules="required"
                class="mt-2 shrink-grow"
              >
                <v-select
                  v-model="handlingItem.businessType"
                  hide-details
                  dense
                  :items="typeOfBusinesses"
                  item-value="id"
                  :item-text="getTypeOfBusinessText"
                  :error-messages="errors"
                  outlined
                  label="Geschäfsart"
                >
                  <template #label> Gesch&#xE4;fsart </template>
                </v-select>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row>
              <ValidationProvider
                v-slot="{ errors }"
                name="Frachtkosten currency"
                rules="required"
                vid="LimitedView-Frachtkosten-Currency"
                class="shrink-grow"
              >
                <v-select
                  v-model="handlingItem.autoClearanceFreightCostsCurrency"
                  hide-details
                  dense
                  :items="valuesCurrency"
                  item-text="name"
                  item-value="name"
                  label="Currency"
                  :error-messages="errors"
                  outlined
                ></v-select>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-row>
              <v-text-field
                v-model="handlingItem.pieces"
                hide-details
                dense
                label="Pieces"
                outlined
                type="number"
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col class="pt-5">
            <v-row>
              <v-checkbox
                v-model="handlingItem.preTaxDeduction"
                hide-details
                color="primary"
                label="Vorsteuerabzug"
              ></v-checkbox>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <articles
      :articles="handlingItem?.articles ?? []"
      :values-currency="valuesCurrency"
      :error-with-articles="errorWithArticles"
      :exchange-rate-currencies="exchangeRateCurrencies"
      :frachtkosten-percentage="handlingItem.freightcostPercentage"
      :get-rounded-to2-digits-value="getRoundedTo2DigitsValue"
      :update-article-prices="updateArticlePrices"
      :article-types="articleTypes"
      :units-of-measurement="unitsOfMeasurement"
      v-on="$listeners"
    />
  </v-card>
</template>

<script setup lang="ts">
import Articles from "./Articles.vue";
import moment from "moment";
import {
  ArticleTypeViewModel,
  CodeViewModel,
  CurrencyViewModel,
  DestinationCountryViewModel,
  TypeOfBusinessViewModel,
  UnitOfMeasurementViewModel,
  CustomsHandlingItemViewModel,
} from "@/openapi";

interface IProps {
  valuesCurrency: CurrencyViewModel[];
  handlingItem: CustomsHandlingItemViewModel;
  destinationCountries: DestinationCountryViewModel[];
  codes: CodeViewModel[];
  getCodeText: Function;
  getDestinationCountryText: Function;
  errorWithArticles: boolean;
  exchangeRateCurrencies: CurrencyViewModel[];
  getRoundedTo2DigitsValue: Function;
  updateArticlePrices: boolean;
  articleTypes: ArticleTypeViewModel[];
  unitsOfMeasurement: UnitOfMeasurementViewModel[];
  openEscalationModal: Function;
  typeOfBusinesses: TypeOfBusinessViewModel[];
  getTypeOfBusinessText: Function;
  isSavingShipment: boolean;
  isCreatingDakosyExport: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  errorWithArticles: false,
});
const emits = defineEmits(["fullView-changed", "exportToDakosy", "save"]);
const formatDateTimeWithTime = (dateTime: string | null | undefined) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("DD.MM.YYYY HH:mm");
};
</script>
