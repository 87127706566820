<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ml-7 mr-2"
            :disabled="disableNewItemButton"
            @click="addNewItem"
          >
            New Item
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="items"
          height="calc(100vh - 189px)"
          item-key="id"
          dense
          class="elevation-1"
          :loading="isLoading"
          :server-items-length="totalAmountOfItems"
          :options.sync="options"
          :footer-props="footerOptions"
        >
          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="(item, i) in items"
                v-slot="{ dirty }"
                ref="observers"
                :key="item.id"
                slim
              >
                <tr>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="names"
                      v-slot="{ errors, failed }"
                      name="Name"
                      :vid="`name-${item.id}`"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">{{ item.name }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.name"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="kontonummers"
                      v-slot="{ errors, failed }"
                      name="Kontonummer"
                      :vid="`kontonummer-${item.id}`"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">{{ item.kontonummer }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.kontonummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item, i)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { useCrudPage } from "@/composables/crudPage";
import { BookingAccountApi, BookingAccountViewModel } from "@/openapi";

const emits = defineEmits(["PageInfoReceived", "errorOccured"]);

const { items, totalAmountOfItems, isLoading, isNewItem, mapItem } =
  useCrudPage<BookingAccountViewModel>({ id: 0, kontonummer: 0 }, "id", 0);

const api: BookingAccountApi = new BookingAccountApi(undefined, "");
const headers = ref<DataTableHeader[]>([
  { text: "Name", value: "name" },
  { text: "Kontonummer", value: "kontonummer" },
  { text: "Actions", value: "actions", sortable: false },
]);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const disableNewItemButton = ref(false);

onBeforeMount(() => {
  const toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Buchungskonten", toolbarButtons);
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({ id: 0 } as BookingAccountViewModel);
  totalAmountOfItems.value += 1;
};

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  api
    .getCustomsBookingAccounts(itemsPerPage, page, orderBy, orderByField)
    .then((result) => {
      items.value = result.data.items as BookingAccountViewModel[];
      totalAmountOfItems.value = result.data.totalItems as number;
    })
    .catch((error) => {
      emits("errorOccured", [error.message]);
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const saveItem = (item: BookingAccountViewModel, index: number) => {
  const validationObserver = observers.value[index];
  const promises: Promise<ValidationResult>[] = [];
  for (const key in observers.value) {
    const split = key.split("-");
    if (!split.length || key === "observer-" + item.id) {
      continue;
    }
    const propName = split[0];
    const id = parseInt(split[1]);
    if (id != item.id) {
      continue;
    }
    const validationProvider = (<Vue[]>(
      (<unknown>validationObserver)
    ))[0] as InstanceType<typeof ValidationProvider>;
    promises.push(
      validationProvider.validate(
        item[propName as keyof BookingAccountViewModel],
      ),
    );
  }
  validationObserver.validate().then(async () => {
    const validationResults = await Promise.all(promises);
    for (const validationResult of validationResults) {
      if (!validationResult.valid) {
        return;
      }
    }
    isLoading.value = true;
    const isNew = isNewItem(item);
    const model: BookingAccountViewModel = {
      id: item.id,
      name: item.name,
      kontonummer: item.kontonummer ? parseInt(item.kontonummer.toString()) : 0,
    };
    api
      .saveCustomsBookingAccount(model)
      .then((result) => {
        if (!result?.data) {
          return;
        }
        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
          totalAmountOfItems.value++;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((cocu) => cocu.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  api
    .deleteCustomsBookingAccount(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    });
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  const isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};
</script>
