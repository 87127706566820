<template>
  <Fragment>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalItems"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="loading"
      show-select
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Containers</v-toolbar-title>
          <v-row align="center">
            <v-col v-if="selected.length > 0" class="ml-5" cols="auto">
              <v-btn color="primary" @click="createManifest()">
                Add selected containers to manifest
              </v-btn>
            </v-col>
            <v-col v-if="selected.length > 0" cols="auto">
              <h3>
                Weight: {{ totalSelectedWeight }} / Pieces:
                {{ totalSelectedPieces }}
              </h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-text-field
                v-model="search"
                clearable
                label="Search by container name"
                hide-details
                @keyup.enter="getContainers"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-autocomplete
                v-model="warehouseFilter"
                :items="warehouses"
                item-value="key"
                item-text="value"
                name="Warehouse"
                label="Filter by warehouse"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="addContainer()">
                New container
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.warehouseId="{ item }">
        {{ getWarehouseNameFromId(item.warehouseId) }}
      </template>
      <template #item.creationDateTime="{ item }">
        {{ formatDateTime(item.creationDateTime) }}
      </template>
      <template #item.actions="{ item }">
        <v-btn color="primary" @click="editContainer(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <DefaultDialog
        v-if="showAddContainerDialog && createContainerRequest"
        :value="true"
      >
        <template #header>Add new container</template>
        <template #content>
          <v-form>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="name"
            >
              <v-text-field
                v-model="createContainerRequest.name"
                label="Container name"
                name="Name"
                :error-messages="errors"
                maxlength="50"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Warehouse"
            >
              <v-autocomplete
                v-model="createContainerRequest.warehouseId"
                :items="warehouses"
                item-value="key"
                item-text="value"
                name="Warehouse"
                label="Select a warehouse"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-form>
        </template>
        <template #footer>
          <v-btn text small @click="cancelAddContainer">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="invalid"
            text
            small
            color="primary"
            :loading="addContainerDialogLoading"
            @click="confirmAddContainer"
            >Add new container</v-btn
          >
        </template>
      </DefaultDialog>
      <DefaultDialog
        v-if="showEditContainerDialog && editContainerRequest"
        :value="true"
      >
        <template #header>Edit container</template>
        <template #content>
          <v-form>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="name"
            >
              <v-text-field
                v-model="editContainerRequest.name"
                label="Container name"
                name="Name"
                :error-messages="errors"
                maxlength="50"
              ></v-text-field>
            </ValidationProvider>
          </v-form>
        </template>
        <template #footer>
          <v-btn text small @click="cancelEditContainer">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="invalid"
            text
            small
            color="primary"
            :loading="editContainerDialogLoading"
            @click="confirmEditContainer"
            >Update container</v-btn
          >
        </template>
      </DefaultDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitSuccess } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  ContainerApi,
  ContainerViewModel,
  CreateContainerManifestRequest,
  CreateContainerRequest,
  DataApi,
  KeyValueItem,
  UpdateContainerRequest,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import moment from "moment";
import { ValidationObserver } from "vee-validate";
import { computed, onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";

const containerApi = new ContainerApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const observer = ref<InstanceType<typeof ValidationObserver>>();

const emits = defineEmits<{
  (e: "PageInfoReceived", title: string, items: ToolbarItem[]): void;
}>();

const loadingContainers = ref(false);
const loading = ref(false);
const totalItems = ref(0);
const items = ref<ContainerViewModel[]>([]);
const warehouseFilter = ref(0);
const search = ref("");
const selected = ref<ContainerViewModel[]>([]);

const warehouses = ref<KeyValueItem[]>([]);
const headers = ref<DataTableHeader[]>([
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Warehouse",
    value: "warehouseId",
  },
  {
    text: "Creation date/time",
    value: "creationDateTime",
  },
  {
    text: "Total weight",
    value: "totalWeight",
    sortable: false,
  },
  {
    text: "# pieces",
    value: "totalPieces",
    sortable: false,
  },
  { text: "Actions", value: "actions", sortable: false, width: "7%" },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: ["creationDateTime"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100],
  disablePagination: false,
});

const showAddContainerDialog = ref(false);
const createContainerRequest = ref<CreateContainerRequest | null>(null);
const addContainerDialogLoading = ref(false);
const showEditContainerDialog = ref(false);
const editContainerRequest = ref<UpdateContainerRequest | null>(null);
const editContainerDialogLoading = ref(false);

watch(
  () => options.value,
  async (newVal, oldVal) => {
    await getContainers();
  },
);

watch(
  () => warehouseFilter.value,
  async (newVal, oldVal) => {
    await getContainers();
  },
);

const getWarehouses = async () => {
  try {
    const response = await dataApi.getWarehousesData();
    warehouses.value = response.data;
  } catch (error: unknown) {
    emitError(
      "An error occurred while retrieving the warehouses from the webservice",
    );
  }
};

const getWarehouseNameFromId = (warehouseId: number) => {
  const warehouse = warehouses.value.find(
    (warehouseKV) => warehouseKV.key === warehouseId,
  );
  if (!warehouse) {
    return "Unknown";
  }

  return warehouse.value;
};

const getContainers = async () => {
  if (loadingContainers.value) {
    return;
  }
  try {
    loading.value = true;
    loadingContainers.value = true;
    const response = await containerApi.getContainersWithoutManifest(
      options.value.itemsPerPage,
      options.value.page,
      options.value.sortBy.length > 0 ? options.value.sortBy[0] : undefined,
      options.value.sortDesc.length > 0 ? options.value.sortDesc[0] : undefined,
      warehouseFilter.value === null ? undefined : warehouseFilter.value,
      search.value === null ? undefined : search.value,
    );
    const pagedResult = response.data;
    totalItems.value = pagedResult.totalAmountOfItems;
    items.value = pagedResult.items!;
    selected.value = [];
  } catch (error: unknown) {
    emitError(
      "An error occurred when loading the containers from the webservice",
    );
  } finally {
    loading.value = false;
    loadingContainers.value = false;
  }
};

const refreshData = async () => {
  await Promise.all([getWarehouses(), getContainers()]);
};

const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }

  const dateTimeMoment = moment(datetime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const addContainer = () => {
  createContainerRequest.value = {
    name: "",
    warehouseId: warehouses.value.length > 0 ? warehouses.value[0].key : 0,
  };
  showAddContainerDialog.value = true;
};

const cancelAddContainer = () => {
  createContainerRequest.value = null;
  showAddContainerDialog.value = false;
};

const confirmAddContainer = async () => {
  if (!createContainerRequest.value) {
    return;
  }

  try {
    addContainerDialogLoading.value = true;
    await containerApi.createContainer(createContainerRequest.value);
    emitSuccess("Container created");
    await getContainers();
    createContainerRequest.value = null;
    showAddContainerDialog.value = false;
  } catch (error: unknown) {
    emitError("An error occurred while creating the container");
  } finally {
    addContainerDialogLoading.value = false;
  }
};

const editContainer = (item: ContainerViewModel) => {
  editContainerRequest.value = {
    name: item.name,
    id: item.id,
  } as UpdateContainerRequest;
  showEditContainerDialog.value = true;
};

const cancelEditContainer = () => {
  editContainerRequest.value = null;
  showEditContainerDialog.value = false;
};

const confirmEditContainer = async () => {
  if (!editContainerRequest.value) {
    return;
  }

  try {
    editContainerDialogLoading.value = true;
    await containerApi.updateContainer(editContainerRequest.value);
    emitSuccess("Container updated");
    await getContainers();
    editContainerRequest.value = null;
    showEditContainerDialog.value = false;
  } catch (error: unknown) {
    emitError("An error occurred while editing the container");
  } finally {
    editContainerDialogLoading.value = false;
  }
};

const createManifest = async () => {
  if (selected.value.length == 0) {
    return;
  }

  loading.value = true;
  try {
    const selectedContainerIds = selected.value.map((item) => item.id);
    const selectedContainers = items.value.filter(
      (c) => selectedContainerIds.find((sci) => c.id === sci) !== undefined,
    );
    const selectedContainerWarehouseIds = selectedContainers.map(
      (sc) => sc.warehouseId,
    );
    const uniqueWarehouseId = [...new Set(selectedContainerWarehouseIds)];
    if (uniqueWarehouseId.length > 1) {
      emitError(
        "You can't select containers from multiple different warehouses. Please make sure all selected containers are in the same warehouse",
      );
      return;
    }

    const request = {
      containerIds: selectedContainerIds,
    } as CreateContainerManifestRequest;
    await containerApi.createManifestForContainers(request);
    emitSuccess("Manifest created");
    await getContainers();
  } catch (error: unknown) {
    emitError("An error occurred while creating the manifest");
  } finally {
    loading.value = false;
  }
};

const totalSelectedWeight = computed(() => {
  if (selected.value.length && selected.value.length > 0) {
    const value = selected.value.reduce((prev, current, index, arr) => {
      return prev + current.totalWeight;
    }, 0);
    return value;
  }

  return 0;
});

const totalSelectedPieces = computed(() => {
  if (selected.value.length && selected.value.length > 0) {
    const value = selected.value.reduce((prev, current, index, arr) => {
      return prev + current.totalPieces;
    }, 0);
    return value;
  }

  return 0;
});

onBeforeMount(() => {
  emits("PageInfoReceived", "Containers", [
    {
      callback: async () => await refreshData(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ]);

  refreshData();
});
</script>
