<template>
  <v-btn color="primary" :loading="loading" @click="confirmShipment">
    Confirm shipment
  </v-btn>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { emitError } from "@/event-bus";
import { CustomsCheckApi } from "@/openapi";
interface IProps {
  shipmentId: number;
}
const props = defineProps<IProps>();
const emits = defineEmits(["confirmedShipment"]);
const customsCheckApi = new CustomsCheckApi(undefined, "");

interface Successful {
  success: boolean;
}

const loading = ref(false);

const confirmShipment = async () => {
  loading.value = true;
  let success = false;

  try {
    await customsCheckApi.confirmShipments([props.shipmentId]);
    success = true;
  } catch {
    emitError("Something went wrong while trying to confirm the shipment.");
  }

  emits("confirmedShipment", { success } as Successful);
  loading.value = false;
};
</script>
