export const isNotNullOrUndefined = (obj: any) => {
  return obj !== null && obj !== undefined;
};

export const getIndexValue = <T>(
  obj: any[] | null | undefined,
  index: number,
  fallBackValue: T,
) => {
  return obj !== null && obj !== undefined && obj.length > index
    ? obj[index]
    : fallBackValue;
};
