<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="internalModelOpen">
      <template #header
        ><span class="title-holder">{{
          internalModelValue.text
        }}</span></template
      >
      <template #content>
        <v-form slim>
          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Text"
              rules="required"
            >
              <v-text-field
                v-model="internalModelValue.text"
                label="Text (DE)"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Text"
              rules="required"
            >
              <v-textarea
                v-model="internalModelValue.htmlTemplate"
                label="E-mail template (DE)"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </div>

          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Text"
              rules="required"
            >
              <v-text-field
                v-model="internalModelValue.textEnglish"
                label="Text (DE)"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Text"
              rules="required"
            >
              <v-textarea
                v-model="internalModelValue.htmlTemplateEnglish"
                label="E-mail template"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <v-checkbox v-model="internalModelValue.showInCustoms"
              ><span slot="label" class="primary--text"
                >Payment term applies for Customs invoice</span
              ></v-checkbox
            >
          </div>
          <div class="form-fields">
            <v-checkbox v-model="internalModelValue.showInCourierInvoice"
              ><span slot="label" class="primary--text"
                >Payment term applies for Courier invoice</span
              ></v-checkbox
            >
          </div>
          <div class="form-fields">
            <v-checkbox v-model="internalModelValue.showInPaymentReminder"
              ><span slot="label" class="primary--text"
                >Payment term applies for Payemnt reminder</span
              ></v-checkbox
            >
          </div>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn :disabled="loading" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn
          :disabled="invalid"
          color="success"
          :loading="loading"
          @click="$emit('save', modelValue)"
          >Save</v-btn
        ></template
      >
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { PaymentTermsCustomsViewModel } from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import { ref, watch } from "vue";

interface PaymentTermProps {
  modelOpen: boolean;
  modelValue: PaymentTermsCustomsViewModel;
  loading: boolean;
}

const props = defineProps<PaymentTermProps>();
const emit = defineEmits([
  "cancel",
  "save",
  "update:model-open",
  "update:model-value",
]);

const internalModelOpen = ref(props.modelOpen);
const internalModelValue = ref(props.modelValue);

watch(
  () => props.modelOpen,
  (newVal) => {
    if (internalModelOpen.value != newVal) {
      internalModelOpen.value = newVal;
    }
  },
);

watch(
  () => props.modelValue,
  (newVal) => {
    if (internalModelValue.value != newVal) {
      internalModelValue.value = newVal;
    }
  },
);

watch(internalModelOpen, (newVal) => {
  emit("update:model-open", newVal);
});
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
</style>
