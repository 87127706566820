<template>
  <ValidationObserver slim ref="observer" v-slot="{ invalid }">
    <v-dialog v-model="value" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">Select consolidation</v-card-title>
        <v-card-text>
          <ValidationProvider
            rules="required|max:50"
            name="Name"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formData.name"
              label="Consolidation"
              single-line
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider rules="required" name="Type" v-slot="{ errors }">
            <v-select
              v-model="formData.type"
              :items="consolidationTypes"
              item-text="name"
              item-value="name"
              label="Type"
              single-line
              :error-messages="errors"
            ></v-select>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-1"
            text
            :disabled="isLoading"
            @click="emits('input', false), emits('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="isLoading"
            :disabled="invalid"
            @click="createConsolidation"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { CONSOLIDATION_TYPE_T1 } from "@/constants/constants";
import { emitError } from "@/event-bus";
import { ShipmentApi } from "@/openapi";
import { DataAction } from "@/store/dataModule";
import { ref, watch, computed, onBeforeMount } from "vue";
import store from "@/store";

interface IProps {
  value: boolean;
  shipmentIds?: number[];
  onlyT1?: boolean;
}
export interface CreateConsolidationForm {
  name: string;
  type: string;
}
const props = withDefaults(defineProps<IProps>(), {
  onlyT1: false,
  shipmentIds: [],
});
const emits = defineEmits(["input", "close", "created-consolidation"]);

const setDefaultFormValues = (): CreateConsolidationForm => {
  return {
    name: "",
    type: "",
  };
};
const api = new ShipmentApi(undefined, "");
const form = ref(null);
const validForm = ref(false);
const formData = ref<CreateConsolidationForm>(setDefaultFormValues());
const isLoading = ref(false);

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      formData.value = setDefaultFormValues();
      (
        form.value! as Vue & { resetValidation: () => boolean }
      ).resetValidation();
    }
  },
);

onBeforeMount(async () => {
  await store.dispatch(DataAction.FetchConsolidationTypes);
});

const createConsolidation = async () => {
  isLoading.value = true;
  try {
    await api.addConsolidationToShipments({
      shipmentIds: props.shipmentIds,
      consolidationNumber: formData.value.name,
      consolidationType: formData.value.type,
    });
    emits("created-consolidation");
    emits("input", false);
  } catch {
    emitError("Something went wrong while creating a consolidation.");
  }
  isLoading.value = false;
};

const consolidationTypes = computed((): string[] => {
  if (props.onlyT1) {
    return store.getters.consolidationTypes.filter(
      (type: string) => type == CONSOLIDATION_TYPE_T1,
    );
  }
  return store.getters.consolidationTypes;
});
</script>
