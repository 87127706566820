<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          height="calc(100vh - 189px)"
          item-key="id"
          dense
          class="elevation-1"
          :loading="isLoading"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
        >
          <template #header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              :disabled="disableNewItemButton"
              @click="addNewItem"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :key="item.id"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Customs Office"
                      :vid="'customsOffice-' + item.id"
                      item-name="customsOffice"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">{{ getOfficeName(item) }}</div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.customsOfficeId"
                          :items="customsOffices"
                          item-text="officeNumber"
                          item-value="id"
                          label="Edit"
                          single-line
                          autofocus
                          clearable
                          :error-messages="errors"
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Customer"
                      :vid="'customerId-' + item.id"
                      item-name="customerId"
                      :item-id="item.id"
                      rules="required|max:300"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getCustomerName(item) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.customerId"
                          :items="customers"
                          item-text="shortName"
                          item-value="id"
                          label="Edit"
                          single-line
                          autofocus
                          clearable
                          :error-messages="errors"
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Freightcost Calculation Type"
                      :vid="'freightCostCalculationType-' + item.id"
                      item-name="freightCostCalculationType"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getFreightCalculationType(item) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.freightCostCalculationType"
                          :items="freightCostCalculationTypes"
                          label="Edit"
                          single-line
                          autofocus
                          clearable
                          :error-messages="errors"
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Freightcost Calculation Type"
                      :vid="'baseFreightCost-' + item.id"
                      item-name="baseFreightCost"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          &euro;{{ item.baseFreightCost }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.baseFreightCost"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          min="0"
                          step="0.1"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Base Freightcost"
                      :vid="'freightCostPerPiece-' + item.id"
                      item-name="freightCostPerPiece"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          &euro;{{ item.freightCostPerPiece }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.freightCostPerPiece"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          min="0"
                          step="0.1"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Freighcost Per Piece After X Pieces"
                      :vid="'freightCostPerPieceAfterXPieces-' + item.id"
                      item-name="freightCostPerPieceAfterXPieces"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.freightCostPerPieceAfterXPieces }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.freightCostPerPieceAfterXPieces"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          min="0"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Freighcost Per Piece Kg"
                      :vid="'freightCostPerKg-' + item.id"
                      item-name="freightCostPerKg"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          &euro;{{ item.freightCostPerKg }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.freightCostPerKg"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          min="0"
                          step="0.1"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Freighcost Per Piece Kg"
                      :vid="'freightCostPerKgAfterXKg-' + item.id"
                      item-name="freightCostPerKgAfterXKg"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.freightCostPerKgAfterXKg }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.freightCostPerKgAfterXKg"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                          :error-messages="errors"
                          type="number"
                          min="0"
                          step="0.1"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  CustomerViewModel,
  CustomsOfficeViewModel,
  DataApi,
  CustomsOfficeCustomerViewModel,
  CustomsOfficeCustomerApi,
} from "@/openapi";

const customsOfficeCustomerApi = new CustomsOfficeCustomerApi(undefined, "");

const dataApi: DataApi = new DataApi(undefined, "");
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);

const headers = ref([
  { text: "Actions", value: "actions", sortable: false },
  { text: "Customs Office", align: "start", value: "customsOfficeId" },
  { text: "CustomerId", value: "customerId" },
  {
    text: "Freightcost Calculation Type",
    value: "freightcostCalculationType",
  },
  { text: "Base Freightcost", value: "baseFreightcost" },
  { text: "Freightcost Per Piece", value: "freightcostPerPiece" },
  {
    text: "Freightcost Per Piece After X Pieces",
    value: "freightcostPerPieceAfterXPieces",
  },
  { text: "Freightcost Per KG", value: "freightcostPerKg" },
  {
    text: "Freightcost Per Kg After X Kg",
    value: "freightcostPerKgAfterXKg",
  },
]);
const isLoading = ref(false);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const items = ref<CustomsOfficeCustomerViewModel[]>([]);
const totalItems = ref(0);
const disableNewItemButton = ref(false);
const customers = ref<CustomerViewModel[]>([]);
const customsOffices = ref<CustomsOfficeViewModel[]>([]);
const freightCostCalculationTypes = ref<{ text: string; value: number }[]>([
  {
    text: "Based On Pieces",
    value: 0,
  },
  {
    text: "Based On Weight",
    value: 1,
  },
]);

onBeforeMount(async () => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Inbound freightcosts", toolbarButtons);

  var responses = await Promise.all([
    dataApi.getCustomers(true),
    dataApi.getCustomsOffices(),
  ]);
  var customerResponse = responses[0];
  if (customerResponse.status === 200) {
    customers.value = customerResponse.data;
  }

  var customsOfficesResponse = responses[1];
  if (customsOfficesResponse.status === 200) {
    customsOffices.value = customsOfficesResponse.data;
  }
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  customsOfficeCustomerApi
    .getCustomsOfficeCustomers(itemsPerPage, page, orderBy, orderByField)
    .then((result) => {
      items.value = result.data.items!;
      totalItems.value = result.data.totalItems;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const saveItem = async (item: CustomsOfficeCustomerViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(
          item[propName as keyof CustomsOfficeCustomerViewModel],
        ),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);
    const postData = JSON.parse(JSON.stringify(item));

    customsOfficeCustomerApi
      .saveCustomsOfficeCustomer(postData as CustomsOfficeCustomerViewModel)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({
    id: 0,
    baseFreightCost: 0,
    customerId: 0,
    customsOfficeId: 0,
    freightCostCalculationType: 0,
    freightCostPerKg: 0,
    freightCostPerKgAfterXKg: 0,
    freightCostPerPiece: 0,
    freightCostPerPieceAfterXPieces: 0,
  } as CustomsOfficeCustomerViewModel);
  totalItems.value++;
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((cocu) => cocu.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  customsOfficeCustomerApi
    .deleteCustomsOfficeCustomer(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    });
};

const isNewItem = (item: CustomsOfficeCustomerViewModel): boolean => {
  return item?.id === 0;
};

const mapItem = (oldItem: any, newItem: any) => {
  for (const key in oldItem) {
    oldItem[key] = newItem[key];
  }
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};

const getOfficeName = (item: CustomsOfficeCustomerViewModel) => {
  var office = customsOffices.value.find(
    (co) => co.id === item.customsOfficeId,
  );
  if (!office) {
    return "";
  }

  return office.officeNumber;
};

const getCustomerName = (item: CustomsOfficeCustomerViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};

const getFreightCalculationType = (item: CustomsOfficeCustomerViewModel) => {
  var calculationType = freightCostCalculationTypes.value.find(
    (f) => f.value === item.freightCostCalculationType,
  );
  if (!calculationType) {
    return "";
  }

  return calculationType.text;
};
</script>
