<template>
  <v-dialog
    :value="value"
    persistent
    max-width="600px"
    @input="emits('input', $event)"
  >
    <v-card>
      <v-toolbar color="light-blue darken-1" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="text-caption">Please add a route</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="routeId"
                  :items="exportInformationItems"
                  item-text="name"
                  item-value="id"
                  label="Route"
                  :rules="[rules.required]"
                  :loading="isRetrievingRoutes"
                  :disabled="isLoading"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="routeId == 0"
          @click="emits('confirm', { routeId: routeId })"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
interface IProps {
  value?: boolean | null;
  isLoading?: boolean | null;
  title?: string | null;
  confirmButtonText?: string | null;
  closeButtonText?: string | null;
}
import { ref, onBeforeMount } from "vue";
import {
  CustomsExportInformationApi,
  CustomsExportInformationViewModel,
} from "@/openapi";
const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Add route",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});
const emits = defineEmits(["errorOccured", "input", "close", "confirm"]);

const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
};
const valid = ref(true);
const isRetrievingRoutes = ref(false);
const routeId = ref(0);
const exportInformationItems = ref<CustomsExportInformationViewModel[]>([]);

const customsExportInformationApi: CustomsExportInformationApi =
  new CustomsExportInformationApi(undefined, "");

const getExportInformation = () => {
  exportInformationItems.value = [];
  isRetrievingRoutes.value = true;
  customsExportInformationApi
    .getCustomsExportInformation(100, 1, "", "ASC")
    .then((result) => {
      exportInformationItems.value = result.data
        .items as CustomsExportInformationViewModel[];
      isRetrievingRoutes.value = false;
    })
    .catch(() => {
      displayError("Something went wrong while retrieving the routes.");
    });
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

onBeforeMount(() => {
  getExportInformation();
});
</script>
