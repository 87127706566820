import { emitError } from "@/event-bus";
import { CustomsArticle, DataApi } from "@/openapi";
import { ref } from "vue";
const api = new DataApi(undefined, "");

export function useArticles() {
  const ezaArticles = ref<CustomsArticle[]>([]);
  const impostArticles = ref<CustomsArticle[]>([]);

  const loadingEzaArticles = ref(false);
  const loadingImpostArticles = ref(false);

  const articlesFlowStepsOnBeforeMount = () => {
    getEzaArticles();
    getImpostArticles();
  };

  const getEzaArticles = async () => {
    loadingEzaArticles.value = true;
    try {
      const response = await api.getCustomsArticles(false);
      ezaArticles.value = response.data;
    } catch {
      emitError("Something went wrong while retrieving the EZA HS codes.");
    }
    loadingEzaArticles.value = false;
  };

  const getImpostArticles = async () => {
    loadingImpostArticles.value = true;
    try {
      const response = await api.getCustomsArticles(true);
      impostArticles.value = response.data;
    } catch {
      emitError("Something went wrong while retrieving the IMPOST HS codes.");
    }
    loadingImpostArticles.value = false;
  };
  return {
    ezaArticles,
    impostArticles,
    loadingEzaArticles,
    loadingImpostArticles,
    articlesFlowStepsOnBeforeMount,
    getEzaArticles,
    getImpostArticles,
  };
}
