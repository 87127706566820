<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="modelOpen">
      <template #header><span class="title-holder">Export MRN</span></template>
      <template #content>
        <v-form slim>
          <div class="form-fields">
            <div v-if="dataList.length == 0">No export MRN</div>
            <ValidationProvider
              v-for="(item, index) in dataList"
              v-slot="{ errors }"
              name="Export MRN"
              :rules="requireValue"
            >
              <v-text-field
                v-model="item.value"
                label="Export MRN"
                :error-messages="errors"
              >
                <v-btn v-if="isMaster" slot="append" icon @click="remove(index)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-text-field>
            </ValidationProvider>
            <v-btn v-if="isMaster" @click="add">Add</v-btn>
          </div>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn :disabled="dataLoading" @click="cancel">Cancel</v-btn>
        <v-btn :disabled="invalid || dataLoading" color="success" @click="save"
          >Save</v-btn
        ></template
      >
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { CustomsExportApi, KeyValueItem } from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import { ref, onMounted, watch } from "vue";

const customsExportApi = new CustomsExportApi(undefined, "");
import { emitError, emitSuccess } from "@/event-bus";

interface CustomsParcelProps {
  isMaster: boolean;
  id: number;
  modelOpen: boolean;
}

const props = defineProps<CustomsParcelProps>();
const emit = defineEmits(["cancel", "saved"]);

const requireValue = ref("");
const internalModelOpen = ref(props.modelOpen);
const dataLoading = ref(false);
const dataList = ref<KeyValueItem[]>([]);

watch(
  () => props.modelOpen,
  (newVal, oldVal) => {
    if (newVal) {
      dataList.value = [];
      getExportMrn();
    }
  },
);

const getExportMrn = async () => {
  try {
    dataLoading.value = true;
    const response = await customsExportApi.getExportMrns(props.id);
    dataList.value = response.data;
    dataLoading.value = false;
  } catch {
    emitError("Something went wrong while retrieving data");
  }
};

const add = () => {
  dataList.value.push({ key: 0, value: "" });
};

const remove = (index: number) => {
  dataList.value.splice(index, 1);
};

const cancel = () => {
  internalModelOpen.value = false;
  emit("cancel");
};

const save = async () => {
  const response = await customsExportApi.saveExportMrn(
    props.id,
    dataList.value,
  );
  internalModelOpen.value = false;
  emit("saved", response.data);
  emitSuccess("Export MRN saved.");
};

onMounted(async () => {
  if (props.isMaster) {
    requireValue.value = "required";
  } else {
  }
  getExportMrn();
});
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
</style>
