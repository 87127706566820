<template>
  <div>
    <v-data-table
      v-if="t2Declarations.length"
      :headers="declarationHeaders"
      :items="t2Declarations"
      :hide-default-footer="true"
      :single-expand="true"
      item-key="id"
      dense
      fixed-header
      fixed-footer
      show-expand
      class="rounded-0"
      :loading="isLoading"
      @item-expanded="onExpandedDeclaration"
    >
      <template #item.seals="{ item }">
        {{ item.seals.join(", ") }}
      </template>

      <template #item.response="{ item }">
        <v-btn
          v-if="!item.hasResponseFile && !item.noResponseStatus.active"
          :disabled="isLoading"
          small
          color="info"
          class="text-center w-100"
          @click="uploadResponse(item)"
          ><v-icon left> mdi-upload </v-icon>UPLOAD RESPONSE</v-btn
        >
        <v-btn
          v-else-if="!item.noResponseStatus.active"
          :disabled="isLoading"
          :href="
            '/api/customsTransit/downloadDeclarationResponse?declarationId=' +
            item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center w-100"
          ><v-icon left> mdi-download </v-icon>DOWNLOAD RESPONSE</v-btn
        >
      </template>

      <template #item.noResponse="{ item }">
        <v-btn
          v-if="!item.noResponseStatus.active && !item.hasResponseFile"
          :disabled="isLoading"
          small
          color="error"
          class="v-btn--flexible text-center w-100"
          @click="setNoResponse(item)"
          ><v-icon left> mdi-file-cancel </v-icon>NO RESPONSE</v-btn
        >
        <span v-else-if="!item.hasResponseFile">
          <strong>Name:</strong> <br />{{ item.noResponseStatus.employee }}
          <br />
          <strong>Date:</strong> <br />{{
            moment(item.noResponseStatus.activationDate).format(
              "DD-MM-YYYY HH:mm:ss",
            )
          }}
        </span>
      </template>

      <template #item.consoDownload="{ item }">
        <v-btn
          icon
          :disabled="!item.hasConsoFile"
          :href="
            '/api/customsTransit/DownloadConsoFile?t2DeclarationId=' + item.id
          "
        >
          <v-icon>mdi-tray-arrow-down</v-icon>
        </v-btn>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-flex class="pa-3 pb-5" light-blue lighten-5>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="shipmentHeaders"
                    :items="items"
                    :server-items-length="totalItems"
                    :options.sync="options"
                    :footer-props="footerOptions"
                    :single-expand="true"
                    :expanded.sync="expanded"
                    item-key="id"
                    dense
                    fixed-header
                    fixed-footer
                    show-expand
                    class="elevation-1"
                    :loading="isLoading"
                  >
                    <template #body.prepend>
                      <tr>
                        <td>
                          <v-icon small>mdi-filter</v-icon>
                        </td>
                        <td>
                          <v-select
                            v-model="filter.step"
                            :items="statusFilter"
                            clearable
                            chips
                            dense
                            single-line
                            @change="onFilterSelected"
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            v-model="filter.shipmentStatusDescription"
                            :items="shipmentStatusFilter"
                            clearable
                            dense
                            single-line
                            @change="onFilterSelected"
                          ></v-select>
                        </td>
                        <td>
                          <FilterParcels
                            v-model="filter.parcels"
                            @input="onFilterSelected"
                          ></FilterParcels>
                        </td>
                        <td>
                          <v-text-field
                            v-model="filter.exportMrn"
                            clearable
                            dense
                            @keyup.enter="onFilterSelected"
                            @click:clear="
                              (filter.exportMrn = ''), onFilterSelected()
                            "
                          ></v-text-field>
                        </td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <v-autocomplete
                            v-model="filter.shipperCountry"
                            :items="dataStore.originCountries"
                            item-key="code"
                            item-text="code"
                            clearable
                            dense
                            single-line
                            @change="onFilterSelected"
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="filter.consigneeCountry"
                            :items="dataStore.originCountries"
                            item-key="code"
                            item-text="code"
                            clearable
                            dense
                            single-line
                            @change="onFilterSelected"
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </template>

                    <template #item.exportFlowStatus="{ item }">
                      <v-avatar
                        class="flow-status-avatar"
                        size="22"
                        :color="flowSteps[item.exportFlowStatus][3]"
                      >
                        {{ item.exportFlowStatus }}
                      </v-avatar>
                    </template>

                    <template #item.exportMrn="{ item }">
                      <span>
                        {{
                          item.isMaster
                            ? item.exportMrns.join(", ")
                            : item.exportMrn
                        }}
                      </span>
                    </template>

                    <template #item.shipper="{ item }">
                      <v-row justify="center">
                        <v-col>
                          {{ item.shipper.name }}<br />
                          {{ item.shipper.address }}<br />
                          {{ item.shipper.zipcode }}
                          {{ item.shipper.city }}<br />
                          {{ item.shipper.country }}
                        </v-col>
                      </v-row>
                    </template>

                    <template #item.consignee="{ item }">
                      <v-row justify="center">
                        <v-col>
                          {{ item.consignee.name }}<br />
                          {{ item.consignee.address }}<br />
                          {{ item.consignee.zipcode }}
                          {{ item.consignee.city }}<br />
                          {{ item.consignee.country }}
                        </v-col>
                      </v-row>
                    </template>

                    <template #item.value="{ item }">
                      <v-row>
                        <v-col>
                          {{ item.value.toFixed(2) }}
                        </v-col>
                      </v-row>
                    </template>

                    <template #item.articles="{ item }">
                      <v-row
                        v-for="article in item.articles.slice(0, 4)"
                        no-gutters
                      >
                        <v-col cols="9">
                          <span class="articleDescription">{{
                            article.description
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="articleDescription pl-4">{{
                            article.rcTarif
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.articles.length > 4" no-gutters>
                        <v-col cols="9">
                          <span class="articleDescription">more...</span>
                        </v-col>
                      </v-row>
                    </template>

                    <template #expanded-item="{ headers, item }">
                      <td
                        v-if="currentShipmentDetail"
                        :colspan="headers.length"
                      >
                        <v-flex class="pa-3 pb-5">
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-card>
                                  <v-card-title>Shipment status</v-card-title>
                                  <ShipmentPaperwork
                                    class="shipment-paperwork"
                                    :shipment-id="currentShipmentDetail.id"
                                  ></ShipmentPaperwork>
                                  <CustomsExportShipmentDetailViewNew
                                    :current-handling-item="
                                      currentShipmentDetail
                                    "
                                  />
                                  <ShipmentStatusFlow
                                    :shipment-id="currentShipmentDetail.id"
                                    :active-status="
                                      currentShipmentDetail.exportFlowStatus
                                    "
                                  ></ShipmentStatusFlow>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>
    <UploadFileDialog
      v-model="showDialogUploadFile"
      :is-loading="dialogUploadFileLoading"
      @confirm="onFileUploaded"
      @cancel="onFileUploadCanceled"
    ></UploadFileDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsShipmentItemViewModel,
  CustomsT2DeclarionItem,
  CustomsTransitApi,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import { UploadFileForm } from "@/components/dialogs/UploadFileDialog.vue";
import UploadFileDialog from "@/components/dialogs/UploadFileDialog.vue";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";
import moment from "moment";
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

const emits = defineEmits(["errorOccured", "reloadDeclarations"]);
const props = defineProps<{
  t2Declarations: {
    type: CustomsT2DeclarionItem[];
    default: () => CustomsT2DeclarionItem[];
  };
}>();

const isLoading = ref(false);

const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref([]);

const startStep = ref(8);
const endStep = ref(12);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const items = ref<CustomsShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const currentDeclarationItem = ref<CustomsT2DeclarionItem | null>(null);

const showDialogUploadFile = ref(false);
const dialogUploadFileLoading = ref(false);
const currentDeclarationItemFileUpload = ref<CustomsT2DeclarionItem | null>(
  null,
);

const declarationHeaders = ref([
  {
    text: "MRN",
    value: "transitMrn",
    align: "start",
    width: "10%",
    sortable: false,
  },
  {
    text: "Expire date",
    value: "expireDate",
    align: "start",
    width: "10%",
    sortable: false,
  },
  {
    text: "Dakosy Reference",
    value: "dakosyReference",
    align: "start",
    width: "10%",
    sortable: false,
  },
  {
    text: "Registration",
    value: "registration",
    align: "start",
    width: "10%",
    sortable: false,
  },
  {
    text: "Seal(s)",
    value: "seals",
    align: "start",
    width: "20%",
    sortable: false,
  },
  {
    text: "Upload/download response",
    value: "response",
    align: "start",
    width: "15%",
    sortable: false,
  },
  {
    text: "No response",
    value: "noResponse",
    align: "start",
    width: "15%",
    sortable: false,
  },
  {
    text: "CONSO",
    value: "consoDownload",
    align: "start",
    width: "10%",
    sortable: false,
  },
]);

const shipmentHeaders = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
    sortable: false,
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
    sortable: false,
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    sortable: false,
    width: "18em",
  },
  {
    text: "LTS",
    value: "lts",
    sortable: false,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eoriNumber", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

onBeforeMount(() => {
  dataStore.fetchOriginCountries();
  statusFilter.value = getStatuses(false, false, false, false, true);
});

let timeoutShipmentsDelay = 0;
watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(timeoutShipmentsDelay);
    timeoutShipmentsDelay = setTimeout(() => {
      loadShipments(currentDeclarationItem.value!.id!, options.value.page);
    }, 250);
  },
);

watch(
  () => expanded.value,
  (
    newVal: CustomsShipmentItemViewModel[],
    oldVal: CustomsShipmentItemViewModel[],
  ) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value.id!)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);

const onExpandedDeclaration = (
  item: CustomsT2DeclarionItem,
  value: boolean,
) => {
  if (!value) {
    currentDeclarationItem.value = null;
    currentShipmentDetail.value = null;
    expanded.value = [];
    items.value = [];
    totalItems.value = 0;
  } else {
    currentDeclarationItem.value = item;
    loadShipments(currentDeclarationItem.value!.id!, 1);
  }
};

const loadShipments = async (declarationId: number, page: number) => {
  try {
    isLoading.value = true;
    const response = await customsTransitApi.getDeclarationShipments({
      declarationId,
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });

    items.value = response.data.shipments!;
    totalItems.value = response.data.totalAmount!;
  } catch {
    displayError(
      "Something went wrong while retrieving the shipments of the selected T2 declaration.",
    );
  }

  isLoading.value = false;
};

const onFilterSelected = () => {
  loadShipments(currentDeclarationItem.value!.id!, options.value.page);
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const onConsoDownload = (item: CustomsT2DeclarionItem) => {
  alert("Still WIP :)");
};

const setNoResponse = async (item: CustomsT2DeclarionItem) => {
  isLoading.value = true;
  try {
    await customsTransitApi.setDeclarationNoResponse(item.id);
    emits("reloadDeclarations");
  } catch {
    displayError('Something went wrong during the "no response" action.');
  }
  isLoading.value = false;
};

const uploadResponse = (item: CustomsT2DeclarionItem) => {
  currentDeclarationItemFileUpload.value = item;
  showDialogUploadFile.value = true;
};

const onFileUploaded = async ({ files }: UploadFileForm) => {
  dialogUploadFileLoading.value = true;

  try {
    await customsTransitApi.uploadDeclarationResponse(
      currentDeclarationItemFileUpload.value?.id,
      files![0],
    );
    emits("reloadDeclarations");
    showDialogUploadFile.value = false;
  } catch {
    displayError(
      "Something went wrong while uploading the declaration response.",
    );
  }

  dialogUploadFileLoading.value = false;
};

const onFileUploadCanceled = () => {
  currentDeclarationItemFileUpload.value = null;
};
</script>
