<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    transition="dialog-bottom-transition"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="error" dark>
        <span class="ml-3 text-button">{{ displayTitle }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="formData.reason"
                  label="Reason"
                  outlined
                  :rules="[rules.required, rules.maxChar]"
                  counter="maxCharactersReason"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="!valid"
          @click="emits('confirm', form)"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import { Actions } from "@/config";

export interface SetHoldReasonForm {
  reason?: string;
}
const props = defineProps({
  value: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  title: { type: String, default: "" },
  confirmButtonText: { type: String, default: "Confirm" },
  closeButtonText: { type: String, default: "Close" },
});
const emits = defineEmits(["input", "confirm", "close"]);
const initializeForm = (): SetHoldReasonForm => {
  return {
    reason: "",
  };
};
const form = ref(null);
const maxCharactersReason = ref(1500);

const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
  maxChar: (value: string) =>
    value.length <= maxCharactersReason.value ||
    `Max ${maxCharactersReason.value} characters`,
};
const valid = ref(true);

const formData = ref(initializeForm());

const displayTitle = computed(() => {
  return !props.title ? Actions.HOLD : props.title;
});

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      formData.value = initializeForm();
      (
        form.value! as Vue & { resetValidation: () => boolean }
      ).resetValidation();
    }
  },
);
</script>
