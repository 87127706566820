import {
  AcDocumentTypeViewModel,
  CountryOfOriginViewModel,
  CurrencyViewModel,
  CustomerViewModel,
  CustomsOfficeViewModel,
  TypeOfBusinessViewModel,
  DataApi,
  DestinationCountryViewModel,
  EmployeeViewModel,
  IncotermViewModel,
  Int32StringKeyValueItem,
  JobLevelItem,
  ShipmentApi,
  StringStringKeyValueItem,
  TransitDeclarationData,
  UnitOfMeasurementViewModel,
  MilestoneViewModel,
  DeliveryConditionViewModel,
  TransportTypeViewModel,
  CodeViewModel,
  ColorViewModel,
  CustomsArticle,
} from "@/openapi";
import { emitError } from "@/event-bus";
import { defineStore } from "pinia";

export interface DataState {
  consolidationTypes: string[];
  customsReturnLocations: StringStringKeyValueItem[];
  customsHandlingCategories: Int32StringKeyValueItem[];
  originCountries: CountryOfOriginViewModel[];
  transitDeclarationDefinitionRoutes: TransitDeclarationData[];
  warehouseLocations: StringStringKeyValueItem[];
  euDestinationCountries: DestinationCountryViewModel[];
  incoterms: IncotermViewModel[];
  generalCustomers: CustomerViewModel[];
  currencies: CurrencyViewModel[];
  exchangeRateCurrencies: CurrencyViewModel[];
  origins: Int32StringKeyValueItem[];
  transitTemplates: Int32StringKeyValueItem[];
  employees: EmployeeViewModel[];
  invoiceFooterTexts: Int32StringKeyValueItem[];
  paymentTerms: Int32StringKeyValueItem[];
  customsOffices: CustomsOfficeViewModel[];
  businessTypes: TypeOfBusinessViewModel[];
  jobLevels: JobLevelItem[];
  acDocumentTypes: AcDocumentTypeViewModel[];
  measurementUnits: UnitOfMeasurementViewModel[];
  airports: Int32StringKeyValueItem[];
  milestoneCustomsHandling: MilestoneViewModel[];
  customsSchemes: Int32StringKeyValueItem[];
  documentTypeCustoms: Int32StringKeyValueItem[];
  destinationCountries: DestinationCountryViewModel[];
  deliveryConditions: DeliveryConditionViewModel[];
  customsTransportType: TransportTypeViewModel[];
  codes: CodeViewModel[];
  colors: ColorViewModel[];
  customsArticles: CustomsArticle[];
  routes: Int32StringKeyValueItem[];
}

const shipmentApi = new ShipmentApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const getInitialState = () =>
  ({
    consolidationTypes: [],
    customsReturnLocations: [],
    customsHandlingCategories: [],
    originCountries: [],
    transitDeclarationDefinitionRoutes: [],
    warehouseLocations: [],
    euDestinationCountries: [],
    incoterms: [],
    generalCustomers: [],
    currencies: [],
    origins: [],
    transitTemplates: [],
    employees: [],
    invoiceFooterTexts: [],
    paymentTerms: [],
    customsOffices: [],
    businessTypes: [],
    jobLevels: [],
    acDocumentTypes: [],
    measurementUnits: [],
    airports: [],
    milestoneCustomsHandling: [],
    customsSchemes: [],
    documentTypeCustoms: [],
    destinationCountries: [],
    deliveryConditions: [],
    customsTransportType: [],
    codes: [],
    colors: [],
    customsArticles: [],
    exchangeRateCurrencies: [],
    routes: [],
  }) as DataState;

export const useDataStore = defineStore("data", {
  state: getInitialState,
  getters: {
    nonHiddenJobLevels(state: DataState) {
      return state.jobLevels.filter((c) => !c.isHidden);
    },
    optionalJobLevels(state: DataState) {
      return state.jobLevels.filter((c) => c.allowedAsOptional);
    },
  },
  actions: {
    async fetchRoutes() {
      if (this.routes?.length) {
        return;
      }

      try {
        const response = await dataApi.getRoutes();
        this.routes = response.data;
      } catch {
        emitError("Something went wrong while retrieving routes");
      }
    },
    async fetchConsolidationTypes() {
      if (this.consolidationTypes?.length) {
        return;
      }

      try {
        const response = await shipmentApi.getConsolidationTypes();
        this.consolidationTypes = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the consolidation types",
        );
      }
    },
    async fetchCustomsReturnLocations() {
      if (this.customsReturnLocations?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsReturnLocations();
        this.customsReturnLocations = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the customs return locations",
        );
      }
    },
    async fetchCustomsHandlingCategories() {
      if (this.customsHandlingCategories?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsHandlingCategories();
        this.customsHandlingCategories = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the customs handling categories",
        );
      }
    },
    async fetchOriginCountries() {
      if (this.originCountries?.length) {
        return;
      }

      try {
        const response = await dataApi.getOriginCountries();
        this.originCountries = response.data;
      } catch {
        emitError("Something went wrong while retrieving the origin countries");
      }
    },
    async fetchTransitDeclarationDefinitionRoutes() {
      if (this.transitDeclarationDefinitionRoutes?.length) {
        return;
      }

      try {
        const response = await dataApi.getTransitDeclarationDefinitionRoutes();
        this.transitDeclarationDefinitionRoutes = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the transit declaration definition routes.",
        );
      }
    },
    async fetchWarehouseLocations() {
      if (this.warehouseLocations?.length) {
        return;
      }

      try {
        const response = await dataApi.getWarehouseLocations();
        this.warehouseLocations = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the warehouse locations",
        );
      }
    },
    async fetchEuDestinationCountries() {
      if (this.euDestinationCountries?.length) {
        return;
      }

      try {
        const response = await dataApi.getEuDestinationCountries();
        this.euDestinationCountries = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the eu destination countries",
        );
      }
    },
    async fetchIncoterms() {
      if (this.incoterms?.length) {
        return;
      }

      try {
        const response = await dataApi.getIncoterms();
        this.incoterms = response.data;
      } catch {
        emitError("Something went wrong while retrieving the incoterms");
      }
    },
    async fetchGeneralCustomers() {
      if (this.generalCustomers?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomers(true);
        this.generalCustomers = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the general customers",
        );
      }
    },
    async fetchCurrencies() {
      if (this.currencies?.length) {
        return;
      }

      try {
        const response = await dataApi.getDataCurrencies();
        this.currencies = response.data;
      } catch {
        emitError("Something went wrong while retrieving the currencies");
      }
    },
    async fetchCustomsCurrencies() {
      if (this.currencies?.length) {
        return;
      }

      try {
        const response = await dataApi.getDataCurrencies();
        this.currencies = response.data;
      } catch {
        emitError("Something went wrong while retrieving the currencies");
      }
    },
    async fetchOrigins() {
      if (this.origins?.length) {
        return;
      }

      try {
        const response = await dataApi.getOrigins();
        this.origins = response.data;
      } catch {
        emitError("Something went wrong while retrieving the origins");
      }
    },
    async fetchInvoiceFooterTexts() {
      if (this.invoiceFooterTexts?.length) {
        return;
      }

      try {
        const response = await dataApi.getDataInvoiceFooterTexts();
        this.invoiceFooterTexts = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the invoice footer texts",
        );
      }
    },
    async fetchTransitTemplates() {
      if (this.transitTemplates?.length) {
        return;
      }

      try {
        const response = await dataApi.getOrigins();
        this.transitTemplates = response.data;
      } catch {
        emitError("Something went wrong while retrieving the origins");
      }
    },
    async fetchEmployees() {
      if (this.employees?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsEmployees();
        this.employees = response.data;
      } catch {
        emitError("Something went wrong while retrieving the employees");
      }
    },
    async fetchPaymentTerms() {
      if (this.paymentTerms?.length) {
        return;
      }

      try {
        const response = await dataApi.getDataPaymentTerms();
        this.paymentTerms = response.data;
      } catch {
        emitError("Something went wrong while retrieving the payment terms");
      }
    },
    async fetchCustomsOffices() {
      if (this.customsOffices?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsOffices();
        this.customsOffices = response.data;
      } catch {
        emitError("Something went wrong while retrieving the customs offices");
      }
    },
    async fetchBusinessTypes() {
      if (this.customsOffices?.length) {
        return;
      }

      try {
        const response = await dataApi.getBusinessTypes();
        this.businessTypes = response.data;
      } catch {
        emitError("Something went wrong while retrieving the customs offices");
      }
    },

    async fetchJobLevels() {
      if (this.jobLevels?.length) {
        return;
      }

      try {
        const response = await dataApi.getJobLevels();
        this.jobLevels = response.data;
      } catch {
        emitError("Something went wrong while retrieving the job levels");
      }
    },
    async fetchAcDocumentTypes() {
      if (this.acDocumentTypes?.length) {
        return;
      }

      try {
        const response = await dataApi.getAcDocumentTypes();
        this.acDocumentTypes = response.data;
      } catch {
        emitError("Something went wrong while retrieving the document types");
      }
    },
    async fetchMeasurementUnits() {
      if (this.measurementUnits?.length) {
        return;
      }

      try {
        const response = await dataApi.getMeasurementUnits();
        this.measurementUnits = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the measurement units",
        );
      }
    },
    async fetchAirports() {
      if (this.airports?.length) {
        return;
      }

      try {
        const response = await dataApi.getAirports();
        this.airports = response.data;
      } catch {
        emitError("Something went wrong while retrieving airports");
      }
    },
    async fetchMilestoneCustomsHandling() {
      if (this.milestoneCustomsHandling?.length) {
        return;
      }

      try {
        const response = await dataApi.getMilestoneCustomsHandling();
        this.milestoneCustomsHandling = response.data;
      } catch {
        emitError("Something went wrong while retrieving the origin countries");
      }
    },
    async fetchCustomsSchemes() {
      if (this.customsSchemes?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsSchemes();
        this.customsSchemes = response.data;
      } catch {
        emitError("Something went wrong while retrieving the schemes");
      }
    },
    async fetchDocumentTypeCustoms() {
      if (this.documentTypeCustoms?.length) {
        return;
      }

      try {
        const response = await dataApi.getDocumentTypeCustoms();
        this.documentTypeCustoms = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the documentTypeCustoms",
        );
      }
    },
    async fetchDestinationCountries() {
      if (this.destinationCountries?.length) {
        return;
      }

      try {
        const response = await dataApi.getDestinationCountries();
        this.destinationCountries = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the destination countries",
        );
      }
    },
    async fetchDeliveryConditions() {
      if (this.deliveryConditions?.length) {
        return;
      }

      try {
        const response = await dataApi.getDeliveryConditions();
        this.deliveryConditions = response.data;
      } catch {
        emitError(
          "Something went wrong while retrieving the delivery conditions",
        );
      }
    },
    async fetchCustomsTransportType() {
      if (this.customsTransportType?.length) {
        return;
      }

      try {
        const response = await dataApi.getDataTransportTypes();
        this.customsTransportType = response.data;
      } catch {
        emitError("Something went wrong while retrieving the transport types");
      }
    },
    async fetchCodes() {
      if (this.codes?.length) {
        return;
      }

      try {
        const response = await dataApi.getCodes();
        this.codes = response.data;
      } catch {
        emitError("Something went wrong while retrieving codes");
      }
    },
    async fetchColors() {
      if (this.colors?.length) {
        return;
      }

      try {
        const response = await dataApi.getColors();
        this.colors = response.data;
      } catch {
        emitError("Something went wrong while retrieving colors");
      }
    },
    async fetchtCustomsArticles() {
      if (this.colors?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsArticles();
        this.customsArticles = response.data;
      } catch {
        emitError("Something went wrong while retrieving customs articles");
      }
    },
    async fetchtExchangeRateCurrencies() {
      if (this.exchangeRateCurrencies?.length) {
        return;
      }

      try {
        const response = await dataApi.getExchangeRateCurrencies();
        this.exchangeRateCurrencies = response.data;
      } catch {
        emitError("Something went wrong while retrieving customs articles");
      }
    },
  },
});
