<template>
  <v-dialog
    :value="value"
    @click:outside="emits('input', false)"
    width="500"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="light-blue darken-1" dark>
        <span class="ml-6">{{ title }}</span>
      </v-toolbar>

      <v-card-text class="pt-6">
        <v-form ref="form">
          <v-menu
            ref="dateRangeMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="ml-4"
          >
            <template v-slot:activator="menuSlotProps">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge color="primary">
                    <template v-slot:badge>
                      <v-icon v-on="on" v-bind="attrs"
                        >mdi-information-variant</v-icon
                      >
                    </template>
                    <v-text-field
                      v-model="form.dateRange"
                      label="Export date range"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="menuSlotProps.attrs"
                      v-on="menuSlotProps.on"
                    ></v-text-field>
                  </v-badge>
                </template>
                <span
                  >Select 2 dates to select a range of dates. Select the same
                  date twice to filter on a single date.</span
                >
              </v-tooltip>
            </template>

            <v-date-picker
              v-model="form.dateRange"
              :range="true"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light-blue darken-1" text @click="emits('input', false)">
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!hasSelectedDateRange"
          :href="exportUrl"
          :download="downloadName"
          @click="onConfirm"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import moment from "moment";
import { ref, watch, computed } from "vue";
interface IProps {
  value: boolean;
  title?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
}
const EXPORT_URL = "/api/customsExport/ExportValueChanges";
const props = withDefaults(defineProps<IProps>(), {
  title: "Export value changes",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});
const emits = defineEmits(["input"]);
const form = ref({
  dateRange: null as string[] | null,
});

watch(
  () => props.value,
  (newVal) => {
    if (newVal) {
      form.value.dateRange = null;
    }
  },
);

const hasSelectedDateRange = computed(() => {
  return !!form.value.dateRange && form.value.dateRange.length === 2;
});

const exportUrl = computed(() => {
  const searchParams = new URLSearchParams();
  if (hasSelectedDateRange.value) {
    searchParams.append("startDate", form.value.dateRange![0]);
    searchParams.append("endDate", form.value.dateRange![1]);
  }

  let exportUrl = EXPORT_URL;
  if (searchParams.has("startDate")) {
    exportUrl += `?${searchParams.toString()}`;
  }

  return exportUrl;
});

const downloadName = computed(() => {
  const now = moment();
  return `export-value-change-${now.format("YYYY-MM-DD")}`;
});

const onConfirm = () => {
  emits("input", false);
};
</script>
