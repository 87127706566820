<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <template>
          <v-stepper v-model="addClaimStep" vertical>
            <v-stepper-step :complete="addClaimStep > 1" step="1">
              Enter HAWB or invoice number
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="inputHawbOrInvoice"
                    hide-details
                    class="mt-1 mb-3"
                    dense
                    label="HAWB / Invoice number"
                    outlined
                    clearable
                    @keydown.enter="onHawbInvoiceEntered()"
                  ></v-text-field>
                </v-col>
                <v-col v-if="claimNotFound" class="mt-3 error--text">
                  No invoice found for entered HAWB / Invoice number.
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                :loading="isLoading"
                @click="onHawbInvoiceEntered()"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="addClaimStep > 2" step="2">
              Claim details
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-row>
                <v-col cols="6">
                  <ClaimDetailView :claim="claim" />
                </v-col>
              </v-row>

              <v-btn color="primary" @click="addClaimStep = 3">
                Continue
              </v-btn>
              <v-btn text @click="addClaimStep = 1"> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="addClaimStep > 3" step="3">
              Choose a claim category
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="chosenCategory"
                    :items="claimCategories"
                    label="Category"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!chosenCategory"
                color="primary"
                @click="addClaimStep = 4"
              >
                Continue
              </v-btn>
              <v-btn text @click="addClaimStep = 1"> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-step step="4"> Register claim </v-stepper-step>
            <v-stepper-content step="4">
              <template v-if="!claimRegistered">
                <v-row>
                  <v-col cols="6">
                    <v-card class="mr-4 mb-4 pa-6" color="#FFF8E1">
                      <v-row no-gutters>
                        <v-col cols="4" class="font-weight-medium mb-2"
                          >Claim category</v-col
                        >
                        <v-col>{{ chosenCategory }}</v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <ClaimDetailView :claim="claim" />
                  </v-col>
                </v-row>

                <v-btn color="primary" :loading="isLoading" @click="addClaim()">
                  Register
                </v-btn>
                <v-btn text @click="addClaimStep = 1"> Cancel </v-btn>
              </template>

              <template v-if="claimRegistered">
                <v-row>
                  <v-col class="mb-3 success--text">
                    Claim successfull registered.
                  </v-col>
                </v-row>

                <v-btn
                  color="primary"
                  @click="(inputHawbOrInvoice = ''), (addClaimStep = 1)"
                >
                  Register another one
                </v-btn>
              </template>
            </v-stepper-content>
          </v-stepper>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { ClaimViewModel, CustomsClaimApi } from "@/openapi";
import { ToolbarItem } from "@/models/ToolbarItem";
import ClaimDetailView from "../components/ClaimDetailView.vue";

const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const customsClaimApi = new CustomsClaimApi(undefined, "");

const inputHawbOrInvoice = ref("");
const chosenCategory = ref("");
const isLoading = ref(false);
const claim = ref<ClaimViewModel | null>(null);
const claimCategories = ref([
  "Category 1",
  "Category 2",
  "Category 3",
  "Category 4",
]);

const claimNotFound = ref(false);
const addClaimStep = ref(1);
const claimRegistered = ref(false);

const refresh = () => {};

const onHawbInvoiceEntered = () => {
  if (inputHawbOrInvoice.value === "") {
    claim.value = null;
    return;
  }

  loadItem();
};

const loadItem = () => {
  isLoading.value = true;
  claimNotFound.value = false;
  claim.value = null;
  customsClaimApi
    .getInvoiceData(inputHawbOrInvoice.value)
    .then((response) => {
      claim.value = response.data;
      if (claim.value == null) {
        claimNotFound.value = true;
      } else {
        addClaimStep.value = 2;
        claimRegistered.value = false;
      }
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
    });
};

const addClaim = () => {
  isLoading.value = true;
  claimRegistered.value = true;

  customsClaimApi
    .addClaim(claim.value!.invoiceId, chosenCategory.value)
    .then(() => {
      claimRegistered.value = true;
    })
    .catch((error) => {
      emits("errorOccured", [error.message]);
      claimRegistered.value = false;
    })
    .finally(() => {
      isLoading.value = false;
      inputHawbOrInvoice.value = "";
    });
};

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];

  emits("PageInfoReceived", "Register an invoice claim", toolbarButtons);
});
</script>
