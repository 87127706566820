<template>
  <div class="v-card__text shipment-general">
    <v-row>
      <v-col cols="4">
        <ImportReferences :detail.sync="detail"></ImportReferences>
      </v-col>
      <v-col cols="4">
        <ImportInvoiceAddress :detail.sync="detail"></ImportInvoiceAddress>
      </v-col>
      <v-col cols="4">
        <ImportWeightPrice :detail.sync="detail"></ImportWeightPrice>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="pa-3 remarks-card">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>Remarks</v-card-title>
          </v-toolbar>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="detail.references.remark"
                label="remarks"
                outlined
                hide-details
                height="350"
                auto-grow
                row-height="15"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <GeneralShipperAddress :detail.sync="detail"></GeneralShipperAddress>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3 dakosy-card">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>Export to Dakosy</v-card-title>
          </v-toolbar>
          <v-col cols="12">
            <v-btn
              :disabled="!detail?.allowExportToDakosy || createAndSend"
              :loading="createAndSend"
              color="primary"
              @click="exportToDakosy"
              >Create & send</v-btn
            >
          </v-col>
          <v-col v-if="detail?.exportToDakosy?.id" cols="12">
            <span
              >This custom is already sent to Dakosy. Creation date/time:
              <b>{{
                formattedDateTime(detail?.exportToDakosy?.createdDateTime)
              }}</b>
              by: {{ detail?.exportToDakosy?.employeeName }} Response date/time:
              <b>{{
                formattedDateTime(detail?.exportToDakosy?.requestDateTime)
              }}</b></span
            >
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <ImportSendingInfo :detail.sync="detail"></ImportSendingInfo>
      </v-col>
      <v-col cols="8">
        <ImportAutomaticClearance
          :detail.sync="detail"
        ></ImportAutomaticClearance>
      </v-col>
      <v-col cols="4">
        <ImportDPD :detail.sync="detail"></ImportDPD>
      </v-col>
      <v-col cols="8"
        ><InvoiceAcDocuemnts :detail.sync="detail"> </InvoiceAcDocuemnts
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <InvoiceAcArticles :detail.sync="detail"></InvoiceAcArticles>
      </v-col>
    </v-row>
    <ConfirmDialog
      v-model="showExportToDakosyConfirmZollWertDialog"
      color="warning"
      @confirm="exportToDakosyConfirmZollWert"
      @close="showExportToDakosyConfirmZollWertDialog = false"
    >
      <slot name="confirm-delete-text">{{ confirmText }}</slot>
    </ConfirmDialog>
    <ConfirmDialog
      v-model="showExportToDakosyConfirmReceiverBusinessDialog"
      color="warning"
      @confirm="exportToDakosyConfirmReceiverBusiness"
      @close="showExportToDakosyConfirmReceiverBusinessDialog = false"
    >
      <slot name="confirm-delete-text">{{ confirmText }}</slot>
    </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel, ShipmentDetailApi } from "@/openapi";
import ImportReferences from "./ImportReferenses.vue";
import GeneralShipperAddress from "./GeneralShipperAddress.vue";
import ImportInvoiceAddress from "./ImportInvoiceAddress.vue";
import ImportWeightPrice from "./ImportWeightPrice.vue";
import ImportSendingInfo from "./ImportSendingInfo.vue";
import ImportAutomaticClearance from "./ImportAutomaticClearance.vue";
import ImportDPD from "./ImportDPD.vue";
import InvoiceAcDocuemnts from "./InvoiceAcDocuments.vue";
import InvoiceAcArticles from "./InvoiceAcArticles.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { emitError, emitSuccess } from "@/event-bus";
import moment from "moment";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const emits = defineEmits(["reloadShipment"]);
const api = new ShipmentDetailApi(undefined, "");

const showExportToDakosyConfirmZollWertDialog = ref(false);
const showExportToDakosyConfirmReceiverBusinessDialog = ref(false);
const confirmText = ref("");
const createAndSend = ref(false);

const isExportedToDakosy = computed(() => !!props.detail?.exportToDakosy?.id);

const formattedDateTime = (dateTime: string | null | undefined) => {
  if (!dateTime) {
    return "";
  }
  return moment(dateTime).format("DD.MM.yyyy HH:mm:ss");
};

const exportToDakosy = async () => {
  try {
    createAndSend.value = true;
    showExportToDakosyConfirmZollWertDialog.value = false;
    showExportToDakosyConfirmReceiverBusinessDialog.value = false;
    const response = await api.exportToDakosy(props.detail.id);
    if (response.data.isConfirmation) {
      showExportToDakosyConfirmZollWertDialog.value = true;
      confirmText.value = response?.data?.message!;
    } else if (response.data.isSuccess) {
      emitSuccess(response?.data?.message!);
      emits("reloadShipment");
    } else {
      emitError(response?.data?.message!);
    }
  } catch {
    emitError("Something went wrong while exporting to Dakosy");
  }
  createAndSend.value = false;
};

const exportToDakosyConfirmZollWert = async () => {
  try {
    createAndSend.value = true;
    showExportToDakosyConfirmZollWertDialog.value = false;
    showExportToDakosyConfirmReceiverBusinessDialog.value = false;
    const response = await api.exportToDakosyConfirmZollWert(props.detail.id);
    if (response.data.isConfirmation) {
      showExportToDakosyConfirmReceiverBusinessDialog.value = true;
      confirmText.value = response?.data?.message!;
    } else if (response.data.isSuccess) {
      emitSuccess(response?.data?.message!);
      emits("reloadShipment");
    } else {
      emitError(response?.data?.message!);
    }
  } catch {
    emitError("Something went wrong while exporting to Dakosy");
  }
  createAndSend.value = false;
};

const exportToDakosyConfirmReceiverBusiness = async () => {
  try {
    createAndSend.value = true;
    showExportToDakosyConfirmZollWertDialog.value = false;
    showExportToDakosyConfirmReceiverBusinessDialog.value = false;
    const response = await api.exportToDakosyConfirmReceiverBusiness(
      props.detail.id,
    );
    if (response.data.isSuccess) {
      emitSuccess(response?.data?.message!);
      emits("reloadShipment");
    } else {
      emitError(response?.data?.message!);
    }
  } catch {
    emitError("Something went wrong while exporting to Dakosy");
  }
  createAndSend.value = false;
};
</script>

<style lang="scss">
.shipment-general .v-card {
  height: 100%;
}
</style>
