<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :footer-props="footerOptions"
    :server-items-length="totalAmountOfItems"
    item-key="id"
    fixed-header
    fixed-footer
    class="elevation-1"
    :loading="loading"
  >
  </v-data-table>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import { CustomsFlightHandlingApi, FlightHistoryShipment } from "@/openapi";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";

const api = new CustomsFlightHandlingApi(undefined, "");

const loading = ref(false);
const headers = ref<DataTableHeader[]>([
  {
    text: "MAWB",
    value: "mawb",
    sortable: false,
  },
  {
    text: "Consolidation number",
    value: "consolidation",
    sortable: false,
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
  },
  {
    text: "Anzahl",
    value: "pieces",
    sortable: false,
  },
  {
    text: "Gewicht",
    value: "weight",
    sortable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<FlightHistoryShipment[]>([]);
const totalAmountOfItems = ref(0);
let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal, oldVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = window.setTimeout(() => {
      getFlightHistoryShipments();
    }, 250);
  },
);

const getFlightHistoryShipments = async () => {
  loading.value = true;

  try {
    const response = await api.getFlightHistoryShipments(
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items!;
    totalAmountOfItems.value = response.data.totalAmountOfItems!;
  } catch {
    emitError("Something went wrong while retrieving the history shipments.");
  }

  loading.value = false;
};
</script>
