<template>
  <Fragment>
    <div class="d-flex justify-end ma-6">
      <v-btn
        small
        color="success"
        class="ms-4"
        :loading="loadingFlighthandlingDialogData"
        @click="openFlightHandlingDialog"
        >Flight handling</v-btn
      >
      <DefaultDialog v-model="showCustomsInspectionDialog" max-width="1200px">
        <template #activator="{ on, attrs }">
          <v-btn color="error" small class="ml-4" v-bind="attrs" v-on="on">
            Customs inspection
          </v-btn>
        </template>
        <template #header> Shipments </template>
        <template #content>
          <CustomsConsolidationInspectionShipments
            v-if="showCustomsInspectionDialog"
            :consolidation-id="props.id"
            height="500"
          ></CustomsConsolidationInspectionShipments>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeCustomsInspectionDialog">
            Close
          </v-btn>
        </template>
      </DefaultDialog>

      <DefaultDialog v-model="showMoveableShipmentDialog" max-width="1200px">
        <template #activator="{ on, attrs }">
          <v-btn
            small
            color="yellow darken-3"
            class="ms-4"
            v-bind="attrs"
            v-on="on"
            >Move shipments from airport</v-btn
          >
        </template>
        <template #header> Moveable shipments </template>
        <template #content>
          <CustomsConsolidationMoveableShipments
            v-if="showMoveableShipmentDialog"
            :consolidation-id="props.id"
            height="500"
          ></CustomsConsolidationMoveableShipments>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeMoveShipmentsDialog">
            Close
          </v-btn>
        </template>
      </DefaultDialog>
    </div>
    <v-row no-gutters>
      <v-col class="mx-6 mb-6">
        <v-expansion-panels v-model="expanded" accordion multiple>
          <SelectableHeaderExpansionPanel
            :expanded="expanded"
            :panel-position="0"
            @toggleExpansionPanel="onToggleExpansionPanel"
          >
            <template #header>
              <h3>No status</h3>
            </template>
            <template #content>
              <CustomsConsolidationShipments
                :consolidation-id="props.id"
                :refresh-key="refreshKey"
                :type="FlightHandlingConsolidationShipmentsType.NoStatus"
              ></CustomsConsolidationShipments>
            </template>
          </SelectableHeaderExpansionPanel>
          <SelectableHeaderExpansionPanel
            :expanded="expanded"
            :panel-position="1"
            @toggleExpansionPanel="onToggleExpansionPanel"
          >
            <template #header>
              <h3>HAWB</h3>
            </template>
            <template #content>
              <CustomsConsolidationShipments
                :consolidation-id="props.id"
                :refresh-key="refreshKey"
                :type="FlightHandlingConsolidationShipmentsType.HAWB"
              ></CustomsConsolidationShipments>
            </template>
          </SelectableHeaderExpansionPanel>
          <SelectableHeaderExpansionPanel
            v-for="(item, i) in consolidationT1Declarations"
            :key="item.t1Id"
            :expanded="expanded"
            :panel-position="i + 2"
            @toggleExpansionPanel="onToggleExpansionPanel"
          >
            <template #header>
              <v-row>
                <v-col cols="auto">
                  <h3>
                    T1 ({{ item.dakosyReference }})<span v-if="item.transitMrn"
                      >- {{ item.transitMrn }}</span
                    >
                  </h3>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-btn color="success" @click="openSetStreetMrnDialog(item)"
                    >Set Street MRN
                    <v-icon v-if="item.streetMrnIsSet" right
                      >mdi-check</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </template>
            <template #content>
              <CustomsConsolidationShipments
                :consolidation-id="item.consolidationId"
                :t1-id="item.t1Id"
                :refresh-key="refreshKey"
                :type="FlightHandlingConsolidationShipmentsType.T1"
              ></CustomsConsolidationShipments>
            </template>
          </SelectableHeaderExpansionPanel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <DefaultDialog v-model="showFlightHandlingDialog">
      <template #header>Flight handling</template>
      <template #content>
        <div class="d-flex flex-column">
          <v-btn
            class="ma-2"
            :color="
              consolidationFlighthandlingDialogData &&
              consolidationFlighthandlingDialogData.hasIcs2Mrn
                ? 'success'
                : ''
            "
            @click="showNachrichtenDialog = true"
            >ICS 2 Nachrichten</v-btn
          >
          <v-btn class="ma-2">Prufe Gestellung (Airport)</v-btn>
          <v-btn
            v-if="!consolidationFlighthandlingDialogData?.hasGestellung"
            class="ma-2"
            @click="showErstelleGestellungDialog = true"
            >Erstelle Gestellung (Airport)</v-btn
          >
          <v-text-field
            v-else
            class="ma-2"
            label="Gestellung dakosy reference"
            :value="
              consolidationFlighthandlingDialogData.gestellungDakosyReference
            "
            readonly
            hide-details
            outlined
          ></v-text-field>
          <v-btn class="ma-2">Maßnahmen</v-btn>
        </div>
      </template>
      <template #footer>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeFlightHandlingDialog">
          Ok
        </v-btn>
      </template>
    </DefaultDialog>

    <NachrichtenDialog
      v-model="showNachrichtenDialog"
      :mrn="ics2Mrn"
      @confirm="ics2MrnSet"
    ></NachrichtenDialog>
    <ErstelleGestellungDialog
      v-model="showErstelleGestellungDialog"
      :dialog-loading="createGestellungLoading"
      @confirm="onErstelleGestellungConfirm"
    ></ErstelleGestellungDialog>
    <StreetMrnDialog
      v-if="currentStreetMrnT1Declaration"
      v-model="showSetStreetMrnDialog"
      :consolidation-id="currentStreetMrnT1Declaration.consolidationId"
      :t1-declaration-id="currentStreetMrnT1Declaration.t1Id"
      @confirm="confirmSetStreetMrn"
    ></StreetMrnDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from "@/event-bus";
import {
  ConsolidationFlighthandlingDialogModel,
  ConsolidationT1ViewModel,
  CustomsFlightHandlingApi,
} from "@/openapi";
import { computed, ref } from "vue";
import CustomsConsolidationShipments from "./CustomsConsolidationShipments.vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import CustomsConsolidationInspectionShipments from "./CustomsConsolidationInspectionShipments.vue";
import CustomsConsolidationMoveableShipments from "./CustomsConsolidationMoveableShipments.vue";
import NachrichtenDialog, {
  NachrichtenFormData,
} from "./dialogs/NachrichtenDialog.vue";
import ErstelleGestellungDialog from "./dialogs/ErstelleGestellungDialog.vue";
import { ErstelleGestellungFormData } from "./dialogs/ErstelleGestellungDialog.vue";
import SelectableHeaderExpansionPanel from "./SelectableHeaderExpansionPanel.vue";
import { FlightHandlingConsolidationShipmentsType } from "@/models/FlightHandlingConsolidationShipmentsType";
import StreetMrnDialog from "./dialogs/StreetMrnDialog.vue";

interface FlighthandlingConsolidationProps {
  id: number;
}

const flightHandlingApi = new CustomsFlightHandlingApi(undefined, "");
const props = defineProps<FlighthandlingConsolidationProps>();

const loading = ref(false);
const consolidationT1Declarations = ref<ConsolidationT1ViewModel[]>([]);

const showFlightHandlingDialog = ref(false);
const showCustomsInspectionDialog = ref(false);
const showMoveableShipmentDialog = ref(false);
const showNachrichtenDialog = ref(false);
const showErstelleGestellungDialog = ref(false);
const createGestellungLoading = ref(false);

const currentStreetMrnT1Declaration = ref<ConsolidationT1ViewModel | null>(
  null,
);
const showSetStreetMrnDialog = ref(false);

const consolidationFlighthandlingDialogData =
  ref<ConsolidationFlighthandlingDialogModel | null>();
const loadingFlighthandlingDialogData = ref(false);

const expanded = ref([0]);
const refreshKey = ref(0);

const getConsolidationT1Declarations = async () => {
  try {
    const response = await flightHandlingApi.getConsolidationT1Declarations(
      props.id,
    );
    consolidationT1Declarations.value = response.data ?? [];
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Failed to retrieve data necessary for the dialogs",
    );
  }
};

const onErstelleGestellungConfirm = async (
  eventData: ErstelleGestellungFormData,
) => {
  createGestellungLoading.value = true;
  try {
    await flightHandlingApi.createGestellung({
      consolidationId: props.id,
      customerId: eventData.customerId!,
      customsOfficeId: eventData.customsClearanceOfficeId!,
      ankunftsdatum: eventData.ankunftsdatum!,
      flugnummer: eventData.flugnummer!,
      originAirportCode: eventData.originAirportCode!,
    });
    showErstelleGestellungDialog.value = false;
    await getConsolidationFlightHandlingDialogData();
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      'Something went wrong while creating the "Gestellung"',
    );
  }
  createGestellungLoading.value = false;
};

const getConsolidationFlightHandlingDialogData = async () => {
  try {
    const getConsolidationFlightHandlingDialogDataResponse =
      await flightHandlingApi.getConsolidationFlighthandlingDialogData(
        props.id,
      );
    consolidationFlighthandlingDialogData.value =
      getConsolidationFlightHandlingDialogDataResponse.data;
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Failed to retrieve data necessary for the dialogs",
    );
  }
};

const ics2MrnSet = async (formData: NachrichtenFormData) => {
  if (!formData.mrnNumber) {
    return;
  }
  await flightHandlingApi.setConsolidationIcs2Mrn(props.id, formData.mrnNumber);
  showNachrichtenDialog.value = false;
  await getConsolidationFlightHandlingDialogData();
};

const openFlightHandlingDialog = async () => {
  loadingFlighthandlingDialogData.value = true;
  await getConsolidationFlightHandlingDialogData();
  showFlightHandlingDialog.value = true;
  loadingFlighthandlingDialogData.value = false;
};

const ics2Mrn = computed(() => {
  if (
    !consolidationFlighthandlingDialogData.value ||
    !consolidationFlighthandlingDialogData.value.mrn
  ) {
    return "";
  }

  return consolidationFlighthandlingDialogData.value.mrn;
});

const refreshData = () => {
  refreshKey.value++;
};

const onToggleExpansionPanel = (panel: number) => {
  const currentPanelIndex = expanded.value.indexOf(panel);
  if (currentPanelIndex !== -1) {
    expanded.value.splice(currentPanelIndex, 1);
  } else {
    expanded.value.push(panel);
  }
};

const closeCustomsInspectionDialog = () => {
  showCustomsInspectionDialog.value = false;
  refreshData();
};

const closeMoveShipmentsDialog = () => {
  showMoveableShipmentDialog.value = false;
  refreshData();
};

const closeFlightHandlingDialog = () => {
  showFlightHandlingDialog.value = false;
  refreshData();
};

const openSetStreetMrnDialog = (t1Declaration: ConsolidationT1ViewModel) => {
  currentStreetMrnT1Declaration.value = t1Declaration;
  showSetStreetMrnDialog.value = true;
};

const confirmSetStreetMrn = async () => {
  currentStreetMrnT1Declaration.value = null;
  await getConsolidationT1Declarations();
};

loading.value = true;
Promise.all([getConsolidationT1Declarations()]).finally(() => {
  loading.value = false;
});
</script>
