<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1"
          :loading="isLoading"
        >
          <template v-slot:header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              @click="addNewItem"
              :disabled="disableNewItemButton"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'customerId-' + item.id"
                  >
                    <ValidationProvider
                      name="Customer"
                      :vid="'customerId-' + item.id"
                      ref="providers"
                      item-name="customerId"
                      :item-id="item.id"
                      rules="required"
                      v-slot="{ errors, failed }"
                      slim
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ getCustomerName(item) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.customerId"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :items="customers"
                          item-text="shortName"
                          item-value="id"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'name-' + item.id"
                  >
                    <ValidationProvider
                      name="Name"
                      :vid="'name-' + item.id"
                      ref="providers"
                      item-name="name"
                      :item-id="item.id"
                      rules="required|max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.name }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.name"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpServer-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpServer"
                      :vid="'sftpServer-' + item.id"
                      ref="providers"
                      item-name="sftpServer"
                      :item-id="item.id"
                      rules="required|max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.sftpServer }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpServer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpUsername-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpUsername"
                      :vid="'sftpUsername-' + item.id"
                      ref="providers"
                      item-name="sftpUsername"
                      :item-id="item.id"
                      rules="required|max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.sftpUsername }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpUsername"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpPort-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpPort"
                      :vid="'sftpPort-' + item.id"
                      ref="providers"
                      item-name="sftpPort"
                      :item-id="item.id"
                      rules="required|numeric"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.sftpPort }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpPort"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    name="Record Enabled"
                    :vid="'recordEnabled-' + item.id"
                    ref="providers"
                    item-name="recordEnabled"
                    :item-id="item.id"
                    v-slot="{ errors, failed }"
                    slim
                  >
                    <td
                      :class="{ red: failed }"
                      :id="'recordEnabled-' + item.id"
                    >
                      <v-checkbox
                        class="mt-0 pt-0"
                        v-model="item.recordEnabled"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpPassword-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpPassword"
                      :vid="'sftpPassword-' + item.id"
                      ref="providers"
                      item-name="sftpPassword"
                      :item-id="item.id"
                      rules="max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ displayAsterisks(item.sftpPassword) }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpPassword"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable(), (showPassword = false)"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpKeyFile-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpKeyFile"
                      :vid="'sftpKeyFile-' + item.id"
                      ref="providers"
                      item-name="sftpKeyFile"
                      :item-id="item.id"
                      rules="max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">{{ item.sftpKeyFile }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpKeyFile"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpKeyFilePassword-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpKeyFilePassword"
                      :vid="'sftpKeyFilePassword-' + item.id"
                      ref="providers"
                      item-name="sftpKeyFilePassword"
                      :item-id="item.id"
                      rules="max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ displayAsterisks(item.sftpKeyFilePassword) }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpKeyFilePassword"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable(), (showPassword = false)"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpOutputFolder-' + item.id"
                  >
                    <ValidationProvider
                      name="sftpOutputFolder"
                      :vid="'sftpOutputFolder-' + item.id"
                      ref="providers"
                      item-name="sftpOutputFolder"
                      :item-id="item.id"
                      rules="max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.sftpOutputFolder }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpOutputFolder"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'sftpInvoiceOutputFolder-' + item.id"
                  >
                    <ValidationProvider
                      name="SftpInvoiceOutputFolder"
                      :vid="'sftpInvoiceOutputFolder-' + item.id"
                      ref="providers"
                      item-name="sftpInvoiceOutputFolder"
                      :item-id="item.id"
                      rules="max:100"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.sftpInvoiceOutputFolder }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sftpInvoiceOutputFolder"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomerViewModel,
  CustomsStatusCustomersApi,
  CustomsStatusCustomersViewModel,
  DataApi,
} from "@/openapi";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ref, watch, onBeforeMount } from "vue";
import { useCrudPage } from "@/composables/crudPage";

const {
  disableNewItemButton,
  items,
  totalAmountOfItems,
  isLoading,
  addNewItem,
  isNewItem,
  mapItem,
} = useCrudPage<CustomsStatusCustomersViewModel>(
  { id: 0, customerId: 0, recordEnabled: false },
  "id",
  0,
);

const dataApi = new DataApi(undefined, "");
const customsStatusCustomersApi = new CustomsStatusCustomersApi(undefined, "");
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);

const headers = ref([
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
  { text: "Customer*", align: "start", value: "customerId" },
  { text: "Name*", value: "name" },
  { text: "SFTP Server*", value: "sftpServer" },
  { text: "SFTP User*", value: "sftpUsername" },
  { text: "SFTP Port*", value: "sftpPort" },
  { text: "Record Enabled", value: "recordEnabled" },
  { text: "SFTP Password", value: "sftpPassword" },
  { text: "SFTP Key File", value: "sftpKeyFile" },
  { text: "SFTP Key File Password", value: "sftpKeyFilePassword" },
  { text: "SFTP Output Folder", value: "sftpOutputFolder" },
  { text: "SFTP Invoice Output Folder", value: "sftpInvoiceOutputFolder" },
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
]);

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const totalItems = ref(0);

const customers = ref<CustomerViewModel[]>([]);
const showPassword = ref(false);
onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Status Customers", toolbarButtons);

  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
  });
});

let timeoutDelay = 0;
watch(
  () => options,
  (newVal: any, oldVal: any) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  customsStatusCustomersApi
    .getCustomsStatusCustomers(itemsPerPage, page, orderBy, orderByField)
    .then((result) => {
      items.value = result.data.items as CustomsStatusCustomersViewModel[];
      totalItems.value = result.data.totalItems as number;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const saveItem = async (item: CustomsStatusCustomersViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(
          item[propName as keyof CustomsStatusCustomersViewModel],
        ),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    let errorsArray: { id: string; errors: string[] }[] = [];
    Object.keys(validationObserver.errors).map((key) => {
      if (validationObserver.errors[key].length > 0) {
        errorsArray.push({ id: key, errors: validationObserver.errors[key] });
      }
    });
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        var errorObject = errorsArray.find(
          (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
        );
        if (errorObject) {
          var errorElementId = errorObject?.id;
          document
            .getElementById(errorElementId)
            ?.scrollIntoView({ inline: "start" });
        }

        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);

    customsStatusCustomersApi
      .saveCustomsStatusCustomer(item)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((cdec) => cdec.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  customsStatusCustomersApi
    .deleteCustomsStatusCustomer(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    });
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: Boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};

const getCustomerName = (item: CustomsStatusCustomersViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};

const displayAsterisks = (value: string) => {
  return value?.length ? "*".repeat(10) : "";
};
</script>
