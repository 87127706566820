<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>
      <v-flex class="px-3" white>
        <v-container fluid>
          <v-row>
            <v-col md="2">
              <v-btn
                :disabled="!selectedHandlingItems.length"
                color="primary"
                outlined
                @click="openDialogConfirmShipment()"
              >
                Confirm shipments
              </v-btn>
            </v-col>
            <v-col md="3" class="pt-3">
              <v-select
                v-model="filter.loadedConfirmed"
                :items="loadedConfirmedFilter"
                class="primary--text"
                :value="selectedLoadedConfirmed"
                dense
                solo
                @change="onFilterSelected"
              ></v-select>
            </v-col>
            <v-col md="5"> </v-col>
            <v-col md="2">
              <v-btn
                color="primary"
                outlined
                :loading="loadingImportNewShipments"
                :disabled="filter.loadedConfirmed == 2"
                @click="importAssignedCustomsHandlingItems(false)"
              >
                <v-badge
                  color="red"
                  :content="assignedCustomsHandlingItems"
                  :value="assignedCustomsHandlingItems"
                >
                  Import new shipments
                </v-badge>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>

      <v-data-table
        v-model="selectedHandlingItems"
        :headers="headers"
        :items="dataTableItems"
        :server-items-length="totalItems"
        :options.sync="options"
        :footer-props="footerOptions"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        height="calc(100vh - 325px)"
        dense
        fixed-header
        fixed-footer
        show-expand
        show-select
        class="elevation-1"
        :loading="isLoading"
      >
        <template #body.prepend>
          <tr>
            <td>
              <v-icon small>mdi-filter</v-icon>
            </td>
            <td></td>
            <td>
              <v-select
                v-model="filter.step"
                :items="statusFilter"
                clearable
                chips
                dense
                single-line
                @change="onFilterSelected"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentLoaded"
                :items="booleanFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              >
              </v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentConfirmed"
                :items="booleanFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              >
              </v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentStatusDescription"
                :items="shipmentStatusFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-select>
            </td>
            <td>
              <FilterParcels
                v-model="filter.parcels"
                @input="onFilterSelected"
              ></FilterParcels>
            </td>
            <td>
              <v-text-field
                v-model="filter.exportMrn"
                clearable
                dense
                @keyup.enter="onFilterSelected"
                @click:clear="(filter.exportMrn = ''), onFilterSelected()"
              ></v-text-field>
            </td>
            <td></td>
            <td>
              <v-text-field
                v-model.number="filter.weight"
                type="number"
                step="0.1"
                min="0"
                clearable
                dense
                @keyup.enter="onFilterSelected"
                @click:clear="(filter.weight = null), onFilterSelected()"
              ></v-text-field>
            </td>
            <td></td>
            <td>
              <v-autocomplete
                v-model="filter.shipperCountry"
                :items="dataStore.originCountries"
                item-key="code"
                item-text="code"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                v-model="filter.consigneeCounty"
                :items="dataStore.originCountries"
                item-key="code"
                item-text="code"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-autocomplete>
            </td>
          </tr>
        </template>

        <template #item.isLoaded="{ item }">
          <v-row justify="center">
            <v-col>
              <v-icon v-if="item.isLoaded"> mdi-check-bold </v-icon>
            </v-col>
          </v-row>
        </template>

        <template #item.isConfirmed="{ item }">
          <v-row justify="center">
            <v-col>
              <v-icon v-if="item.isConfirmed"> mdi-check-bold </v-icon>
            </v-col>
          </v-row>
        </template>

        <template #item.exportFlowStatus="{ item }">
          <v-avatar
            class="flow-status-avatar"
            size="22"
            :color="flowSteps[item.exportFlowStatus][3]"
          >
            {{ item.exportFlowStatus }}
          </v-avatar>
        </template>

        <template #item.exportMrn="{ item }">
          <v-icon
            v-if="!item.isMaster && item.exportMrn == null"
            class="error--text"
            @click="openDialogShipmentAddMrn(item)"
          >
            mdi-plus-circle
          </v-icon>
          <span v-else>
            {{ item.isMaster ? item.exportMrns.join(", ") : item.exportMrn }}
          </span>
        </template>

        <template #item.shipper="{ item }">
          <v-row justify="center">
            <v-col>
              {{ item.shipper.name }}<br />
              {{ item.shipper.address }}<br />
              {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
              {{ item.shipper.country }}
            </v-col>
          </v-row>
        </template>

        <template #item.consignee="{ item }">
          <v-row justify="center">
            <v-col>
              {{ item.consignee.name }}<br />
              {{ item.consignee.address }}<br />
              {{ item.consignee.zipcode }}
              {{ item.consignee.city }}<br />
              {{ item.consignee.country }}
            </v-col>
          </v-row>
        </template>

        <template #item.value="{ item }">
          <v-row>
            <v-col>
              {{ item.value.toFixed(2) }}
            </v-col>
          </v-row>
        </template>

        <template #item.articles="{ item }">
          <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
            <v-col cols="9">
              <span class="articleDescription">{{ article.description }}</span>
            </v-col>
            <v-col>
              <span class="articleDescription pl-4">{{ article.rcTarif }}</span>
            </v-col>
          </v-row>
          <v-row v-if="item.articles.length > 4" no-gutters>
            <v-col cols="9">
              <span class="articleDescription">more...</span>
            </v-col>
          </v-row>
        </template>

        <template #item.documents="{ item }">
          <v-row>
            <v-col>
              <UploadPaperworkButton
                :shipment-id="item.id"
                :parcel-number="item.hawb"
              ></UploadPaperworkButton>
            </v-col>
          </v-row>
        </template>

        <template #expanded-item="{ headers, item }">
          <td v-if="currentShipmentDetail" :colspan="headers.length">
            <v-flex class="pa-3 pb-5">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title>
                        <span> Shipment status </span>
                      </v-card-title>
                      <ShipmentPaperwork
                        class="shipment-paperwork"
                        :shipment-id="currentShipmentDetail.id"
                      ></ShipmentPaperwork>
                      <CustomsExportShipmentDetailViewNew
                        v-if="currentShipmentDetail"
                        :current-handling-item="currentShipmentDetail"
                        :readonly="false"
                        @updated-details="onShipmentDetailsUpdated"
                      />
                      <div class="ms-6">
                        <ConfirmShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          :disabled="item.isConfirmed"
                          class="mr-3 mb-5"
                          @confirmedShipment="onShipmentConfirmed"
                        ></ConfirmShipmentButton>

                        <HoldShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          :export-flow-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </HoldShipmentButton>

                        <ReturnShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </ReturnShipmentButton>

                        <CustomsInspectionButton
                          :shipment-id="currentShipmentDetail.id"
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </CustomsInspectionButton>
                      </div>

                      <ShipmentStatusFlow
                        :shipment-id="currentShipmentDetail.id"
                        :active-status="currentShipmentDetail.exportFlowStatus"
                      ></ShipmentStatusFlow>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmShipmentsDialog
      v-model="showConfirmShipmentsDialog"
      :is-loading="dialogConfirmShipmentsLoading"
      @confirm="onShipmentsConfirmed"
    >
    </ConfirmShipmentsDialog>

    <AddMrnDialog
      v-if="currentHandlingItem"
      v-model="showDialogAddMrn"
      :parcel-number="currentHandlingItem.hawb"
      :is-loading="dialogAddMrnLoading"
      @confirm="onMrnEntered"
    ></AddMrnDialog>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, computed, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import ConfirmShipmentReasonDialog from "./dialogs/ConfirmShipmentReasonDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import ConfirmShipmentsDialog from "./dialogs/ConfirmShipmentsDialog.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsCheckApi,
  CustomsExportApi,
  CustomsPaperworkHandlingApi,
  CustomsShipmentItemViewModel,
  CustomsShipmentDetailViewModel,
} from "@/openapi";
import UploadPaperworkButton from "./buttons/UploadPaperworkButton.vue";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import ConfirmShipmentButton from "./buttons/ConfirmShipmentButton.vue";
import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";
const dataStore = useDataStore();
const customsCheckApi = new CustomsCheckApi(undefined, "");
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const props = defineProps({
  color: { type: String, default: "" },
});

const customsPaperworkHandlingApi: CustomsPaperworkHandlingApi =
  new CustomsPaperworkHandlingApi(undefined, "");
const customsExportApi: CustomsExportApi = new CustomsExportApi(undefined, "");

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  loadedConfirmed: 0,
  shipmentConfirmed: null,
  shipmentLoaded: null,
  weight: null,
  shipperCountry: null,
  consigneeCounty: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref([]);

const loadingSaveShipmentReason = ref(false);
const loadingImportNewShipments = ref(false);

const assignedCustomsHandlingItems = ref(0);

const checkShipmentStep = ref(2);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const loadedConfirmedFilter = ref([
  { text: "Show all (mine)", value: 0 },
  { text: "Show Confirmed but not loaded (mine)", value: 1 },
  { text: "Show Loaded but not confirmed (all)", value: 2 },
]);
const selectedLoadedConfirmed = ref("");

const booleanFilter = ref(["Yes", "No"]);

const items = ref<CustomsShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const selectedHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);

const currentAction = ref("");

const showDialogAddMrn = ref(false);
const dialogAddMrnLoading = ref(false);

const showConfirmShipmentsDialog = ref(false);
const dialogConfirmShipmentsLoading = ref(false);

const headers = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Loaded",
    value: "isLoaded",
    align: "center",
  },
  {
    text: "Confirmed",
    value: "isConfirmed",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
  {
    text: "Upload document(s)",
    value: "documents",
    sortable: false,
  },
]);

const hasSelectedHandlingItems = computed(() => {
  return !!selectedHandlingItems.value?.length;
});

const dataTableItems = computed(() => {
  return items.value.map((c) => ({
    ...c,
    isSelectable: !c.isConfirmed && !isLoading.value,
  }));
});

const loadShipments = async (page: number) => {
  isLoading.value = true;

  try {
    const response = await customsCheckApi.getCheckShipments({
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      loadedConfirmed: filter.value.loadedConfirmed ?? undefined,
      shipmentLoaded: filter.value.shipmentLoaded ?? undefined,
      shipmentConfirmed: filter.value.shipmentConfirmed ?? undefined,
      weight: filter.value.weight ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCounty ?? undefined,
    });

    items.value = response.data.shipments ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the shipments");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1 ? await loadShipments(1) : (options.value.page = 1);
};

const timeoutDelay = ref(0);
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay.value);
    timeoutDelay.value = setTimeout(() => {
      loadShipments(options.value.page);
    }, 250);
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    currentShipmentDetail.value = null;

    if (newVal.length === 0) {
      currentHandlingItem.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      getShipmentDetail();
    }
  },
);

const openDialogShipmentAddMrn = (item: CustomsShipmentItemViewModel) => {
  stopLoadingButtons();
  currentHandlingItem.value = item;
  showDialogAddMrn.value = true;
};

const onFilterSelected = () => {
  reloadShipments();
};

const stopLoadingButtons = () => {
  loadingSaveShipmentReason.value = false;
  loadingImportNewShipments.value = false;
};

const closeAllDialogs = () => {
  showDialogAddMrn.value = false;
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const openDialogConfirmShipment = () => {
  stopLoadingButtons();
  showConfirmShipmentsDialog.value = true;
};

const importAssignedCustomsHandlingItems = async (
  getOnlyNumberOfAssigned: boolean = true,
) => {
  loadingImportNewShipments.value = true;

  try {
    var response = await customsCheckApi.importAssignedCustomsHandlingItems(
      getOnlyNumberOfAssigned,
    );
    assignedCustomsHandlingItems.value = response.data;
    if (response.data > 0) {
      reloadShipments();
    }
  } catch {
    displayError(
      "Something went wrong while importing the assigned shipments.",
    );
  }

  loadingImportNewShipments.value = false;
};

const onMrnEntered = async ({ mrn }: { mrn: string }) => {
  dialogAddMrnLoading.value = true;
  try {
    await customsExportApi.setExportMrn({
      mrn: mrn,
      shipmentId: currentHandlingItem.value!.id!,
    });

    currentHandlingItem.value!.exportMrn = mrn;
  } catch {
    displayError("Something went wrong while setting the MRN of the shipment");
  }

  dialogAddMrnLoading.value = false;
  showDialogAddMrn.value = false;
};

const getShipmentDetail = async () => {
  isLoading.value = true;
  return customsExportApi
    .getCustomsShipmentDetail(currentHandlingItem.value!.id!)
    .then((response) => {
      currentShipmentDetail.value = response.data;
    })
    .catch((error) =>
      displayError("Something went wrong while retrieving the detail data."),
    )
    .finally(() => (isLoading.value = false));
};

const onShipmentConfirmed = async ({ success }: any) => {
  if (success) {
    expanded.value = [];
    loadShipments(options.value.page);
  }
};

const onShipmentsConfirmed = async () => {
  dialogConfirmShipmentsLoading.value = true;
  await confirmShipments(selectedHandlingItems.value.map((item) => item.id!));
  dialogConfirmShipmentsLoading.value = false;
  showConfirmShipmentsDialog.value = false;
  selectedHandlingItems.value = [];
};

const confirmShipments = async (shipmentIds: number[]) => {
  try {
    await customsCheckApi.confirmShipments(shipmentIds);
    expanded.value = [];
    loadShipments(options.value.page);
  } catch {
    displayError("Something went wrong while trying to confirm the shipment.");
  }
};

const onShipmentDetailsUpdated = async () => {
  isLoading.value = true;
  currentShipmentDetail.value = null;
  try {
    const response = await customsExportApi.getCustomsShipmentDetail(
      currentHandlingItem.value!.id!,
    );
    currentShipmentDetail.value = response.data;
  } catch {
    displayError(
      "Something went wrong while re-retrieving the shipment details",
    );
  }

  isLoading.value = false;
};

const deselectItem = async (shipmentId: number) => {
  var selectedItemIndex = selectedHandlingItems.value.findIndex(
    (item) => item.id === shipmentId,
  );
  if (selectedItemIndex > -1) {
    selectedHandlingItems.value.splice(selectedItemIndex, 1);
    return;
  }
};

const onShipmentStatusChanged = async ({ success }: any) => {
  if (success) {
    selectedHandlingItems.value = [];
    loadShipments(options.value.page);
  }
};

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeMount(() => {
  dataStore.fetchOriginCountries();

  selectedLoadedConfirmed.value = loadedConfirmedFilter.value[0].text;
  importAssignedCustomsHandlingItems();
  statusFilter.value = getStatuses();

  addRefreshToolbarOption(reloadShipments);
});
</script>
