<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalItems"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Manifests</v-toolbar-title>
          <v-row align="center">
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                clearable
                label="Search by container name or manifest number"
                hide-details
                @keyup.enter="getManifests"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="alsoShowFinishedManifests"
                label="Also show finished manifests"
                :hide-details="true"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-autocomplete
                v-model="warehouseFilter"
                :items="warehouses"
                item-value="key"
                item-text="value"
                name="Warehouse"
                label="Filter by warehouse"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.warehouseId="{ item }">
        {{ getWarehouseNameFromId(item.warehouseId) }}
      </template>
      <template #item.creationDateTime="{ item }">
        {{ formatDateTime(item.creationDateTime) }}
      </template>
      <template #item.isClosed="{ item }">
        <v-icon :color="item.isClosed ? 'success' : 'error'"
          >{{ item.isClosed ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>
      <template #group.header="{ group, items, isOpen, toggle }">
        <th colspan="5">
          <v-row align-content="center">
            <v-col class="d-flex align-center" cols="auto">
              <h2 v-if="group">
                <v-icon @click="toggle">
                  {{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                {{ formatManifestNumber(group) }}
              </h2>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :disabled="
                  creatingPdfReport &&
                  currentManifestPdfReportCreating !== group
                "
                :loading="
                  creatingPdfReport &&
                  currentManifestPdfReportCreating === group
                "
                @click="createReport(group)"
                >Create PDF report</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="
                  creatingExcelExport &&
                  currentManifestExcelExportCreating === group
                "
                :disabled="
                  creatingExcelExport &&
                  currentManifestExcelExportCreating !== group
                "
                @click="createExcelExport(group)"
                >Create Excel export</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :disabled="!doesManifestHaveSignedManifest(group)"
                @click="downloadSignedManifest(group)"
                >Download signed manifest</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :disabled="doesManifestHaveSignedManifestDate(group)"
                @click="moveManifestToHistory(group)"
                >Move to history</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="primary" @click="uploadSignedPaperwork(group)">
                <v-icon
                  v-if="doesManifestHaveSignedPaperwork(group)"
                  color="success"
                  left
                  >mdi-check</v-icon
                >
                Upload signed paperwork</v-btn
              >
            </v-col>
          </v-row>
        </th>
      </template>
    </v-data-table>
    <DefaultDialog v-if="showUploadSignedPaperworkDialog" :value="true">
      <template #header>Upload signed paperwork</template>
      <template #content>
        <v-file-input
          v-model="signedPaperworkFile"
          label="Select signed paperwork"
        ></v-file-input>
      </template>
      <template #footer>
        <v-btn
          :disabled="uploadingPaperworkFile"
          text
          small
          @click="cancelUploadSignedPaperwork"
          >Close</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          color="primary"
          :loading="uploadingPaperworkFile"
          @click="confirmUploadSignedPaperwork"
          >Upload paperwork</v-btn
        >
      </template>
    </DefaultDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  ContainerApi,
  ContainerManifestViewModel,
  ContainerViewModel,
  DataApi,
  KeyValueItem,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import moment from "moment";
import { computed, onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";

const containerApi = new ContainerApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const emits = defineEmits<{
  (e: "PageInfoReceived", title: string, items: ToolbarItem[]): void;
}>();

const loadingManifests = ref(false);
const loading = ref(false);
const totalItems = ref(0);
const manifests = ref<ContainerManifestViewModel[]>([]);
const alsoShowFinishedManifests = ref(false);
const warehouseFilter = ref(0);
const search = ref("");

const showUploadSignedPaperworkDialog = ref(false);
const currentUploadSignedPaperworkManifestId = ref<number | null>(null);
const signedPaperworkFile = ref<File | null>(null);
const uploadingPaperworkFile = ref(false);
const creatingPdfReport = ref(false);
const currentManifestPdfReportCreating = ref<number | null>(null);
const creatingExcelExport = ref(false);
const currentManifestExcelExportCreating = ref<number | null>(null);

const warehouses = ref<KeyValueItem[]>([]);
const headers = ref<DataTableHeader[]>([
  {
    text: "Container name",
    value: "name",
  },
  {
    text: "Warehouse",
    value: "warehouseId",
  },
  {
    text: "Creation date/time",
    value: "creationDateTime",
  },
  {
    text: "Total weight",
    value: "totalWeight",
    sortable: false,
  },
  {
    text: "# pieces",
    value: "totalPieces",
    sortable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: ["creationDateTime"],
  sortDesc: [true],
  groupBy: ["manifestId"],
  groupDesc: [true],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100],
  disablePagination: false,
});

const items = computed(() => {
  if (manifests.value.length === 0) {
    return [];
  }

  const containers =
    manifests.value
      .filter(
        (manifest) =>
          manifest.containers !== null && manifest.containers !== undefined,
      )
      .map((manifest) => manifest.containers?.flat())
      .reduce((prev, current) => {
        if (current) {
          current.forEach((element) => {
            prev?.push(element);
          });
        }

        return prev;
      }) ?? [];
  return containers;
});

watch(
  () => options.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

watch(
  () => alsoShowFinishedManifests.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

watch(
  () => warehouseFilter.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

const getWarehouses = async () => {
  try {
    const response = await dataApi.getWarehousesData();
    warehouses.value = response.data;
  } catch (error: unknown) {
    emitError(
      "An error occurred while retrieving the warehouses from the webservice",
    );
  }
};

const getWarehouseNameFromId = (warehouseId: number) => {
  const warehouse = warehouses.value.find(
    (warehouseKV) => warehouseKV.key === warehouseId,
  );
  if (!warehouse) {
    return "Unknown";
  }

  return warehouse.value;
};

const getManifests = async () => {
  if (loadingManifests.value) {
    return;
  }
  try {
    loading.value = true;
    loadingManifests.value = true;
    const response = await containerApi.getContainerManifests(
      options.value.itemsPerPage,
      options.value.page,
      options.value.sortBy.length > 0 ? options.value.sortBy[0] : undefined,
      options.value.sortDesc.length > 0 ? options.value.sortDesc[0] : undefined,
      alsoShowFinishedManifests.value,
      warehouseFilter.value === null ? undefined : warehouseFilter.value,
      search.value === null ? undefined : search.value,
    );
    const pagedResult = response.data;
    totalItems.value = pagedResult.totalAmountOfItems;
    manifests.value = pagedResult.items!;
  } catch (error: unknown) {
    emitError(
      "An error occurred when loading the manifests from the webservice",
    );
  } finally {
    loading.value = false;
    loadingManifests.value = false;
  }
};

const refreshData = async () => {
  await Promise.all([getWarehouses(), getManifests()]);
};

const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }

  const dateTimeMoment = moment(datetime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const formatManifestNumber = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return "Unknown";
  }

  const creationYear = moment(manifest.creationDateTime).year();
  return `${creationYear}-${manifest.manifestNumber}`;
};

const createReport = async (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return;
  }

  if (creatingPdfReport.value) {
    return;
  }

  try {
    creatingPdfReport.value = true;
    currentManifestPdfReportCreating.value = manifestId;
    const response = await containerApi.createPdfReportForManifest(
      manifestId,
      1,
      { responseType: "blob" },
    );
    const fileName = getFileNameFromContentDispositionHeader(response);
    downloadFile(response.data, fileName!);
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while creating the pdf report",
    );
  } finally {
    creatingPdfReport.value = false;
    currentManifestPdfReportCreating.value = null;
  }
};

const createExcelExport = async (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return;
  }

  if (creatingExcelExport.value) {
    return;
  }

  try {
    creatingExcelExport.value = true;
    currentManifestExcelExportCreating.value = manifestId;
    const response = await containerApi.createExcelReportForManifest(
      manifestId,
      { responseType: "blob" },
    );
    const fileName = getFileNameFromContentDispositionHeader(response);
    downloadFile(response.data, fileName!);
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while creating the Excel export",
    );
  } finally {
    creatingExcelExport.value = false;
    currentManifestExcelExportCreating.value = null;
  }
};

const uploadSignedPaperwork = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return true;
  }

  currentUploadSignedPaperworkManifestId.value = manifest.id;
  signedPaperworkFile.value = null;
  showUploadSignedPaperworkDialog.value = true;
};

const cancelUploadSignedPaperwork = () => {
  showUploadSignedPaperworkDialog.value = false;
  currentUploadSignedPaperworkManifestId.value = null;
  signedPaperworkFile.value = null;
};

const confirmUploadSignedPaperwork = async () => {
  if (!signedPaperworkFile.value) {
    emitError("Please select a file to upload");
  }

  try {
    uploadingPaperworkFile.value = true;
    const response = await containerApi.uploadSignedPaperworkForManifest(
      signedPaperworkFile.value!,
      currentUploadSignedPaperworkManifestId.value!,
    );
    const manifestIndex = manifests.value.findIndex(
      (m) => m.id == currentUploadSignedPaperworkManifestId.value,
    );
    if (manifestIndex > -1) {
      manifests.value[manifestIndex] = response.data;
    }
    showUploadSignedPaperworkDialog.value = false;
    currentUploadSignedPaperworkManifestId.value = null;
    signedPaperworkFile.value = null;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while uploading the paperwork",
    );
  } finally {
    uploadingPaperworkFile.value = false;
  }
};

const doesManifestHaveSignedPaperwork = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return true;
  }

  return manifest.signedManifestUploadedAt;
};

const doesManifestHaveSignedManifest = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return false;
  }

  return manifest.hasSignedManifest;
};

const downloadSignedManifest = (manifestId: number) => {
  containerApi
    .downloadSignedManifest(manifestId)
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
    })
    .catch(() => {
      emitError("Something went wrong while downloading the signed manifest");
    });
};

const doesManifestHaveSignedManifestDate = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return false;
  }

  return !!manifest.signedManifestUploadedAt;
};

const moveManifestToHistory = (manifestId: number) => {
  containerApi.moveManifestToHistory(manifestId).then((response) => {
    const manifest = manifests.value.find((m) => m.id === manifestId);
    if (manifest) {
      emitSuccess("Manifest moved to history");
      manifest.creationDateTime = response.data.creationDateTime;
      manifest.hasSignedManifest = response.data.hasSignedManifest;
      manifest.signedManifestUploadedAt =
        response.data.signedManifestUploadedAt;
      manifest.signedManifestUploadedByEmpId =
        response.data.signedManifestUploadedByEmpId;
    }
  });
};

onBeforeMount(() => {
  emits("PageInfoReceived", "Manifests", [
    {
      callback: async () => await refreshData(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ]);

  refreshData();
});
</script>
