<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    transition="dialog-bottom-transition"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="error" dark>
        <span class="ml-3 text-button">{{ displayTitle }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <template>
                  <v-select
                    v-model="shipmentReasonsForm.shipmentReasonFirst"
                    :items="returnReasonsFirst"
                    item-text="reason"
                    item-value="id"
                    label="Please select a reason"
                    outlined
                    v-on:change="returnReasonsChange"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-if="returnReasonsSecond.length"
                    v-model="shipmentReasonsForm.shipmentReasonSecond"
                    :items="returnReasonsSecond"
                    item-text="reason"
                    item-value="id"
                    label="Please select a second reason"
                    outlined
                    :rules="[rules.required]"
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="!valid"
          @click="emits('confirm', shipmentReasonsForm)"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from "vue";

import {
  CustomsExportApi,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
} from "@/openapi";

const customsExportApi = new CustomsExportApi(undefined, "");
import { Actions } from "@/config";
import { emitError } from "@/event-bus";

export interface ReturnShipmentReasonsForm {
  shipmentReasonFirst?: number | null;
  shipmentReasonSecond?: number | null;
}

interface IProps {
  value: boolean;
  isLoading: boolean;
  title: string;
  confirmButtonText: string;
  closeButtonText: string;
}

const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});
const emits = defineEmits(["input", "close", "confirm"]);

const form = ref(null);
const initializeForm = {
  shipmentReasonFirst: null,
  shipmentReasonSecond: null,
};

const rules = ref({
  required: (value: string) => (value && !!value.toString()) || "Required",
});
const valid = ref(true);

const shipmentReasonsForm = ref<ReturnShipmentReasonsForm>({
  ...initializeForm,
});

const isRetrievingReturnReasons = ref(false);

const returnReasonsFirst = ref<ReturnReasonFirstViewModel[]>([]);
const returnReasonsSecond = ref<ReturnReasonSecondViewModel[]>([]);

const displayTitle = computed(() => {
  return !props.title ? Actions.RETURN : props.title;
});

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      shipmentReasonsForm.value = { ...initializeForm };
      (
        form.value! as Vue & { resetValidation: () => boolean }
      ).resetValidation();
      returnReasonsSecond.value = [];
    }
  },
);

onBeforeMount(() => {
  getReturnReasons();
});

const returnReasonsChange = () => {
  var reasonFirst = returnReasonsFirst.value.find(
    (x) => x.id == shipmentReasonsForm.value.shipmentReasonFirst,
  );
  if (reasonFirst == null) {
    returnReasonsSecond.value = [];
    return;
  }

  returnReasonsSecond.value = reasonFirst.returnReasonsSecond!;
  if (returnReasonsSecond.value.length == 1) {
    shipmentReasonsForm.value.shipmentReasonSecond =
      returnReasonsSecond.value[0].id;
  }
};

const getReturnReasons = async () => {
  returnReasonsFirst.value = [];
  isRetrievingReturnReasons.value = true;
  try {
    var response = await customsExportApi.getReturnReasons();
    returnReasonsFirst.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the return reasons.");
  }
  isRetrievingReturnReasons.value = false;
};
</script>
