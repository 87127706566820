<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="reminderNumber"
          :expanded.sync="expanded"
          :single-expand="true"
          fixed-header
          fixed-footer
          show-expand
          class="elevation-1"
          :loading="isLoading"
        >
          <template #expanded-item="{ headers, item }">
            <td v-if="currentItem" :colspan="headers.length">
              <v-flex class="pa-3 pb-5">
                <v-container fluid>
                  <v-row>
                    <v-col cols="1">
                      <v-text-field
                        v-model="currentItem.reminderNumber"
                        hide-details
                        dense
                        label="Reminder number"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn color="primary" class="ml-3" @click="save"
                        >Save</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card color="grey darken-3">
                        <v-card-title>Payment Reminder Email</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-model="currentItem.reminderSubjectEnglish"
                                hide-details
                                dense
                                label="Subject (English)"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="currentItem.reminderSubjectGerman"
                                hide-details
                                dense
                                label="Subject (German)"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-textarea
                                v-model="currentItem.reminderEmailEnglish"
                                hide-details
                                dense
                                label="E-mail template (English)"
                                outlined
                              ></v-textarea>
                            </v-col>
                            <v-col cols="6">
                              <v-textarea
                                v-model="currentItem.reminderEmailGerman"
                                hide-details
                                dense
                                label="E-mail template (German)"
                                outlined
                              ></v-textarea>
                            </v-col>
                            <v-col cols="6">
                              <v-textarea
                                v-model="currentItem.reminderTextEnglish"
                                hide-details
                                dense
                                label="Reminder text (English)"
                                outlined
                              ></v-textarea>
                            </v-col>
                            <v-col cols="6">
                              <v-textarea
                                v-model="currentItem.reminderTextGerman"
                                hide-details
                                dense
                                label="Reminder text (German)"
                                outlined
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="saveSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Saved successful
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="saveSuccessful = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ReminderEmailViewModel, PaymentReminderEmailsApi } from "@/openapi";

const paymentReminderEmailsApi = new PaymentReminderEmailsApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);

const headers = ref([
  { text: "Reminder number", value: "reminderNumber" },
  { text: "Reminder subject English", value: "reminderSubjectEnglish" },
  { text: "Reminder subject German", value: "reminderSubjectGerman" },
]);

const isLoading = ref(false);
const saveSuccessful = ref(false);
const expanded = ref([]);
const items = ref<ReminderEmailViewModel[]>([]);
const currentItem = ref<ReminderEmailViewModel | null>(null);

watch(
  () => expanded.value,
  (newVal: ReminderEmailViewModel[], oldVal: ReminderEmailViewModel[]) => {
    if (newVal.length === 0) {
      currentItem.value = null;
    } else {
      var reminderEmailId = newVal[0].id;
      isLoading.value = true;
      paymentReminderEmailsApi
        .getReminderEmailById(reminderEmailId)
        .then((response) => {
          currentItem.value = response.data;
          isLoading.value = false;
        })
        .catch((error) => {
          isLoading.value = false;
        });
    }
  },
);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];

  emits("PageInfoReceived", "Payment Reminder Emails", toolbarButtons);
  loadData();
});

const refresh = () => {
  loadData();
};

const loadData = () => {
  isLoading.value = true;

  paymentReminderEmailsApi
    .getReminderEmails()
    .then((response) => {
      items.value = response.data;
      isLoading.value = false;
    })
    .catch((errors) => {
      isLoading.value = false;
    });
};

const save = async (item: ReminderEmailViewModel) => {
  paymentReminderEmailsApi
    .saveReminderEmail(currentItem.value!)
    .then((result) => {
      currentItem.value = result.data;
      saveSuccessful.value = true;
    })
    .catch((error) => {
      saveSuccessful.value = false;
    });
};
</script>
