<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <div>
          <h1>Customer</h1>
          <EditableField
            v-slot="{ makeEditable, removeEditable, isEditable }"
            :id="'customerId'"
          >
            <ValidationProvider
              name="Customer"
              :vid="'customerId-' + customerId"
              ref="providerCustomer"
              :item-id="customerId"
              rules="required"
              v-slot="{ errors, failed }"
              slim
            >
              <div
                @click="makeEditable"
                :class="{ red: failed && !isEditable }"
              >
                <div v-if="!isEditable">
                  {{ getCustomerName(customerId) }}
                </div>
                <v-autocomplete
                  v-if="isEditable"
                  v-model="customerId"
                  :class="{ 'editable-field': isEditable }"
                  label="Edit"
                  :items="customers"
                  item-text="shortName"
                  item-value="id"
                  :error-messages="errors"
                  single-line
                  autofocus
                  @blur="removeEditable"
                ></v-autocomplete>
              </div>
            </ValidationProvider>
          </EditableField>
        </div>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1"
          :loading="isLoading"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                  </td>
                  <td>
                    {{ item.milestoneName }}
                  </td>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'exportCode1-' + item.id"
                  >
                    <ValidationProvider
                      name="ExportCode1"
                      :vid="'exportCode1-' + item.id"
                      ref="providers"
                      item-name="exportCode1"
                      :item-id="item.id"
                      rules="max:20"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCode1 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.exportCode1"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="20"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'exportCode2-' + item.id"
                  >
                    <ValidationProvider
                      name="ExportCode2"
                      :vid="'exportCode2-' + item.id"
                      ref="providers"
                      item-name="exportCode2"
                      :item-id="item.id"
                      rules="max:20"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCode2 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.exportCode2"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="20"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'exportCode1ForExport-' + item.id"
                  >
                    <ValidationProvider
                      name="ExportCode1ForExport"
                      :vid="'exportCode1ForExport-' + item.id"
                      ref="providers"
                      item-name="exportCode1ForExport"
                      :item-id="item.id"
                      rules="max:20"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCode1ForExport }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.exportCode1ForExport"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="20"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'exportCode2ForExport-' + item.id"
                  >
                    <ValidationProvider
                      name="ExportCode2ForExport"
                      :vid="'exportCode2ForExport-' + item.id"
                      ref="providers"
                      item-name="exportCode2ForExport"
                      :item-id="item.id"
                      rules="max:20"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCode2ForExport }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.exportCode2ForExport"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="20"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'comment1-' + item.id"
                  >
                    <ValidationProvider
                      name="Comment1"
                      :vid="'comment1-' + item.id"
                      ref="providers"
                      item-name="comment1"
                      :item-id="item.id"
                      rules="max:200"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.comment1 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.comment1"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="200"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'comment2-' + item.id"
                  >
                    <ValidationProvider
                      name="Comment2"
                      :vid="'comment2-' + item.id"
                      ref="providers"
                      item-name="comment2"
                      :item-id="item.id"
                      rules="max:200"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.comment2 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.comment2"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="200"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'comment1ForExport-' + item.id"
                  >
                    <ValidationProvider
                      name="Comment 1 for export"
                      :vid="'comment1ForExport-' + item.id"
                      ref="providers"
                      item-name="comment1ForExport"
                      :item-id="item.id"
                      rules="max:200"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.comment1ForExport }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.comment1ForExport"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="200"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'comment2ForExport-' + item.id"
                  >
                    <ValidationProvider
                      name="Comment 2 for export"
                      :vid="'comment2ForExport-' + item.id"
                      ref="providers"
                      item-name="comment2ForExport"
                      :item-id="item.id"
                      rules="max:200"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.comment2ForExport }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.comment2ForExport"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          maxlength="200"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <ValidationProvider
                    name="Export Milestone Comment"
                    :vid="'exportMilestoneComment-' + item.id"
                    ref="providers"
                    item-name="exportMilestoneComment"
                    :item-id="item.id"
                    v-slot="{ errors, failed }"
                    slim
                  >
                    <td
                      :class="{ red: failed }"
                      :id="'exportMilestoneComment-' + item.id"
                    >
                      <v-checkbox
                        class="mt-0 pt-0"
                        v-model="item.exportMilestoneComment"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'waitForMilestone'"
                  >
                    <ValidationProvider
                      name="waitForMilestone"
                      :vid="'waitForMilestone-' + item.id"
                      ref="providers"
                      item-name="waitForMilestone"
                      :item-id="item.id"
                      v-slot="{ errors, failed }"
                      slim
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ getMilestoneName(item.waitForMilestone) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.waitForMilestone"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :items="customerMilestones"
                          item-text="milestoneName"
                          item-value="milestoneId"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                    :id="'waitXSecondsForMilestone-' + item.id"
                  >
                    <ValidationProvider
                      name="WaitXSecondsForMilestone"
                      :vid="'waitXSecondsForMilestone-' + item.id"
                      ref="providers"
                      item-name="waitXSecondsForMilestone"
                      :item-id="item.id"
                      rules="max:86400"
                      v-slot="{ errors, failed }"
                      slim
                      :persist="true"
                    >
                      <div
                        @click="makeEditable"
                        :class="{ red: failed && !isEditable }"
                      >
                        <div v-if="!isEditable">
                          {{ item.waitXSecondsForMilestone }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.waitXSecondsForMilestone"
                          :class="{ 'editable-field': isEditable }"
                          type="number"
                          min="0"
                          max="86400"
                          step="1"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomerViewModel,
  DataApi,
  CustomsMilestoneHandlingViewModel,
  CustomsMilestoneHandlingApi,
  MilestoneViewModel,
} from "@/openapi";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ref, watch, onBeforeMount } from "vue";

const dataApi = new DataApi(undefined, "");
const customsMilestoneHandlingApi = new CustomsMilestoneHandlingApi(
  undefined,
  "",
);
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);

const headers = ref([
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
  { text: "Milestone name", align: "start", value: "milestoneName" },
  { text: "Export code 1", value: "exportCode1" },
  { text: "Export code 2", value: "exportCode2" },
  { text: "Export code 1 for export", value: "exportCode1ForExport" },
  { text: "Export code 2 for export", value: "exportCode2ForExport" },
  { text: "Comment 1", value: "comment1" },
  { text: "Comment 2", value: "comment2" },
  { text: "Comment 1 for export", value: "comment1ForExport" },
  { text: "Comment 2 for export", value: "comment2ForExport" },
  { text: "Export milestone comment", value: "exportMilestoneComment" },
  { text: "Wait for milestone", value: "waitForMilestone" },
  { text: "Wait x seconds for milestone", value: "waitXSecondsForMilestone" },
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
]);

const isLoading = ref(false);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const customerId = ref(0);
const items = ref<CustomsMilestoneHandlingViewModel[]>([]);
const totalItems = ref(0);
const disableNewItemButton = ref(false);
const customers = ref<CustomerViewModel[]>([]);
const customerMilestones = ref<MilestoneViewModel[]>([]);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs milestone handling", toolbarButtons);

  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
    customerId.value =
      customers.value[0].id != null ? customers.value[0].id : 0;
  });
});

let customerIdDelay = 0;
watch(
  () => customerId.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(customerIdDelay);
    customerIdDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    }, 250);

    dataApi.getCustomerMilestones(customerId.value).then((result) => {
      customerMilestones.value = result.data;
    });
  },
);

let optionsIdDelay = 0;
watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(optionsIdDelay);
    optionsIdDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  customsMilestoneHandlingApi
    .getCustomsMilestoneHandling(
      itemsPerPage,
      page,
      orderBy,
      orderByField,
      customerId.value,
    )
    .then((result) => {
      items.value = result.data.items ?? [];
      totalItems.value = result.data.totalItems as number;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const saveItem = async (item: CustomsMilestoneHandlingViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];

      item.waitXSecondsForMilestone =
        !item.waitXSecondsForMilestone && item.waitForMilestone != 0
          ? null
          : item.waitXSecondsForMilestone;
      promises.push(
        provider.validate(
          item[propName as keyof CustomsMilestoneHandlingViewModel],
        ),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    let errorsArray: { id: string; errors: string[] }[] = [];
    Object.keys(validationObserver.errors).map((key) => {
      if (validationObserver.errors[key].length > 0) {
        errorsArray.push({ id: key, errors: validationObserver.errors[key] });
      }
    });
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        var errorObject = errorsArray.find(
          (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
        );
        if (errorObject) {
          var errorElementId = errorObject?.id;
          document
            .getElementById(errorElementId)
            ?.scrollIntoView({ inline: "start" });
        }

        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);

    customsMilestoneHandlingApi
      .saveCustomsMilestoneHandling(item)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const isNewItem = (item: CustomsMilestoneHandlingViewModel): Boolean => {
  return item?.id === 0;
};
const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: Boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const mapItem = (oldItem: any, newItem: any) => {
  for (const key in oldItem) {
    oldItem[key] = newItem[key];
  }
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};

const getCustomerName = (customerId: number) => {
  var customer = customers.value.find((c) => c.id === customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};

const getMilestoneName = (waitForMilestone: number) => {
  var milestone = customerMilestones.value.find(
    (c) => c.milestoneId === waitForMilestone,
  );
  if (!milestone) {
    return "";
  }

  return milestone.milestoneName;
};
</script>
