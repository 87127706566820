<template>
  <Fragment>
    <ValidationObserver slim ref="observer" v-slot="{ invalid }">
      <DefaultDialog
        :value="value"
        :max-width="'900px'"
        @input="$emit('input', $event)"
      >
        <template v-slot:header> Search MRN </template>
        <template v-slot:content>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    item-key="mrn"
                    dense
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                  >
                    <template v-slot:top>
                      <div class="px-4 py-4">
                        <v-text-field
                          v-model="search"
                          placeholder="Search"
                          append-icon="mdi-magnify"
                          :disabled="loading"
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item.mrnDateAdded="{ item }">
                      {{ item.mrnDateAdded | formatDate }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        class="ma-2"
                        small
                        @click="selectMRN(item)"
                        >Select <v-icon right>mdi-eye</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="$emit('input', false), $emit('close')"
          >
            Cancel
          </v-btn>
        </template>
      </DefaultDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import { DiscrepancyMrn, MrnDiscrepanciesApi } from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import { DataTableHeader } from "vuetify";
import { onBeforeMount, ref } from "vue";

interface ShowDiscrepancyMrnDialogProps {
  value: boolean;
  archived?: boolean;
}

const mrnDiscrepanciesApi = new MrnDiscrepanciesApi(undefined, "");

const props = defineProps<ShowDiscrepancyMrnDialogProps>();
const emits = defineEmits(["selectedMrn"]);

const headers = ref<DataTableHeader[]>([
  { text: "MRN number", value: "mrn", sortable: true },
  { text: "General customer", value: "customer", sortable: true },
  { text: "MRN date added", value: "mrnDateAdded", sortable: true },
  { text: "Total amount of pieces", value: "totalAmount", sortable: true },
  {
    text: "Amount of pieces not scanned",
    value: "amountNotScanned",
    sortable: true,
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    width: "6em",
  },
]);

const items = ref<DiscrepancyMrn[]>([]);
const loading = ref(true);
const search = ref("");

const getDiscrepanciesMrn = async () => {
  try {
    const response = await mrnDiscrepanciesApi.getDiscrepanciesMrn(
      props.archived,
    );
    items.value = response.data.items ?? [];
  } catch {
    emitError("Something went wrong while retrieving the MRNs.");
  }
  loading.value = false;
};

const selectMRN = (mrn: DiscrepancyMrn) => {
  emits("selectedMrn", mrn);
};

onBeforeMount(async () => {
  await getDiscrepanciesMrn();
});
</script>
