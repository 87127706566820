<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer" slim>
    <DefaultDialog
      v-model="localValue"
      :loading="loading"
      :is-confirm-disabled="invalid"
      :close-button-state="{
        disabled: loading,
      }"
      :confirm-button-state="{
        loading: loading,
        disabled: invalid,
      }"
      :readonly="readonly"
      @click:close="localValue = false"
      @click:confirm="onConfirm"
    >
      <template #header> {{ title }} </template>
      <template #content>
        <ValidationProvider v-slot="{ errors }" rules="required" name="Remark">
          <v-textarea
            v-model="formData.remark"
            :error-messages="errors"
            :disabled="readonly"
            name="Remark"
          ></v-textarea>
        </ValidationProvider>
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from "vee-validate";
import { computed, ref, watch } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";

export interface RemarkDialogFormData {
  remark: string;
}

interface RemarkDialogProps {
  value: boolean;
  loading?: boolean;
  readonly?: boolean;
  initialFormData?: RemarkDialogFormData | null;
}

const props = defineProps<RemarkDialogProps>();
const emits = defineEmits(["input", "confirm"]);

let defaultFormValues: RemarkDialogFormData = {
  remark: "",
};

const formData = ref<RemarkDialogFormData>({ ...defaultFormValues });
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const localValue = computed({
  get() {
    return props.value;
  },
  set(newValue: boolean) {
    emits("input", newValue);
  },
});
const title = computed(() =>
  props.initialFormData?.remark ? "View remark" : "Add remark",
);

watch(
  () => props.value,
  (newValue: boolean, oldValue: boolean) => {
    if (!newValue) {
      formData.value = { ...defaultFormValues };
      observer.value?.reset();
    }
  },
);

watch(
  () => props.initialFormData,
  (newVal: RemarkDialogFormData, oldVal: RemarkDialogFormData) => {
    formData.value = newVal;
    defaultFormValues = { ...newVal };
  },
  { deep: true },
);

const onConfirm = () => {
  emits("confirm", { ...formData.value });
};
</script>
