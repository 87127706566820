<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <ValidationObserver ref="observer" slim v-slot="{ invalid }">
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="ml-5 text-button">{{ title }}</span>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <div class="d-flex flex-column mb-4">
              <strong>EZA*</strong>
              <ValidationProvider
                rules="required"
                name="HS Code"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model.number="form.eza.hsCode"
                  :items="ezaArticles"
                  item-value="id"
                  :item-text="getItemName"
                  label="HS Code*"
                  :error-messages="errors"
                  :loading="loadingEzaArticles"
                ></v-autocomplete>
              </ValidationProvider>
              <ValidationProvider
                rules="required|max:400"
                name="Description"
                vid="ezaDescription"
                v-slot="{ errors }"
              >
                <v-textarea
                  v-model="form.eza.description"
                  label="Description*"
                  :error-messages="errors"
                  counter
                ></v-textarea>
              </ValidationProvider>
            </div>

            <v-divider class="mb-8"></v-divider>

            <div class="d-flex flex-column mb-4">
              <strong>IMPOST</strong>
              <v-autocomplete
                v-model.number="form.impost.hsCode"
                :items="impostArticles"
                item-value="id"
                :item-text="getItemName"
                label="HS Code"
                :loading="loadingImpostArticles"
              ></v-autocomplete>
              <ValidationProvider
                rules="max:400"
                name="Description"
                vid="impostDescription"
                v-slot="{ errors }"
              >
                <v-textarea
                  v-model="form.impost.description"
                  label="Description"
                  :error-messages="errors"
                  counter
                ></v-textarea>
              </ValidationProvider>
            </div>

            <v-divider class="mb-4"></v-divider>

            <div class="d-flex flex-column">
              <v-checkbox
                v-model.number="form.useGeneralCustomerForAutomatedProcess"
                :disabled="!form.customer"
                label="Use general customer for automated process"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model.number="form.useSenderForAutomatedProcess"
                label="Use sender for automated process"
                :disabled="!form.senderName"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model.number="form.documents"
                label="Documents"
                hide-details
              ></v-checkbox>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-1"
            text
            :disabled="isLoading"
            @click="emits('input', false), emits('close')"
          >
            {{ closeButtonText }}
          </v-btn>
          <v-btn
            color="light-blue darken-1"
            text
            :loading="isLoading"
            :disabled="invalid"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { CustomsArticle, DataApi } from "@/openapi";
import { emitError } from "@/event-bus";
import { ValidationObserver } from "vee-validate";

interface IProps {
  value: boolean;
  isLoading: boolean;
  title: string;
  confirmButtonText: string;
  closeButtonText: string;
  initialData?: ArticleMaintenanceFulfillmentForm;
}

export interface ArticleMaintenanceEditableData {
  hsCode: number | undefined | null;
  description: string | undefined | null;
}

export interface ArticleMaintenanceFulfillmentForm {
  eza: ArticleMaintenanceEditableData;
  impost: ArticleMaintenanceEditableData;
  useGeneralCustomerForAutomatedProcess: boolean;
  useSenderForAutomatedProcess: boolean;
  documents: boolean;
  customer?: string | null;
  senderName?: string | null;
}
const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Fulfill open article",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
  initialData: {
    eza: {
      hsCode: null,
      description: null,
    },
    impost: {
      hsCode: null,
      description: "",
    },
    useGeneralCustomerForAutomatedProcess: false,
    useSenderForAutomatedProcess: false,
    documents: false,
  },
});
const emits = defineEmits(["input", "close", "confirm"]);
const api = new DataApi(undefined, "");
const ezaArticles = ref<CustomsArticle[]>([]);
const impostArticles = ref<CustomsArticle[]>([]);

const loadingEzaArticles = ref(false);
const loadingImpostArticles = ref(false);

const form = ref<ArticleMaintenanceFulfillmentForm>({
  eza: {
    hsCode: null,
    description: "",
  },
  impost: {
    hsCode: null,
    description: "",
  },
  useGeneralCustomerForAutomatedProcess: false,
  useSenderForAutomatedProcess: false,
  documents: false,
});

onBeforeMount(() => {
  getEzaArticles();
  getImpostArticles();
  if (!!props.initialData) {
    form.value = props.initialData;
  }
});

const getEzaArticles = async () => {
  loadingEzaArticles.value = true;
  try {
    const response = await api.getCustomsArticles(false);
    ezaArticles.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the EZA HS codes.");
  }
  loadingEzaArticles.value = false;
};

const getImpostArticles = async () => {
  loadingImpostArticles.value = true;
  try {
    const response = await api.getCustomsArticles(true);
    impostArticles.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the IMPOST HS codes.");
  }
  loadingImpostArticles.value = false;
};

const getItemName = (item: CustomsArticle) => {
  return item.hsCode === item.description
    ? item.hsCode
    : `${item.hsCode} - ${item.description}`;
};

const confirm = () => {
  emits("confirm", {
    eza: {
      ...form.value.eza,
    },
    impost: {
      ...form.value.impost,
    },
    useGeneralCustomerForAutomatedProcess:
      form.value.useGeneralCustomerForAutomatedProcess,
    useSenderForAutomatedProcess: form.value.useSenderForAutomatedProcess,
    documents: form.value.documents,
  } as ArticleMaintenanceFulfillmentForm);
};
</script>
