import { render, staticRenderFns } from "./CustomsInspectionButton.vue?vue&type=template&id=24929d00"
import script from "./CustomsInspectionButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./CustomsInspectionButton.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports