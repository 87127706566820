<template>
  <v-menu
    transition="scale-transition"
    offset-y
    :close-on-content-click="false"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateDisplayText"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        clearable
        v-bind="$attrs"
        v-on="on"
        @click:clear="dateValue = null"
        :error-messages="errorMessages"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dateValue"
      :range="range"
      :type="type"
      :min="min"
      :max="max"
    ></v-date-picker>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
const props = defineProps({
  value: {
    type: [String, Array] as PropType<String | String[] | null>,
    default: null,
  },
  label: {
    type: String,
    default: "Date",
  },
  formatWith: {
    type: String,
    default: "-",
  },
  errorMessages: {
    type: Array as PropType<String[]>,
    default: () => [],
  },
  range: {
    type: Boolean,
    default: false,
  },
  min: {
    type: String,
    default: null,
  },
  max: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: "date",
    validator: (value: string) => ["date", "month"].includes(value),
  },
});
const emits = defineEmits(["input"]);

const dateDisplayText = computed(() => {
  if (!props.value) {
    return "";
  }

  return Array.isArray(props.value)
    ? props.value?.map((c) => formatDate(c.split("T")[0])).join(" - ")
    : formatDate(props.value.split("T")[0]);
});

const dateValue = computed({
  get() {
    if (!props.value) {
      return props.value;
    }
    return Array.isArray(props.value)
      ? props.value?.map((c) => c.split("T")[0])
      : props.value?.split("T")[0];
  },
  set(newValue) {
    emits("input", newValue);
  },
});

const formatDate = (dateText: string) => {
  if (!dateText) {
    return "";
  }
  const date = new Date(dateText);
  const day = `${date.getDate()}`.padStart(2, "0");
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const year = date.getFullYear();
  return `${day}${props.formatWith}${month}${props.formatWith}${year}`;
};
</script>
