<template>
  <v-card class="pa-3">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Documents</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openNewAcDocumentDialog()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-simple-table class="pt-3">
      <template #default>
        <thead>
          <tr>
            <th>Doc.code</th>
            <th>Description</th>
            <th>Number</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(doc, index) in detail.acDocuments"
            :key="`${timeAcDocumentStampKey}-${index}`"
          >
            <td>{{ doc.documentCode }}</td>
            <td>{{ doc.description }}</td>
            <td>{{ doc.number }}</td>
            <td>{{ formatDate(doc.date, null) }}</td>
            <td>
              <v-btn icon @click="openAcDocumentDialog(doc, index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteAcDocumentDialog(doc.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="acDocumentDialog" width="660px">
      <ShipmentAcDocumentsDialog
        :key="timeStampKey"
        :document="selectedAcDocument"
        :index="selectedIndex"
        :saving.sync="savinfAcDocument"
        @close="closeAcDocumentDialog"
        @save="saveAcDocument"
      ></ShipmentAcDocumentsDialog>
    </v-dialog>
    <ConfirmDialog
      v-model="showConfirmDeleteAcDocumentDialog"
      color="error"
      @confirm="deleteAcDocument"
      @close="deleteAcDocumentId = null"
    >
      <slot name="confirm-delete-text" :default-text="confirmDeleteText">{{
        confirmDeleteText
      }}</slot>
    </ConfirmDialog>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  ShipmentDetailApi,
  ShipmentDetailViewModel,
  ShipmentDetailAcDocumentModel,
} from "@/openapi";
import { formatDate } from "@/helpers/dateTimeHelper";
import ShipmentAcDocumentsDialog from "./dialogs/ShipmentAcDocumentsDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const api = new ShipmentDetailApi(undefined, "");
const props = defineProps<IProps>();
const timeStampKey = ref(Date.now());
const timeAcDocumentStampKey = ref(Date.now());
const savinfAcDocument = ref(false);
const acDocumentDialog = ref(false);
const selectedIndex = ref<number | null>(null);
const deleteAcDocumentId = ref<number | null>(null);
const defaultDocument: ShipmentDetailAcDocumentModel = { id: 0 };
const selectedAcDocument = ref<ShipmentDetailAcDocumentModel>(defaultDocument);
const confirmDeleteText = "Are you sure you want to delete this item?";

const showConfirmDeleteAcDocumentDialog = computed({
  get() {
    return deleteAcDocumentId.value != null;
  },
  set(value: boolean) {
    deleteAcDocumentId.value = null;
  },
});

const openNewAcDocumentDialog = () => {
  timeStampKey.value = Date.now();
  selectedIndex.value = null;
  selectedAcDocument.value = { ...defaultDocument };
  acDocumentDialog.value = true;
};

const openAcDocumentDialog = (
  doc: ShipmentDetailAcDocumentModel,
  index: number,
) => {
  timeStampKey.value = Date.now();
  selectedIndex.value = index;
  selectedAcDocument.value = doc;
  acDocumentDialog.value = true;
};

const deleteAcDocumentDialog = async (id: number) => {
  deleteAcDocumentId.value = id;
};

const deleteAcDocument = async () => {
  await api.deleteShipmentDetailAcDocument(deleteAcDocumentId.value ?? 0);
  const index = props.detail.acDocuments?.findIndex(
    (item) => item.id == deleteAcDocumentId.value,
  );
  if (index != undefined && index != null && props.detail.acDocuments) {
    props.detail.acDocuments.splice(index, 1);
  }
  deleteAcDocumentId.value = null;
};

const saveAcDocument = async (
  document: ShipmentDetailAcDocumentModel,
  index: number,
) => {
  if (
    props.detail.acDocuments != null &&
    props.detail.acDocuments != undefined
  ) {
    const response = await api.saveShipmentDetailAcDocument(
      props.detail.id,
      document,
    );
    if (index == null) {
      props.detail.acDocuments.unshift({ ...response.data });
    } else {
      props.detail.acDocuments[index] = response.data;
      timeAcDocumentStampKey.value = Date.now();
    }
    acDocumentDialog.value = false;
  }
  selectedAcDocument.value = defaultDocument;
  selectedIndex.value = null;
};
const closeAcDocumentDialog = () => {
  acDocumentDialog.value = false;
  selectedIndex.value = null;
  selectedAcDocument.value = defaultDocument;
};
</script>
