<template>
  <v-container fluid>
    <v-row>
      <v-col lg="6" class="mx-auto">
        <v-card>
          <v-toolbar class="grad" dark extension-height="100">
            <v-toolbar-title> Login </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ValidationObserver v-slot="{ invalid }" ref="observer">
              <form @submit.prevent="login">
                <ValidationProvider v-slot="v" name="username" rules="required">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    :error-messages="v.errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="v" name="password" rules="required">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    :error-messages="v.errors"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="login"
                  ></v-text-field>
                </ValidationProvider>
                <div>
                  <span
                    v-if="loginResult && !loginResult.success"
                    class="red--text"
                    >{{ loginResult.errorMessage }}</span
                  >
                </div>
                <v-btn
                  color="primary"
                  :loading="loggingIn"
                  :disabled="loggingIn || invalid"
                  @click="login"
                  >Login</v-btn
                >
                <a
                  class="v-btn v-btn--is-elevated btn-azure v-size--default"
                  :href="loginazure"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"
                    />
                  </svg>
                  Login with Microsoft account</a
                >
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ValidationObserver } from "vee-validate";
import { useRoute, useRouter } from "vue-router/composables";

import { AccountApi, LoginResultViewModel, LoginViewModel } from "@/openapi";
const route = useRoute();
const router = useRouter();
const accountApi = new AccountApi(undefined, "");
const emits = defineEmits(["errorOccured"]);
const observer = ref<InstanceType<typeof ValidationObserver>>();

const showPassword = ref(false);
const username = ref("");
const password = ref("");
const loggingIn = ref(false);
const loginazure = ref(
  "/account/loginazure?path=/account/ClearAzureLogin%3Fpath=" +
    route.query.next || "/",
);
const loginResult = ref<LoginResultViewModel | null>(null);
import store from "@/store";
const login = () => {
  if (store.state.loggedIn) {
    return;
  }

  observer.value?.validate().then((success: boolean) => {
    if (!success) {
      return;
    }
    const loginVm: LoginViewModel = {
      username: username.value,
      password: password.value,
    };
    accountApi
      .login(loginVm)
      .then((response) => {
        if (response.data.success) {
          accountApi
            .deleteJobsFromEmployee()
            .then((result) => {
              router.push({
                path: (route.query.next as string) || "/",
              });
            })
            .catch((error) => {
              const errors = [error.message];
              emits("errorOccured", errors);
            });
          store.commit("setLoggedinState", response.data);
          store.commit("setIsBpoUser", response.data.isBpoUser);
        } else {
          loginResult.value = response.data;
        }
      })
      .catch((error) => {
        const errors = [error.message];
        emits("errorOccured", errors);
      });
  });
};
</script>
