<template>
  <Fragment>
    <v-row class="mb-5">
      <v-col cols="4">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          @keyup.enter="onFilterSelected"
        ></v-text-field>
      </v-col>
      <v-col>
        <div class="text-right">
          <v-btn color="primary" @click="enterDateSelection">
            <v-icon color="black">mdi-file-export</v-icon> Export By Date
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="dakosyReference"
      height="calc(100vh - 280px)"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
      show-expand
      :single-expand="true"
      :expanded.sync="expandedItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
    >
      <template #item.date="{ item }">
        {{ moment(item.date).format("DD-MM-YYYY") }}
      </template>

      <template #item.actions="{ item }">
        <v-btn
          color="primary"
          small
          class="mr-3"
          @click="createExportForRefund(item.name)"
        >
          <v-icon color="black" small>mdi-file-move</v-icon>
          Export
        </v-btn>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ShipmentsHistory
            :consolidation="item.name"
            :dakosy-reference="item.dakosyReference"
          ></ShipmentsHistory>
        </td>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showDateSelection"
      v-model="showDateSelection"
      persistent
      max-width="700"
    >
      <ValidationObserver v-slot="{ invalid }">
        <v-card>
          <v-card-title class="text-h5">
            Choose date range to export
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Date range"
                    rules="required|dateRange|dateRangeOrder"
                    slim
                  >
                    <DatePicker
                      v-model="dateRange"
                      label="Date range"
                      :range="true"
                      :error-messages="errors"
                    ></DatePicker>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="categoryId"
                      :items="categories"
                      item-value="key"
                      item-text="value"
                      label="Category"
                      clearable
                      :error-messages="errors"
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="cancelDateSelection"> Cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="createExportDeclarationByDate"
            >
              Export
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  Int32StringKeyValueItem,
  ShipmentApi,
  ShipmentConsolidation,
} from "@/openapi";
import moment from "moment";
import { ref, watch, onBeforeMount, nextTick, computed } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import ShipmentsHistory from "./ShipmentsHistory.vue";
import { downloadFile } from "@/helpers/downloadHelper";
import { DataAction } from "@/store/dataModule";
import store from "@/store";

interface Filters {
  search?: string;
}

const api = new ShipmentApi(undefined, "");

const isLoading = ref(false);
const headers = ref<DataTableHeader[]>([
  {
    text: "Consolidation name",
    align: "start",
    value: "name",
    width: 300,
    sortable: false,
  },
  {
    text: "Consolidation date",
    align: "start",
    value: "date",
    sortable: false,
  },
  { text: "Type", align: "start", value: "type", sortable: false },
  {
    text: "Transit MRN",
    align: "start",
    value: "transitMrn",
    sortable: false,
  },
  {
    text: "Dakosy reference",
    align: "start",
    value: "dakosyReference",
    sortable: false,
  },
  { text: "", align: "end", value: "actions", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);
const items = ref<ShipmentConsolidation[]>([]);
const expandedItems = ref<ShipmentConsolidation[]>([]);

const filters = ref<Filters>({
  search: "",
});

const showDateSelection = ref(false);
const dateRange = ref<string[] | null>(null);
const categoryId = ref<number | null>(null);

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getShipmentConsolidationHistory();
    }, 250);
  },
);

onBeforeMount(async () => {
  await store.dispatch(DataAction.FetchCustomsHandlingCategories);
});

const getShipmentConsolidationHistory = async (page?: number) => {
  page ??= options.value.page;
  isLoading.value = true;
  try {
    const response = await api.getShipmentConsolidationsHistory(
      filters.value.search ?? undefined,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    options.value.page = response.data.page ?? 1;
    options.value.itemsPerPage = response.data.itemsPerPage ?? 5;
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the items.");
  }
  isLoading.value = false;
};

const reloadConsolidations = async () => {
  options.value.page == 1
    ? await getShipmentConsolidationHistory(1)
    : (options.value.page = 1);
};

const onFilterSelected = async () => {
  nextTick(() => {
    reloadConsolidations();
  });
};

const enterDateSelection = () => {
  showDateSelection.value = true;
};

const cancelDateSelection = () => {
  showDateSelection.value = false;
};

const createExportForRefund = async (consolidation: string) => {
  try {
    const response = await api.createExportForRefundRequest(consolidation, {
      responseType: "blob",
    });
    downloadFile(response.data, "Declaration-" + Date.now() + ".xlsx");
  } catch {
    emitError("Something went wrong while downloading the export declaration.");
  }
};

const createExportDeclarationByDate = async () => {
  showDateSelection.value = false;
  try {
    const response = await api.createExportDeclarationsByDate(
      dateRange.value![0],
      dateRange.value![1],
      categoryId.value ?? undefined,
      {
        responseType: "blob",
      },
    );
    downloadFile(response.data, "Declaration-" + Date.now() + ".xlsx");
  } catch {
    emitError("Something went wrong while creating of the data by date.");
  }
};

const categories = computed((): Int32StringKeyValueItem[] => {
  return store.getters.customsHandlingCategories;
});
</script>
