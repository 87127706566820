<template>
  <!-- ToDo SOVC-758: Add models to inputs -->
  <v-card class="pa-3 shipment-info-card mb-1">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Shipment information</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.pieces"
          label="Pieces"
          outlined
          type="number"
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="detail.shipmentInformation.value"
          label="Value"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="detail.shipmentInformation.currency"
          :items="dataStore.currencies"
          item-text="name"
          item-value="name"
          hide-details
          dense
          label="Currency"
          outlined
        ></v-select>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.realWeight"
          label="Real weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.shipmentInformation.incoterms"
          :items="dataStore.incoterms"
          item-text="name"
          item-value="id"
          hide-details
          dense
          label="Incoterms"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.volumeWeight"
          label="Volume weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="shipmentDimensionsDialog = true"
          >D</v-btn
        >
      </v-col>
      <v-dialog v-model="shipmentDimensionsDialog" width="1200"
        ><ShipmentInfoDimensions
          :detail.sync="detail"
          :open-dialog.sync="shipmentDimensionsDialog"
        ></ShipmentInfoDimensions>
      </v-dialog>
      <v-col cols="4">
        <v-text-field
          v-model="detail.shipmentInformation.calculatedVolumeWeight"
          hide-details
          dense
          label="Calc. vol. weight"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
import ShipmentInfoParcels from "./dialogs/ShipmentInfoParcelsDialog.vue";
import ShipmentInfoDimensions from "./dialogs/ShipmentInfoDimensionsDialog.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();

const shipmentDimensionsDialog = ref<boolean>(false);
</script>
