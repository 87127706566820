<template>
  <ValidationObserver v-slot="{ invalid }" tag="div" class="d-flex flex-column">
    <DefaultDialog
      v-model="localValue"
      max-width="1600px"
      no-spacing="true"
      @close="$emit('close')"
    >
      <template #header>Dakosy export</template>
      <template #content>
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-divider></v-divider>
            <v-stepper-step :complete="currentStep > 1" :step="1">
              Ausfuhr
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 2" :step="2">
              Articles
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content :step="1">
              <div class="form-fields">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Address"
                  :rules="'required'"
                >
                  <v-select
                    v-model="formData.addressId"
                    :items="addresses"
                    item-value="id"
                    item-text="name"
                    label="Address"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>

                <v-checkbox
                  v-model="formData.useShipperAddress"
                  label="Use shipper address"
                  class="mt-0 pt-0"
                ></v-checkbox>
              </div>

              <template v-if="formData.useShipperAddress">
                <v-divider class="pb-6"></v-divider>
                <div class="form-fields">
                  <TextField
                    v-model="formData.zusatz"
                    name="Zusatz"
                    label="Zusatz*"
                    rules="required|max:200"
                  ></TextField>
                  <TextField
                    v-model="formData.ausfuhrzollstelle"
                    name="Ausfuhrzollstelle"
                    label="Ausfuhrzollstelle*"
                    rules="required|max:50"
                  ></TextField>
                  <TextField
                    v-model="formData.vorgeseheneAusgangszollstelle"
                    name="Vorgesehene Ausgangszollstelle"
                    label="Vorgesehene Ausgangszollstelle*"
                    rules="required|max:50"
                  ></TextField>
                  <TextField
                    v-model="formData.specialCircumstancesCharacteristics"
                    name="Kennzeichen des besonderen Tatbestandes"
                    label="Kennzeichen des besonderen Tatbestandes*"
                    rules="required|max:100"
                  ></TextField>
                  <TextField
                    v-model="formData.remarkSpecialCircumstancesCharacteristics"
                    name="Vermerk / Erläuternder Text zum besonderen Tatbestand"
                    label="Vermerk / Erläuternder Text zum besonderen Tatbestand*"
                    rules="required|max:100"
                  ></TextField>
                  <TextField
                    v-model="formData.aBDEmail"
                    name="ABD email"
                    label="ABD email*"
                    rules="required|email|max:200"
                  ></TextField>
                  <TextField
                    v-model="formData.aVMEmail"
                    name="AVM email"
                    label="AVM email*"
                    rules="required|email|max:200"
                  ></TextField>
                </div>
                <v-divider class="pb-6"></v-divider>
              </template>
              <div class="form-fields">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Presentation date"
                  rules="required"
                >
                  <DateTimePicker
                    v-model="formData.presentationDate"
                    label="Presentation date*"
                    :dense="true"
                    :outlined="true"
                    :error-messages="errors"
                  ></DateTimePicker>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="End date"
                  rules="required"
                >
                  <DateTimePicker
                    v-model="formData.endDate"
                    label="End date*"
                    :dense="true"
                    :outlined="true"
                    :error-messages="errors"
                  ></DateTimePicker>
                </ValidationProvider>
              </div>
            </v-stepper-content>

            <v-stepper-content :step="2">
              <v-data-table
                :headers="headers"
                :items="items"
                :options="options"
                :footer-props="footerOptions"
                :loading="loading"
                height="500px"
              >
                <template v-if="items.length" #body="{ items }">
                  <tbody>
                    <ValidationObserver
                      v-for="item in items"
                      :key="item.id"
                      :ref="'observer-' + item.id"
                      slim
                    >
                      <tr>
                        <td>
                          <EditableTextField
                            v-model="item.wtn"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableTextField
                            v-model="item.goodsDescription"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableTextField
                            v-model="item.rchsCode"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableTextField
                            v-model.number="item.grossWeight"
                            type="number"
                            :text-field-attr="{
                              step: '0.01',
                            }"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableTextField
                            v-model.number="item.ownWeight"
                            :text-field-attr="{
                              step: '0.01',
                            }"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableTextField
                            v-model.number="item.invoiceAmount"
                            :text-field-attr="{
                              step: '0.01',
                            }"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableDropdown
                            v-model="item.invoiceCurrency"
                            :items="dataStore.currencies"
                            :return-object="false"
                            item-value="name"
                            item-text="name"
                          ></EditableDropdown>
                        </td>
                        <td>
                          <EditableTextField
                            v-model.number="item.freightCostsAmount"
                            :text-field-attr="{
                              step: '0.01',
                            }"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableDropdown
                            v-model="item.freightCostsCurrency"
                            :items="dataStore.currencies"
                            :return-object="false"
                            item-value="name"
                            item-text="name"
                          ></EditableDropdown>
                        </td>
                        <td>
                          <EditableTextField
                            v-model.number="item.statAmount"
                            :text-field-attr="{
                              step: '0.01',
                            }"
                          ></EditableTextField>
                        </td>
                        <td>
                          <EditableDropdown
                            v-model="item.statUnit"
                            :items="measurementUnits"
                            :return-object="false"
                            item-value="code"
                            item-text="text"
                          ></EditableDropdown>
                        </td>
                      </tr>
                    </ValidationObserver>
                  </tbody>
                </template>
              </v-data-table>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template #footer>
        <v-btn
          class="align-self-end"
          color="primary"
          :disabled="isProcessing"
          text
          @click="onClose"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentStep > 1"
          class="align-self-end"
          color="primary"
          :disabled="invalid || isProcessing"
          text
          @click="currentStep--"
        >
          Back
        </v-btn>
        <v-btn
          v-if="currentStep < 2"
          class="align-self-end"
          color="primary"
          :disabled="invalid || isProcessing"
          text
          @click="currentStep++"
        >
          Next
        </v-btn>

        <v-btn
          v-if="currentStep == 2"
          class="align-self-end"
          color="primary"
          :disabled="invalid"
          :loading="isProcessing"
          text
          @click="onConfirm"
        >
          Confirm
        </v-btn>
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from "vee-validate";
import { computed, onBeforeMount, ref, watch } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import TextField from "@/components/editors/TextField.vue";
import DateTimePicker from "@/components/editors/DateTimePicker.vue";
import { useDataTableDefaults } from "@/composables/dataTable";
import {
  AusfuhrInfoDto,
  CustomsAusfuhrInfoApi,
  ShipmentArticleApi,
} from "@/openapi";
import { emitErrorWithFallback } from "@/event-bus";
import { DataTableHeader } from "vuetify";
import EditableTextField from "@/components/EditableTextField.vue";
import { useDataStore } from "@/stores/data-store";
import EditableDropdown from "@/components/EditableDropdown.vue";
import { DakosyExportData, ShipmentArticle } from "@/types/types";
import { useFormReset } from "@/composables/formReset";

interface DakosyExportAusfuhrInfoDialogProps {
  value?: boolean;
  shipmentId: number;
  isProcessing: boolean;
}

interface DakosyExportAusfuhrInfoDialogFormData {
  addressId: number | null;
  useShipperAddress: boolean;
  zusatz: string;
  ausfuhrzollstelle: string;
  vorgeseheneAusgangszollstelle: string;
  specialCircumstancesCharacteristics: string;
  remarkSpecialCircumstancesCharacteristics: string;
  aBDEmail: string;
  aVMEmail: string;
  presentationDate: string | null;
  endDate: string | null;
}

const defaultFormValues: DakosyExportAusfuhrInfoDialogFormData = {
  addressId: null,
  useShipperAddress: false,
  zusatz: "",
  ausfuhrzollstelle: "",
  vorgeseheneAusgangszollstelle: "",
  specialCircumstancesCharacteristics: "",
  remarkSpecialCircumstancesCharacteristics: "",
  aBDEmail: "",
  aVMEmail: "",
  presentationDate: null,
  endDate: null,
};

const dataStore = useDataStore();

const ausfuhrInfoApi = new CustomsAusfuhrInfoApi(undefined, "");
const shipmentArticleApi = new ShipmentArticleApi(undefined, "");

const props = defineProps<DakosyExportAusfuhrInfoDialogProps>();
const emits = defineEmits(["input", "confirm", "close"]);

const currentStep = ref(1);
const formData = ref<DakosyExportAusfuhrInfoDialogFormData>({
  ...defaultFormValues,
});
const addresses = ref<AusfuhrInfoDto[]>([]);
const retrievingData = ref(false);

watch(
  () => formData.value.addressId,
  (newVal, oldVal) => {
    if (!newVal) return;
    const selectedAddress = addresses.value.find((a) => a.id === newVal);
    if (!selectedAddress) return;
    formData.value.zusatz = selectedAddress.zusatz ?? "";
    formData.value.ausfuhrzollstelle = selectedAddress.ausfuhrzollstelle ?? "";
    formData.value.vorgeseheneAusgangszollstelle =
      selectedAddress.vorgeseheneAusgangszollstelle ?? "";
    formData.value.remarkSpecialCircumstancesCharacteristics =
      selectedAddress.remarkSpecialCircumstancesCharacteristics ?? "";
    formData.value.specialCircumstancesCharacteristics =
      selectedAddress.specialCircumstancesCharacteristics ?? "";
    formData.value.aBDEmail = selectedAddress.abdEmail ?? "";
    formData.value.aVMEmail = selectedAddress.avmEmail ?? "";
  },
);

const localValue = computed({
  get() {
    return props.value ?? false;
  },
  set(value: boolean) {
    emits("input", value);
  },
});

useFormReset(localValue, formData, defaultFormValues);

const headers = ref<DataTableHeader[]>([
  { value: "wtn", text: "Warentarifnummer" },
  { value: "goodsDescription", text: "Description" },
  { value: "rchsCode", text: "HS code" },
  { value: "grossWeight", text: "Rohmasse" },
  { value: "ownWeight", text: "Eigenmasse" },
  { value: "invoiceAmount", text: "Invoice price" },
  { value: "invoiceCurrency", text: "Invoice price currency" },
  { value: "freightCostsAmount", text: "Freightcosts" },
  { value: "freightCostsCurrency", text: "Freightcosts currency" },
  { value: "statAmount", text: "Amount" },
  { value: "statCurrency", text: "Masseinheit" },
]);

const { items, totalAmountOfItems, footerOptions, loading, options } =
  useDataTableDefaults<ShipmentArticle>();
const hasRetrievedArticles = ref(false);

const getAddresses = async () => {
  try {
    const response = await ausfuhrInfoApi.getAusfuhrInfoList(1, -1);
    addresses.value = response.data.items ?? [];
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the addresses",
    );
  }
};

const getArticles = async () => {
  loading.value = true;
  try {
    const response = await shipmentArticleApi.getArticles(
      props.shipmentId,
      1,
      -1,
    );
    items.value =
      response.data.items?.map(
        (c) =>
          ({
            id: c.id,
            wtn: c.wtn ?? "",
            goodsDescription: c.goodsDescription ?? "",
            rchsCode: c.rchsCode ?? "",
            grossWeight: c.grossWeight,
            ownWeight: c.ownWeight,
            invoiceAmount: c.invoiceMoney?.amount,
            invoiceCurrency: c.invoiceMoney?.currency,
            freightCostsAmount: c.freightCostMoney?.amount,
            freightCostsCurrency: c.freightCostMoney?.currency,
            statAmount: c.statWeight?.amount,
            statUnit: c.statWeight?.unit,
          }) as ShipmentArticle,
      ) ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    hasRetrievedArticles.value = true;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the articles",
    );
  }
  loading.value = false;
};

const onClose = () => {
  localValue.value = false;
  items.value = [];
  currentStep.value = 1;
  emits("close");
};

const onConfirm = () => {
  emits(
    "confirm",
    structuredClone({
      ...formData.value,
      articles: items.value,
    } as DakosyExportData),
  );
};

const measurementUnits = computed(() => {
  return dataStore.measurementUnits.map((c) => ({
    ...c,
    text: `${c.code} | ${c.description}`,
  }));
});

watch(
  () => currentStep.value,
  (newValue: number, oldValue: number) => {
    if (newValue == 2 && !hasRetrievedArticles.value) {
      getArticles();
    }
  },
);

onBeforeMount(async () => {
  retrievingData.value = true;
  await Promise.all([
    getAddresses(),
    dataStore.fetchMeasurementUnits(),
    dataStore.fetchCurrencies(),
  ]);
  retrievingData.value = false;
});
</script>

<style scoped lang="scss">
.form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
}
</style>
