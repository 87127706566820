<template>
  <Fragment>
    <CrudDataTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :height="tableHeightCalc"
      @click:create="onCreateItem"
      @click:edit="onEditItem"
      @click:delete="onDeleteItem"
    >
      <template #item.enabled="{ item }">
        <v-icon :color="item.enabled ? 'success' : 'error'"
          >{{ item.enabled ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>
      <template #item.articleDescriptions="{ item }">
        {{ getArticleDescriptionText(item) }}
      </template>
      <template #item.alsoUseForDAP="{ item }">
        <v-icon :color="item.alsoUseForDAP ? 'success' : 'error'"
          >{{ item.alsoUseForDAP ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>
    </CrudDataTable>

    <ValidationObserver v-slot="{ invalid }" slim>
      <ConfirmDialog
        v-if="showDialog && formData"
        v-model="showDialog"
        title="Create/edit item"
        :is-loading="dialogLoading"
        :is-confirm-disabled="invalid"
        @close="(currentItem = null), (formData = null)"
        @confirm="onConfirmed"
      >
        <ValidationProvider
          v-slot="{ errors }"
          name="Customer"
          rules="required"
        >
          <v-autocomplete
            v-model="formData.customerId"
            :items="customers"
            :error-messages="errors"
            item-value="id"
            item-text="customerName"
            label="Customer"
            clearable
            dense
            outlined
            single-line
          ></v-autocomplete>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="Article descriptions">
          <v-combobox
            :value="formData.articleDescriptions"
            hide-selected
            label="Article descriptions"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            return-object
            item-text="description"
            :error-messages="errors"
            @input="onInput"
          ></v-combobox>
        </ValidationProvider>
        <v-row>
          <v-col cols="6">
            <ValidationProvider name="Enabled">
              <v-checkbox
                v-model="formData.enabled"
                :hide-details="true"
                label="Enabled"
                class="mt-0"
              ></v-checkbox>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider name="Use for DAP?">
              <v-checkbox
                v-model="formData.alsoUseForDAP"
                label="Use for DAP?"
                :hide-details="true"
                class="mt-0"
              ></v-checkbox>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider name="Transport costs">
              <v-text-field
                v-model="formData.transportCosts"
                type="number"
                min="0"
                max="2147483647"
                step="0.01"
                label="Transport costs"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider name="Transport cost currency">
              <v-autocomplete
                v-model="formData.transportCostsCurrency"
                label="Transport costs currency"
                item-text="name"
                item-value="name"
                :items="currencies"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider name="Value">
              <v-text-field
                v-model="formData.value"
                type="number"
                min="0"
                max="2147483647"
                step="0.01"
                label="Value"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider name="Value currency">
              <v-autocomplete
                v-model="formData.valueCurrency"
                label="Value currency"
                item-text="name"
                item-value="name"
                :items="currencies"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ConfirmDialog>
    </ValidationObserver>
  </Fragment>
</template>
<script setup lang="ts">
import { useDataTableDefaults } from "@/composables/dataTable";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ValidationObserver } from "vee-validate";
import { onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import CrudDataTable from "@/components/CrudDataTable.vue";
import { useFormReset } from "@/composables/formReset";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { determineTableHeightCalc } from "@/helpers/dataTableHelper";
import {
  DocumentShipmentArticleDescriptionDto,
  DocumentShipmentInfo,
  DocumentShipmentsApi,
  EcomCustomerApi,
  EcomCustomerOverview,
  DataApi,
  CurrencyViewModel,
  CurrencyApi,
} from "@/openapi";

interface DocumentShipmentForm {
  customerId?: number;
  enabled: boolean;
  articleDescriptions: DocumentShipmentArticleDescriptionDto[];
  transportCosts: number;
  transportCostsCurrency: string;
  value: number;
  valueCurrency: string;
  alsoUseForDAP: boolean;
}

const documentShipmentsApi = new DocumentShipmentsApi(undefined, "");
const customerApi = new EcomCustomerApi(undefined, "");
const dataApi = new DataApi(undefined, "");
const currencyApi = new CurrencyApi(undefined, "");

const defaultFormValues: DocumentShipmentForm = {
  enabled: false,
  articleDescriptions: [],
  transportCosts: 0,
  transportCostsCurrency: "",
  value: 0,
  valueCurrency: "",
  alsoUseForDAP: false,
};

const headers = ref<DataTableHeader[]>([
  {
    text: "Customer",
    value: "customerName",
    sortable: false,
  },
  {
    text: "Enabled",
    value: "enabled",
    sortable: false,
  },
  {
    text: "Article descriptions",
    value: "articleDescriptions",
    sortable: false,
  },
  {
    text: "Transport costs",
    value: "transportCosts",
    sortable: false,
  },
  {
    text: "Transport cost currency",
    value: "transportCostsCurrency",
    sortable: false,
  },
  {
    text: "Value",
    value: "value",
    sortable: false,
  },
  {
    text: "Value currency",
    value: "valueCurrency",
    sortable: false,
  },
  {
    text: "Also for DAP?",
    value: "alsoUseForDAP",
    sortable: false,
  },
]);

const { options, footerOptions, items, loading, totalAmountOfItems } =
  useDataTableDefaults<DocumentShipmentInfo>();
const customers = ref<EcomCustomerOverview[]>([]);

const observer = ref<InstanceType<typeof ValidationObserver>>();
const showDialog = ref(false);
const dialogLoading = ref(false);
const currentItem = ref<DocumentShipmentInfo | null>();
const formData = ref<DocumentShipmentForm | null>({
  ...defaultFormValues,
});
useFormReset(showDialog, formData, defaultFormValues, observer);

const tableHeightCalc = ref<string>(determineTableHeightCalc(187));

const currencies = ref<CurrencyViewModel[]>([]);

watch(
  () => options.value,
  async (newValue: DataOptions, oldValue: DataOptions) => {
    loading.value = true;
    await getItems();
    loading.value = false;
  },
  { deep: true },
);

const getArticleDescriptionText = (item: DocumentShipmentInfo) => {
  return item.articleDescriptions?.map((c) => c.description).join(", ") ?? "";
};

const onCreateItem = () => {
  formData.value = { ...defaultFormValues };
  showDialog.value = true;
};

const onEditItem = (item: DocumentShipmentInfo) => {
  currentItem.value = item;
  formData.value = {
    customerId: item.customerId,
    enabled: item.enabled ?? false,
    articleDescriptions: item.articleDescriptions ?? [],
    alsoUseForDAP: item.alsoUseForDAP,
    transportCosts: item.transportCosts ?? 0,
    transportCostsCurrency: item.transportCostsCurrency ?? "",
    value: item.value ?? 0,
    valueCurrency: item.valueCurrency ?? "",
  };
  showDialog.value = true;
};

const onConfirmed = async () => {
  let success = false;
  if (currentItem.value) {
    success = await editItem();
  } else {
    success = await createItem();
  }

  if (success) {
    loading.value = true;
    await getItems();
    loading.value = false;
  }
};

const onDeleteItem = async (item: DocumentShipmentInfo) => {
  currentItem.value = item;
  if (await deleteItem(item)) {
    currentItem.value = null;
    loading.value = true;
    await getItems();
    loading.value = false;
  }
};

const getItems = async () => {
  try {
    const response = await documentShipmentsApi.getDocumentShipmentInfo(
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while retrieving the items");
  }
};

const createItem = async () => {
  if (!formData.value) {
    return false;
  }
  dialogLoading.value = true;
  try {
    await documentShipmentsApi.addDocumentShipmentInfo({
      customerId: formData.value.customerId!,
      enabled: formData.value.enabled,
      articleDescriptions: formData.value.articleDescriptions.map(
        (c) => c.description!,
      ),
      alsoUseForDAP: formData.value.alsoUseForDAP,
      transportCosts: Number(formData.value.transportCosts),
      transportCostsCurrency: formData.value.transportCostsCurrency,
      value: Number(formData.value.value),
      valueCurrency: formData.value.valueCurrency,
    });
    showDialog.value = false;
    emitSuccess("Successfully created the item");
    return true;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while creating the item");
    return false;
  } finally {
    dialogLoading.value = false;
  }
};

const editItem = async () => {
  if (!currentItem.value?.id || !formData.value) {
    return false;
  }

  dialogLoading.value = true;
  try {
    await documentShipmentsApi.updateDocumentShipmentInfo({
      id: currentItem.value.id!,
      customerId: formData.value.customerId!,
      enabled: formData.value.enabled,
      articleDescriptions: formData.value.articleDescriptions,
      alsoUseForDAP: formData.value.alsoUseForDAP,
      transportCosts: Number(formData.value.transportCosts),
      transportCostsCurrency: formData.value.transportCostsCurrency,
      value: Number(formData.value.value),
      valueCurrency: formData.value.valueCurrency,
    });
    currentItem.value = null;
    showDialog.value = false;
    emitSuccess("Successfully edited the item");
    return true;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while editing the item");
    return false;
  } finally {
    dialogLoading.value = false;
  }
};

const deleteItem = async (item: DocumentShipmentInfo) => {
  loading.value = true;
  try {
    await documentShipmentsApi.deleteDocumentShipmentInfo(item.id!);
    emitSuccess("Successfully deleted the item");
    return true;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while deleting the item");
    return false;
  } finally {
    loading.value = false;
  }
};

const onInput = (value: unknown[]) => {
  formData.value!.articleDescriptions =
    value?.map((c): DocumentShipmentArticleDescriptionDto => {
      if (typeof c === "string") {
        return <DocumentShipmentArticleDescriptionDto>{
          id: null,
          description: c,
        };
      } else {
        return <DocumentShipmentArticleDescriptionDto>c;
      }
    }) ?? [];
};

const getCustomers = async () => {
  try {
    const response = await customerApi.getEcomCustomers(
      undefined,
      undefined,
      undefined,
      1,
      -1,
    );
    customers.value = response.data.items ?? [];
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the customers",
    );
  }
};

const getCurrencies = async () => {
  try {
    const response = await dataApi.getDataCurrencies();
    currencies.value = response.data;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the currencies",
    );
  }
};

onBeforeMount(async () => {
  loading.value = true;
  await Promise.all([getItems(), getCustomers(), getCurrencies()]);
  loading.value = false;
});
</script>
