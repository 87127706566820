<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1"
          :loading="isLoading"
        >
          <template #top="{ header }">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                class="mr-2"
                :disabled="disableNewItemButton"
                @click="addNewItem"
              >
                <v-icon left>mdi-plus</v-icon>
                Create
              </v-btn>
            </v-toolbar>
          </template>

          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty, validateWithInfo }"
                :ref="'observer-' + item.id"
                slim
              >
                <tr>
                  <td>
                    <ValidationProvider name="Keyword" rules="required" slim>
                      <EditableTextField
                        v-model="item.keyword"
                        class="my-2"
                        placeholder="Keyword"
                      ></EditableTextField>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="Lookup in sender">
                      <v-checkbox
                        v-model="item.lookupInSender"
                        class="my-2"
                        hide-details
                      >
                      </v-checkbox>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="Lookup in consignee">
                      <v-checkbox
                        v-model="item.lookupInConsignee"
                        class="my-2"
                        hide-details
                      >
                      </v-checkbox>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider name="Customer">
                      <EditableDropdown
                        v-model="item.customerId"
                        class="my-2"
                        :items="customers"
                        :return-object="false"
                      ></EditableDropdown>
                    </ValidationProvider>
                  </td>
                  <td>
                    <div class="d-flex justify-end">
                      <v-btn
                        class="mr-2"
                        color="success"
                        :disabled="invalid || !dirty || item.isDeleting"
                        :loading="item.isSaving"
                        small
                        @click="onSave(item, validateWithInfo)"
                        ><v-icon left>mdi-floppy</v-icon>Save</v-btn
                      >
                      <v-btn
                        color="error"
                        small
                        :disabled="item.isDeleting"
                        :loading="item.isDeleting"
                        @click="onDelete(item)"
                        ><v-icon left> mdi-delete </v-icon>Delete</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomerViewModel,
  CustomsKeywordApi,
  CustomsKeywordViewModel,
  KeyValueItem,
} from "@/openapi";
import { computed, onBeforeMount, ref } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { useCrudPage } from "@/composables/crudPage";
import { FooterOptions } from "@/types/types";
import EditableTextField from "@/components/EditableTextField.vue";
import EditableDropdown from "@/components/EditableDropdown.vue";
import store from "@/store";
import { DataAction } from "@/store/dataModule";

interface EnrichedCustomsKeywordViewmodel extends CustomsKeywordViewModel {
  isSaving: boolean;
  isDeleting: boolean;
}

const api = new CustomsKeywordApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);

const {
  items,
  disableNewItemButton,
  isLoading,
  addNewItem,
  deleteItem,
  isNewItem,
} = useCrudPage<EnrichedCustomsKeywordViewmodel>(
  {
    id: 0,
    keyword: "",
    lookupInSender: false,
    lookupInConsignee: false,
    isSaving: false,
    isDeleting: false,
  },
  "id",
  0,
);

const headers = ref<DataTableHeader[]>([
  { text: "Keywords", value: "keywords" },
  { text: "Lookup in sender", value: "lookupInSender" },
  { text: "Lookup in consignee", value: "lookupInConsignee" },
  { text: "Customer", value: "customerId" },
  { text: " ", value: "actions", sortable: false, width: "250px" },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const isSaving = ref(false);
const isDeleting = ref(false);

const customers = computed((): KeyValueItem[] => {
  const customers = store.getters.generalCustomers as CustomerViewModel[];
  return (
    customers?.map(
      (c) =>
        ({
          key: c.id,
          value: c.shortName,
        }) as KeyValueItem,
    ) ?? []
  );
});

const loadItems = async () => {
  isLoading.value = true;

  try {
    const response = await api.getCustomsKeywords();
    items.value =
      response.data.map(
        (item) =>
          ({
            ...item,
            isSaving: false,
            isDeleting: false,
          }) as EnrichedCustomsKeywordViewmodel,
      ) ?? [];
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while retrieving the keywords",
    );
  }
  isLoading.value = false;
};

const onSave = async (
  item: EnrichedCustomsKeywordViewmodel,
  validate: () => Promise<boolean>,
) => {
  const isValid = await validate();
  if (!isValid) {
    return;
  }

  await saveCustomsKeyword(item);
};

const saveCustomsKeyword = async (item: EnrichedCustomsKeywordViewmodel) => {
  isSaving.value = true;
  item.isSaving = true;
  try {
    await api.saveCustomsKeyword({
      id: item.id,
      keyword: item.keyword ?? undefined,
      lookupInSender: item.lookupInSender,
      lookupInConsignee: item.lookupInConsignee,
      customerId: item.customerId ?? undefined,
    });
    emitSuccess("Successfully saved the keyword");
    if (isNewItem(item)) {
      disableNewItemButton.value = false;
    }
    items.value = [];
    await loadItems();
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while saving the keyword",
    );
  }
  isSaving.value = false;
  item.isSaving = false;
};

const onDelete = async (item: EnrichedCustomsKeywordViewmodel) => {
  isDeleting.value = true;
  item.isDeleting = true;
  if (isNewItem(item)) {
    deleteItem(item);
    item.isDeleting = false;
    isDeleting.value = false;
    disableNewItemButton.value = false;
    return;
  }

  await deleteCustomsKeyword(item);
  item.isDeleting = false;
  isDeleting.value = false;
};

const deleteCustomsKeyword = async (item: EnrichedCustomsKeywordViewmodel) => {
  try {
    await api.deleteCustomsKeyword(item.id);
    emitSuccess("Succesfully deleted the keyword");
    loadItems();
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Someting went wrong while deleting the keyword",
    );
  }
};

const refresh = () => {
  loadItems();
  disableNewItemButton.value = false;
};

onBeforeMount(async () => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Keywords", toolbarButtons);
  await loadItems();

  store.dispatch(DataAction.FetchGeneralCustomers);
});
</script>
