<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalAmountOfItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          class="elevation-1 data-table_default-height"
          :loading="loading"
        >
          <template #item.enabled="{ item }"
            ><v-icon :color="item.enabled ? 'success' : 'error'"
              >{{ item.enabled ? "mdi-check" : "mdi-close" }}
            </v-icon></template
          >
          <template #header.add="{ header }">
            <v-btn icon color="primary" @click="addNewItem">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #item.actions="{ item }">
            <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small class="ml-6" @click="removeItem(item)"
              >mdi-delete</v-icon
            >
          </template></v-data-table
        >
      </v-col>
    </v-row>

    <EditCustomsIossNumberDialog
      :show="showDialogItem"
      :loading="loadingPopup"
      :item.sync="selectedItem"
      @close="closeDialog"
      @save="saveItem"
    ></EditCustomsIossNumberDialog>
    <DeleteCustomsIossNumberDialog
      :show="showDialogDelete"
      :loading="loadingPopup"
      :item.sync="selectedItem"
      @close="closeDialog"
      @delete="deleteItem"
    ></DeleteCustomsIossNumberDialog>
  </v-container>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { emitError, emitSuccess } from "@/event-bus";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";
import { CustomsIossNumbersModel, IossNumbersApi } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
import EditCustomsIossNumberDialog from "./components/dialogs/EditCustomsIossNumberDialog.vue";
import DeleteCustomsIossNumberDialog from "./components/dialogs/DeleteCustomsIossNumberDialog.vue";

const dataStore = useDataStore();
const iossNumbersApi = new IossNumbersApi(undefined, "");
enum Dialogs {
  Item = 0,
  Delete = 1,
}
const headers = ref<DataTableHeader[]>([
  { text: " ", value: "add", sortable: false, width: "6em" },
  {
    text: "Customer",
    value: "customer",
    align: "start",
    width: 300,
    sortable: true,
  },
  { text: "IOSS Number", value: "iossNumber", sortable: false },
  {
    text: "Enabled",
    value: "enabled",
    sortable: false,
  },
  {
    text: " ",
    value: "actions",
    sortable: false,
    width: "12em",
  },
]);
const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100],
  disablePagination: false,
});
const defaultItem = {
  id: 0,
  customerId: 0,
  iossNumber: "",
  enabled: true,
};
const selectedItem = ref<CustomsIossNumbersModel>({ ...defaultItem });
const items = ref<CustomsIossNumbersModel[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const loadingPopup = ref(false);

const selectedDialog = ref<number | null>(null);

const showDialogItem = computed({
  get() {
    return selectedDialog.value === Dialogs.Item;
  },
  set() {
    selectedDialog.value = null;
  },
});

const showDialogDelete = computed({
  get() {
    return selectedDialog.value === Dialogs.Delete;
  },
  set() {
    selectedDialog.value = null;
  },
});

const closeDialog = () => {
  selectedDialog.value = null;
};

const getIossNumbers = async (page?: number) => {
  page ??= options.value.page;

  loading.value = true;
  try {
    const response = await iossNumbersApi.getCustomsIossNumbers(
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went while retrieving the jobs");
  }
  loading.value = false;
};

const addNewItem = () => {
  selectedItem.value = { ...defaultItem };
  selectedDialog.value = Dialogs.Item;
};

const editItem = (item: CustomsIossNumbersModel) => {
  selectedItem.value = { ...item };
  selectedDialog.value = Dialogs.Item;
};

const removeItem = (item: CustomsIossNumbersModel) => {
  selectedItem.value = { ...item };
  selectedDialog.value = Dialogs.Delete;
};

const deleteItem = (item: CustomsIossNumbersModel) => {
  iossNumbersApi
    .deleteCustomsIossNumber(item.id)
    .then(() => {
      emitSuccess("The item has been deleted successfully");
      selectedDialog.value = null;
      getIossNumbers();
    })
    .catch(() => {
      emitError("Something went wrong while deleting the item");
    });
};

const saveItem = async (item: CustomsIossNumbersModel) => {
  iossNumbersApi
    .saveCustomsIossNumber(item)
    .then(() => {
      emitSuccess("The item has been saved successfully");
      selectedDialog.value = null;
      getIossNumbers();
    })
    .catch(() => {
      emitError("Something went wrong while saving the item");
    });
};

watch(
  () => options.value,
  () => {
    getIossNumbers();
  },
);

onMounted(() => {
  dataStore.fetchGeneralCustomers();
  getIossNumbers();
});
</script>
