<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="dataTableItems"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
    >
      <template #item.multiPieceComplete="{ item }">
        <v-icon :color="item.multiPieceComplete ? 'success' : 'error'"
          >{{ item.multiPieceComplete ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>

      <template #item.exportMrn="{ item }">
        <v-icon
          v-if="!item.exportMrn && hasShipmentRenderCategory(item.category)"
          class="error--text"
          @click="openAddExportMrnDialog(item)"
        >
          mdi-plus-circle
        </v-icon>
        <span v-else> {{ item.exportMrn }} </span>
      </template>

      <template #item.category="{ item }">
        <span>{{ getCategoryName(item.category) }} </span>
      </template>

      <template #item.actions="{ item }">
        <div class="consolidation-shipment">
          <v-btn
            v-if="!item.dakosyErrorMessage"
            :color="item.dakosyButtonColor"
            :loading="item.dakosyButtonLoading"
            :disabled="!hasShipmentRenderCategory(item.category)"
            class="ml-4 export-shipments-high__export-dakosy"
            small
            @click="exportToDakosy(item)"
          >
            <v-icon left dark> {{ item.dakosyButtonIcon }} </v-icon
            >{{ item.dakosyButtonText }}</v-btn
          >
          <v-tooltip v-else right>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :color="item.dakosyButtonColor"
                :loading="item.dakosyButtonLoading"
                class="export-shipments-high__export-dakosy"
                v-on="on"
                @click="exportToDakosy(item)"
              >
                <v-icon left dark> {{ item.dakosyButtonIcon }} </v-icon
                >{{ item.dakosyButtonText }}</v-btn
              >
            </template>
            <span>{{ item.dakosyErrorMessage }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <AddMrnDialog
      v-if="showAddExportMrnDialog"
      v-model="showAddExportMrnDialog"
      :is-loading="addExportMrnLoading"
      @confirm="onConfirmAddExportMrn"
    ></AddMrnDialog>

    <DakosyExportAusfuhrInfoDialog
      v-if="currentItem"
      v-model="showDialogAusfuhrInfo"
      :shipment-id="currentItem.id"
      :is-processing="currentItem.dakosyButtonLoading"
      @close="(currentItem = null), (showDialogAusfuhrInfo = false)"
      @confirm="exportToDakosyConfirm"
    ></DakosyExportAusfuhrInfoDialog>
  </Fragment>
</template>
<script setup lang="ts">
import { emitError, emitErrorWithFallback } from "@/event-bus";
import {
  CreateDakosyExportRequestArticle,
  CustomsHandlingConsolidationViewModel,
  Int32StringKeyValueItem,
  ShipmentApi,
} from "@/openapi";
import { ref, computed, watch, onBeforeMount } from "vue";
import store from "@/store";
import { DataOptions, DataTableHeader } from "vuetify";
import { downloadFile } from "@/helpers/downloadHelper";
import { DataAction } from "@/store/dataModule";
// Import the component and the interface
import AddMrnDialog, {
  AddMrnForm,
} from "@/components/dialogs/AddMrnDialog.vue";

import { DakosyExportData } from "@/types/types";
import DakosyExportAusfuhrInfoDialog from "@/components/dialogs/DakosyExportAusfuhrInfoDialog.vue";

interface IProps {
  consolidation: string;
}

interface DakosyExportButtonProperties {
  dakosyButtonIcon?: string;
  dakosyButtonColor?: string;
  dakosyButtonText?: string;
  dakosyButtonLoading?: boolean;
}

type EnrichedCustomsHandlingConsolidationViewModel =
  CustomsHandlingConsolidationViewModel & DakosyExportButtonProperties;

const props = defineProps<IProps>();
const DDPHOLDSHIPMENTABOVE1000 = ref(1);
const DAPHOLDSHIPMENTABOVE1000 = ref(8);

const api = new ShipmentApi(undefined, "");

const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    align: "start",
    value: "hawb",
    groupable: false,
    sortable: false,
  },
  { text: "Pieces", value: "pieces", groupable: false, sortable: false },
  { text: "Weight", value: "weight", groupable: false, sortable: false },
  {
    text: "Multi piece complete",
    value: "multiPieceComplete",
    groupable: false,
    sortable: false,
  },
  {
    text: "Export MRN",
    value: "exportMrn",
    groupable: false,
    sortable: false,
  },
  { text: "Value", value: "value", groupable: false, sortable: false },
  {
    text: "Value currency",
    value: "valueCurrency",
    groupable: false,
    sortable: false,
  },
  {
    text: "Warehouse location",
    value: "warehouseLocation",
    groupable: false,
    sortable: false,
  },
  {
    text: "Incoterm",
    value: "incoterm",
    groupable: false,
    sortable: false,
  },
  {
    text: "Category",
    value: "category",
    groupable: false,
    sortable: false,
  },
  { text: "", value: "actions", align: "end", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalAmountOfItems = ref<number>(0);

const isLoading = ref(false);
const items = ref<EnrichedCustomsHandlingConsolidationViewModel[]>([]);
const currentItem = ref<EnrichedCustomsHandlingConsolidationViewModel | null>(
  null,
);

const dakosyExportStatus = ref({
  createExport: 1,
  createdExport: 2,
  sendToDakosy: 3,
  error: 4,
});

const consolidationLoading = ref(false);
const addExportMrnLoading = ref(false);
const showAddExportMrnDialog = ref(false);
const showDialogAusfuhrInfo = ref(false);

watch(
  () => props.consolidation,
  (newVal) => {
    if (newVal) {
      onConsolidationLoad();
    }
  },
);

let shipmentOptionsTimeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    if (consolidationLoading.value) {
      return;
    }

    clearTimeout(shipmentOptionsTimeoutDelay);
    shipmentOptionsTimeoutDelay = setTimeout(() => {
      getShipmentsOfConsolidation();
    }, 250);
  },
);

const onConsolidationLoad = async () => {
  consolidationLoading.value = true;
  items.value = [];
  await getShipmentsOfConsolidation(1);
  consolidationLoading.value = false;
};

onBeforeMount(async () => {
  await store.dispatch(DataAction.FetchCustomsHandlingCategories);
});

const getShipmentsOfConsolidation = async (page?: number) => {
  page ??= options.value.page;
  isLoading.value = true;
  try {
    const response = await api.getShipmentsOfConsolidation(
      props.consolidation,
      undefined,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the shipments.");
  }

  isLoading.value = false;
};

const getCategoryName = (category: number) => {
  return categories.value.find((c) => c.key === category)?.value ?? "";
};

const categories = computed((): Int32StringKeyValueItem[] => {
  return store.getters.customsHandlingCategories;
});

const dataTableItems = computed(() => {
  return items.value.map((c) => ({
    ...c,
    ...getDakosyButtonProperties(c.dakosyExportStatus),
  }));
});

const exportConsolidationWithConsol = async (id: number) => {
  try {
    const response = await api.exportShipment(
      { hasConsolidation: true, shipmentIds: [id] },
      {
        responseType: "blob",
      },
    );

    downloadFile(response.data, "Consolidated-" + Date.now() + ".xlsx");
  } catch {
    emitError("Something went wrong while creating the export declaration.");
  }
};

const hasShipmentRenderCategory = (category: number) => {
  return (
    category == DDPHOLDSHIPMENTABOVE1000.value ||
    category == DAPHOLDSHIPMENTABOVE1000.value
  );
};

const getDakosyButtonProperties = (status: number) => {
  let dakosyButtonColor: string = "";
  let dakosyButtonIcon: string = "";
  let dakosyButtonText: string = "";

  if (status === dakosyExportStatus.value.createExport) {
    dakosyButtonColor = "primary";
    dakosyButtonIcon = "mdi-export-variant";
    dakosyButtonText = "Create Dakosy export";
  } else if (status === dakosyExportStatus.value.createdExport) {
    dakosyButtonColor = "blue";
    dakosyButtonIcon = "mdi-sort-clock-descending-outline";
    dakosyButtonText = "Created export";
  } else if (status === dakosyExportStatus.value.sendToDakosy) {
    dakosyButtonColor = "success";
    dakosyButtonIcon = "mdi-check";
    dakosyButtonText = "Send to Dakosy";
  } else if (status === dakosyExportStatus.value.error) {
    dakosyButtonColor = "error";
    dakosyButtonIcon = "mdi-alert-circle-outline";
    dakosyButtonText = "Error";
  }

  return {
    dakosyButtonColor: dakosyButtonColor,
    dakosyButtonIcon: dakosyButtonIcon,
    dakosyButtonText: dakosyButtonText,
    dakosyButtonLoading: false,
  } as DakosyExportButtonProperties;
};

const exportToDakosy = async (
  item: EnrichedCustomsHandlingConsolidationViewModel,
) => {
  if (item.dakosyExportStatus !== dakosyExportStatus.value.createExport) {
    return;
  }

  currentItem.value = item;
  showDialogAusfuhrInfo.value = true;
  item.dakosyButtonLoading = false;
};

const exportToDakosyConfirm = async (data: DakosyExportData) => {
  if (!currentItem.value) {
    return;
  }

  currentItem.value.dakosyButtonLoading = true;
  try {
    await api.createExportDeclaration({
      endDate: data.endDate ?? "",
      presentationDate: data.presentationDate ?? "",
      shipmentId: currentItem.value.id,
      useShipperAddress: data.useShipperAddress,
      abdEmail: data.aBDEmail,
      articles: data.articles.map(
        (c) =>
          ({
            id: c.id,
            wtn: c.wtn,
            goodsDescription: c.goodsDescription,
            ownWeight: c.ownWeight,
            grossWeight: c.grossWeight,
            rchsCode: c.rchsCode,
            invoiceAmount: c.invoiceAmount,
            invoiceAmountCurrency: c.invoiceCurrency,
            freightCostAmount: c.freightCostsAmount,
            freightCostAmountCurrency: c.freightCostsCurrency,
            statAmount: c.statAmount,
            statAmountUnit: c.statUnit,
          }) as CreateDakosyExportRequestArticle,
      ),
      ausfuhrInfoId: data.addressId,
      ausfuhrzollstelle: data.ausfuhrzollstelle,
      avmEmail: data.aVMEmail,
      remarkSpecialCircumstancesCharacteristics:
        data.remarkSpecialCircumstancesCharacteristics,
      specialCircumstancesCharacteristics:
        data.specialCircumstancesCharacteristics,
      vorgeseheneAusgangszollstelle: data.vorgeseheneAusgangszollstelle,
      zusatz: data.zusatz,
    });
    const currentId = currentItem.value.id;
    const foundItem = items.value.find((c) => c.id === currentId);
    if (foundItem) {
      foundItem.dakosyExportStatus = dakosyExportStatus.value.createdExport;
    }
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while during the Dakosy export",
    );
  } finally {
    showDialogAusfuhrInfo.value = false;
    currentItem.value.dakosyButtonLoading = false;
    currentItem.value = null;
  }
};

const openAddExportMrnDialog = (
  item: EnrichedCustomsHandlingConsolidationViewModel,
) => {
  currentItem.value = item;
  showAddExportMrnDialog.value = true;
};

const onConfirmAddExportMrn = async (formData: AddMrnForm) => {
  try {
    addExportMrnLoading.value = true;

    if (currentItem.value === null) {
      emitError(...["Current item is null. This shouldn't happen."]);
      return;
    }

    await api.addExportMrnToConsolidationShipment({
      exportMrn: formData.mrn,
      shipmentId: currentItem.value?.id ?? 0,
    });

    currentItem.value!.exportMrn = formData.mrn;
    currentItem.value = null;
    showAddExportMrnDialog.value = false;
  } catch {
    emitError("Something went wrong while adding the MRN.");
  }
  addExportMrnLoading.value = false;
};
</script>
