<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text class="justify-center">
        <v-card class="mt-5 pa-5" color="#FFF8E1" max-width="600">
          <v-row no-gutters>
            <v-col class="font-weight-medium">Paketscheinnummer</v-col>
            <v-col>{{ parcelNumber }}</v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        v-model="formModel.mrn"
                        background-color="white"
                        outlined
                        dense
                        label="Movement Reference Number (MRN)"
                        placeholder="Please enter a Movement Reference Number (MRN)"
                        :rules="[rules.mrn]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="primary"
                        :loading="isLoading"
                        :disabled="!valid"
                        @click="emits('confirm', { ...formModel })"
                      >
                        {{ confirmButtonText }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
          >{{ closeButtonText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
export interface AddMrnForm {
  mrn: string | null;
}

import { ref, watch } from "vue";

interface IProps {
  value?: boolean | null;
  isLoading?: boolean | null;
  title?: string | null;
  confirmButtonText?: string | null;
  closeButtonText?: string | null;
  parcelNumber?: string | null;
  initialMrn?: string | null;
}
const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Shipment add MRN",
  confirmButtonText: "Save",
  closeButtonText: "Close",
  parcelNumber: "",
  initialMrn: "",
});
const emits = defineEmits(["input", "close", "confirm"]);
const form = ref(null);

const rules = {
  mrn: (value: string) => {
    const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
    return pattern.test(value) || "Invalid MRN number";
  },
};

const valid = ref(true);
const formModel = ref<AddMrnForm>({
  mrn: "",
});

watch(
  () => props.initialMrn,
  (newVal) => {
    formModel.value.mrn = newVal;
  },
);

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      formModel.value.mrn = "";
      (
        form.value! as Vue & { resetValidation: () => boolean }
      ).resetValidation();
    }
  },
);
</script>
