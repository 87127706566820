<template>
  <Fragment>
    <v-row class="mb-5">
      <v-col cols="4">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          @keyup.enter="onFilterSelected"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="consolidationHeaders"
      :items="consolidations"
      item-key="name"
      height="calc(100vh - 330px)"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
      show-expand
      :single-expand="true"
      :expanded.sync="expandedConsolidations"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
    >
      <template #item.date="{ item }">
        {{ moment(item.date).format("DD-MM-YYYY") }}
      </template>

      <template #item.type="{ item }">
        <td @click="onClickType(item)">
          <span
            :class="{
              'text-decoration-underline': item.canCreateT1 || item.canCreateT2,
              interactive: item.canCreateT1 || item.canCreateT2,
            }"
          >
            {{ item.type }}
          </span>
        </td>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          color="primary"
          small
          class="mr-3"
          @click="enterExportMrn(item.name)"
        >
          <v-icon color="black" small>mdi-file-move</v-icon>
          Enter Export MRN
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!item.canCreateT1 && !item.canCreateT2"
          small
          @click="openT1T2CreationDialog(item)"
          >Create T1/T2</v-btn
        >
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ShipmentsOfConsolidation
            :consolidation="item.name"
          ></ShipmentsOfConsolidation>
        </td>
      </template>
    </v-data-table>
    <CreateTransitDeclarationDialog
      v-model="showT1OrT2Dialog"
      :create-transit-declaration-loading="createT1OrT2Loading"
      :hide-transit-definition-section="false"
      @confirm="createT1OrT2"
      @close="onDialogClose"
    >
      <template #header> {{ createT1OrT2DialogTitle }} </template>
    </CreateTransitDeclarationDialog>
    <v-dialog
      v-if="showExportMrnModal"
      v-model="showExportMrnModal"
      persistent
      max-width="500"
    >
      <ValidationObserver v-slot="{ invalid }" slim>
        <v-card>
          <v-card-title class="text-h5"> Export MRN </v-card-title>
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Export MRN number"
            >
              <v-text-field
                v-model="exportMrn.mrnNumber"
                label="Export MRN number"
                single-line
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="MAWN"
            >
              <v-text-field
                v-model="exportMrn.mawb"
                label="MAWB"
                single-line
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|numeric|max:7"
              name="Gateway Export"
            >
              <v-text-field
                v-model="exportMrn.gatewayExport"
                label="Gateway Export"
                single-line
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Sealnumber"
            >
              <v-text-field
                v-model="exportMrn.sealNumber"
                label="Sealnumber"
                single-line
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Registration number"
            >
              <v-text-field
                v-model="exportMrn.registrationNumber"
                label="Registration number"
                single-line
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <v-checkbox
              v-model="exportMrn.returnShipmentLoaded"
              class="mt-0 pt-0"
              label="Don't set return shipment loaded (outbound) milestone"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn text :loading="exportMrnLoading" @click="cancelExportMrn">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="invalid"
              :loading="exportMrnLoading"
              @click="createExportMrn"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <ValidationObserver v-slot="{ invalid }" slim>
      <ConfirmDialog
        v-model="showConsolidationTypeDialog"
        title="Edit type"
        confirm-button-text="Save"
        :is-loading="updateConsolidationTypeLoading"
        :is-confirm-disabled="invalid"
        @confirm="onConsolidationTypeUpdate"
        @cancel="resetConsolidationUpdateValues"
      >
        <ValidationProvider name="Type" rules="required">
          <v-select
            v-model="newConsolidationType"
            :items="consolidationTypes"
            item-text="name"
            item-value="name"
            label="Type"
            single-line
          ></v-select>
        </ValidationProvider>
      </ConfirmDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from "@/event-bus";
import {
  CreateDeclarationsForConsolidationViewModel,
  ShipmentApi,
  ShipmentConsolidation,
} from "@/openapi";
import { ref, watch, computed, onBeforeMount, nextTick } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import moment from "moment";
import ShipmentsOfConsolidation from "./ShipmentsOfConsolidation.vue";
import { downloadFile } from "@/helpers/downloadHelper";
import CreateTransitDeclarationDialog, {
  CreateTransitDeclarationForm,
} from "@/components/dialogs/CreateTransitDeclarationDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { useDataStore } from "@/stores/data-store";
import { CONSOLIDATION_TYPE_T1 } from "@/constants/constants";

interface Filters {
  search?: string;
}

const api: ShipmentApi = new ShipmentApi(undefined, "");
const dataStore = useDataStore();

const consolidationHeaders = ref<DataTableHeader[]>([
  {
    text: "Consolidation name",
    align: "start",
    value: "name",
    width: 300,
    sortable: false,
  },
  {
    text: "Consolidation date",
    align: "start",
    value: "date",
    sortable: false,
  },
  { text: "Type", align: "start", value: "type", sortable: false },
  { text: "", align: "end", value: "actions", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);

const consolidations = ref<ShipmentConsolidation[]>([]);
const expandedConsolidations = ref<ShipmentConsolidation[]>([]);
const currentShipmentConsolidation = ref<ShipmentConsolidation | null>(null);

const exportMrn = {
  mrnNumber: "",
  mawb: "",
  gatewayExport: "",
  sealNumber: "",
  registrationNumber: "",
  returnShipmentLoaded: false,
};
const consolNrToExport = ref("");

const isLoading = ref(false);
const createT1Loading = ref(false);
const createT1OrT2Loading = ref(false);
const exportMrnLoading = ref(false);
const updateConsolidationTypeLoading = ref(false);

const showExportMrnModal = ref(false);
const showT1OrT2Dialog = ref(false);
const showConsolidationTypeDialog = ref(false);

const newConsolidationType = ref("");

const filters = ref<Filters>({
  search: "",
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getConsolidations();
    }, 250);
  },
);

onBeforeMount(async () => {
  await Promise.all([
    dataStore.fetchCustomsHandlingCategories(),
    dataStore.fetchConsolidationTypes(),
  ]);
});

const getConsolidations = async (page?: number) => {
  page ??= options.value.page;
  isLoading.value = true;
  try {
    const response = await api.getShipmentConsolidations(
      filters.value.search ?? undefined,
      page,
      options.value.itemsPerPage,
    );
    consolidations.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the consolidations.");
  }
  isLoading.value = false;
};

const reloadConsolidations = async () => {
  options.value.page == 1
    ? await getConsolidations(1)
    : (options.value.page = 1);
};

const onFilterSelected = () => {
  nextTick(() => {
    reloadConsolidations();
  });
};

const enterExportMrn = (consolNr: string) => {
  showExportMrnModal.value = true;

  consolNrToExport.value = consolNr;
};
const createExportMrn = async () => {
  showExportMrnModal.value = false;
  exportMrnLoading.value = true;
  try {
    const response = await api.exportMRN(
      consolNrToExport.value,
      exportMrn.mrnNumber,
      exportMrn.mawb,
      exportMrn.gatewayExport,
      exportMrn.sealNumber,
      exportMrn.registrationNumber,
      exportMrn.returnShipmentLoaded,
    );

    downloadFile(response.data, "CONSOL-" + Date.now());
    consolNrToExport.value = "";
  } catch {
    emitError(
      'Something went wrong while during the "Enter Export MRN" process.',
    );
  }
  exportMrnLoading.value = false;
};

const cancelExportMrn = () => {
  showExportMrnModal.value = false;
};

const openT1T2CreationDialog = (item: ShipmentConsolidation) => {
  showT1OrT2Dialog.value = true;
  currentShipmentConsolidation.value = item;
};

const onDialogClose = () => {
  currentShipmentConsolidation.value = null;
};

const onClickType = (currentShipmentConsolidation: ShipmentConsolidation) => {
  if (
    !currentShipmentConsolidation.canCreateT1 &&
    !currentShipmentConsolidation.canCreateT2
  ) {
    return;
  }
  newConsolidationType.value = currentShipmentConsolidation.type!;
  currentShipmentConsolidation = currentShipmentConsolidation;
  showConsolidationTypeDialog.value = true;
};

const onConsolidationTypeUpdate = async () => {
  if (!currentShipmentConsolidation.value) {
    return;
  }
  updateConsolidationTypeLoading.value = true;
  try {
    await api.updateConsolidationType({
      consolidationNumber: currentShipmentConsolidation.value?.name!,
      consolidationType: newConsolidationType.value,
    });

    emitSuccess("Successfully updated the type");
    showConsolidationTypeDialog.value = false;
    resetConsolidationUpdateValues();
    await getConsolidations();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while updating the type");
  }
  updateConsolidationTypeLoading.value = false;
};

const createT1OrT2 = async (formData: CreateTransitDeclarationForm) => {
  createT1OrT2Loading.value = true;

  try {
    var requestData: CreateDeclarationsForConsolidationViewModel = {
      amount: formData.amount!,
      consolidationNumber: currentShipmentConsolidation.value?.name,
      country: formData.country,
      registration: formData.registration,
      countryTruck: formData.countryTruck,
      registrationTruck: formData.registrationTruck,
      transportAcrossEuBorder: formData.transportAcrossEuBorder,
      signs: formData.signs.map((c) => c.value).filter((c) => !!c),
      routeId: formData.routeId!,
      templateId: formData.templateId!,
    };

    if (currentShipmentConsolidation.value?.canCreateT1) {
      await api.createT1DeclarationsForConsolidation(requestData);
    } else if (currentShipmentConsolidation.value?.canCreateT2) {
      await api.createT2DeclarationsForConsolidation(requestData);
    } else {
      emitError("No declaration can be created for this consolidation.");
      createT1OrT2Loading.value = false;
      return;
    }

    currentShipmentConsolidation.value = null;
    showT1OrT2Dialog.value = false;
    await getConsolidations();
  } catch {
    emitError("Something went wrong while creating the declaration.");
    createT1OrT2Loading.value = false;
  }
};

const resetConsolidationUpdateValues = () => {
  currentShipmentConsolidation.value = null;
  newConsolidationType.value = "";
};

const createT1OrT2DialogTitle = computed(() => {
  return currentShipmentConsolidation.value?.canCreateT1
    ? "Create T1 document"
    : currentShipmentConsolidation.value?.canCreateT2
      ? "Create T2 document"
      : "";
});

const consolidationTypes = computed(() => {
  if (currentShipmentConsolidation.value?.canOnlyChangeToT1) {
    return dataStore.consolidationTypes.filter(
      (c: string) => c == CONSOLIDATION_TYPE_T1,
    );
  }
  return dataStore.consolidationTypes;
});
</script>
