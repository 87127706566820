<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <DefaultDialog :value="value" @input="emits('input', $event)">
      <template #header
        ><slot name="header"> Create Transit Declaration </slot></template
      >
      <template #content>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Anzahl"
                >
                  <v-text-field
                    v-model.number="formData.amount"
                    type="number"
                    label="Anzahl"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Kennzeichen Anhänger"
                >
                  <v-text-field
                    v-model="formData.registration"
                    label="Kennzeichen Anhänger"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Kennzeichen Zugmaschine"
                >
                  <v-text-field
                    v-model="formData.registrationTruck"
                    label="Kennzeichen Zugmaschine"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Staat Anhänger"
                >
                  <v-autocomplete
                    v-model="formData.country"
                    :items="customsCountries"
                    :item-text="getCountryValueString"
                    item-value="code"
                    label="Staat Anhänger"
                    :loading="!customsCountries.length"
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Staat Zugmaschine"
                >
                  <v-autocomplete
                    v-model="formData.countryTruck"
                    label="Staat Zugmaschine"
                    :items="customsCountries"
                    :item-text="getCountryValueString"
                    item-value="code"
                    :loading="!customsCountries.length"
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="formData.transportAcrossEuBorder"
                  label="Transport überschreitet EU Außengrenze"
                ></v-checkbox>
              </v-col>
              <v-col v-if="!hideSignSection" cols="12">
                <strong>Zeichen</strong>
                <MultipleItemsEditor
                  v-model="formData.signs"
                  :constructor-fn="createSign"
                >
                  <template #editor="{ item, index }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      :name="`Zeichen ${index + 1}`"
                      :vid="'zeichen-' + index"
                      slim
                    >
                      <v-text-field
                        v-model="item.value"
                        :label="`Zeichen ${index + 1}`"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                </MultipleItemsEditor>
              </v-col>
              <v-col v-if="!hideTransitDefinitionSection" cols="12">
                <TransitDefinitionEditor
                  v-model="formData.routeId"
                ></TransitDefinitionEditor>
                <TransitTemplateEditor
                  v-model="formData.templateId"
                  :definition-route-id="formData.routeId ?? 0"
                ></TransitTemplateEditor>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template #footer>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="createTransitDeclarationLoading"
          @click="emits('input', false), emits('close')"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="createTransitDeclarationLoading"
          :disabled="invalid"
          @click="confirm"
        >
          Confirm
        </v-btn>
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from "vee-validate";
import { ref, watch, onBeforeMount, computed } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import TransitDefinitionEditor from "@/components/editors/TransitDefinitionEditor.vue";
import MultipleItemsEditor from "@/components/editors/MultipleItemsEditor.vue";
import { DataAction } from "@/store/dataModule";
import TransitTemplateEditor from "@/components/editors/TransitTemplateEditor.vue";
import { CountryOfOriginViewModel } from "@/openapi";
import store from "@/store";

export interface CreateTransitDeclarationForm {
  amount: number;
  registration: string;
  registrationTruck: string;
  country: string;
  countryTruck: string;
  transportAcrossEuBorder: boolean;
  signs: TransitDeclarationSign[];
  routeId: number | null;
  templateId: number | null;
}

export interface TransitDeclarationSign {
  value: string;
}

const sign: TransitDeclarationSign = {
  value: "",
};
const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
  createTransitDeclarationLoading: {
    type: Boolean,
    default: false,
  },
  hideSignSection: {
    type: Boolean,
    default: false,
  },
  hideTransitDefinitionSection: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["input", "close", "confirm"]);

const setDefaultFormValues = (): CreateTransitDeclarationForm => {
  return {
    amount: 0,
    registration: "",
    registrationTruck: "",
    country: "",
    countryTruck: "",
    transportAcrossEuBorder: false,
    signs: [{ ...sign }],
    routeId: null,
    templateId: null,
  };
};

const form = ref(null);
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);
const formData = ref<CreateTransitDeclarationForm>(setDefaultFormValues());

watch(
  () => props.value,
  (newVal) => {
    if (!newVal) {
      formData.value = setDefaultFormValues();
      observer.value!.reset();
    }
  },
);
watch(
  () => formData.value.routeId,
  (newVal) => {
    formData.value.templateId = null;
  },
);

onBeforeMount(async () => {
  await store.dispatch(DataAction.FetchCountriesOfOrigin);
});

const confirm = () => {
  emits(
    "confirm",
    JSON.parse(JSON.stringify(form)) as CreateTransitDeclarationForm,
  );
};

const createSign = (): TransitDeclarationSign => {
  return {
    value: "",
  };
};

const customsCountries = computed(() => {
  return store.getters.countriesOfOrigin;
});

const getCountryValueString = (country: CountryOfOriginViewModel) => {
  return `${country.code} (${country.description})`;
};
</script>
