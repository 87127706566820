<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <div class="article-maintenance">
          <v-autocomplete
            v-model="selectedCustomer"
            class="article-maintenance__filter"
            :items="customers"
            label="Customers"
            :disabled="loading"
            clearable
            @change="onFilterSelected"
          ></v-autocomplete>
          <SenderAutocomplete
            v-model="selectedSender"
            :is-export="false"
            :disabled="isGroupedByCustomer || loading"
            type="open"
            class="article-maintenance__filter"
            @change="onFilterSelected"
          ></SenderAutocomplete>
          <v-select
            v-model="selectedIncoterm"
            :items="incoterms"
            item-value="id"
            item-text="name"
            label="DAP/DDP"
            clearable
            :disabled="isGroupedByCustomer || loading"
            class="article-maintenance__filter"
            @change="onFilterSelected"
          >
          </v-select>
          <v-checkbox
            v-model="isGroupedByCustomer"
            label="Group by customer"
            :disabled="loading"
            @change="onFilterSelected"
          ></v-checkbox>
        </div>
      </template>

      <template #item.lastImportDate="{ item }">
        {{ item.lastImportDate | formatDate }}
      </template>

      <template #item.actions="{ item }">
        <v-btn color="primary" class="ma-2" small @click="fulfill(item)"
          >Fulfill</v-btn
        >
      </template>
    </v-data-table>
    <ArticleMaintenanceDialog
      v-if="showDialog"
      v-model="showDialog"
      :is-loading="dialogLoading"
      :initial-data="fulfillmentArticleData"
      @confirm="onFulfillConfirmed"
    >
    </ArticleMaintenanceDialog>
  </div>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  CustomsArticleMaintenanceApi,
  IncotermViewModel,
  OpenArticle,
} from "@/openapi";
import { DataAction } from "@/store/dataModule";
import { DefaultDataTableItem } from "@/types/types";
import { ref, watch, PropType, onBeforeMount, computed } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import ArticleMaintenanceDialog, {
  ArticleMaintenanceFulfillmentForm,
} from "./dialogs/ArticleMaintenanceDialog.vue";
import SenderAutocomplete from "./editors/SenderAutocomplete.vue";
import { useCrudPage } from "@/composables/crudPage";
import store from "@/store";

const api = new CustomsArticleMaintenanceApi(undefined, "");
const { items, totalAmountOfItems, isLoading, isNewItem, mapItem } =
  useCrudPage<OpenArticle>({ id: 0, count: 0 }, "id", 0);

const props = defineProps({
  customers: {
    type: Array as PropType<DefaultDataTableItem<string>[]>,
    default: () => [],
  },
  senders: {
    type: Array as PropType<DefaultDataTableItem<string>[]>,
    default: () => [],
  },
  incotermIds: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
});

const loading = ref(false);
const dialogLoading = ref(false);

const selectedCustomer = ref<string | null>(null);
const selectedSender = ref<string | null>(null);
const selectedIncoterm = ref<number | null>(null);
const isGroupedByCustomer = ref(false);

const currentItem = ref<OpenArticle | null>(null);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const showDialog = ref(false);

const fulfillmentArticleData = ref<ArticleMaintenanceFulfillmentForm | null>(
  null,
);

onBeforeMount(() => {
  store.dispatch(DataAction.FetchIncoterms);
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      getOpenArticles(options.value.page);
    }, 250);
  },
  { deep: true },
);

const getOpenArticles = async (page: number) => {
  loading.value = true;
  try {
    items.value = [];
    const response = await api.getOpenArticles(
      selectedCustomer.value ? parseInt(selectedCustomer.value) : undefined,
      selectedSender.value ?? undefined,
      isGroupedByCustomer.value,
      selectedIncoterm.value ?? undefined,
      sortBy.value,
      sortDesc.value,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the open articles.");
  }

  loading.value = false;
};

const onFilterSelected = async () => {
  await getOpenArticles(1);
};

const fulfill = (item: OpenArticle) => {
  currentItem.value = item;
  showDialog.value = true;
  fulfillmentArticleData.value = {
    eza: {
      hsCode: null,
      description: "",
    },
    impost: {
      hsCode: null,
      description: "",
    },
    useGeneralCustomerForAutomatedProcess: false,
    useSenderForAutomatedProcess: false,
    documents: false,
    customer: item.customer,
    senderName: item.sender,
  } as ArticleMaintenanceFulfillmentForm;
};

const onFulfillConfirmed = async (
  eventData: ArticleMaintenanceFulfillmentForm,
) => {
  dialogLoading.value = true;
  try {
    await api.fulFillOpenArticles({
      openArticleIds: currentItem.value!.groupedIds?.length
        ? currentItem.value!.groupedIds!
        : [currentItem.value!.id!],
      ezaHsCode: eventData.eza.hsCode!,
      ezaDescription: eventData.eza.description,
      impostHsCode: eventData.impost.hsCode,
      impostDescription: eventData.impost.description,
      useGeneralCustomerForAutomatedProcess:
        eventData.useGeneralCustomerForAutomatedProcess,
      useSenderForAutomatedProcess: eventData.useSenderForAutomatedProcess,
      documents: eventData.documents,
    });
    currentItem.value = null;
    dialogLoading.value = false;
    showDialog.value = false;
    await getOpenArticles(options.value.page);
  } catch (e: any) {
    dialogLoading.value = false;
    let errorMessages: string[] = [
      "Something went wrong while fulfilling the open article.",
    ];

    if (e?.response?.data?.errorMessages?.length) {
      errorMessages = e.response.data.errorMessages;
    }
    emitError(...errorMessages);
  }
};

const headers = computed((): DataTableHeader[] => {
  return !isGroupedByCustomer.value
    ? [
        { text: "Customer", value: "customer", sortable: false },
        { text: "Sender", value: "sender", sortable: false },
        { text: "HS Code", value: "hsCode", sortable: false },
        {
          text: "Content description",
          value: "description",
          sortable: false,
        },
        { text: "DAP/DDP", value: "incoterm", sortable: false },
        {
          text: "Last import date",
          value: "lastImportDate",
          sortable: true,
        },
        { text: "Count", value: "count", sortable: true },
        {
          text: " ",
          value: "actions",
          sortable: false,
          width: "6em",
        },
      ]
    : [
        { text: "Customer", value: "customer", sortable: false },
        { text: "HS Code", value: "hsCode", sortable: false },
        {
          text: "Content description",
          value: "description",
          sortable: false,
        },
        {
          text: "Last import date",
          value: "lastImportDate",
          sortable: true,
        },
        { text: "Count", value: "count", sortable: true },
        {
          text: " ",
          value: "actions",
          sortable: false,
          width: "6em",
        },
      ];
});

const sortBy = computed((): string => {
  return options.value.sortBy?.length ? options.value.sortBy[0] : "";
});

const sortDesc = computed((): boolean => {
  return options.value.sortDesc?.length ? options.value.sortDesc[0] : false;
});

const incoterms = computed(() => {
  return store.getters.incoterms.filter((c: IncotermViewModel) =>
    props.incotermIds.includes(c.id!),
  );
});
</script>
