<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer" slim>
    <ConfirmDialog
      v-model="localValue"
      :title="title"
      :is-loading="loading"
      :confirm-disabled="invalid"
      @confirm="onConfirm"
    >
      <v-container>
        <v-row>
          <v-col v-if="masterEmailTemplates?.length" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Master template"
              rules="required"
            >
              <v-autocomplete
                v-model="formData.masterTemplateId"
                :items="masterEmailTemplates"
                item-value="id"
                item-text="name"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Template name"
              rules="required"
            >
              <v-text-field
                v-model="formData.templateName"
                label="Template name*"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
    </ConfirmDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { EmailTemplateSimple } from "@/openapi";
import { ValidationObserver } from "vee-validate";
import { computed, ref } from "vue";

import { useFormReset } from "@/composables/formReset";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export interface NewEmailTemplateFormData {
  masterTemplateId: number | null;
  templateName: string;
}

interface NewEmailTemplateDialogProps {
  value: boolean;
  title: string;
  masterEmailTemplates?: EmailTemplateSimple[];
  loading: boolean;
}

const defaultFormValues: NewEmailTemplateFormData = {
  masterTemplateId: null,
  templateName: "",
};

const props = withDefaults(defineProps<NewEmailTemplateDialogProps>(), {
  value: false,
  title: "",
  masterEmailTemplates: () => [],
  loading: false,
});
const emits = defineEmits(["input", "confirm"]);

const observer = ref<InstanceType<typeof ValidationObserver>>();
const formData = ref<NewEmailTemplateFormData>({ ...defaultFormValues });

const onConfirm = () => {
  emits("confirm", { ...formData.value });
};

const localValue = computed({
  get() {
    return props.value;
  },
  set(value: boolean) {
    emits("input", value);
  },
});

useFormReset(localValue, formData, defaultFormValues, observer);
</script>
