<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="true"
      :expanded.sync="expanded"
      :item-class="getItemClass"
      item-key="id"
      height="calc(100vh - 295px)"
      dense
      fixed-header
      fixed-footer
      show-expand
      class="elevation-1"
      :loading="isLoading"
    >
      <template #body.prepend>
        <tr>
          <td>
            <v-icon small>mdi-filter</v-icon>
          </td>
          <td>
            <v-select
              v-model="filter.step"
              :items="statusFilter"
              clearable
              chips
              dense
              single-line
              @change="onFilterSelected"
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="filter.shipmentStatusDescription"
              :items="shipmentStatusFilter"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-select>
          </td>
          <td>
            <FilterParcels
              v-model="filter.parcels"
              @input="onFilterSelected"
            ></FilterParcels>
          </td>
          <td>
            <v-text-field
              v-model="filter.exportMrn"
              clearable
              dense
              @keyup.enter="onFilterSelected"
              @click:clear="(filter.exportMrn = ''), onFilterSelected()"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-autocomplete
              v-model="filter.shipperCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
          <td>
            <v-autocomplete
              v-model="filter.consigneeCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
        </tr>
      </template>

      <template #item.lts="{ item }">
        <v-icon class="error--text" @click="openDialogAddLts(item)">
          mdi-plus-circle
        </v-icon>
      </template>

      <template #item.exportFlowStatus="{ item }">
        <v-avatar
          class="flow-status-avatar"
          size="22"
          :color="flowSteps[item.exportFlowStatus][3]"
        >
          {{ item.exportFlowStatus }}
        </v-avatar>
      </template>

      <template #item.exportMrn="{ item }">
        <span>
          {{ item.isMaster ? item.exportMrns.join(", ") : item.exportMrn }}
        </span>
      </template>

      <template #item.shipper="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.shipper.name }}<br />
            {{ item.shipper.address }}<br />
            {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
            {{ item.shipper.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.consignee="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.consignee.name }}<br />
            {{ item.consignee.address }}<br />
            {{ item.consignee.zipcode }} {{ item.consignee.city }}<br />
            {{ item.consignee.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.value="{ item }">
        <v-row>
          <v-col> {{ item.value.toFixed(2) }} </v-col>
        </v-row>
      </template>

      <template #item.articles="{ item }">
        <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">{{ article.description }}</span>
          </v-col>
          <v-col>
            <span class="articleDescription pl-4">{{ article.rcTarif }}</span>
          </v-col>
        </v-row>
        <v-row v-if="item.articles.length > 4" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">more...</span>
          </v-col>
        </v-row>
      </template>

      <template #expanded-item="{ headers, item }">
        <td v-if="currentShipmentDetail" :colspan="headers.length">
          <v-flex class="pa-3 pb-5">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Shipment status</v-card-title>
                    <ShipmentPaperwork
                      class="shipment-paperwork"
                      :shipment-id="currentShipmentDetail.id"
                    ></ShipmentPaperwork>
                    <CustomsExportShipmentDetailViewNew
                      :current-handling-item="currentShipmentDetail"
                    />
                    <ShipmentStatusFlow
                      :shipment-id="currentShipmentDetail.id"
                      :active-status="currentShipmentDetail.exportFlowStatus"
                    ></ShipmentStatusFlow>

                    <v-btn
                      v-if="
                        automatedSteps.indexOf(
                          currentShipmentDetail.exportFlowStatus,
                        ) !== -1
                      "
                      :color="flowSteps[checkShipmentStep][3]"
                      outlined
                      :loading="loadingSimulateAutomatedStep"
                      class="mr-3"
                      @click="loadingSimulateAutomatedStep = true"
                    >
                      Simulate automated step
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>

    <AddLtsNumberDialog
      v-model="showDialog"
      :is-loading="isDialogLoading"
      @close="onDialogClose"
      @confirm="onDialogConfirm"
    ></AddLtsNumberDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddLtsNumberDialog from "./dialogs/AddLtsNumberDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsReadyForTransitShipmentItemViewModel,
  CustomsShipmentItemViewModel,
  CustomsShipmentDetailViewModel,
  CustomsTransitApi,
  CustomsExportApi,
} from "@/openapi";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();

interface IProps {
  color?: string;
}
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const props = withDefaults(defineProps<IProps>(), {
  color: "",
});

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref<CustomsReadyForTransitShipmentItemViewModel[]>([]);

const loadingSimulateAutomatedStep = ref(false);

const checkShipmentStep = ref(2);
const automatedSteps = ref([6, 9, 10, 11, 13]); // for testing

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const items = ref<CustomsReadyForTransitShipmentItemViewModel[]>([]);
const currentHandlingItem =
  ref<CustomsReadyForTransitShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const currentLtsNumberItem =
  ref<CustomsReadyForTransitShipmentItemViewModel | null>(null);

const showDialog = ref(false);
const isDialogLoading = ref(false);

const headers = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
    sortable: false,
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
    sortable: false,
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    sortable: false,
    width: "18em",
  },
  {
    text: "LTS",
    value: "lts",
    sortable: false,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eoriNumber", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

onBeforeMount(() => {
  dataStore.fetchOriginCountries();
  customsFlowStepsOnBeforeMount();
  statusFilter.value = getStatuses();

  addRefreshToolbarOption(reloadShipments);
});

const loadShipments = async (page: number) => {
  isLoading.value = true;
  try {
    const response = await customsTransitApi.getReadyForTransitShipments({
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });

    items.value = response.data.items ?? [];
    totalItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the shipments");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1
    ? await loadShipments(options.value.page)
    : (options.value.page = 1);
};

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadShipments(options.value.page);
    }, 250);
  },
);
watch(
  () => expanded.value,
  (newVal) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value!.id)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);
const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};
const onFilterSelected = () => {
  reloadShipments();
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const openDialogAddLts = (
  item: CustomsReadyForTransitShipmentItemViewModel,
) => {
  showDialog.value = true;
  currentLtsNumberItem.value = item;
};

const onDialogClose = () => {
  currentLtsNumberItem.value = null;
};

const onDialogConfirm = async ({ ltsNumber }: { ltsNumber: string }) => {
  isDialogLoading.value = true;
  try {
    await customsTransitApi.addLtsNumber({
      shipmentId: currentLtsNumberItem.value!.id,
      ltsNumber: ltsNumber,
    });

    loadShipments(options.value.page);
  } catch {
    displayError(
      `Someting went wrong while adding the LTS number "${ltsNumber}"`,
    );
  }

  showDialog.value = false;
  isDialogLoading.value = false;
};

const getItemClass = (item: CustomsReadyForTransitShipmentItemViewModel) => {
  return item.isOverdue ? "transit-ready-shipment-overdue" : "";
};
</script>
