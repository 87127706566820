<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <ValidationObserver v-slot="{ invalid }" ref="observers">
          <v-form ref="form" v-model="valid" lazy-validation>
            <template>
              <v-tabs v-model="currentTab" align-with-title class="mt-3">
                <v-tabs-slider :color="modusColor[activeModus]"></v-tabs-slider>

                <v-tab
                  class="dashboard-tab rounded-t-xl mr-3"
                  outlined
                  @click="changeModus(dashboardModus)"
                >
                  Dashboard
                </v-tab>
                <v-tab-item>
                  <v-card
                    class="mx-3"
                    outlined
                    :color="modusColor[activeModus]"
                    elevation="2"
                  >
                    <v-flex class="pa-5"></v-flex>

                    <v-card>
                      <v-container class="grey lighten-4 dashboard-container">
                        <v-progress-linear
                          :active="loadingBarDashboard"
                          indeterminate
                          color="primary"
                        ></v-progress-linear>

                        <v-row>
                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-row>
                                <v-col
                                  cols="3"
                                  align="center"
                                  class="yellow darken-3"
                                >
                                  <v-icon large class="white--text py-3">
                                    mdi-text-box-check
                                  </v-icon>
                                </v-col>
                                <v-col>
                                  <div class="text-button grey--text">
                                    Check shipments
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ dashboardTotals.totalCheck }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-row>
                                <v-col
                                  cols="3"
                                  align="center"
                                  class="light-blue darken-1"
                                >
                                  <v-icon large class="white--text py-3">
                                    mdi-account-tie-hat-outline
                                  </v-icon>
                                </v-col>
                                <v-col>
                                  <div class="text-button grey--text">
                                    Export shipments
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ dashboardTotals.totalExport }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-row>
                                <v-col cols="3" align="center" class="purple">
                                  <v-icon large class="white--text py-3">
                                    mdi-file-send
                                  </v-icon>
                                </v-col>
                                <v-col>
                                  <div class="text-button grey--text">
                                    Transit shipments
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ dashboardTotals.totalTransit }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-card
                                class="mt-n5 mb-5 pa-3 yellow darken-3 white--text"
                              >
                                Total per step
                              </v-card>
                              <v-row>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalHoldPerStep,
                                      checkModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        20
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalHoldPerStep,
                                          checkModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalReturnPerStep,
                                      checkModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        21
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalReturnPerStep,
                                          checkModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalCustomsInspectionPerStep,
                                      checkModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        22
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalCustomsInspectionPerStep,
                                          checkModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                              </v-row>

                              <v-row
                                v-for="step in range(
                                  dataReceivedStep,
                                  checkShipmentStep,
                                )"
                              >
                                <v-col cols="2" align="center">
                                  <div>
                                    <v-avatar
                                      class="flow-status-avatar"
                                      size="24"
                                      color="yellow darken-3"
                                    >
                                      {{ step }}
                                    </v-avatar>
                                  </div>
                                </v-col>
                                <v-col>
                                  <div
                                    v-if="
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      ) > 0
                                    "
                                  >
                                    {{
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      )
                                    }}
                                    <span v-if="step == 0"> (all)</span>
                                    <span v-else> (mine)</span>
                                  </div>
                                  <div v-else>-</div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>

                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-card
                                class="mt-n5 mb-5 pa-3 light-blue darken-1 white--text"
                              >
                                Total per step
                              </v-card>
                              <v-row>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalHoldPerStep,
                                      exportModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        20
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalHoldPerStep,
                                          exportModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalReturnPerStep,
                                      exportModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        21
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalReturnPerStep,
                                          exportModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalCustomsInspectionPerStep,
                                      exportModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        22
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalCustomsInspectionPerStep,
                                          exportModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                              </v-row>

                              <v-row
                                v-for="step in range(
                                  valueBreaksStep,
                                  confirmManifestStep,
                                )"
                              >
                                <v-col cols="2" align="center">
                                  <div>
                                    <v-avatar
                                      class="flow-status-avatar"
                                      size="24"
                                      color="light-blue darken-1"
                                    >
                                      {{ step }}
                                    </v-avatar>
                                  </div>
                                </v-col>
                                <v-col>
                                  <div
                                    v-if="
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      ) > 0
                                    "
                                  >
                                    {{
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      )
                                    }}
                                  </div>
                                  <div v-else>-</div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>

                          <v-col cols="4">
                            <v-card class="ma-3 pa-3" outlined>
                              <v-card
                                class="mt-n5 mb-5 pa-3 purple white--text"
                              >
                                Total per step
                              </v-card>
                              <v-row>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalHoldPerStep,
                                      transitModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        20
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalHoldPerStep,
                                          transitModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalReturnPerStep,
                                      transitModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        21
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalReturnPerStep,
                                          transitModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                                <template
                                  v-if="
                                    getIndexValue(
                                      dashboardTotals.totalCustomsInspectionPerStep,
                                      transitModus,
                                      0,
                                    ) > 0
                                  "
                                >
                                  <v-col cols="2" align="center">
                                    <div>
                                      <v-avatar
                                        class="flow-status-avatar"
                                        size="24"
                                        color="error"
                                      >
                                        22
                                      </v-avatar>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <div>
                                      {{
                                        getIndexValue(
                                          dashboardTotals.totalCustomsInspectionPerStep,
                                          transitModus,
                                          0,
                                        )
                                      }}
                                    </div>
                                  </v-col>
                                </template>
                              </v-row>

                              <v-row
                                v-for="step in range(
                                  confirmWABStep,
                                  t2DischargedStep,
                                )"
                              >
                                <v-col cols="2" align="center">
                                  <div>
                                    <v-avatar
                                      class="flow-status-avatar"
                                      size="24"
                                      color="purple"
                                    >
                                      {{ step }}
                                    </v-avatar>
                                  </div>
                                </v-col>
                                <v-col>
                                  <div
                                    v-if="
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      ) > 0
                                    "
                                  >
                                    {{
                                      getIndexValue(
                                        dashboardTotals.totalPerStep,
                                        step,
                                        0,
                                      )
                                    }}
                                  </div>
                                  <div v-else>-</div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-card class="mx-3 yellow darken-3">
                              <v-row>
                                <v-col cols="4" align="center">
                                  <v-icon size="100" class="white--text py-3">
                                    mdi-human-dolly
                                  </v-icon>
                                </v-col>
                                <v-col class="pt-6">
                                  <div
                                    class="text-caption white--text mt-3"
                                    align="center"
                                  >
                                    CONFIRMED BUT NOT LOADED (MINE)
                                  </div>
                                  <div
                                    class="text-h5 white--text"
                                    align="center"
                                  >
                                    {{
                                      dashboardTotals.totalConfirmedButNotLoaded
                                    }}
                                  </div>
                                  <div
                                    class="text-caption white--text"
                                    align="center"
                                  >
                                    LOADED BUT NOT CONFIRMED (ALL)
                                  </div>
                                  <div
                                    class="text-h5 white--text"
                                    align="center"
                                  >
                                    {{
                                      dashboardTotals.totalLoadedButNotConfirmed
                                    }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card class="mx-3 light-blue darken-1">
                              <v-row>
                                <v-col cols="4" align="center">
                                  <v-icon size="100" class="white--text py-3">
                                    mdi-currency-eur
                                  </v-icon>
                                </v-col>
                                <v-col>
                                  <v-row class="pt-6">
                                    <v-col
                                      class="text-caption white--text"
                                      align="center"
                                    >
                                      < 1.000
                                    </v-col>
                                    <v-col
                                      class="text-caption white--text"
                                      align="center"
                                    >
                                      1.000 - 3.000
                                    </v-col>
                                    <v-col
                                      class="text-caption white--text"
                                      align="center"
                                    >
                                      > 3.000 or has MRN
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      class="text-h5 white--text"
                                      align="center"
                                    >
                                      {{
                                        dashboardTotals.totalExportValueBreak1
                                      }}
                                    </v-col>
                                    <v-col
                                      class="text-h5 white--text"
                                      align="center"
                                    >
                                      {{
                                        dashboardTotals.totalExportValueBreak2
                                      }}
                                    </v-col>
                                    <v-col
                                      class="text-h5 white--text"
                                      align="center"
                                    >
                                      {{
                                        dashboardTotals.totalExportValueBreak3
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card class="mx-3 purple">
                              <v-row>
                                <v-col cols="4" align="center">
                                  <v-icon size="100" class="white--text py-3">
                                    mdi-truck-trailer
                                  </v-icon>
                                </v-col>
                                <v-col class="pt-6">
                                  <div class="text-caption white--text">
                                    WAB NUMBERS
                                  </div>
                                  <div
                                    v-for="n in dashboardTotalTransitWABNumbers.length"
                                  >
                                    <div class="text-h6 white--text"></div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-card class="mx-3" outlined color="grey">
                              <template>
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header>
                                      Flow example
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <v-stepper
                                        alt-labels
                                        :value="fullfilledStep"
                                      >
                                        <v-stepper-header>
                                          <template
                                            v-for="step in fullfilledStep"
                                          >
                                            <v-stepper-step
                                              :step="step"
                                              :color="flowSteps[step][3]"
                                              complete
                                            >
                                              <span
                                                class="text-caption text-center flowExample"
                                                >{{ step }}</span
                                              >
                                              <span
                                                class="text-caption text-center"
                                                >{{ flowSteps[step][4] }}</span
                                              >
                                            </v-stepper-step>
                                            <v-divider
                                              v-if="step < fullfilledStep"
                                            ></v-divider>
                                          </template>
                                          <v-stepper-step
                                            class="error-step"
                                            step="20"
                                            color="error"
                                            complete
                                          >
                                            <span
                                              class="text-caption text-center flowExample"
                                              >20</span
                                            >
                                            <span
                                              class="text-caption text-center"
                                              >Hold</span
                                            >
                                          </v-stepper-step>
                                          <v-stepper-step
                                            class="error-step"
                                            step="21"
                                            color="error"
                                            complete
                                          >
                                            <span
                                              class="text-caption text-center flowExample"
                                              >21</span
                                            >
                                            <span
                                              class="text-caption text-center"
                                              >Return</span
                                            >
                                          </v-stepper-step>
                                          <v-stepper-step
                                            class="error-step"
                                            step="22"
                                            color="error"
                                            complete
                                          >
                                            <span
                                              class="text-caption text-center flowExample"
                                              >22</span
                                            >
                                            <span
                                              class="text-caption text-center"
                                              >Customs inspection</span
                                            >
                                          </v-stepper-step>
                                        </v-stepper-header>
                                      </v-stepper>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </template>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-card>
                </v-tab-item>

                <v-tab
                  class="check-tab rounded-t-xl mr-3"
                  @click="changeModus(checkModus)"
                >
                  Check modus
                </v-tab>
                <v-tab-item>
                  <customs-export-check
                    v-if="checkModus == activeModus"
                    :color="modusColor[activeModus]"
                    @errorOccured="emits('errorOccured', $event)"
                    @PageInfoReceived="emitPageInfoReceived"
                  ></customs-export-check>
                </v-tab-item>

                <v-tab
                  class="holdreturn-tab rounded-t-xl mr-3"
                  @click="changeModus(holdReturnModus)"
                >
                  Hold / Return
                </v-tab>
                <v-tab-item>
                  <CustomsExportHoldReturn
                    v-if="holdReturnModus == activeModus"
                    :color="modusColor[activeModus]"
                    @errorOccured="emits('errorOccured', $event)"
                    @PageInfoReceived="emitPageInfoReceived"
                  ></CustomsExportHoldReturn>
                </v-tab-item>

                <v-tab
                  class="export-wab-tab rounded-t-xl mr-3"
                  @click="changeModus(wabModus)"
                >
                  WAB modus
                </v-tab>
                <v-tab-item>
                  <customs-export-wab
                    v-if="wabModus == activeModus"
                    :color="modusColor[activeModus]"
                    class="testclass"
                    @errorOccured="emits('errorOccured', $event)"
                    @PageInfoReceived="emitPageInfoReceived"
                  ></customs-export-wab>
                </v-tab-item>
                <v-tab
                  class="export-tab rounded-t-xl mr-3"
                  @click="changeModus(exportModus)"
                >
                  Export modus
                </v-tab>
                <v-tab-item>
                  <v-card
                    v-if="exportModus == activeModus"
                    class="mx-3"
                    outlined
                    :color="modusColor[activeModus]"
                    elevation="2"
                  >
                    <v-flex class="pa-5"></v-flex>
                    <v-tabs
                      v-model="exportModusInnerTab"
                      class="pt-3 export-modus-inner-tabs"
                      align-with-title
                    >
                      <v-tabs-slider
                        :color="modusColor[activeModus]"
                      ></v-tabs-slider>
                      <v-tab class="mr-3 export-modus-inner-tabs__tab">
                        <strong>&lt; 1000€</strong>
                      </v-tab>
                      <v-tab class="mr-3 export-modus-inner-tabs__tab">
                        <strong>&gt; 1000€</strong>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="exportModusInnerTab">
                      <v-tab-item>
                        <customs-export-shipments-low
                          v-if="exportModusInnerTab == 0"
                          @PageInfoReceived="emitPageInfoReceived"
                        ></customs-export-shipments-low>
                      </v-tab-item>
                      <v-tab-item>
                        <customs-export-shipments-high
                          v-if="exportModusInnerTab == 1"
                          @PageInfoReceived="emitPageInfoReceived"
                        ></customs-export-shipments-high>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-tab-item>

                <v-tab
                  class="transit-tab rounded-t-xl mr-3"
                  @click="changeModus(transitModus)"
                >
                  Transit modus
                </v-tab>
                <v-tab-item>
                  <v-card
                    v-if="transitModus == activeModus"
                    class="mx-3"
                    outlined
                    :color="modusColor[activeModus]"
                    elevation="2"
                  >
                    <v-flex class="pa-5"></v-flex>
                    <v-tabs
                      v-model="transitModusInnerTab"
                      class="pt-3 transit-modus-inner-tabs"
                      align-with-title
                    >
                      <v-tabs-slider
                        :color="modusColor[activeModus]"
                      ></v-tabs-slider>
                      <v-tab class="mr-3 transit-modus-inner-tabs__tab">
                        <strong>Ready for transit</strong>
                      </v-tab>
                      <v-tab class="mr-3 transit-modus-inner-tabs__tab">
                        <strong>In transit</strong>
                      </v-tab>
                      <v-tab class="mr-3 transit-modus-inner-tabs__tab">
                        <strong>History</strong>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="transitModusInnerTab">
                      <v-tab-item>
                        <customs-export-ready-for-transit
                          v-if="transitModusInnerTab == 0"
                          @PageInfoReceived="emitPageInfoReceived"
                        ></customs-export-ready-for-transit>
                      </v-tab-item>
                      <v-tab-item>
                        <customs-export-in-transit
                          v-if="transitModusInnerTab == 1"
                          @PageInfoReceived="emitPageInfoReceived"
                        ></customs-export-in-transit>
                      </v-tab-item>
                      <v-tab-item>
                        <customs-export-transit-history
                          v-if="transitModusInnerTab == 2"
                          @PageInfoReceived="emitPageInfoReceived"
                        ></customs-export-transit-history>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-tab-item>

                <v-tab
                  class="admin-tab rounded-t-xl mr-3"
                  outlined
                  @click="changeModus(adminModus)"
                >
                  Admin
                </v-tab>
                <v-tab-item>
                  <CustomsExportAdmin
                    v-if="adminModus == activeModus"
                    :color="modusColor[activeModus]"
                    @errorOccured="emits('errorOccured', $event)"
                    @PageInfoReceived="emitPageInfoReceived"
                  ></CustomsExportAdmin>
                </v-tab-item>
                <v-tab
                  class="paperwork-tab rounded-t-xl mr-3"
                  @click="changeModus(paperworkHandlingModus)"
                >
                  Paperwork handling
                </v-tab>
                <v-tab-item>
                  <customs-paperwork-handeling
                    v-if="paperworkHandlingModus == activeModus"
                    @PageInfoReceived="emitPageInfoReceived"
                  >
                  </customs-paperwork-handeling>
                </v-tab-item>

                <v-tab
                  class="discrepancies-tab rounded-t-xl mr-3"
                  outlined
                  @click="changeModus(discrepanciesModus)"
                >
                  discrepancies
                </v-tab>
                <v-tab-item>
                  <customs-discrepancies
                    v-if="discrepanciesModus == activeModus"
                    :color="modusColor[activeModus]"
                    @PageInfoReceived="emitPageInfoReceived"
                  ></customs-discrepancies>
                </v-tab-item>

                <v-tab
                  class="t2-creation-tab rounded-t-xl mr-3"
                  @click="changeModus(t2CreationModus)"
                >
                  T2 Creation
                </v-tab>
                <v-tab-item>
                  <CustomsT2Creation
                    v-if="t2CreationModus == activeModus"
                    :color="modusColor[activeModus]"
                  ></CustomsT2Creation>
                </v-tab-item>
              </v-tabs>
            </template>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogUploadShipments" persistent max-width="600px">
          <v-card>
            <v-toolbar color="light-blue darken-1" dark>
              <span class="ml-3 text-button"
                >Upload shipments from GeoData file</span
              >
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="dialogUploadShipmentsFile"
                        outlined
                        label="GeoData file"
                        placeholder="Please upload a valid GeoData file"
                        :rules="[rules.required]"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogUploadShipments = false"
              >
                Close
              </v-btn>
              <v-btn
                color="light-blue darken-1"
                text
                :loading="loadingButton11"
                @click="loadingButton11 = true"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-dialog
        v-model="dialogShipmentAddMrn"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            <span class="ml-3 text-button">Shipment add MRN</span>
          </v-toolbar>
          <v-card-text class="justify-center">
            <v-card
              v-if="currentHandlingItem"
              class="mt-5 pa-5"
              color="#FFF8E1"
              max-width="600"
            >
              <v-row no-gutters>
                <v-col class="font-weight-medium">Paketscheinnummer</v-col>
                <v-col>{{ currentHandlingItem.hawb }}</v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="dialogExportMrn"
                            background-color="white"
                            outlined
                            dense
                            label="Movement Reference Number (MRN)"
                            placeholder="Please enter a Movement Reference Number (MRN)"
                            :rules="[rules.mrnnumber]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            color="primary"
                            :loading="loadingButton11"
                            @click="
                              (loadingButton11 = true),
                                saveDialogEnterExportMrn()
                            "
                          >
                            Save
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogShipmentAddMrn = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogShipmentReason" persistent max-width="600px">
          <v-card>
            <v-toolbar color="error" dark>
              <span class="ml-3 text-button">{{ currentAction }}</span>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <template v-if="currentAction == 'Return'">
                        <v-select
                          v-model="enterShipmentReasonFirst"
                          :items="returnReasonsFirst"
                          item-text="reason"
                          item-value="id"
                          label="Please select a reason"
                          outlined
                          :rules="[rules.required]"
                          @change="returnReasonsChange"
                        ></v-select>
                        <v-select
                          v-if="returnReasonsSecond.length > 0"
                          v-model="enterShipmentReasonSecond"
                          :items="returnReasonsSecond"
                          item-text="reason"
                          item-value="id"
                          label="Please select a second reason"
                          outlined
                          :rules="[rules.required]"
                        ></v-select>
                      </template>
                      <template v-else>
                        <v-card class="pa-3 mt-2 mb-8" elevation="0" outlined>
                          <v-row>
                            <v-col>Beschau</v-col>
                          </v-row>
                        </v-card>
                        <v-select
                          v-model="enterShipmentReason"
                          :items="returnReasonsBeschau"
                          label="Please select a reason"
                          outlined
                          :rules="[rules.required]"
                        ></v-select>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogShipmentReason = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingButton11"
                @click="
                  (loadingButton11 = true),
                    saveDialogShipmentReason(currentAction)
                "
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmShipment" persistent max-width="600px">
          <v-card>
            <v-toolbar color="yellow darken-3" dark>
              <span class="ml-3 text-button">Confirm shipments</span>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-stepper alt-labels :value="confirmManifestStep">
                  <v-stepper-header>
                    <v-stepper-step
                      :color="flowSteps[checkShipmentStep][3]"
                      complete
                    >
                      <span class="text-caption text-center flowExample">{{
                        checkShipmentStep
                      }}</span>
                      <span class="text-caption text-center">{{
                        flowSteps[checkShipmentStep][4]
                      }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :color="flowSteps[checkShipmentStep][3]"
                      complete
                    >
                      <span class="text-caption text-center">Loaded</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :color="flowSteps[valueBreaksStep][3]"
                      complete
                    >
                      <span class="text-caption text-center flowExample">{{
                        valueBreaksStep
                      }}</span>
                      <span class="text-caption text-center">{{
                        flowSteps[valueBreaksStep][4]
                      }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :color="flowSteps[confirmLoadingStep][3]"
                      complete
                    >
                      <span class="text-caption text-center flowExample">{{
                        confirmLoadingStep
                      }}</span>
                      <span class="text-caption text-center">{{
                        flowSteps[confirmLoadingStep][4]
                      }}</span>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-container>

              <div class="mx-5">
                <span class="black--text">
                  Click CONFIRM to confirm that the shipment data is checked. If
                  the shipment is loaded then the status will change to '4'.
                </span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogConfirmShipment = false"
              >
                Close
              </v-btn>
              <v-btn
                color="light-blue darken-1"
                text
                :loading="loadingButton11"
                @click="(loadingButton11 = true), saveDialogConfirmShipments()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmLoading" persistent max-width="600px">
          <v-card>
            <v-toolbar color="light-blue darken-1" dark>
              <span class="ml-3 text-button">Confirm loading</span>
            </v-toolbar>
            <v-card-text>
              <template v-if="currentValueBreak == 3">
                <v-container>
                  <v-stepper alt-labels :value="confirmManifestStep">
                    <v-stepper-header>
                      <v-stepper-step
                        :step="confirmLoadingStep"
                        :color="flowSteps[confirmLoadingStep][3]"
                        complete
                      >
                        <span class="text-caption text-center flowExample">{{
                          confirmLoadingStep
                        }}</span>
                        <span class="text-caption text-center">{{
                          flowSteps[confirmLoadingStep][4]
                        }}</span>
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step
                        :step="confirmDocumentOrMRN"
                        :color="flowSteps[confirmManifestStep][3]"
                        complete
                      >
                        <span class="text-caption text-center flowExample">{{
                          confirmManifestStep
                        }}</span>
                        <span class="text-caption text-center">{{
                          flowSteps[confirmManifestStep][currentValueBreak - 1]
                        }}</span>
                      </v-stepper-step>
                    </v-stepper-header>
                  </v-stepper>
                </v-container>

                <div class="mx-5">
                  <span class="black--text">
                    Click CONFIRM to change all shipments with status '4' to
                    status '7'.
                  </span>
                </div>
              </template>
              <template v-else>
                <v-container>
                  <v-stepper alt-labels :value="confirmManifestStep">
                    <v-stepper-header>
                      <v-stepper-step
                        :step="confirmLoadingStep"
                        :color="flowSteps[confirmLoadingStep][3]"
                        complete
                      >
                        <span class="text-caption text-center flowExample">{{
                          confirmLoadingStep
                        }}</span>
                        <span class="text-caption text-center">{{
                          flowSteps[confirmLoadingStep][4]
                        }}</span>
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step
                        :step="confirmDocumentOrMRN"
                        :color="flowSteps[confirmDocumentOrMRN][3]"
                        complete
                      >
                        <span class="text-caption text-center flowExample">{{
                          confirmDocumentOrMRN
                        }}</span>
                        <span class="text-caption text-center">{{
                          flowSteps[confirmDocumentOrMRN][currentValueBreak - 1]
                        }}</span>
                      </v-stepper-step>
                    </v-stepper-header>
                  </v-stepper>
                </v-container>

                <div class="mx-5">
                  <span class="black--text">
                    Click CONFIRM to change all shipments with status '4' to
                    status '5
                    {{
                      flowSteps[confirmDocumentOrMRN][currentValueBreak - 1]
                    }}'.
                  </span>
                </div>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogConfirmLoading = false"
              >
                Close
              </v-btn>
              <v-btn
                color="light-blue darken-1"
                text
                :loading="loadingButton11"
                @click="(loadingButton11 = true), saveDialogConfirmLoading()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmManifest" persistent max-width="600px">
          <v-card>
            <v-toolbar color="light-blue darken-1" dark>
              <span class="ml-3 text-button">Confirm manifest</span>
            </v-toolbar>
            <v-card-text>
              <v-row no-gutters class="ml-4 mt-4">
                <v-progress-linear
                  :active="loadingBarConfirmManifest"
                  indeterminate
                  color="primary"
                ></v-progress-linear>

                <v-col cols="8" class="font-weight-bold">
                  Total status 7:
                </v-col>
                <v-col class="font-weight-bold">
                  {{ manifestTotal7Exit }}
                </v-col>
              </v-row>
              <v-row v-if="manifestTotalNot7 > 0" no-gutters class="ml-4">
                <v-col cols="8" class="font-weight-bold red--text">
                  Total not status 7:
                </v-col>
                <v-col class="font-weight-bold red--text">
                  {{ manifestTotalNot7 }}
                </v-col>
              </v-row>
              <v-row v-if="manifestTotal7NotExit > 0" no-gutters class="ml-4">
                <v-col cols="8" class="font-weight-bold red--text">
                  Total status 7 but not 'Customs EXIT released':
                </v-col>
                <v-col class="font-weight-bold red--text">
                  {{ manifestTotal7NotExit }}
                </v-col>
              </v-row>

              <v-container>
                <v-stepper alt-labels :value="confirmManifestStep">
                  <v-stepper-header>
                    <v-stepper-step
                      :step="confirmManifestStep"
                      :color="flowSteps[confirmManifestStep][3]"
                      complete
                    >
                      <span class="text-caption text-center flowExample">{{
                        confirmManifestStep
                      }}</span>
                      <span class="text-caption text-center">{{
                        flowSteps[confirmManifestStep][4]
                      }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :step="confirmWABStep"
                      :color="flowSteps[confirmWABStep][3]"
                      complete
                    >
                      <span class="text-caption text-center flowExample">{{
                        confirmWABStep
                      }}</span>
                      <span class="text-caption text-center">{{
                        flowSteps[confirmWABStep][4]
                      }}</span>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-container>
              <div class="mx-5">
                <span class="black--text">
                  Click CONFIRM to change all shipments with status '7 Customs
                  EXIT released' to status '8'.
                </span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogConfirmManifest = false"
              >
                Close
              </v-btn>
              <v-btn
                color="light-blue darken-1"
                text
                :loading="loadingButton11"
                @click="(loadingButton11 = true), saveDialogConfirmManifest()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-if="currentHandlingWABItem"
          v-model="dialogAddRoute"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-toolbar color="light-blue darken-1" dark>
              <span class="ml-3 text-button">Add route to WAB</span>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span class="text-caption">Please add a route</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model="dialogAddRouteId"
                        :items="allCustomsExportInformationItems"
                        item-text="name"
                        item-value="id"
                        label="Route"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogAddRoute = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingButton11"
                @click="(loadingButton11 = true), saveDialogAddRouteId()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-if="currentHandlingWABItem"
          v-model="dialogConfirmWABReadyToDepart"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-toolbar color="purple" dark>
              <span class="ml-3 text-button">Confirm WAB ready to depart</span>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span class="text-caption">VERSCHLUESSE</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        v-model="dialogWABReadyToDepartType"
                        :items="['P', 'R']"
                        label="Art"
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="dialogWABReadyToDepartAmount"
                        label="Anzahl"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="dialogWABReadyToDepartRegistration"
                        label="Kenzeichen"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="dialogWABReadyToDepartCountry"
                        :items="customsCountries"
                        item-text="description"
                        item-value="code"
                        label="Staat"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="dialogWABReadyToDepartSign"
                        label="Zeichen"
                        outlined
                        :rules="[rules.required]"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue darken-1"
                text
                @click="dialogConfirmWABReadyToDepart = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingButton11"
                @click="
                  (loadingButton11 = true), saveDialogConfirmWABReadyToDepart()
                "
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <HoldReturnExportDialog
      v-model="showHoldReturnExportDialog"
      :include-return="filter.showReturn"
      :shipment-ids="customsHandlingItemsIds"
      :return-date-filter="holdReturnExportDateFilter ?? undefined"
    ></HoldReturnExportDialog>

    <ShipmentValueChangesDialog
      v-model="showShipmentValueChangeDialog"
    ></ShipmentValueChangesDialog>

    <v-snackbar v-model="saveSuccessful" timeout="4000" top color="success">
      Saved successful
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="saveSuccessful = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noItemsImported" timeout="4000" top color="warning">
      No items imported
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="noItemsImported = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noItemsSelected" timeout="2000" top color="warning">
      No items selected
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="noItemsSelected = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noEmployeeSelected" timeout="2000" top color="warning">
      No employee selected
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="noEmployeeSelected = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noStatusSelected" timeout="2000" top color="warning">
      No status selected
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="noStatusSelected = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup lang="ts">
interface IFilter {
  step: string;
  shipmentStatusDescription: string;
  hawb: string;
  exportMrn: string;
  eori: string;
  employee: number;
  loadedConfirmed: number;
  showAssigned: false;
  showWithoutExportMrn: false;
  showReturn: boolean;
  isLoaded: string;
  isConfirmed: string;
  returnReasonSecond: string;
  returnShipmentsDateRange: string[] | null;
}
import { ref, computed, onBeforeMount, watch } from "vue";
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
import {
  isNotNullOrUndefined,
  getIndexValue,
} from "@/helpers/validationHelper";
import { ValidationObserver } from "vee-validate";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomsExportApi,
  CustomsExportInformationApi,
  CustomsExportInformationViewModel,
  CustomsHandlingItemViewModel,
  CustomsHandlingWABItemViewModel,
  CustomsCountryViewModel,
  EmployeeViewModel,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
  CustomsHandlingExportDashboardViewModel,
  CustomsShipmentDetailViewModel,
  ExportFlowStatusHistoryItem,
  EmployeesApi,
  CustomsCountriesApi,
} from "@/openapi";
import CustomsExportWab from "./components/CustomsExportWab.vue";
import CustomsExportShipmentsLow from "./components/CustomsExportShipmentsLow.vue";
import CustomsExportShipmentsHigh from "./components/CustomsExportShipmentsHigh.vue";
import CustomsExportReadyForTransit from "./components/CustomsExportReadyForTransit.vue";
import CustomsExportInTransit from "./components/CustomsExportInTransit.vue";
import HoldReturnExportDialog from "./components/dialogs/HoldReturnExportDialog.vue";
import CustomsExportCheck from "./components/CustomsExportCheck.vue";
import CustomsExportAdmin from "./components/CustomsExportAdmin.vue";
import ShipmentValueChangesDialog from "./components/dialogs/ShipmentValueChangesExportDialog.vue";
import CustomsExportHoldReturn from "./components/CustomsExportHoldReturn.vue";
import CustomsPaperworkHandeling from "./components/CustomsPaperworkHandeling.vue";
import CustomsDiscrepancies from "./components/CustomsDiscrepancies.vue";
import CustomsExportTransitHistory from "./components/CustomsExportTransitHistory.vue";
import CustomsT2Creation from "./components/CustomsT2Creation.vue";
import { useConsolidationStore } from "@/stores/consolidation-store";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";

const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();

const api = new CustomsExportApi(undefined, "");
const exportInformationApi = new CustomsExportInformationApi(undefined, "");
const employeesApi = new EmployeesApi(undefined, "");
const customsCountriesApi = new CustomsCountriesApi(undefined, "");
const consolidationStore = useConsolidationStore();

const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
  mrnnumber: (value: string) => {
    const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
    return pattern.test(value) || "Invalid MRN number";
  },
  email: (value: string) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail";
  },
};

const saveSuccessful = ref(false);
const noItemsImported = ref(false);
const noItemsSelected = ref(false);
const noEmployeeSelected = ref(false);
const noStatusSelected = ref(false);
const loadingBarDashboard = ref(false);
const loadingBarConfirmManifest = ref(false);

const expanded = ref<any[]>([]);

const loadingButton1 = ref(false);
const loadingButton2 = ref(false);
const loadingButton3 = ref(false);
const loadingButton4 = ref(false);
const loadingButton5 = ref(false);
const loadingButton6 = ref(false);
const loadingButton7 = ref(false);
const loadingButton8 = ref(false);
const loadingButton9 = ref(false);
const loadingButton10 = ref(false);
const loadingButton11 = ref(false);

const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const form = ref([]);

const expandValueBreak = ref(false);
const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [] as any[],
  sortDesc: [] as any[],
  groupBy: [] as any[],
  groupDesc: [] as any[],
  multiSort: false,
  mustSort: true,
});
const totalItems = ref(0);
const filter = ref<IFilter>({
  step: "",
  shipmentStatusDescription: "",
  hawb: "",
  exportMrn: "",
  eori: "",
  employee: 0,
  loadedConfirmed: 0,
  showAssigned: false,
  showWithoutExportMrn: false,
  showReturn: false,
  isLoaded: "",
  isConfirmed: "",
  returnReasonSecond: "",
  returnShipmentsDateRange: null,
});

const dashboardTotals = ref<CustomsHandlingExportDashboardViewModel>({
  totalCheck: 0,
  totalLoadedButNotConfirmed: 0,
  totalConfirmedButNotLoaded: 0,
  totalExport: 0,
  totalExportValueBreak1: 0,
  totalExportValueBreak2: 0,
  totalExportValueBreak3: 0,
  totalTransit: 0,
  totalPerStep: [0],
  totalHoldPerStep: [0],
  totalReturnPerStep: [],
  totalCustomsInspectionPerStep: [],
  exportWABNumbers: [],
  transitWABNumbers: [],
});
const currentHandlingItem = ref<CustomsHandlingItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const customsHandlingItems = ref<CustomsHandlingItemViewModel[]>([]);
const selectedHandlingItems = ref<CustomsHandlingItemViewModel[]>([]);
const filteredCustomsHandlingItems = ref<CustomsHandlingItemViewModel[]>([]);
const currentHandlingWABItem = ref<CustomsHandlingWABItemViewModel | null>(
  null,
);
const customsHandlingWABItems = ref<CustomsHandlingWABItemViewModel[]>([]);
const allCustomsExportInformationItems = ref<
  CustomsExportInformationViewModel[]
>([]);

const customsCountries = ref<CustomsCountryViewModel[]>([]);

const currentEmployee = ref<EmployeeViewModel | null>(null);
const employees = ref<EmployeeViewModel[]>([]);
const selectedEmployee = ref<EmployeeViewModel | null>(null);

const currentValueBreak = ref(0);

const currentAction = ref("");
const newFunction: Function = new Function();

const currentTab = ref(0);
const exportModusInnerTab = ref(0);
const transitModusInnerTab = ref(0);

const dataReceivedStep = ref(0);
const assignedToEmployeeStep = ref(1);

const checkShipmentStep = ref(2);

const valueBreaksStep = ref(3);
const confirmLoadingStep = ref(4);
const confirmDocumentOrMRN = ref(5);
const confirmManifestStep = ref(7);
const confirmWABStep = ref(8);
const t2DischargedStep = ref(13);

const fullfilledStep = ref(14);

const returnStep = ref(21);
const customsInspectionStep = ref(22);

const valid = ref(true);

const loadDataDate = ref(0);

const dialogShipmentReason = ref(false);
const enterShipmentReason = ref("");
const enterShipmentReasonFirst = ref(-1);
const enterShipmentReasonSecond = ref(-1);

const returnReasonsFirst = ref<ReturnReasonFirstViewModel[]>([]);
const returnReasonsSecond = ref<ReturnReasonSecondViewModel[]>([]);
const returnReasonsBeschau = ref<string[]>([]);

const uploadExcelLTSFile = ref<string | Blob>("");

const dialogUploadShipments = ref(false);
const dialogUploadShipmentsFile = ref(false);

const dialogShipmentAddMrn = ref(false);

const dialogConfirmShipment = ref(false);

const dialogAddRoute = ref(false);
const dialogAddRouteId = ref(0);

const dialogConfirmLoading = ref(false);
const dialogExportMrn = ref("");

const dialogConfirmManifest = ref(false);
const manifestTotal7Exit = ref(0);
const manifestTotalNot7 = ref(0);
const manifestTotal7NotExit = ref(0);

const dialogConfirmWABReadyToDepart = ref(false);
const dialogWABReadyToDepartType = ref("");
const dialogWABReadyToDepartAmount = ref(1);
const dialogWABReadyToDepartSign = ref("");
const dialogWABReadyToDepartRegistration = ref("");
const dialogWABReadyToDepartCountry = ref("");

const statusFilter = ref<string[]>([]);
const statusFilterWithHoldReturn = ref<string[]>([]);
const loadedConfirmedFilter = ref<any[]>([]);
const selectedLoadedConfirmed = ref("");

const modusColor = ref([
  "grey",
  "yellow darken-3",
  "red darken-3",
  "light-blue darken-1",
  "purple",
  "green darken-3",
  "brown",
  "",
  "teal darken-3",
  "orange darken-3",
]);
const dashboardModus = ref(0);
const checkModus = ref(1);
const holdReturnModus = ref(2);
const exportModus = ref(3);
const transitModus = ref(4);
const adminModus = ref(5);
const wabModus = ref(6);
const paperworkHandlingModus = ref(7);
const discrepanciesModus = ref(8);
const t2CreationModus = ref(9);
const activeModus = ref(0);

const dashboardTotalTransitWABNumbers = ref<string[]>([]);

const showShipmentValueChangeDialog = ref(false);

// NEW STUFF

const exportFlowStatusHistoryItems = ref<ExportFlowStatusHistoryItem[]>([]);

// NEW STUFF

const showHoldReturnExportDialog = ref(false);

watch(
  () => [
    filter.value.loadedConfirmed,
    filter.value.showReturn,
    filter.value.showAssigned,
    filter.value.showWithoutExportMrn,
    filter.value.step,
    filter.value.shipmentStatusDescription,
    filter.value.employee,
    filter.value.returnReasonSecond,
  ],
  (newValue) => {
    loadData(
      options.value.page,
      options.value.itemsPerPage,
      getSort(options.value.sortDesc),
      getSortField(options.value.sortBy),
      filter.value,
      true,
    );
  },
);

watch(
  () => filter.value.returnShipmentsDateRange,
  (newVal) => {
    if (newVal == null || newVal.length === 2) {
      loadData(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
        filter.value,
        true,
      );
    }
  },
);

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal: any) => {
    if (!expandValueBreak.value) {
      if (isNotNullOrUndefined(timeoutDelay)) {
        clearTimeout(timeoutDelay);
      }
      timeoutDelay = setTimeout(() => {
        loadData(
          newVal.page,
          newVal.itemsPerPage,
          getSort(newVal.sortDesc),
          getSortField(newVal.sortBy),
          filter.value,
        );
      }, 250);
    }
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      var customsHandlingId = newVal[0].id;
      isLoading.value = true;

      loadDataDate.value = Date.now();
      var callDate = loadDataDate.value;
      var customsHandlingItemPromise = api
        .getCustomsExportHandlingItemById(customsHandlingId)
        .then((response) => {
          if (loadDataDate.value != callDate) {
            return;
          }

          currentHandlingItem.value = response.data;
        });

      var shipmentDetailPromise = api
        .getCustomsShipmentDetail(customsHandlingId)
        .then((response) => (currentShipmentDetail.value = response.data));
      var exportFlowStatusHistoryPromise = api
        .getExportFlowStatusHistory(customsHandlingId)
        .then(
          (response) => (exportFlowStatusHistoryItems.value = response.data),
        );

      Promise.all([
        customsHandlingItemPromise,
        shipmentDetailPromise,
        exportFlowStatusHistoryPromise,
      ]).then(() => (isLoading.value = false));
    }
  },
  { deep: true },
);

watch(
  () => currentTab.value,
  () => {
    consolidationStore.resetState();
  },
);

onBeforeMount(() => {
  getCurrentEmployee();
  customsFlowStepsOnBeforeMount();
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Export shipments", toolbarButtons);

  for (let i = 1; i <= fullfilledStep.value; i++) {
    statusFilter.value.push(i.toString());
    statusFilterWithHoldReturn.value.push(i.toString());
  }
  statusFilterWithHoldReturn.value.push(returnStep.value.toString());
  statusFilterWithHoldReturn.value.push(customsInspectionStep.value.toString());

  getReturnReasons();
  returnReasonsBeschau.value[0] = "Customs inspection documents";
  returnReasonsBeschau.value[1] = "Customs inspection parcel";
  getCustomsCountries();
  getEmployees();
  getExportInformation();
  loadData(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
    filter.value,
  );
});

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const refresh = () => {
  loadData(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
    filter.value,
  );
};

const loadData = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
  filter: any,
  filterOn: boolean = false,
) => {
  isLoading.value = true;

  if (
    filterOn &&
    (activeModus.value == exportModus.value ||
      activeModus.value == transitModus.value)
  ) {
    customsHandlingItems.value = [];
    filteredCustomsHandlingItems.value = [];

    loadDataDate.value = Date.now();
    var callDate = loadDataDate.value;
    api
      .getCustomsExportHandlingItems(
        activeModus.value,
        currentHandlingWABItem.value!.id,
        currentValueBreak.value,
        itemsPerPage,
        page,
        orderBy,
        orderByField,
        JSON.stringify(filter),
        0,
      )
      .then((response) => {
        if (loadDataDate.value != callDate) {
          return;
        }

        customsHandlingItems.value = response.data.items ?? [];
        totalItems.value = response.data.totalItems;
        filteredCustomsHandlingItems.value = customsHandlingItems.value;
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
      });

    return;
  }

  if (activeModus.value == dashboardModus.value) {
    loadingBarDashboard.value = true;

    loadDataDate.value = Date.now();
    var callDate = loadDataDate.value;
    api
      .getDashboardTotals()
      .then((response) => {
        loadingBarDashboard.value = false;

        if (loadDataDate.value != callDate) {
          return;
        }

        dashboardTotals.value = response.data;
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
      });
  }

  if (
    activeModus.value == checkModus.value ||
    activeModus.value == holdReturnModus.value ||
    activeModus.value == adminModus.value
  ) {
    customsHandlingItems.value = [];
    filteredCustomsHandlingItems.value = [];

    loadDataDate.value = Date.now();
    var callDate = loadDataDate.value;

    if (filter.returnShipmentsDateRange?.length == 1) {
      filter.returnShipmentsDateRange = null;
    }

    api
      .getCustomsExportHandlingItems(
        activeModus.value,
        0,
        0,
        itemsPerPage,
        page,
        orderBy,
        orderByField,
        JSON.stringify(filter),
        0,
      )
      .then((response) => {
        if (loadDataDate.value != callDate) {
          return;
        }

        expanded.value = [];
        customsHandlingItems.value = response.data.items ?? [];
        totalItems.value = response.data.totalItems;
        filteredCustomsHandlingItems.value = customsHandlingItems.value;

        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
      });
  }

  if (
    activeModus.value == exportModus.value ||
    activeModus.value == transitModus.value
  ) {
    customsHandlingWABItems.value = [];

    loadDataDate.value = Date.now();
    var callDate = loadDataDate.value;
    api
      .getCustomsHandlingWABItems(activeModus.value)
      .then((response) => {
        if (loadDataDate.value != callDate) {
          return;
        }

        expanded.value = [];
        customsHandlingWABItems.value = response.data;
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
      });
  }
};

const getReturnReasons = () => {
  returnReasonsFirst.value = [];

  api
    .getReturnReasons()
    .then((response) => {
      returnReasonsFirst.value = response.data;
    })
    .catch((error) => {});
};

const getCustomsCountries = () => {
  customsCountries.value = [];

  customsCountriesApi
    .getCustomsCountries()
    .then((response) => {
      customsCountries.value = response.data;

      customsCountries.value.forEach(function (item) {
        item.description = item.code + " - " + item.description;
      });
    })
    .catch((error) => {});
};

const getEmployees = () => {
  employees.value = [];

  employeesApi
    .getEmployees()
    .then((response) => {
      employees.value = response.data.items!;
    })
    .catch((error) => {});
};

const getCurrentEmployee = () => {
  api
    .getCurrentEmployee()
    .then((response) => {
      currentEmployee.value = response.data;
    })
    .catch((error) => {});
};

const getExportInformation = () => {
  allCustomsExportInformationItems.value = [];

  exportInformationApi
    .getCustomsExportInformation(100, 1, "", "ASC")
    .then((result) => {
      allCustomsExportInformationItems.value = result.data.items ?? [];
    })
    .catch((error) => {});
};

const clearFilters = () => {
  filter.value.step = "";
  filter.value.shipmentStatusDescription = "";
  filter.value.loadedConfirmed = 0;
  filter.value.showAssigned = false;
  filter.value.showWithoutExportMrn = false;
  filter.value.showReturn = false;
  filter.value.isLoaded = "";
  filter.value.isConfirmed = "";

  if (activeModus.value == holdReturnModus.value) {
    options.value.sortBy[0] = "customsHandlingHistoryItem.createDate";
    options.value.sortDesc[0] = "DESC";
  } else {
    options.value.sortBy[0] = "customsHandlingHistoryItem.exportFlowStatus";
    options.value.sortDesc[0] = "ASC";
  }
};

const changeModus = (selectedModus: number) => {
  selectedHandlingItems.value = [];
  customsHandlingItems.value = [];
  customsHandlingWABItems.value = [];
  stopLoadingButtons();
  activeModus.value = selectedModus;
  clearFilters();

  expanded.value = [];

  if (activeModus.value == checkModus.value) {
    loadedConfirmedFilter.value = [
      { text: "Show all (mine)", value: 0 },
      { text: "Show Confirmed but not loaded (mine)", value: 1 },
      { text: "Show Loaded but not confirmed (all)", value: 2 },
    ];

    selectedLoadedConfirmed.value = "Show all (mine)";
  } else {
    loadData(
      options.value.page,
      options.value.itemsPerPage,
      getSort(options.value.sortDesc),
      getSortField(options.value.sortBy),
      filter.value,
    );
    loadedConfirmedFilter.value = [
      { text: "Show all", value: 0 },
      { text: "Show Confirmed but not loaded", value: 1 },
      { text: "Show Loaded but not confirmed", value: 2 },
    ];
    selectedLoadedConfirmed.value = "Show all";
  }
};

const changeShipmentStatus = (action: string) => {
  currentAction.value = action;
  selectedHandlingItems.value = [];
  currentHandlingItem.value!.action = action;
  selectedHandlingItems.value.push(currentHandlingItem.value!);

  saveSelectedHandlingItems(
    currentHandlingItem.value!.customsHandlingHistoryItem.exportFlowStatus,
    newFunction,
  );
};

const returnReasonsChange = () => {
  var reasonFirst = returnReasonsFirst.value.find(
    (x) => x.id == enterShipmentReasonFirst.value,
  );
  if (reasonFirst != null) {
    returnReasonsSecond.value = reasonFirst.returnReasonsSecond!;
    if (returnReasonsSecond.value.length == 1) {
      enterShipmentReasonSecond.value = returnReasonsSecond.value[0].id;
    }
  }
};

const saveDialogConfirmShipments = () => {
  currentAction.value = "Confirmed";
  selectedHandlingItems.value.forEach(function (item) {
    item.action = "Confirmed";
  });

  saveSelectedHandlingItems(checkShipmentStep.value, newFunction);
};

const saveDialogShipmentReason = (action: string) => {
  if (action == "Return") {
    currentHandlingItem.value!.customsHandlingHistoryItem.shipmentReasonFirstId =
      enterShipmentReasonFirst.value;
    currentHandlingItem.value!.customsHandlingHistoryItem.shipmentReasonSecondId =
      enterShipmentReasonSecond.value;
  } else {
    currentHandlingItem.value!.customsHandlingHistoryItem.shipmentReasonFirstId =
      undefined;
    currentHandlingItem.value!.customsHandlingHistoryItem.shipmentReasonSecondId =
      undefined;
    currentHandlingItem.value!.customsHandlingHistoryItem.shipmentReason =
      enterShipmentReason.value;
  }

  changeShipmentStatus(action);
};

const saveDialogConfirmLoading = () => {
  currentAction.value = "ConfirmLoading";
  saveSelectedHandlingItems(
    confirmLoadingStep.value,
    newFunction,
    currentValueBreak.value == 3,
    false,
  );
};

const saveDialogEnterExportMrn = () => {
  if (!(form.value as Vue & { validate: () => boolean }).validate()) {
    stopLoadingButtons();
    return;
  }

  selectedHandlingItems.value = [];
  selectedHandlingItems.value.push(currentHandlingItem.value!);
  currentHandlingItem.value!.customsHandlingHistoryItem.exportMrn =
    dialogExportMrn.value;

  currentAction.value = "changeExportMrn";
  currentHandlingItem.value!.action = "changeExportMrn";
  saveSelectedHandlingItems(-1, newFunction);
};

const saveDialogAddRouteId = () => {
  currentHandlingWABItem.value!.routeId = dialogAddRouteId.value;

  saveCurrentHandlingWABItem(newFunction, "AddRouteId");
};

const saveDialogConfirmManifest = () => {
  currentAction.value = "ConfirmManifest";
  saveSelectedHandlingItems(
    confirmManifestStep.value,
    newFunction,
    true,
    false,
  );
};

const saveDialogConfirmWABReadyToDepart = () => {
  var signs = dialogWABReadyToDepartSign.value
    ? dialogWABReadyToDepartSign.value.split("\n")
    : [];
  var sign = signs.filter((value) => value).join(",");

  currentHandlingWABItem.value!.type = dialogWABReadyToDepartType.value;
  currentHandlingWABItem.value!.amount = dialogWABReadyToDepartAmount.value;
  currentHandlingWABItem.value!.sign = sign;
  currentHandlingWABItem.value!.registration =
    dialogWABReadyToDepartRegistration.value;
  currentHandlingWABItem.value!.countryCode =
    dialogWABReadyToDepartCountry.value;

  saveCurrentHandlingWABItem(newFunction);
};

const saveSelectedHandlingItems = (
  flowStep: number,
  saveSuccessfulCallback: Function,
  validateForm: boolean = false,
  validateSelectedItems: boolean = true,
) => {
  if (
    validateSelectedItems &&
    flowStep != assignedToEmployeeStep.value &&
    selectedHandlingItems.value.length == 0
  ) {
    stopLoadingButtons();
    closeAllDialogs();
    noItemsSelected.value = true;
    return;
  }

  if (validateForm) {
    if (!(form.value as Vue & { validate: () => boolean }).validate()) {
      selectedHandlingItems.value = [];
      stopLoadingButtons();
      return;
    }
  }

  var currentHandlingWABItemId = 0;
  if (
    currentAction.value == "ConfirmLoading" ||
    currentAction.value == "ConfirmExcelIsSentToCustoms" ||
    currentAction.value == "AcceptanceFromCustoms" ||
    currentAction.value == "ConfirmManifest"
  ) {
    currentHandlingWABItemId = currentHandlingWABItem.value!.id;
  }

  api
    .saveSelectedHandlingItems(
      flowStep,
      activeModus.value,
      currentHandlingWABItemId,
      currentValueBreak.value,
      selectedHandlingItems.value,
    )
    .then((result) => {
      selectedHandlingItems.value = result.data;
      if (selectedHandlingItems.value.length == 0) {
        noItemsImported.value = true;
      } else {
        saveSuccessful.value = true;
      }

      if (selectedHandlingItems.value.length == 1) {
        currentHandlingItem.value = selectedHandlingItems.value[0];
        var filterItem = filteredCustomsHandlingItems.value.find(
          (x) => x.hawb == currentHandlingItem.value!.hawb,
        );
        var itemIndex = filteredCustomsHandlingItems.value.indexOf(filterItem!);

        if (currentHandlingItem.value.action == "LoadedAndConfirmed") {
          expanded.value = [];
          filteredCustomsHandlingItems.value.splice(itemIndex, 1);
          refresh();
        } else if (
          currentHandlingItem.value.action == "Release" ||
          currentHandlingItem.value.action == "Return" ||
          currentHandlingItem.value.action == "Customs inspection" ||
          currentHandlingItem.value.action == "changeExportMrn"
        ) {
          expanded.value = [];
          filteredCustomsHandlingItems.value.splice(itemIndex, 1);
        } else {
          filteredCustomsHandlingItems.value.splice(
            itemIndex,
            1,
            currentHandlingItem.value,
          );
        }
      }

      if (flowStep == confirmLoadingStep.value) {
        if (
          activeModus.value == exportModus.value &&
          currentValueBreak.value == 1
        ) {
          expanded.value = [];
          refresh();
        }
      }

      if (selectedHandlingItems.value.length > 1) {
        currentHandlingItem.value = null;
        refresh();
      }

      selectedHandlingItems.value = [];
      stopLoadingButtons();
      closeAllDialogs();
      if (saveSuccessfulCallback) {
        saveSuccessfulCallback();
      }
    })
    .catch((error) => {
      stopLoadingButtons();
      closeAllDialogs();
    });
};

const saveCurrentHandlingWABItem = (
  saveSuccessfulCallback: Function,
  action: string = "",
) => {
  if ((form.value as Vue & { validate: () => boolean }).validate()) {
    stopLoadingButtons();
    return;
  }

  api
    .saveCustomsHandlingWABItem(action, currentHandlingWABItem.value!)
    .then((result) => {
      currentHandlingWABItem.value = result.data;

      saveSuccessful.value = true;
      stopLoadingButtons();
      closeAllDialogs();

      if (action == "AddRouteId") {
        dialogConfirmLoading.value = true;
      } else {
        refresh();
      }

      if (saveSuccessfulCallback) {
        saveSuccessfulCallback();
      }
    })
    .catch((error) => {
      stopLoadingButtons();
      closeAllDialogs();
    });
};

const stopLoadingButtons = () => {
  loadingButton1.value = false;
  loadingButton2.value = false;
  loadingButton3.value = false;
  loadingButton4.value = false;
  loadingButton5.value = false;
  loadingButton6.value = false;
  loadingButton7.value = false;
  loadingButton8.value = false;
  loadingButton9.value = false;
  loadingButton10.value = false;
  loadingButton11.value = false;
};

const closeAllDialogs = () => {
  dialogShipmentAddMrn.value = false;
  dialogShipmentReason.value = false;
  dialogConfirmShipment.value = false;
  dialogConfirmLoading.value = false;
  dialogAddRoute.value = false;
  dialogConfirmManifest.value = false;
  dialogConfirmWABReadyToDepart.value = false;
};

const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx);
};

const emitPageInfoReceived = (toolbarItems: ToolbarItem[]) => {
  emits("PageInfoReceived", "Customs Export shipments", toolbarItems);
};

const customsHandlingItemsIds = computed(() => {
  return customsHandlingItems.value?.map((c) => c.id) ?? [];
});

const holdReturnExportDateFilter = computed(() => {
  return filter.value.returnShipmentsDateRange?.length === 2
    ? {
        fromDate: filter.value.returnShipmentsDateRange[0],
        tillDate: filter.value.returnShipmentsDateRange[1],
      }
    : null;
});
</script>
