<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1"
          :loading="isLoading"
        >
          <template #header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              :disabled="disableNewItemButton"
              @click="addNewItem"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                  <EditableField
                    :id="'customerId-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Customer"
                      :vid="'customerId-' + item.id"
                      item-name="customerId"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getCustomerName(item) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model.trim="item.customerId"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :items="customers"
                          item-text="shortName"
                          item-value="id"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'name-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Name"
                      :vid="'name-' + item.id"
                      item-name="name"
                      :item-id="item.id"
                      rules="required|max:20"
                      slim
                      :persist="true"
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.name }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.name"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          counter
                          :error-messages="errors"
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import Enabled"
                    :vid="'importEnabled-' + item.id"
                    item-name="importEnabled"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importEnabled-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importEnabled"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import Abgabenbescheid"
                    :vid="'importAbgabenbescheid-' + item.id"
                    item-name="importAbgabenbescheid"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importAbgabenbescheid-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importAbgabenbescheid"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import Entladeerlaubnis"
                    :vid="'importEntladeerlaubnis-' + item.id"
                    item-name="importEntladeerlaubnis"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importEntladeerlaubnis-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importEntladeerlaubnis"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import GenerellesEreignis"
                    :vid="'importGenerellesEreignis-' + item.id"
                    item-name="importGenerellesEreignis"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importGenerellesEreignis-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importGenerellesEreignis"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import EntscheidungAnmeldung"
                    :vid="'importEntscheidungAnmeldung-' + item.id"
                    item-name="importEntscheidungAnmeldung"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importEntscheidungAnmeldung-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importEntscheidungAnmeldung"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>

                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import ErledigungsmeldungNCTSDE"
                    :vid="'importErledigungsmeldungNCTSDE-' + item.id"
                    item-name="importErledigungsmeldungNCTSDE"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importErledigungsmeldungNCTSDE-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importErledigungsmeldungNCTSDE"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export Enabled"
                    :vid="'exportEnabled-' + item.id"
                    item-name="exportEnabled"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportEnabled-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportEnabled"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export FreierVerkehr"
                    :vid="'exportFreierVerkehr-' + item.id"
                    item-name="exportFreierVerkehr"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportFreierVerkehr-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportFreierVerkehr"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export Ankunftsanzeige"
                    :vid="'exportAnkunftsanzeige-' + item.id"
                    item-name="exportAnkunftsanzeige"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportAnkunftsanzeige-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportAnkunftsanzeige"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export Entladekommentar"
                    :vid="'exportEntladekommentar-' + item.id"
                    item-name="exportEntladekommentar"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportEntladekommentar-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportEntladekommentar"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export SummarischeAnmeldung"
                    :vid="'exportSummarischeAnmeldung-' + item.id"
                    item-name="exportSummarischeAnmeldung"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportSummarischeAnmeldung-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportSummarischeAnmeldung"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export BestaetigungVorzeitigeAnmeldung"
                    :vid="'exportBestaetigungVorzeitigeAnmeldung-' + item.id"
                    item-name="exportBestaetigungVorzeitigeAnmeldung"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportBestaetigungVorzeitigeAnmeldung-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportBestaetigungVorzeitigeAnmeldung"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    :id="'server-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Server"
                      :vid="'server-' + item.id"
                      item-name="server"
                      :item-id="item.id"
                      rules="required|max:50"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.server }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.server"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'port-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Port"
                      :vid="'port-' + item.id"
                      item-name="port"
                      :item-id="item.id"
                      rules="required|numeric"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.port }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.port"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'username-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Username"
                      :vid="'username-' + item.id"
                      item-name="username"
                      :item-id="item.id"
                      rules="required|max:50"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.username }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.username"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'password-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Password"
                      :vid="'password-' + item.id"
                      item-name="password"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{
                            item.password
                              ? item.password.replace(/./gi, "*")
                              : ""
                          }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.password"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          type="password"
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'importFolder-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Import Folder"
                      :vid="'importFolder-' + item.id"
                      item-name="importFolder"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.importFolder }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.importFolder"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'exportFolder-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Folder"
                      :vid="'exportFolder-' + item.id"
                      item-name="exportFolder"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportFolder }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.exportFolder"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Use Clearance Office"
                    :vid="
                      'useClearanceOfficeForAdressierteZollstelle-' + item.id
                    "
                    item-name="useClearanceOfficeForAdressierteZollstelle"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="
                        'useClearanceOfficeForAdressierteZollstelle-' + item.id
                      "
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="
                          item.useClearanceOfficeForAdressierteZollstelle
                        "
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    :id="'adressierteZollstelle-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Adressierte Zollstelle"
                      :vid="'adressierteZollstelle-' + item.id"
                      item-name="adressierteZollstelle"
                      :item-id="item.id"
                      rules="required|max:50"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.adressierteZollstelle }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.adressierteZollstelle"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'registeringWorkerPhoneNumber-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Registering Worker Phone Number"
                      :vid="'registeringWorkerPhoneNumber-' + item.id"
                      item-name="registeringWorkerPhoneNumber"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.registeringWorkerPhoneNumber }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.registeringWorkerPhoneNumber"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'registeringWorkerEmail-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Registering Worker Email"
                      :vid="'registeringWorkerEmail-' + item.id"
                      item-name="registeringWorkerEmail"
                      :item-id="item.id"
                      rules="required|email"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.registeringWorkerEmail }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.registeringWorkerEmail"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'issueingOffice-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Issueing Office"
                      :vid="'issueingOffice-' + item.id"
                      item-name="issueingOffice"
                      :item-id="item.id"
                      rules="required|max:20"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.issueingOffice }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.issueingOffice"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'vorpapierTypeCode-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Vorpapier Type Code"
                      :vid="'vorpapierTypeCode-' + item.id"
                      item-name="vorpapierTypeCode"
                      :item-id="item.id"
                      rules="required|max:10"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.vorpapierTypeCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.vorpapierTypeCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'empfaengerEori-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Empfaenger EORI"
                      :vid="'empfaengerEori-' + item.id"
                      item-name="empfaengerEori"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerEori }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.empfaengerEori"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'empfaengerNlnr-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Empfaenger NlNR"
                      :vid="'empfaengerNlnr-' + item.id"
                      item-name="empfaengerNlnr"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerNlnr }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.empfaengerNlnr"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'aufschubnehmerEori-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Aufschubnehmer EORI"
                      :vid="'aufschubnehmerEori-' + item.id"
                      item-name="aufschubnehmerEori"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.aufschubnehmerEori }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.aufschubnehmerEori"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'kennbuchstabenAufschub-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Kennbuchstaben Aufschub"
                      :vid="'kennbuchstabenAufschub-' + item.id"
                      item-name="kennbuchstabenAufschub"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.kennbuchstabenAufschub }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.kennbuchstabenAufschub"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'kontoNr10-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Konto NR10"
                      :vid="'kontoNr10-' + item.id"
                      item-name="kontoNr10"
                      :item-id="item.id"
                      rules="required|max:20"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.kontoNr10 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.kontoNr10"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'aufschubBin10-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Aufschub BIN10"
                      :vid="'aufschubBin10-' + item.id"
                      item-name="aufschubBin10"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.aufschubBin10 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.aufschubBin10"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'kontoNr20-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Konto NR20"
                      :vid="'kontoNr20-' + item.id"
                      item-name="kontoNr20"
                      :item-id="item.id"
                      rules="required|max:20"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.kontoNr20 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.kontoNr20"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'aufschubBin20-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Aufschub BIN20"
                      :vid="'aufschubBin20-' + item.id"
                      item-name="aufschubBin20"
                      :item-id="item.id"
                      rules="required|max:50"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.aufschubBin20 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.aufschubBin20"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'gestellungsOrt-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Gestellungs Ort"
                      :vid="'gestellungsOrt-' + item.id"
                      item-name="gestellungsOrt"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.gestellungsOrt }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.gestellungsOrt"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'bewilligungsnummerEmpfaenger-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Bewilligungsnummer Empfaenger"
                      :vid="'bewilligungsnummerEmpfaenger-' + item.id"
                      item-name="bewilligungsnummerEmpfaenger"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.bewilligungsnummerEmpfaenger }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.bewilligungsnummerEmpfaenger"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Use Hardcoded Bestimmungsstelle Dienststellnummer"
                    :vid="
                      'useHardcodedBestimmungsstelleDienststellnummer-' +
                      item.id
                    "
                    item-name="useHardcodedBestimmungsstelleDienststellnummer"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="
                        'useHardcodedBestimmungsstelleDienststellnummer-' +
                        item.id
                      "
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="
                          item.useHardcodedBestimmungsstelleDienststellnummer
                        "
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    :id="'bestimmungsstelleDienststellnummer-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Bestimmungsstelle Dienststellnummer"
                      :vid="'bestimmungsstelleDienststellnummer-' + item.id"
                      item-name="bestimmungsstelleDienststellnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.bestimmungsstelleDienststellnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.bestimmungsstelleDienststellnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Use Hardcoded Dienststelle"
                    :vid="'useHardcodedDienststelle-' + item.id"
                    item-name="useHardcodedDienststelle"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'useHardcodedDienststelle-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.useHardcodedDienststelle"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    :id="'dienststelle-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Dienststelle"
                      :vid="'dienststelle-' + item.id"
                      item-name="dienststelle"
                      :item-id="item.id"
                      rules="required|max:20"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.dienststelle }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.dienststelle"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'warehouseLicense-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Warehouse License"
                      :vid="'warehouseLicense-' + item.id"
                      item-name="warehouseLicense"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.warehouseLicense }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.warehouseLicense"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="`ortDesVerbringens-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Ort des Verbringens"
                      :vid="`ortDesVerbringens-${item.id}`"
                      item-name="ortDesVerbringens"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.ortDesVerbringens }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.ortDesVerbringens"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'exportPercentageImpost-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Percentage Import"
                      :vid="'exportPercentageImpost-' + item.id"
                      item-name="exportPercentageImpost"
                      :item-id="item.id"
                      rules="percentage:0"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportPercentageImpost }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.exportPercentageImpost"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          type="number"
                          min="0"
                          max="100"
                          step="0.01"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'exportMaximumRegular-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Maximum Regular"
                      :vid="'exportMaximumRegular-' + item.id"
                      item-name="exportMaximumRegular"
                      :item-id="item.id"
                      rules="required|numeric"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportMaximumRegular }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.exportMaximumRegular"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'exportMaximumImpost-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Maximum Impost"
                      :vid="'exportMaximumImpost-' + item.id"
                      item-name="exportMaximumImpost"
                      :item-id="item.id"
                      rules="required|numeric"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportMaximumImpost }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.exportMaximumImpost"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export Prioritize Regular"
                    :vid="'exportPrioritizeRegular-' + item.id"
                    item-name="exportPrioritizeRegular"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportPrioritizeRegular-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportPrioritizeRegular"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>

                  <EditableField
                    :id="'exportCounterRegular-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Counter Regular"
                      :vid="'exportCounterRegular-' + item.id"
                      item-name="exportCounterRegular"
                      :item-id="item.id"
                      rules="required|numeric"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCounterRegular }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.exportCounterRegular"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'exportCounterImpost-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Export Counter Impost"
                      :vid="'exportCounterImpost-' + item.id"
                      item-name="exportCounterImpost"
                      :item-id="item.id"
                      rules="required|numeric"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.exportCounterImpost }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.exportCounterImpost"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <td>
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  CustomerViewModel,
  CustomsDakosyExportCustomersApi,
  CustomsDakosyExportCustomerViewModel,
  DataApi,
} from "@/openapi";

const dataApi: DataApi = new DataApi(undefined, "");
const customsDakosyExportApi: CustomsDakosyExportCustomersApi =
  new CustomsDakosyExportCustomersApi(undefined, "");
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);

const headers = ref([
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
  { text: "Customer*", align: "start", value: "customerId" },
  { text: "Name*", value: "name" },
  { text: "Import Enabled*", value: "importEnabled" },
  { text: "Import Abgabenbescheid*", value: "importAbgabenbescheid" },
  { text: "Import Entladeerlaubnis*", value: "importEntladeerlaubnis" },
  {
    text: "Import GenerellesEreignis*",
    value: "importGenerellesEreignis",
  },
  {
    text: "Import EntscheidungAnmeldung*",
    value: "importEntscheidungAnmeldung",
  },
  {
    text: "Import ErledigungsmeldungNCTSDE*",
    value: "importErledigungsmeldungNCTSDE",
  },
  { text: "Export Enabled*", value: "exportEnabled" },
  { text: "Export FreierVerkehr*", value: "exportFreierVerkehr" },
  { text: "Export Ankunftsanzeige*", value: "exportAnkunftsanzeige" },
  { text: "Export Entladekommentar*", value: "exportEntladekommentar" },
  {
    text: "Export SummarischeAnmeldung*",
    value: "exportSummarischeAnmeldung",
  },
  {
    text: "Export BestaetigungVorzeitigeAnmeldung*",
    value: "exportBestaetigungVorzeitigeAnmeldung",
  },
  { text: "Server*", value: "server" },
  { text: "Port*", value: "port" },
  { text: "Username*", value: "username" },
  { text: "Password*", value: "password" },
  { text: "Import Folder*", value: "importFolder" },
  { text: "Export Folder*", value: "exportFolder" },
  {
    text: "Use Clearance Office For Adressierte Zollstelle*",
    value: "useClearanceOfficeForAdressierteZollstelle",
  },
  { text: "Adressierte Zollstelle*", value: "adressierteZollstelle" },
  {
    text: "Registering Worker Phone Number*",
    value: "registeringWorkerPhoneNumber",
  },
  { text: "Registering Worker Email*", value: "registeringWorkerEmail" },
  { text: "IssueingOffice*", value: "issueingOffice" },
  { text: "Vorpapier Type* Code", value: "vorpapierTypeCode" },
  { text: "Empfaenger EORI*", value: "empfaengerEori" },
  { text: "Empfaenger NLNR*", value: "empfaengerNlnr" },
  { text: "Aufschubnehmer EORI*", value: "aufschubnehmerEori" },
  { text: "Kennbuchstaben Aufschub*", value: "kennbuchstabenAufschub" },
  { text: "Konto NR 10*", value: "kontoNr10" },
  { text: "Aufschub BIN 10*", value: "aufschubBin10" },
  { text: "Konto NR 20*", value: "kontoNr20" },
  { text: "Aufschub BIN 20*", value: "aufschubBin20" },
  { text: "Gestellungs Ort*", value: "gestellungsOrt" },
  {
    text: "Bewilligungsnummer Empfaenger*",
    value: "bewilligungsnummerEmpfaenger",
  },
  {
    text: "Use Hardcoded Bestimmungsstelle Dienststellnummer*",
    value: "useHardcodedBestimmungsstelleDienststellnummer",
  },
  {
    text: "Bestimmungsstelle Dienststellnummer*",
    value: "bestimmungsstelleDienststellnummer",
  },
  {
    text: "Use Hardcoded Dienststelle*",
    value: "useHardcodedDienststelle",
  },
  { text: "Dienststelle*", value: "dienststelle" },
  { text: "Warehouse License*", value: "warehouseLicense" },
  { text: "Ort des Verbringens*", value: "ortDesVerbringens" },
  { text: "Export Percentage Impost*", value: "exportPercentageImpost" },
  { text: "Export Maximum Regular*", value: "exportMaximumRegular" },
  { text: "Export Maximum Impost*", value: "exportMaximumImpost" },
  {
    text: "Export Prioritize Regular*",
    value: "exportPrioritizeRegular",
  },
  { text: "Export Counter Regular*", value: "exportCounterRegular" },
  { text: "Export Counter Impost*", value: "exportCounterImpost" },
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
]);
const isLoading = ref(false);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const items = ref<CustomsDakosyExportCustomerViewModel[]>([]);
const totalItems = ref(0);
const disableNewItemButton = ref(false);
const customers = ref<CustomerViewModel[]>([]);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits(
    "PageInfoReceived",
    "Dakosy definitions for Import shipments",
    toolbarButtons,
  );

  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
  });
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  customsDakosyExportApi
    .getDakosyExportCustomers(itemsPerPage, page, orderBy, orderByField)
    .then((result) => {
      items.value = result.data.items as CustomsDakosyExportCustomerViewModel[];
      totalItems.value = result.data.totalItems as number;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const saveItem = async (item: CustomsDakosyExportCustomerViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(
          item[propName as keyof CustomsDakosyExportCustomerViewModel],
        ),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    let errorsArray: { id: string; errors: string[] }[] = [];
    Object.keys(validationObserver.errors).map((key) => {
      if (validationObserver.errors[key].length > 0) {
        errorsArray.push({
          id: key,
          errors: validationObserver.errors[key],
        });
      }
    });
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        var errorObject = errorsArray.find(
          (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
        );
        if (errorObject) {
          var errorElementId = errorObject?.id;
          document
            .getElementById(errorElementId)
            ?.scrollIntoView({ inline: "start" });
        }

        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);

    customsDakosyExportApi
      .saveDakosyExportCustomer(item)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((cdec) => cdec.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  customsDakosyExportApi
    .deleteDakosyExportCustomer(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    });
};

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({ id: 0 } as CustomsDakosyExportCustomerViewModel);
};

const isNewItem = (item: CustomsDakosyExportCustomerViewModel): boolean => {
  return item?.id === 0;
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const mapItem = (oldItem: any, newItem: any) => {
  for (const key in oldItem) {
    oldItem[key] = newItem[key];
  }
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};

const getCustomerName = (item: CustomsDakosyExportCustomerViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};
</script>
