<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      :loading="loading"
      height="calc(100vh - 475px)"
    >
      <template #item.actions="{ item }">
        <div class="item__actions">
          <v-btn color="success" small @click="onConnectToExisting(item)">
            <v-icon left>mdi-connection</v-icon>
            Connect to existing
          </v-btn>
          <v-btn color="success" small @click="onConnectToNew(item)">
            <v-icon left>mdi-new-box</v-icon>
            Connect to new
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <FindShipmentByHawbDialog
      v-if="showFindShipmentByHawbDialog && selectedItem"
      v-model="showFindShipmentByHawbDialog"
      :is-loading="isConnectingToExistingShipment"
      @close="onClose"
      @confirm="onSelectedExistingShipment"
    >
      <template #header
        >Connect to existing shipment</template
      ></FindShipmentByHawbDialog
    >
    <ConfirmDialog
      v-if="showGetOtherAtcValuesDialog"
      v-model="showGetOtherAtcValuesDialog"
      :is-loading="isRetrievingOtherAtcValues"
      title="Similar ATCs found"
      @close="onClose"
      @confirm="onSelectedOtherDiscrepancyValues"
    >
      <p>
        The current ATC was also found in other Bundeskasse files. Please select
        for each value whether it's Zoll or EUSt.
      </p>
      <ValidationObserver v-slot="{ invalid }">
        <v-form slim>
          <template v-for="(otherDiscrepancy, index) in otherDiscrepancyValues">
            <div
              :key="otherDiscrepancy.discrepancyId"
              class="other-discrepancy"
            >
              <div class="other-discrepancy__labels">
                <span class="other-discrepancy__label"
                  >Value: &euro;{{ otherDiscrepancy.value }}</span
                >
                <span class="other-discrepancy__label"
                  >Account number: {{ otherDiscrepancy.accountNumber }}</span
                >
                <span class="other-discrepancy__label"
                  >Due date:
                  {{
                    otherDiscrepancy.dueDate | formatDate("DD-MM-YYYY")
                  }}</span
                >
              </div>
              <ValidationProvider rules="required">
                <v-radio-group
                  v-model="otherDiscrepancy.isZoll"
                  mandatory
                  hide-details
                  class="other-discrepancy__radio"
                >
                  <v-radio label="Zoll" :value="true"></v-radio>
                  <v-radio label="EUSt" :value="false"></v-radio>
                </v-radio-group>
              </ValidationProvider>
            </div>
            <v-divider
              v-if="index !== otherDiscrepancyValues.length - 1"
              class="my-4"
            ></v-divider>
          </template>
        </v-form>
      </ValidationObserver>
    </ConfirmDialog>

    <ConnectToNewShipmentDialog
      v-if="showConnectToNewShipmentDialog"
      v-model="showConnectToNewShipmentDialog"
      :is-loading="isConnectingToNewShipment"
      :initial-form-data="connectToNewInitialFormData"
      @close="onClose"
      @confirm="connectToNew"
    ></ConnectToNewShipmentDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitSuccess } from "@/event-bus";
import {
  OtherDiscrepancyValue,
  DutiesAndTaxesApi,
  Int32StringKeyValueItem,
  NotInSovexDiscrepancyItem,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import { ValidationObserver } from "vee-validate";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import ConnectToNewShipmentDialog, {
  ConnectToNewShipmentForm,
} from "./dialogs/ConnectToNewShipmentDialog.vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog";
import FindShipmentByHawbDialog from "./dialogs/FindShipmentByHawbDialog.vue";

interface DutiesAndTaxesdiscrepanciesProps {
  id: number;
}

interface EnrichedDiscrepancyValue extends OtherDiscrepancyValue {
  isZoll: boolean;
}

const api = new DutiesAndTaxesApi(undefined, "");

const props = defineProps<DutiesAndTaxesdiscrepanciesProps>();

const headers: DataTableHeader[] = [
  { text: "ATC", value: "atcNumber", sortable: false },
  { text: "Value", value: "value", sortable: false },
  { text: "", value: "actions", sortable: false },
];

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [100, 200, 500],
  disablePagination: false,
});

const items = ref<NotInSovexDiscrepancyItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const selectedItem = ref<NotInSovexDiscrepancyItem | null>();

const showFindShipmentByHawbDialog = ref(false);
const isConnectingToExistingShipment = ref(false);

const showGetOtherAtcValuesDialog = ref(false);
const isRetrievingOtherAtcValues = ref(false);
const otherDiscrepancyValues = ref<EnrichedDiscrepancyValue[]>([]);

const showConnectToNewShipmentDialog = ref(false);
const isConnectingToNewShipment = ref(false);
const connectToNewInitialFormData = ref<ConnectToNewShipmentForm | null>(null);

let timeoutDelay = 0;
watch(options, (newValue: DataOptions, oldValue: DataOptions) => {
  clearTimeout(timeoutDelay);
  timeoutDelay = setTimeout(async () => {
    await getItems();
  }, 250);
});

async function getItems(page?: number) {
  page ??= options.value.page;
  loading.value = true;
  try {
    const response = await api.getNotInSovexDiscrepancies(
      props.id,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the items");
  }
  loading.value = false;
}

function onConnectToExisting(item: NotInSovexDiscrepancyItem) {
  showFindShipmentByHawbDialog.value = true;
  selectedItem.value = item;
}

async function onConnectToNew(item: NotInSovexDiscrepancyItem) {
  try {
    const response = await api.getOtherAtcValues(item.id);
    if (response.data.discrepancyValues?.length) {
      otherDiscrepancyValues.value =
        response.data.discrepancyValues?.map((c) => ({
          ...c,
          isZoll: false,
        })) ?? [];
      showGetOtherAtcValuesDialog.value = true;
      selectedItem.value = item;
    } else {
      showConnectToNewShipmentDialog.value = true;
    }
  } catch (e: any) {
    const errorMessages =
      e?.response?.data?.detail ??
      "Something went wrong while searching for the selected ATC in other bundeskasse files";
    emitError(errorMessages);
  }
}

async function connectToNew(eventData: ConnectToNewShipmentForm) {
  isConnectingToNewShipment.value = true;
  try {
    await api.connectDiscrepancyToNewShipment({
      abfertigEza: eventData.abfertigEza ? eventData.abfertigEza : 0,
      beschaukosten: eventData.beschaukosten ? eventData.beschaukosten : 0,
      ccCharges: eventData.ccCharges ? eventData.ccCharges : 0,
      chargesEust: eventData.chargesEust ? eventData.chargesEust : 0,
      chargesOther: eventData.chargesOther ? eventData.chargesOther : 0,
      chargesOtherWithoutVat: eventData.chargesOtherWithoutVat
        ? eventData.chargesOtherWithoutVat
        : 0,
      chargesToSender: eventData.chargesToSender ?? false,
      chargesZoll: eventData.chargesZoll ? eventData.chargesZoll : 0,
      contactEmail: eventData.contactEmail,
      contactPhone: eventData.contactPhone,
      customerId: eventData.customerId!,
      frachtkosten: eventData.frachtkosten ? eventData.frachtkosten : 0,
      hawbNumber: eventData.hawbNumber!,
      lagerkosten: eventData.lagerkosten ? eventData.lagerkosten : 0,
      incotermId: eventData.incotermId,
      invoiceAddress: eventData.invoiceAddress,
      invoiceCity: eventData.invoiceCity,
      invoiceCountryId: eventData.invoiceCountryId,
      invoiceName: eventData.invoiceName,
      invoiceZipCode: eventData.invoiceZipCode,
      isClearedByCustomer: eventData.isClearedByCustomer,
      isEza: eventData.isEza,
      origin: eventData.origin,
      pieces: eventData.pieces ? eventData.pieces : 0,
      realWeight: eventData.realWeight ? eventData.realWeight : 0,
      shipperAddress: eventData.shipperAddress,
      shipperCity: eventData.shipperCity,
      shipperCountryId: eventData.shipperCountryId,
      shipperName: eventData.shipperName,
      shipperZipCode: eventData.shipperZipCode,
      transitT1: eventData.transitT1,
      value: eventData.value ? eventData.value : 0,
      valueCurrencyId: eventData.valueCurrencyId,
      vorlageengelt: eventData.vorlageengelt ? eventData.vorlageengelt : 0,
      zessionsgeb: eventData.zessionsgeb ? eventData.zessionsgeb : 0,
      discrepancyId: selectedItem.value?.id,
      otherDiscrepancyIds:
        otherDiscrepancyValues.value?.map((c) => c.discrepancyId!) ?? [],
    });
    showConnectToNewShipmentDialog.value = false;
    await getItems();
    emitSuccess("Successfully connected to the new shipment.");
  } catch (e: any) {
    const errorMessages =
      e?.response?.data?.detail ??
      "Something went wrong while connecting to the new shipment.";
    emitError(errorMessages);
  }
  isConnectingToNewShipment.value = false;
}

async function onSelectedExistingShipment(eventData: Int32StringKeyValueItem) {
  isConnectingToExistingShipment.value = true;
  try {
    await api.connectDiscrepancyToExistingShipment({
      discrepancyId: selectedItem.value?.id,
      shipmentId: eventData.key,
    });
    emitSuccess("Successfully connected to the existing shipment");
    showFindShipmentByHawbDialog.value = false;
    await getItems();
  } catch (e: any) {
    const errorMessages =
      e?.response?.data?.detail ??
      "Something went wrong while connecting the selected HAWB.";
    emitError(errorMessages);
  }
  isConnectingToExistingShipment.value = false;
}

function onClose() {
  selectedItem.value = null;
  otherDiscrepancyValues.value = [];
}

function onSelectedOtherDiscrepancyValues() {
  let chargesZoll = 0;
  let chargesEust = 0;
  for (let otherDiscrepancy of otherDiscrepancyValues.value) {
    if (otherDiscrepancy.isZoll) {
      chargesZoll += otherDiscrepancy.value ?? 0;
    } else {
      chargesEust += otherDiscrepancy.value ?? 0;
    }
  }

  connectToNewInitialFormData.value = {
    chargesZoll: chargesZoll,
    chargesEust: chargesEust,
  };
  showGetOtherAtcValuesDialog.value = false;
  showConnectToNewShipmentDialog.value = true;
}
</script>

<style scoped lang="scss">
.item__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 10px;
}

.other-discrepancy {
  display: flex;
  align-items: start;
  gap: 10px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &__labels {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__label {
    font-size: 1rem;
    font-weight: 400;

    &:first-of-type {
      font-weight: 600;
    }
  }

  &__radio {
    margin-top: 0;
  }
}
</style>
