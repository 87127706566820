<template>
  <div class="transit-definition-editor">
    <v-autocomplete
      v-model="selectedSender"
      :items="senders"
      label="From"
      :loading="!senders.length"
      @change="onSelectedSenderChange"
    ></v-autocomplete>

    <ValidationProvider
      ref="toField"
      v-slot="{ errors }"
      rules="required"
      name="To"
    >
      <v-autocomplete
        v-model="selectedRoute"
        :items="routes"
        label="To"
        :disabled="!selectedSender || !routes.length"
        :error-messages="errors"
      ></v-autocomplete>
    </ValidationProvider>
  </div>
</template>

<script setup lang="ts">
import { TransitDeclarationData } from "@/openapi";
import { ValidationProvider } from "vee-validate";
import { ref, watch, computed, onBeforeUnmount } from "vue";
import store from "@/store";
import { DataAction } from "@/store/dataModule";
const props = defineProps({
  value: {
    type: Number,
    default: null,
  },
});
const emits = defineEmits(["input"]);
const selectedSenderId = ref<number | null>(null);

const toField = ref<InstanceType<typeof ValidationProvider> | null>(null);
onBeforeUnmount(async () => {
  await store.dispatch(DataAction.FetchTransitDeclarationDefinitionRoutes);
});

const onSelectedSenderChange = () => {
  selectedRoute.value = null;
  toField.value!.reset();
};

const transitDeclarationDefinitionRoutes = computed(
  (): TransitDeclarationData[] => {
    return store.getters.transitDeclarationDefinitionRoutes;
  },
);

const selectedRoute = computed({
  get(): number | null {
    return props.value;
  },
  set(newVal: number | null) {
    emits("input", newVal);
  },
});

const senders = computed(() => {
  return transitDeclarationDefinitionRoutes.value.map((c) => ({
    value: c.senderId,
    text: c.name,
  }));
});

const routes = computed(() => {
  return (
    transitDeclarationDefinitionRoutes.value
      .find(
        (c) =>
          c.senderId == selectedSenderId.value ||
          c.routes?.some((d) => d.routeId == selectedRoute.value),
      )
      ?.routes?.map((c) => ({ value: c.routeId, text: c.name })) ?? []
  );
});

const selectedSender = computed({
  get() {
    return (
      selectedSenderId.value ||
      (transitDeclarationDefinitionRoutes.value.find((c) =>
        c.routes?.some((d) => d.routeId == selectedRoute.value),
      )?.senderId ??
        null)
    );
  },
  set(senderId: number | null) {
    selectedSenderId.value = senderId;
  },
});
</script>
