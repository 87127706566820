import store from "@/store";
import axios from "axios";
import router from "@/router";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit("setLoggedoutState");

      const isLoginPage =
        router.currentRoute && router.currentRoute.name == "Login";
      if (!isLoginPage) {
        router.push("/login");
      }
    } else if (error.response.status === 403) {
      router.push("/");
    }
    return Promise.reject(error);
  },
);
