<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog
      :value="value"
      :close-button-state="{
        disabled: isUploadingBundeskasse,
      }"
      :confirm-button-state="{
        disabled: invalid,
        loading: isUploadingBundeskasse,
      }"
      @input="$emit('input', $event)"
      @click:close="$emit('input', false)"
      @click:confirm="confirm"
    >
      <template #header>{{ title }}</template>
      <template #content>
        <v-container>
          <v-row>
            <v-col cols="12"
              ><v-form>
                <ValidationProvider
                  v-if="!id"
                  v-slot="{ errors }"
                  name="Month"
                  rules="required"
                >
                  <DatePicker
                    v-model="formData.selectedMonth"
                    :error-messages="errors"
                    type="month"
                    label="Select month"
                  ></DatePicker>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Bundeskasse"
                  rules="required"
                >
                  <v-file-input
                    v-model="formData.file"
                    :error-messages="errors"
                    label="Upload bundeskasse"
                  ></v-file-input>
                </ValidationProvider>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import DatePicker from "@/components/editors/DatePicker.vue";
import { DefaultDialogButtonState } from "@/components/dialogs/DefaultDialog.vue";
import { computed, ref, watch } from "vue";
import { DutiesAndTaxesApi } from "@/openapi";
import { emitError, emitSuccess } from "@/event-bus";
import moment from "moment";

interface UploadBundeskasseDialogProps {
  value: boolean;
  id?: number | null;
}
interface UploadBundeskasseDialogFormData {
  selectedMonth: string | null;
  file: File | null;
}

const api = new DutiesAndTaxesApi(undefined, "");

const props = defineProps<UploadBundeskasseDialogProps>();
const emits = defineEmits(["input", "upload:success"]);

const isUploadingBundeskasse = ref(false);
const formData = ref<UploadBundeskasseDialogFormData>(getDefaultValuesForm());
const observer = ref<(Vue & { reset: () => void }) | null>(null);

const title = computed(() =>
  props.id ? "Reüpload bundeskasse" : "Upload bundeskasse",
);

watch(
  () => props.value,
  async (newValue, oldValue) => {
    if (!newValue) {
      formData.value = getDefaultValuesForm();
      observer.value?.reset();
    }
  },
);

function getDefaultValuesForm(): UploadBundeskasseDialogFormData {
  return {
    selectedMonth: null,
    file: null,
  };
}

async function confirm() {
  isUploadingBundeskasse.value = true;
  try {
    const formattedDate = formData.value.selectedMonth
      ? moment(formData.value.selectedMonth).format("YYYY-MM-DD")
      : undefined;

    await api.uploadBundeskasse(
      formData.value.file!,
      formattedDate,
      props.id ?? undefined,
    );
    emitSuccess("Succeeded in uploading the Bundeskasse");
    emits("upload:success");
  } catch (e: any) {
    const errorMessages =
      e?.response?.data?.detail ??
      "Something went wrong while uploading the Bundeskasse";
    emitError(errorMessages);
  }

  isUploadingBundeskasse.value = false;
}
</script>
