<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table :headers="headers" :items="items" :loading="isLoading">
          <template #header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              :disabled="disableNewItemButton"
              @click="addNewItem"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <v-icon small class="mr-2" @click="saveItem(item)">
                    mdi-content-save
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </td>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">{{ getCustomerName(item) }}</div>
                    <v-autocomplete
                      v-if="isEditable"
                      v-model="item.customerId"
                      :items="customers"
                      item-text="shortName"
                      item-value="id"
                      label="Edit"
                      single-line
                      autofocus
                      clearable
                      @blur="removeEditable"
                    ></v-autocomplete>
                  </div>
                </EditableField>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">
                      {{ item.daysUntilFirstReminder }}
                    </div>
                    <v-text-field
                      v-if="isEditable"
                      v-model.number="item.daysUntilFirstReminder"
                      :class="{ 'editable-field': isEditable }"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                      type="number"
                      min="0"
                      @blur="removeEditable"
                    ></v-text-field>
                  </div>
                </EditableField>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">
                      {{ item.daysUntilSecondReminder }}
                    </div>
                    <v-text-field
                      v-if="isEditable"
                      v-model.number="item.daysUntilSecondReminder"
                      :class="{ 'editable-field': isEditable }"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                      type="number"
                      min="0"
                      @blur="removeEditable"
                    ></v-text-field>
                  </div>
                </EditableField>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">
                      {{ item.daysUntilThirdReminder }}
                    </div>
                    <v-text-field
                      v-if="isEditable"
                      v-model.number="item.daysUntilThirdReminder"
                      :class="{ 'editable-field': isEditable }"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                      type="number"
                      min="0"
                      @blur="removeEditable"
                    ></v-text-field>
                  </div>
                </EditableField>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">
                      {{ item.interestPercentage }} %
                    </div>
                    <v-text-field
                      v-if="isEditable"
                      v-model.number="item.interestPercentage"
                      :class="{ 'editable-field': isEditable }"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                      type="number"
                      min="0"
                      @blur="removeEditable"
                    ></v-text-field>
                  </div>
                </EditableField>

                <EditableField
                  v-slot="{ makeEditable, removeEditable, isEditable }"
                >
                  <div @click="makeEditable">
                    <div v-if="!isEditable">
                      &euro;{{ item.reminderByPostCosts }}
                    </div>
                    <v-text-field
                      v-if="isEditable"
                      v-model.number="item.reminderByPostCosts"
                      :class="{ 'editable-field': isEditable }"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                      type="number"
                      min="0"
                      @blur="removeEditable"
                    ></v-text-field>
                  </div>
                </EditableField>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomerViewModel,
  DataApi,
  ReminderSettingsViewModel,
  PaymentReminderSettingsApi,
} from "@/openapi";

const dataApi: DataApi = new DataApi(undefined, "");
const paymentReminderSettingsApi: PaymentReminderSettingsApi =
  new PaymentReminderSettingsApi(undefined, "");
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);

const headers = ref([
  { text: " ", value: "actions", sortable: false, width: "6em" },
  { text: "Customer", value: "customerId" },
  { text: "First reminder after XX days", value: "daysUntilFirstReminder" },
  { text: "Second reminder after XX days", value: "daysUntilSecondReminder" },
  { text: "Third reminder after XX days", value: "daysUntilThirdReminder" },
  { text: "Percentage of interest", value: "interestPercentage" },
  { text: "Costs for reminder by post", value: "reminderByPostCosts" },
]);

const isLoading = ref(false);
const customers = ref<CustomerViewModel[]>([]);
const items = ref<ReminderSettingsViewModel[]>([]);

const disableNewItemButton = ref(false);
const totalItems = ref(0);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];

  emits("PageInfoReceived", "Payment Reminder Settings", toolbarButtons);

  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
  });

  loadData();
});

const refresh = () => {
  loadData();
};

const loadData = () => {
  isLoading.value = true;

  paymentReminderSettingsApi
    .getReminderSettings()
    .then((response) => {
      items.value = response.data;
      isLoading.value = false;
    })
    .catch((errors) => {
      isLoading.value = false;
    });
};

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({
    id: 0,
    customerId: 0,
    daysUntilFirstReminder: 0,
    daysUntilSecondReminder: 0,
    daysUntilThirdReminder: 0,
    interestPercentage: 0,
    reminderByPostCosts: 0,
  } as ReminderSettingsViewModel);
};

const saveItem = async (item: ReminderSettingsViewModel) => {
  isLoading.value = true;
  const isNew = isNewItem(item);
  const postData = JSON.parse(JSON.stringify(item));

  paymentReminderSettingsApi
    .saveReminderSetting(postData as ReminderSettingsViewModel)
    .then((result) => {
      if (!result?.data) {
        return;
      }

      if (isNew) {
        disableNewItemButton.value = false;
      }
    })
    .catch((error) => {
      emits("errorOccured", [error.message]);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((rs) => rs.id === item.id);

  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  paymentReminderSettingsApi
    .deleteReminderSetting(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadData();
    });
};

const isNewItem = (item: ReminderSettingsViewModel) => {
  return item?.id === 0;
};

const getCustomerName = (item: ReminderSettingsViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};
</script>
