<template>
  <v-container fluid>
    <v-snackbar v-model="downloadUnsuccessful">
      The files could not be downloaded for this date.
    </v-snackbar>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="fileDates"
          :items-per-page="20"
          :footer-props="{ itemsPerPageOptions: [5, 10, 20, -1] }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item }}</td>
              <td>
                <v-btn @click="downloadFile(props.item)">Download</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { FileDownloadApi } from "@/openapi";
const fileDownloadApi = new FileDownloadApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);
const fileDates = ref<string[]>([]);
const downloadUnsuccessful = ref(false);

const headers = [
  {
    text: "Date",
  },
];

onBeforeMount(() => {
  emits("PageInfoReceived", "Download files", []);

  loadData();
});

const loadData = () => {
  fileDownloadApi.listFileDates().then((response) => {
    fileDates.value = response.data;
  });
};

const downloadFile = (date: string) => {
  window.open(
    `api/FileDownload/downloadFiles/?date=${encodeURIComponent(date)}`,
    "_blank",
  );
};
</script>
