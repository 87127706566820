<template>
  <Fragment>
    <v-card>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-data-table
              :headers="headers"
              :items="invoices"
              hide-default-footer
              loading="isLoading"
            >
              <template #item.invoiceNumber="{ item }">
                <ValidationProvider
                  v-show="item.editable"
                  v-slot="{ errors }"
                  name="Invoice number"
                  rules="required"
                  :vid="item.id.toString()"
                  immediate
                >
                  <v-text-field
                    v-model="item.invoiceNumber"
                    dense
                    autofocus
                    :error-messages="errors"
                    @keydown.enter.prevent="saveInvoice(item)"
                  ></v-text-field>
                </ValidationProvider>
                <span v-show="!item.editable" @click="editInvoice(item)">{{
                  item.invoiceNumber ? item.invoiceNumber : "-"
                }}</span>
              </template>

              <template #item.invoiceFile="{ item }">
                <ButtonGroup>
                  <v-btn
                    color="info"
                    small
                    class="text-center"
                    @click="(currentItem = item), (showDialogUploadFile = true)"
                    ><v-icon color="white" left>mdi-upload</v-icon>
                    <span v-if="!item.invoiceFile">Upload invoice</span
                    ><span v-else>{{ item.invoiceFile.name }}</span>
                  </v-btn>
                  <v-btn
                    v-if="item.invoiceFile"
                    color="info"
                    small
                    class="text-center pa-0"
                    @click="item.invoiceFile = null"
                    ><v-icon color="white" small>mdi-close</v-icon></v-btn
                  >
                </ButtonGroup>
              </template>

              <template #item.actions="{ item }">
                <v-icon v-if="item.editable" @click="saveInvoice(item)"
                  >mdi-content-save</v-icon
                >
                <v-icon @click="removeInvoice(item.id)">mdi-delete</v-icon>
              </template>
            </v-data-table>
            <div class="mt-4">
              <v-btn
                :disabled="!noActiveEditableFields"
                color="success"
                @click="addInvoice"
                >Add invoice <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn
                :disabled="invalid"
                color="primary"
                class="ml-2"
                @click="$emit('next-step')"
              >
                Continue
              </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <UploadFileDialog
      v-model="showDialogUploadFile"
      @confirm="onFileUploaded"
    ></UploadFileDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { CustomsConsolidationApi } from "@/openapi";
import UploadFileDialog from "@/components/dialogs/UploadFileDialog.vue";
import { UploadFileForm } from "@/components/dialogs/UploadFileDialog.vue";
import { emitError } from "@/event-bus";
import ButtonGroup from "./buttons/ButtonGroup.vue";
import { computed, onBeforeMount, ref } from "vue";
import { DataTableHeader } from "vuetify";
import { useConsolidationStore } from "@/stores/consolidation-store";
import { Invoice } from "@/types/consolidation-types";

interface CustomsConsolidationInvoicesProps {
  parcelNumbers: string[];
}

const api = new CustomsConsolidationApi(undefined, "");
const consolidationStore = useConsolidationStore();
const props = withDefaults(defineProps<CustomsConsolidationInvoicesProps>(), {
  parcelNumbers: () => [],
});

const isLoading = ref(false);

const headers = ref<DataTableHeader[]>([
  {
    text: "Invoice number",
    align: "start",
    value: "invoiceNumber",
    width: "40em",
  },
  {
    text: "Invoice file",
    align: "start",
    value: "invoiceFile",
  },
  { text: "Actions", align: "end", sortable: false, value: "actions" },
]);
const originalInvoices = ref<string[]>([]);

const currentItem = ref<Invoice | null>(null);
const showDialogUploadFile = ref(false);

const getInvoices = async () => {
  try {
    isLoading.value = true;
    const response = await api.getInvoices({
      parcelNumbers: props.parcelNumbers,
    });

    originalInvoices.value = response.data;
    consolidationStore.setInvoices(
      response.data.map(
        (c: string, index: number) =>
          ({
            id: index,
            invoiceNumber: c,
            editable: false,
            invoiceFile: null,
          }) as Invoice,
      ),
    );
  } catch (e) {
    emitError("Something went wrong while retrieving the invoices");
  } finally {
    isLoading.value = false;
  }
};

const addInvoice = () => {
  let id = 0;
  if (invoices.value?.length) {
    id = invoices.value[invoices.value.length - 1].id + 1;
  }
  consolidationStore.addInvoice({
    id: id,
    invoiceNumber: "",
    editable: true,
    invoiceFile: null,
  } as Invoice);
};

const editInvoice = (item: Invoice) => {
  for (const invoice of invoices.value) {
    invoice.editable = invoice.id === item.id;
    consolidationStore.editInvoice(invoice);
  }
};

const removeInvoice = (invoiceId: number) => {
  consolidationStore.removeInvoice(invoiceId);
};

const saveInvoice = (invoice: Invoice) => {
  invoice.editable = false;
  consolidationStore.editInvoice(invoice);
};

const onFileUploaded = (item: UploadFileForm) => {
  showDialogUploadFile.value = false;
  currentItem.value!.invoiceFile = item.files![0];
  consolidationStore.editInvoice(currentItem.value!);
  currentItem.value = null;
};

const invoices = computed(() => {
  return consolidationStore.invoices;
});

const noActiveEditableFields = computed(() => {
  return invoices.value.every((c) => !c.editable);
});

onBeforeMount(async () => {
  await getInvoices();
});
</script>
