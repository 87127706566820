<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="d-flex justify-end mb-3">
          <v-btn
            right
            color="primary"
            elevation="2"
            @click="openDialogNewTemplate"
            ><v-icon color="black">mdi-plus</v-icon> Create template</v-btn
          >
        </div>
        <ValidationObserver v-slot="{ invalid }" ref="createObserver">
          <DefaultDialog v-model="showDialogNewTemplate">
            <template #header>New template for csv file</template>
            <template #content>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Csv type"
                      rules="required"
                    >
                      <v-radio-group
                        v-model="csvType"
                        row
                        :error-messages="errors"
                      >
                        <v-radio label="Import" value="import"></v-radio>
                        <v-radio label="Export" value="export"></v-radio>
                      </v-radio-group>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Template name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="newTemplateName"
                        label="Template name*"
                        dense
                        outlined
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template #footer>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="showDialogNewTemplate = false"
              >
                Close
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="blue darken-1"
                text
                :loading="isCreating"
                @click="createNewTemplate"
              >
                Create
              </v-btn>
            </template>
          </DefaultDialog>
        </ValidationObserver>

        <ConfirmDialog
          v-model="showDialogDeleteTemplate"
          :is-loading="isDeleting"
          title="Delete confirmation"
          @cancel="showDialogDeleteTemplate = false"
          @confirm="deleteTemplate"
          >Are you sure you want to delete this template?</ConfirmDialog
        >

        <v-card>
          <v-toolbar color="white" class="grey darken-3" dark flat>
            <v-toolbar-title>Template for csv file </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
              <v-select
                :items="selectableTemplates"
                dense
                outlined
                label="Select existing template"
                class="mr-4"
                hide-details
                @change="onSelectedTemplate"
              ></v-select>
              <v-btn
                class="mr-4"
                color="error"
                elevation="2"
                :disabled="!selectedTemplate"
                @click="showDialogDeleteTemplate = true"
                ><v-icon small color="white">mdi-trash-can-outline</v-icon>
                Delete template</v-btn
              >
              <v-btn
                class="mr-4"
                color="success"
                elevation="2"
                :disabled="!selectedTemplate"
                :loading="isSaving"
                @click="saveTemplate"
                ><v-icon small color="white">mdi-floppy</v-icon> Save
                template</v-btn
              >
            </div>
          </v-toolbar>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-row v-if="selectedTemplate">
            <v-col>
              <v-list dense height="calc(100vh - 280px)" class="overflow-auto">
                <v-list-item-group color="success">
                  <template
                    v-for="(lineType, index) in selectableTemplateLineTypes"
                  >
                    <v-list-item
                      :key="lineType.key"
                      color="success"
                      @click="addToLines(lineType)"
                    >
                      <v-list-item-content color="success">
                        <v-list-item-title
                          v-text="lineType.value"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < selectableTemplateLineTypes.length - 1"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="12" md="6">
              <v-list dense height="calc(100vh - 280px)" class="overflow-auto">
                <v-list-item-group color="primary">
                  <template
                    v-for="(lineType, index) in removeableTemplateLineTypes"
                  >
                    <v-list-item
                      :key="lineType.key"
                      @click="removeFromLines(lineType)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="lineType.value"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < removeableTemplateLineTypes.length - 1"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-snackbar v-model="showSuccessMessage" :timeout="5000">
            {{ successMessage }}
            <template #action="{ attrs }">
              <v-btn
                color="success"
                v-bind="attrs"
                @click="showSuccessMessage = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ToolbarItem } from "@/models/ToolbarItem";
import { CSVTemplateApi, CSVTemplateViewModel, KeyValueItem } from "@/openapi";
import { emitError } from "@/event-bus";
import { ValidationObserver } from "vee-validate";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import { computed, onBeforeMount, ref } from "vue";

const api = new CSVTemplateApi(undefined, "");

const emits = defineEmits(["PageInfoReceived"]);
const createObserver = ref<InstanceType<typeof ValidationObserver> | null>(
  null,
);

const showDialogNewTemplate = ref(false);
const showDialogDeleteTemplate = ref(false);
const csvType = ref("import");
const templates = ref<CSVTemplateViewModel[]>([]);
const selectedTemplate = ref<CSVTemplateViewModel | null>();
const newTemplateName = ref<string | null>(null);

const templateLineTypes = ref<KeyValueItem[]>([]);
const isLoading = ref(false);
const isSaving = ref(false);
const isCreating = ref(false);
const isDeleting = ref(false);

const showSuccessMessage = ref(false);
const successMessage = ref("");

const selectableTemplates = computed(() => {
  const sortedTemplates = templates.value.sort((a, b) =>
    a.isExport === b.isExport ? 0 : a.isExport ? -1 : 1,
  );

  return sortedTemplates.reduce((prev, current, index, arr) => {
    if (current.isExport && !prev.some((c) => c.header === "Export")) {
      prev.push({ header: "Export" });
    } else if (!current.isExport && !prev.some((c) => c.header === "Import")) {
      prev.push({ header: "Import" });
    }

    prev.push({ value: current.id, text: current.name });

    return prev;
  }, [] as any[]);
});

const selectableTemplateLineTypes = computed((): KeyValueItem[] => {
  if (!selectedTemplate.value) {
    return templateLineTypes.value;
  }

  return templateLineTypes.value.filter(
    (c) => !selectedTemplate.value?.lines?.some((d) => d.type === c.key),
  );
});

const removeableTemplateLineTypes = computed((): KeyValueItem[] => {
  if (!selectedTemplate.value) {
    return templateLineTypes.value;
  }

  return templateLineTypes.value
    .filter((c) => selectedTemplate.value?.lines?.some((d) => d.type === c.key))
    .sort((a, b) => getOrderOfLineById(a.key!) - getOrderOfLineById(b.key!));
});

const onSelectedTemplate = async (templateId: number) => {
  selectedTemplate.value = null;
  await getCsvTemplate(templateId);
};

const getCsvTemplates = async () => {
  isLoading.value = true;
  try {
    const response = await api.getCSVTemplates();
    templates.value = response.data.csvTemplates ?? [];
  } catch (e) {
    emitError("Something went wrong while retrieving the templates");
  }
  isLoading.value = false;
};

const getCsvTemplate = async (templateId: number) => {
  isLoading.value = true;
  try {
    const response = await api.getCSVTemplate(templateId);
    selectedTemplate.value = response.data.csvTemplate!;
  } catch (e) {
    emitError("Something went wrong while retrieving the template");
  }
  isLoading.value = false;
};

const saveTemplate = async () => {
  isSaving.value = true;
  try {
    await api.saveCSVTemplate({
      id: selectedTemplate.value?.id,
      name: selectedTemplate.value?.name!,
      lines: selectedTemplate.value?.lines!,
      isExport: selectedTemplate.value?.isExport,
    });
    setSuccessMessage(
      `Successfully saved the template '${selectedTemplate.value?.name}'`,
    );
  } catch (e) {
    emitError("Something went wrong while saving the template");
  }
  isSaving.value = false;
};

const deleteTemplate = async () => {
  isDeleting.value = true;
  try {
    await api.deleteCSVTemplate(selectedTemplate.value?.id);
    showDialogDeleteTemplate.value = false;
    setSuccessMessage(
      `Successfully deleted the template '${selectedTemplate.value?.name}'`,
    );
    selectedTemplate.value = null;
    refresh();
  } catch (e) {
    emitError("Something went wrong while deleting the template");
  }
  isDeleting.value = false;
};

const createNewTemplate = async () => {
  isCreating.value = true;
  try {
    const response = await api.saveCSVTemplate({
      name: newTemplateName.value,
      isExport: csvType.value === "export",
    });
    showDialogNewTemplate.value = false;
    selectedTemplate.value = response.data;
    setSuccessMessage(
      `Successfully created the template '${newTemplateName.value}'`,
    );
    newTemplateName.value = "";
    createObserver.value!.reset();
    refresh();
  } catch (e) {
    emitError("Something went wrong while creating the template");
  }
  isCreating.value = false;
};

const getCsvTemplateLineTypes = async () => {
  isLoading.value = true;
  try {
    const response = await api.getCSVTemplateLineTypes();
    templateLineTypes.value = response.data.csvTemplateLineTypes ?? [];
  } catch {
    emitError("Something went wrong while retrieving the template types");
  }
  isLoading.value = false;
};

const openDialogNewTemplate = () => {
  showDialogNewTemplate.value = true;
};

const refresh = () => {
  getCsvTemplates();
};

const addToLines = (lineType: KeyValueItem) => {
  let orderValues = selectedTemplate.value!.lines!.map((c) => c.order!);
  const newOrderValue = orderValues.length ? Math.max(...orderValues) + 1 : 1;
  selectedTemplate.value?.lines?.push({
    type: lineType.key,
    order: newOrderValue,
  });
};

const removeFromLines = (lineType: KeyValueItem) => {
  const lines = selectedTemplate.value?.lines;
  const index = lines?.findIndex((c) => c.type === lineType.key) ?? -1;

  if (index < 0) {
    return;
  }
  lines?.splice(index, 1);
  for (let i = 0; i < lines?.length!; i++) {
    lines![i].order = i + 1;
  }
};

const getOrderOfLineById = (id: number) => {
  return selectedTemplate.value!.lines!.find((d) => d.type === id)?.order!;
};

const setSuccessMessage = (message: string) => {
  showSuccessMessage.value = true;
  successMessage.value = message;
};

onBeforeMount(async () => {
  await Promise.all([getCsvTemplates(), getCsvTemplateLineTypes()]);
  const toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Schemes", toolbarButtons);
});
</script>
