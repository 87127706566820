<template>
  <div>
    <slot :show-dialog="toggleDialog">
      <v-btn color="info" small @click="toggleDialog"
        ><v-icon left> mdi-upload </v-icon>Upload</v-btn
      >
    </slot>
    <UploadPaperworkDialog
      v-model="showDialog"
      :is-loading="loading"
      :parcel-number="parcelNumber"
      :multiple="true"
      @confirm="onFilesSelected"
    ></UploadPaperworkDialog>
    <v-snackbar
      v-model="showSnackbar"
      color="success"
      :timeout="snackbarTimeout"
      bottom
    >
      Successfuly uploaded the file(s) with the selected category.
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import UploadPaperworkDialog, {
  UploadPaperworkFormConfirmed,
} from "../dialogs/UploadPaperworkDialog.vue";
import { CustomsPaperworkHandlingApi } from "@/openapi";
import { emitError } from "@/event-bus";
import axios from "axios";

import { serialize } from "object-to-formdata";

interface IProps {
  shipmentId: number;
  parcelNumber?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  parcelNumber: "",
});

const paperworkHandlingApi = new CustomsPaperworkHandlingApi(undefined, "");

const loading = ref(false);
const showDialog = ref(false);

const showSnackbar = ref(false);
const snackbarTimeout = ref(4000);

const toggleDialog = () => {
  showDialog.value = !showDialog.value;
};

const onFilesSelected = async (eventData: UploadPaperworkFormConfirmed) => {
  loading.value = true;
  try {
    const formData = serialize(
      {
        shipmentId: props.shipmentId,
        paperworkGroupings: eventData.paperworkGroupings.map((grouping) => ({
          paperworkCategory: grouping.category,
          documents: grouping.files,
        })),
      },
      {
        indices: true,
        dotsForObjectNotation: true,
        noFilesWithArrayNotation: true,
      },
    );

    await axios.post(
      "/api/CustomsPaperworkHandling/SavePaperworkDocumentsForShipment",
      formData,
    );

    showSnackbar.value = true;
    showDialog.value = false;
  } catch {
    emitError("Something went wrong while uploading the documents");
  }

  loading.value = false;
};
</script>
