<template>
  <div class="export-shipments-high">
    <v-btn
      :disabled="!hasSelectedHandlingItems"
      :loading="releaseAndMoveLoading"
      class="ma-4"
      @click="onReleaseAndMoveSelected"
      >RELEASE AND MOVE SHIPMENTS TO TRANSIT</v-btn
    >
    <v-data-table
      v-model="selectedHandlingItems"
      :headers="headers"
      :items="dataTableItems"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      dense
      fixed-header
      fixed-footer
      show-expand
      show-select
      class="elevation-1"
      :loading="isLoading"
    >
      <template #body.prepend>
        <tr>
          <td>
            <v-icon small>mdi-filter</v-icon>
          </td>
          <td></td>
          <td></td>
          <td>
            <v-select
              v-model="filter.step"
              :items="statusFilter"
              clearable
              chips
              dense
              single-line
              @change="onFilterSelected"
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="filter.shipmentStatusDescription"
              :items="shipmentStatusFilter"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-select>
          </td>
          <td>
            <FilterParcels
              v-model="filter.parcels"
              @input="onFilterSelected"
            ></FilterParcels>
          </td>
          <td>
            <v-text-field
              v-model="filter.exportMrn"
              clearable
              dense
              @keyup.enter="onFilterSelected"
              @click:clear="(filter.exportMrn = ''), onFilterSelected()"
            ></v-text-field>
          </td>

          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-autocomplete
              v-model="filter.shipperCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
          <td>
            <v-autocomplete
              v-model="filter.consigneeCountry"
              :items="dataStore.originCountries"
              item-key="code"
              item-text="code"
              clearable
              dense
              single-line
              @change="onFilterSelected"
            ></v-autocomplete>
          </td>
        </tr>
      </template>

      <template #item.dakosy="{ item }">
        <v-btn
          v-if="!item.dakosyErrorMessage"
          :color="item.dakosyButtonColor"
          :loading="item.dakosyButtonLoading"
          class="export-shipments-high__export-dakosy"
          @click="exportToDakosy(item)"
        >
          <v-icon left dark> {{ item.dakosyButtonIcon }} </v-icon
          >{{ item.dakosyButtonText }}</v-btn
        >
        <v-tooltip v-else right>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :color="item.dakosyButtonColor"
              :loading="item.dakosyButtonLoading"
              class="export-shipments-high__export-dakosy"
              v-on="on"
              @click="exportToDakosy(item)"
            >
              <v-icon left dark> {{ item.dakosyButtonIcon }} </v-icon
              >{{ item.dakosyButtonText }}</v-btn
            >
          </template>
          <span>{{ item.dakosyErrorMessage }}</span>
        </v-tooltip>
      </template>

      <template #item.exportFlowStatus="{ item }">
        <v-avatar
          class="flow-status-avatar"
          size="22"
          :color="flowSteps[item.exportFlowStatus][3]"
        >
          {{ item.exportFlowStatus }}
        </v-avatar>
      </template>

      <template #item.exportMrn="{ item }">
        <v-icon
          v-if="!item.isMaster && item.exportMrn == null"
          class="error--text"
          @click="openDialogShipmentAddMrn(item)"
        >
          mdi-plus-circle
        </v-icon>
        <span v-else>
          {{ item.isMaster ? item.exportMrns.join(", ") : item.exportMrn }}
        </span>
      </template>

      <template #item.shipper="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.shipper.name }}<br />
            {{ item.shipper.address }}<br />
            {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
            {{ item.shipper.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.consignee="{ item }">
        <v-row justify="center">
          <v-col>
            {{ item.consignee.name }}<br />
            {{ item.consignee.address }}<br />
            {{ item.consignee.zipcode }} {{ item.consignee.city }}<br />
            {{ item.consignee.country }}
          </v-col>
        </v-row>
      </template>

      <template #item.value="{ item }">
        <v-row>
          <v-col> {{ item.value.toFixed(2) }} </v-col>
        </v-row>
      </template>

      <template #item.articles="{ item }">
        <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">{{ article.description }}</span>
          </v-col>
          <v-col>
            <span class="articleDescription pl-4">{{ article.rcTarif }}</span>
          </v-col>
        </v-row>
        <v-row v-if="item.articles.length > 4" no-gutters>
          <v-col cols="9">
            <span class="articleDescription">more...</span>
          </v-col>
        </v-row>
      </template>

      <template #expanded-item="{ headers, item }">
        <td v-if="currentShipmentDetail" :colspan="headers.length">
          <v-flex class="pa-3 pb-5">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Shipment status</v-card-title>
                    <ShipmentPaperwork
                      class="shipment-paperwork"
                      :shipment-id="currentShipmentDetail.id"
                    ></ShipmentPaperwork>
                    <CustomsExportShipmentDetailViewNew
                      v-if="currentShipmentDetail"
                      :current-handling-item="currentShipmentDetail"
                    />
                    <div class="ms-6">
                      <HoldShipmentButton
                        :shipment-id="currentShipmentDetail.id"
                        :export-flow-status="
                          currentShipmentDetail.exportFlowStatus
                        "
                        class="ml-3 mb-5"
                        @shipmentStatusChanged="onShipmentStatusChanged"
                      >
                      </HoldShipmentButton>

                      <ReturnShipmentButton
                        :shipment-id="currentShipmentDetail.id"
                        class="ml-3 mb-5"
                        @shipmentStatusChanged="onShipmentStatusChanged"
                      >
                      </ReturnShipmentButton>

                      <CustomsInspectionButton
                        :shipment-id="currentShipmentDetail.id"
                        class="ml-3 mb-5"
                        @shipmentStatusChanged="onShipmentStatusChanged"
                      >
                      </CustomsInspectionButton>
                    </div>

                    <ShipmentStatusFlow
                      :shipment-id="currentShipmentDetail.id"
                      :active-status="currentShipmentDetail.exportFlowStatus"
                    ></ShipmentStatusFlow>

                    <v-btn
                      v-if="
                        automatedSteps.indexOf(
                          currentShipmentDetail.exportFlowStatus,
                        ) !== -1
                      "
                      :color="flowSteps[checkShipmentStep][3]"
                      outlined
                      :loading="loadingSimulateAutomatedStep"
                      class="mr-3"
                      @click="loadingSimulateAutomatedStep = true"
                    >
                      Simulate automated step
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </td>
      </template>
    </v-data-table>

    <AddMrnDialog
      v-if="currentHandlingItem"
      v-model="showDialogAddMrn"
      :parcel-number="currentHandlingItem.hawb"
      :is-loading="dialogAddMrnLoading"
      @confirm="onMrnEntered"
    ></AddMrnDialog>

    <AddRouteDialog
      v-model="showDialogAddRoute"
      :is-loading="dialogAddRouteLoading"
      title="Add route to shipments"
      @confirm="onRouteSelected"
    ></AddRouteDialog>

    <DakosyExportAusfuhrInfoDialog
      v-if="currentHandlingItem"
      v-model="showDialogAusfuhrInfo"
      :shipment-id="currentHandlingItem.id"
      :is-processing="currentHandlingItem.dakosyButtonLoading || false"
      @close="(currentHandlingItem = null), (showDialogAusfuhrInfo = false)"
      @confirm="exportToDakosyConfirm"
    ></DakosyExportAusfuhrInfoDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddRouteDialog from "./dialogs/AddRouteDialog.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CreateDakosyExportRequestArticle,
  CustomsExportHighApi,
  CustomsHighShipmentItemViewModel,
  CustomsExportApi,
  CustomsShipmentDetailViewModel,
} from "@/openapi";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";
import DakosyExportAusfuhrInfoDialog from "@/components/dialogs/DakosyExportAusfuhrInfoDialog.vue";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { DakosyExportData } from "@/types/types";
import { emitErrorWithFallback } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import { useDataStore } from "@/stores/data-store";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";

const dataStore = useDataStore();
const customsExportHighApi = new CustomsExportHighApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

interface IProps {
  color?: string;
}

interface DakosyExportButtonProperties {
  dakosyButtonIcon?: string;
  dakosyButtonColor?: string;
  dakosyButtonText?: string;
  dakosyButtonLoading?: boolean;
}

const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const props = withDefaults(defineProps<IProps>(), {
  color: "",
});
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();

const isLoading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref<any[]>([]);

const loadingSimulateAutomatedStep = ref(false);

const checkShipmentStep = ref(2);
const automatedSteps = ref([6, 9, 10, 11, 13]); // for testing

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);

const items = ref<CustomsHighShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<
  (CustomsHighShipmentItemViewModel & DakosyExportButtonProperties) | null
>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const selectedHandlingItems = ref<CustomsHighShipmentItemViewModel[]>([]);
const noItemsSelected = ref(false);

const showDialogAddMrn = ref(false);
const dialogAddMrnLoading = ref(false);

const releaseAndMoveLoading = ref(false);
const showDialogAddRoute = ref(false);
const dialogAddRouteLoading = ref(false);

const showDialogAusfuhrInfo = ref(false);

const dakosyExportStatus = ref({
  createExport: 1,
  createdExport: 2,
  sendToDakosy: 3,
  error: 4,
});

const headers = ref([
  {
    text: "Dakosy",
    value: "dakosy",
  },
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
    align: "end",
  },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eoriNumber", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  {
    text: "Warenwert",
    value: "value",
    sortable: false,
    align: "end",
  },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

const hasSelectedHandlingItems = computed(() => {
  return !!selectedHandlingItems.value?.length;
});

const dataTableItems = computed(() => {
  return items.value.map((c) => ({
    ...c,
    isSelectable: !!c.exportMrn || !!c.exportMrns?.length,
    ...getDakosyButtonProperties(c),
  }));
});

const getDakosyButtonProperties = (
  shipment: CustomsHighShipmentItemViewModel,
) => {
  let dakosyButtonColor: string = "";
  let dakosyButtonIcon: string = "";
  let dakosyButtonText: string = "";

  if (shipment.dakosyExportStatus === dakosyExportStatus.value.createExport) {
    dakosyButtonColor = "primary";
    dakosyButtonIcon = "mdi-export-variant";
    dakosyButtonText = "Create Dakosy export";
  } else if (
    shipment.dakosyExportStatus === dakosyExportStatus.value.createdExport
  ) {
    dakosyButtonColor = "blue";
    dakosyButtonIcon = "mdi-sort-clock-descending-outline";
    dakosyButtonText = "Created export";
  } else if (
    shipment.dakosyExportStatus === dakosyExportStatus.value.sendToDakosy
  ) {
    dakosyButtonColor = "success";
    dakosyButtonIcon = "mdi-check";
    dakosyButtonText = "Send to Dakosy";
  } else if (shipment.dakosyExportStatus === dakosyExportStatus.value.error) {
    dakosyButtonColor = "error";
    dakosyButtonIcon = "mdi-alert-circle-outline";
    dakosyButtonText = "Error";
  }

  return {
    dakosyButtonColor: dakosyButtonColor,
    dakosyButtonIcon: dakosyButtonIcon,
    dakosyButtonText: dakosyButtonText,
    dakosyButtonLoading: false,
  } as DakosyExportButtonProperties;
};

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeMount(() => {
  dataStore.fetchOriginCountries();
  statusFilter.value = getStatuses();
  customsFlowStepsOnBeforeMount();
  addRefreshToolbarOption(reloadShipments);
});

const loadShipments = async (page: number) => {
  isLoading.value = true;
  try {
    const response = await customsExportHighApi.getHighShipments({
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
    });

    items.value = response.data.shipments ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the shipments");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1
    ? await loadShipments(options.value.page)
    : (options.value.page = 1);
};

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadShipments(options.value.page);
    }, 250);
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      customsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value?.id!)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);

const openDialogShipmentAddMrn = (item: CustomsHighShipmentItemViewModel) => {
  stopLoadingButtons();
  currentHandlingItem.value = item;
  showDialogAddMrn.value = true;
};

const onFilterSelected = () => {
  reloadShipments();
};

const stopLoadingButtons = () => {
  loadingSimulateAutomatedStep.value = false;
};

const closeAllDialogs = () => {
  showDialogAddMrn.value = false;
  showDialogAddRoute.value = false;
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const onReleaseAndMoveSelected = async () => {
  if (selectedHandlingItems.value.every((item) => item.hasRoute)) {
    releaseAndMoveLoading.value = true;
    moveShipmentsToTransit()
      .then(() => {
        loadShipments(options.value.page);
      })
      .finally(() => (releaseAndMoveLoading.value = false));
  } else {
    showDialogAddRoute.value = true;
  }
};

const onRouteSelected = async (value: any) => {
  dialogAddRouteLoading.value = true;

  moveShipmentsToTransit(value.routeId)
    .then(() => {
      dialogAddRouteLoading.value = false;
      showDialogAddRoute.value = false;
      loadShipments(options.value.page);
    })
    .finally(() => {
      dialogAddRouteLoading.value = false;
      showDialogAddRoute.value = false;
    });
};

const onMrnEntered = async ({ mrn }: { mrn: string }) => {
  dialogAddMrnLoading.value = true;
  try {
    await customsExportApi.setExportMrn({
      mrn: mrn,
      shipmentId: currentHandlingItem.value!.id!,
    });

    var currentItem = items.value.find(
      (c) => c.id === currentHandlingItem.value!.id!,
    );
    currentItem!.exportMrn = mrn;
  } catch {
    displayError("Something went wrong while setting the MRN of the shipment");
  }

  currentHandlingItem.value = null;
  dialogAddMrnLoading.value = false;
  showDialogAddMrn.value = false;
};

const exportToDakosy = async (
  item: CustomsHighShipmentItemViewModel & DakosyExportButtonProperties,
) => {
  if (item.dakosyExportStatus !== dakosyExportStatus.value.createExport) {
    return;
  }

  showDialogAusfuhrInfo.value = true;
  currentHandlingItem.value = item;
};

const exportToDakosyConfirm = async (data: DakosyExportData) => {
  if (!currentHandlingItem.value) {
    return;
  }

  currentHandlingItem.value.dakosyButtonLoading = true;
  try {
    await customsExportHighApi.createDakosyExport({
      endDate: data.endDate ?? "",
      presentationDate: data.presentationDate ?? "",
      shipmentId: currentHandlingItem.value.id,
      useShipperAddress: data.useShipperAddress,
      abdEmail: data.aBDEmail,
      articles: data.articles.map(
        (c) =>
          ({
            id: c.id,
            wtn: c.wtn,
            goodsDescription: c.goodsDescription,
            ownWeight: c.ownWeight,
            grossWeight: c.grossWeight,
            rchsCode: c.rchsCode,
            invoiceAmount: c.invoiceAmount,
            invoiceAmountCurrency: c.invoiceCurrency,
            freightCostAmount: c.freightCostsAmount,
            freightCostAmountCurrency: c.freightCostsCurrency,
            statAmount: c.statAmount,
            statAmountUnit: c.statUnit,
          }) as CreateDakosyExportRequestArticle,
      ),
      ausfuhrInfoId: data.addressId,
      ausfuhrzollstelle: data.ausfuhrzollstelle,
      avmEmail: data.aVMEmail,
      remarkSpecialCircumstancesCharacteristics:
        data.remarkSpecialCircumstancesCharacteristics,
      specialCircumstancesCharacteristics:
        data.specialCircumstancesCharacteristics,
      vorgeseheneAusgangszollstelle: data.vorgeseheneAusgangszollstelle,
      zusatz: data.zusatz,
    });
    const currentId = currentHandlingItem.value.id;
    const foundItem = items.value.find((c) => c.id === currentId);
    if (foundItem) {
      foundItem.dakosyExportStatus = dakosyExportStatus.value.createdExport;
    }
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong during the Dakosy export");
  } finally {
    showDialogAusfuhrInfo.value = false;
    currentHandlingItem.value.dakosyButtonLoading = false;
    currentHandlingItem.value = null;
  }
};

const moveShipmentsToTransit = async (routeId?: number) => {
  try {
    const response = await customsExportHighApi.moveShipmentsToTransit({
      routeId: routeId,
      shipmentIds: selectedHandlingItems.value.map((item) => item.id!),
    });

    selectedHandlingItems.value = [];
    return response;
  } catch (e) {
    displayError(
      'Something went wrong while executing the "Release and move shipments to transit" action.',
    );
    throw e;
  }
};

const onShipmentStatusChanged = ({ success }: any) => {
  if (success) {
    selectedHandlingItems.value = [];
    loadShipments(options.value.page);
  }
};
</script>
