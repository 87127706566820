<template>
  <div class="v-card v-sheet theme--light grey darken-3 mb-5">
    <ValidationObserver v-slot="{ invalid }" ref="observerSelected">
      <div class="d-flex justify-space-between">
        <div class="v-card__title" v-if="!canEditRatecardName">
          <b class="mr-2">TEST CUSTOMER</b>
          {{ name }}
          <v-icon @click="editRatecardName" class="ml-2" color="grey darken-3"
            >mdi-pencil</v-icon
          >
        </div>
        <div class="v-card__title" v-else>
          <ValidationProvider
            name="New name"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="selectedEmailRatecard.name"
              class="mr-2"
              label="New name"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          E-mail Ratecard
        </div>
        <div class="mt-4">
          <v-btn
            class="mr-4"
            @click="openDialogDeleteRatecard"
            color="error"
            elevation="2"
            ><v-icon small color="white">mdi-trash-can-outline</v-icon> Delete
            ratecard</v-btn
          >
          <v-btn
            class="mr-4"
            @click="saveRatecard"
            :disabled="invalid"
            color="success"
            elevation="2"
            ><v-icon small color="white">mdi-floppy</v-icon> Save
            ratecard</v-btn
          >
        </div>
      </div>

      <div class="v-card__text">
        <v-select
          v-model="selectedSubCategories"
          :items="subCategories"
          chips
          label="Fixed values"
          multiple
          outlined
          hint="Select a sub category"
        ></v-select>
        <ul class="category-list">
          <li :key="index" v-for="(category, index) in selectedSubCategories">
            <span class="font-weight-bold">{{ category }}</span>
            <v-text-field
              dense
              hide-details
              class="category-list__name"
              label="Value"
              type="number"
              min="0"
              step="0.01"
              outlined
              v-model="category.value"
            ></v-text-field>

            <v-checkbox
              v-model="priceIsManually"
              label="Individual price will be entered manually"
            ></v-checkbox>
          </li>
        </ul>
      </div>
    </ValidationObserver>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
const props = defineProps({
  name: {
    type: String,
    default: "",
  },
});

const priceIsManually = ref(false);

const canEditRatecardName = ref(false);

const subCategories = ref(["A1 service", "A2 service", "A3 service"]);
const selectedSubCategories = ref<{ value: string }[]>([]);

const showDialogDeleteRatecard = ref(false);
const selectedEmailRatecard = ref({ name: "" });
const deleteRatecard = () => {
  // SOVC-669 - WIP
};

const openDialogDeleteRatecard = () => {
  showDialogDeleteRatecard.value = true;
};

const editRatecardName = () => {
  canEditRatecardName.value = true;
};

const saveRatecard = () => {
  // SOVC-669 - WIP
};
</script>
