<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="showDatepicker"
          transition="scale-transition"
          offset-y
          :close-on-content-click="false"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateDisplayText"
              class="ml-5"
              label="Date range"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="(dates = null), onFilterSelected()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            :range="true"
            @change="onFilterSelected"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      height="calc(100vh - 295px)"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="isLoading"
    >
    </v-data-table>
  </div>
</template>
<script setup lang="ts">
import { CustomsDiscrepanciesApi, DamagedShipment } from "@/openapi";
import { ref, watch, computed, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";

const api = new CustomsDiscrepanciesApi(undefined, "");
const props = defineProps({
  color: {
    type: String,
    default: "",
  },
});
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const isLoading = ref(false);
const dates = ref<string[] | null>(null);
const showDatepicker = ref(false);

const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [1, 5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<DamagedShipment[]>([]);
const totalAmountOfItems = ref(0);

const headers = ref([
  {
    text: "HAWB",
    value: "hawbNumber",
    align: "center",
    sortable: false,
  },
  {
    text: "parcelNumber",
    value: "parcelNumber",
    align: "center",
    sortable: false,
  },
  {
    text: "",
    value: "",
    align: "end",
    sortable: false,
  },
]);

watch(
  () => options.value,
  (newVal) => {
    getDamagedShipments(options.value.page);
  },
  { deep: true },
);

onBeforeMount(async () => {
  addRefreshToolbarOption(reloadDamagedShipments);
});

const getDamagedShipments = async (page: number) => {
  let fromDate;
  let toDate;

  if (dates.value?.length === 2) {
    fromDate = dates.value[0];
    toDate = dates.value[1];
  }

  isLoading.value = true;
  try {
    const response = await api.getDamagedShipments(
      fromDate,
      toDate,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    displayError("Something went wrong while retrieving the damaged shipments");
  }
  isLoading.value = false;
};

const reloadDamagedShipments = async () => {
  options.value.page == 1
    ? await getDamagedShipments(options.value.page)
    : (options.value.page = 1);
};

const onFilterSelected = () => {
  reloadDamagedShipments();
};

const dateDisplayText = computed(() => {
  return dates.value?.join(" - ");
});

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};
const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};
</script>
