<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    content-class="default-dialog"
    :persistent="persistent"
    :max-width="maxWidth"
  >
    <template v-slot:activator="props">
      <slot name="activator" v-bind="props"></slot>
    </template>
    <v-card>
      <v-card-title
        class="text-h5 primary white--text"
        :class="{ 'mb-4': !loading }"
      >
        <slot name="header"></slot>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        class="mb-4"
      ></v-progress-linear>

      <v-card-text :style="cardTextStyle">
        <slot name="content"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="footer">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="emits('click:close')"
            :loading="closeButtonState.loading"
            :disabled="closeButtonState.disabled"
            >{{ closeButtonText }}</v-btn
          >
          <v-btn
            v-if="!readonly"
            text
            @click="emits('click:confirm')"
            :loading="confirmButtonState.loading"
            :disabled="confirmButtonState.disabled"
          >
            {{ confirmButtonText }}</v-btn
          >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

export interface DefaultDialogButtonState {
  loading: boolean;
  disabled: boolean;
}
const props = defineProps({
  value: { type: Boolean, default: false },
  maxWidth: { type: String, default: "660px" },
  loading: { type: Boolean, default: false },
  persistent: { type: Boolean, default: true },
  contentOverflowHeight: { type: String, default: undefined },
  closeButtonText: { type: String, default: "Close" },
  confirmButtonText: { type: String, default: "Confirm" },
  closeButtonState: {
    type: Object as PropType<DefaultDialogButtonState>,
    default: () => ({
      loading: false,
      disabled: false,
    }),
  },
  confirmButtonState: {
    type: Object as PropType<DefaultDialogButtonState>,
    default: () => ({
      loading: false,
      disabled: false,
    }),
  },
  readonly: { type: Boolean, default: false },
});
const emits = defineEmits(["input", "click:close", "click:confirm"]);
const cardTextStyle = computed(() => {
  return props.contentOverflowHeight
    ? `height: ${props.contentOverflowHeight}; overflow: auto;`
    : "";
});
</script>
