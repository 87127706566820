<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="footerOptions"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1 stickyColumn2"
          :loading="isLoading"
        >
          <template #header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              :disabled="disableNewItemButton"
              @click="addNewItem"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td valign="top">
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>

                  <EditableField
                    :id="`name-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Name"
                      :vid="`name-${item.id}`"
                      item-name="name"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.name }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.name"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`consoFileCountry-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Customer"
                      :vid="`consoFileCountry-${item.id}`"
                      item-name="consoFileCountry"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getConsoFileCountryName(item.consoFileCountry) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.consoFileCountry"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :items="consoFileCountries"
                          item-text="value"
                          item-value="key"
                          :error-messages="errors"
                          single-line
                          autofocus
                          clearable
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`customerId-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed }"
                      name="Customer"
                      :vid="`customerId-${item.id}`"
                      item-name="customerId"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getCustomerName(item) }}
                        </div>
                        <v-autocomplete
                          v-if="isEditable"
                          v-model="item.customerId"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :items="customers"
                          item-text="shortName"
                          item-value="id"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-autocomplete>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`idCountryOfOrigin-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="idCountryOfOrigin"
                      :vid="`idCountryOfOrigin-${item.id}`"
                      item-name="idCountryOfOrigin"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getCountryName(item.idCountryOfOrigin) }}
                        </div>
                        <v-select
                          v-if="isEditable"
                          v-model="item.idCountryOfOrigin"
                          :class="{ 'editable-field': isEditable }"
                          :items="customsCountries"
                          item-text="description"
                          item-value="id"
                          label="Select"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-select>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`idCountryDestination-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="idCountryDestination"
                      :vid="`idCountryDestination-${item.id}`"
                      item-name="idCountryDestination"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ getCountryName(item.idCountryDestination) }}
                        </div>
                        <v-select
                          v-if="isEditable"
                          v-model="item.idCountryDestination"
                          :class="{ 'editable-field': isEditable }"
                          :items="customsCountries"
                          item-text="description"
                          item-value="id"
                          label="Select"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-select>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`customsExportInformationRouteIds-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="customsExportInformationRouteIds"
                      :vid="`customsExportInformationRouteIds-${item.id}`"
                      item-name="customsExportInformationRouteIds"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{
                            getCountryNames(
                              item.customsExportInformationRouteIds,
                            )
                          }}
                        </div>
                        <v-select
                          v-if="isEditable"
                          v-model="item.customsExportInformationRouteIds"
                          :class="{ 'editable-field': isEditable }"
                          :items="customsCountries"
                          item-text="description"
                          item-value="id"
                          label="Select"
                          multiple
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-select>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`manifestName-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="manifestName"
                      :vid="`manifestName-${item.id}`"
                      item-name="manifestName"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.manifestName }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.manifestName"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`numOfdays-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="numOfdays"
                      :vid="`numOfdays-${item.id}`"
                      item-name="numOfdays"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.numOfdays }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.number="item.numOfdays"
                          type="number"
                          min="0"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`anmeldeArtAusfuhrCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="anmeldeArtAusfuhrCode"
                      :vid="`anmeldeArtAusfuhrCode-${item.id}`"
                      item-name="anmeldeArtAusfuhrCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.anmeldeArtAusfuhrCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.anmeldeArtAusfuhrCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`anmeldeArtUeberfuehrungCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="anmeldeArtUeberfuehrungCode"
                      :vid="`anmeldeArtUeberfuehrungCode-${item.id}`"
                      item-name="anmeldeArtUeberfuehrungCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.anmeldeArtUeberfuehrungCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.anmeldeArtUeberfuehrungCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`verkehrszweigGrenze-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="verkehrszweigGrenze"
                      :vid="`verkehrszweigGrenze-${item.id}`"
                      item-name="verkehrszweigGrenze"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.verkehrszweigGrenze }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.verkehrszweigGrenze"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`befoerderungsmittelGrenzeArtCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="befoerderungsmittelGrenzeArtCode"
                      :vid="`befoerderungsmittelGrenzeArtCode-${item.id}`"
                      item-name="befoerderungsmittelGrenzeArtCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.befoerderungsmittelGrenzeArtCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.befoerderungsmittelGrenzeArtCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`verkehrszweigInland-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="verkehrszweigInland"
                      :vid="`verkehrszweigInland-${item.id}`"
                      item-name="verkehrszweigInland"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.verkehrszweigInland }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.verkehrszweigInland"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`ausfuhrZollstelleID-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="ausfuhrZollstelleID"
                      :vid="`ausfuhrZollstelleID-${item.id}`"
                      item-name="ausfuhrZollstelleID"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.ausfuhrZollstelleID }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.ausfuhrZollstelleID"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="`ausgangZollstelleId-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="ausgangZollstelleId"
                      :vid="`ausgangZollstelleId-${item.id}`"
                      item-name="ausgangZollstelleId"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.ausgangZollstelleId }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.ausgangZollstelleId"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`verfahrenAngemeldetCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="verfahrenAngemeldetCode"
                      :vid="`verfahrenAngemeldetCode-${item.id}`"
                      item-name="verfahrenAngemeldetCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.verfahrenAngemeldetCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.verfahrenAngemeldetCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`verfahrenVorangegangenCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="verfahrenVorangegangenCode"
                      :vid="`verfahrenVorangegangenCode-${item.id}`"
                      item-name="verfahrenVorangegangenCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.verfahrenVorangegangenCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.verfahrenVorangegangenCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versendungslandCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versendungslandCode"
                      :vid="`versendungslandCode-${item.id}`"
                      item-name="versendungslandCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versendungslandCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versendungslandCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`bestimmungslandCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="bestimmungslandCode"
                      :vid="`bestimmungslandCode-${item.id}`"
                      item-name="bestimmungslandCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.bestimmungslandCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.bestimmungslandCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`artAnmeldungKopfCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="artAnmeldungKopfCode"
                      :vid="`artAnmeldungKopfCode-${item.id}`"
                      item-name="artAnmeldungKopfCode"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.artAnmeldungKopfCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.artAnmeldungKopfCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`beladungsOrt-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="beladungsOrt"
                      :vid="`beladungsOrt-${item.id}`"
                      item-name="beladungsOrt"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.beladungsOrt }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.beladungsOrt"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`bewilligungsnummerVersender-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="bewilligungsnummerVersender"
                      :vid="`bewilligungsnummerVersender-${item.id}`"
                      item-name="bewilligungsnummerVersender"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.bewilligungsnummerVersender }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.bewilligungsnummerVersender"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`durchgangszollstelleDienststellnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="durchgangszollstelleDienststellnummer"
                      :vid="`durchgangszollstelleDienststellnummer-${item.id}`"
                      item-name="durchgangszollstelleDienststellnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.durchgangszollstelleDienststellnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.durchgangszollstelleDienststellnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`durchgangszollstelleDienststellnummer2-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="durchgangszollstelleDienststellnummer2"
                      :vid="`durchgangszollstelleDienststellnummer2-${item.id}`"
                      item-name="durchgangszollstelleDienststellnummer2"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.durchgangszollstelleDienststellnummer2 }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.durchgangszollstelleDienststellnummer2"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`bestimmungsstelleDienststellnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="bestimmungsstelleDienststellnummer"
                      :vid="`bestimmungsstelleDienststellnummer-${item.id}`"
                      item-name="bestimmungsstelleDienststellnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.bestimmungsstelleDienststellnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.bestimmungsstelleDienststellnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`abgangsstelleDienststellnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="abgangsstelleDienststellnummer"
                      :vid="`abgangsstelleDienststellnummer-${item.id}`"
                      item-name="abgangsstelleDienststellnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.abgangsstelleDienststellnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.abgangsstelleDienststellnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`zusatz-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      name="zusatz"
                      :vid="`zusatz-${item.id}`"
                      item-name="zusatz"
                      :item-id="item.id"
                      slim
                    >
                      <div @click="makeEditable">
                        <div v-if="!isEditable">
                          {{ item.zusatz }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.zusatz"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versenderName-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versenderName"
                      :vid="`versenderName-${item.id}`"
                      item-name="versenderName"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versenderName }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versenderName"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versenderStrasseHausnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versenderStrasseHausnummer"
                      :vid="`versenderStrasseHausnummer-${item.id}`"
                      item-name="versenderStrasseHausnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versenderStrasseHausnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versenderStrasseHausnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versenderOrt-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versenderOrt"
                      :vid="`versenderOrt-${item.id}`"
                      item-name="versenderOrt"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versenderOrt }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versenderOrt"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versenderPLZ-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versenderPLZ"
                      :vid="`versenderPLZ-${item.id}`"
                      item-name="versenderPLZ"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versenderPLZ }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versenderPLZ"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`versenderLand-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="versenderLand"
                      :vid="`versenderLand-${item.id}`"
                      item-name="versenderLand"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.versenderLand }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.versenderLand"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`empfaengerName-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="empfaengerName"
                      :vid="`empfaengerName-${item.id}`"
                      item-name="empfaengerName"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerName }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.empfaengerName"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`empfaengerStrasseHausnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="empfaengerStrasseHausnummer"
                      :vid="`empfaengerStrasseHausnummer-${item.id}`"
                      item-name="empfaengerStrasseHausnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerStrasseHausnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.empfaengerStrasseHausnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`empfaengerOrt-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="empfaengerOrt"
                      :vid="`empfaengerOrt-${item.id}`"
                      item-name="empfaengerOrt"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerOrt }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.empfaengerOrt"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`empfaengerPLZ-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="empfaengerPLZ"
                      :vid="`empfaengerPLZ-${item.id}`"
                      item-name="empfaengerPLZ"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerPLZ }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.empfaengerPLZ"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`empfaengerLand-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="empfaengerLand"
                      :vid="`empfaengerLand-${item.id}`"
                      item-name="empfaengerLand"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.empfaengerLand }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.empfaengerLand"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`ansprechpartnerEmailAdresse-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="ansprechpartnerEmailAdresse"
                      :vid="`ansprechpartnerEmailAdresse-${item.id}`"
                      item-name="ansprechpartnerEmailAdresse"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.ansprechpartnerEmailAdresse }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.ansprechpartnerEmailAdresse"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`ansprechpartnerTelefonnummer-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="ansprechpartnerTelefonnummer"
                      :vid="`ansprechpartnerTelefonnummer-${item.id}`"
                      item-name="ansprechpartnerTelefonnummer"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.ansprechpartnerTelefonnummer }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.ansprechpartnerTelefonnummer"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`artAnmeldungPositionCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="artAnmeldungPositionCode"
                      :vid="`artAnmeldungPositionCode-${item.id}`"
                      item-name="artAnmeldungPositionCode"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.artAnmeldungPositionCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.artAnmeldungPositionCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`sicherheitsangabenArtCode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="sicherheitsangabenArtCode"
                      :vid="`sicherheitsangabenArtCode-${item.id}`"
                      item-name="sicherheitsangabenArtCode"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.sicherheitsangabenArtCode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sicherheitsangabenArtCode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`sicherheitsangabenBezeichnungBescheinigung-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="sicherheitsangabenBezeichnungBescheinigung"
                      :vid="`sicherheitsangabenBezeichnungBescheinigung-${item.id}`"
                      item-name="sicherheitsangabenBezeichnungBescheinigung"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.sicherheitsangabenBezeichnungBescheinigung }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="
                            item.sicherheitsangabenBezeichnungBescheinigung
                          "
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`sicherheitsangabenGRN-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="sicherheitsangabenGRN"
                      :vid="`sicherheitsangabenGRN-${item.id}`"
                      item-name="sicherheitsangabenGRN"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.sicherheitsangabenGRN }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sicherheitsangabenGRN"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`sicherheitsangabenZugriffscode-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="sicherheitsangabenZugriffscode"
                      :vid="`sicherheitsangabenZugriffscode-${item.id}`"
                      item-name="sicherheitsangabenZugriffscode"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.sicherheitsangabenZugriffscode }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sicherheitsangabenZugriffscode"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="`sicherheitsangabenTIN-${item.id}`"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="sicherheitsangabenTIN"
                      :vid="`sicherheitsangabenTIN-${item.id}`"
                      item-name="sicherheitsangabenTIN"
                      :item-id="item.id"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.sicherheitsangabenTIN }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.sicherheitsangabenTIN"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import UeberlassungsmeldungNCTSDE"
                    :vid="'importUeberlassungsmeldungNCTSDE-' + item.id"
                    item-name="importUeberlassungsmeldungNCTSDE"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importUeberlassungsmeldungNCTSDE-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importUeberlassungsmeldungNCTSDE"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Import UeberlassungAusfuhr"
                    :vid="'importUeberlassungAusfuhr-' + item.id"
                    item-name="importUeberlassungAusfuhr"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'importUeberlassungAusfuhr-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.importUeberlassungAusfuhr"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export VollstaendigeAusfuhrAnmeldung"
                    :vid="'exportVollstaendigeAusfuhrAnmeldung-' + item.id"
                    item-name="exportVollstaendigeAusfuhrAnmeldung"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportVollstaendigeAusfuhrAnmeldung-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportVollstaendigeAusfuhrAnmeldung"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="providers"
                    v-slot="{ errors, failed }"
                    name="Export VersandanmeldungNCTSDE"
                    :vid="'exportVersandanmeldungNCTSDE-' + item.id"
                    item-name="exportVersandanmeldungNCTSDE"
                    :item-id="item.id"
                    rules="required"
                    slim
                  >
                    <td
                      :id="'exportVersandanmeldungNCTSDE-' + item.id"
                      :class="{ red: failed }"
                    >
                      <v-checkbox
                        v-model="item.exportVersandanmeldungNCTSDE"
                        class="mt-0 pt-0"
                        hide-details
                      ></v-checkbox>
                    </td>
                  </ValidationProvider>
                  <EditableField
                    :id="'teilnehmerEORI-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Teilnehmer EORI"
                      :vid="'teilnehmerEORI-' + item.id"
                      item-name="teilnehmerEORI"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.teilnehmerEORI }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.teilnehmerEORI"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <EditableField
                    :id="'teilnehmerNLNR-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="Teilnehmer NlNR"
                      :vid="'teilnehmerNLNR-' + item.id"
                      item-name="teilnehmerNLNR"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">
                          {{ item.teilnehmerNLNR }}
                        </div>
                        <v-text-field
                          v-if="isEditable"
                          v-model.trim="item.teilnehmerNLNR"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                  <td valign="top">
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  CustomerViewModel,
  CustomsExportInformationApi,
  CustomsExportInformationViewModel,
  DataApi,
  KeyValueItem,
  CustomsCountryViewModel,
  CustomsCountriesApi,
} from "@/openapi";

const dataApi: DataApi = new DataApi(undefined, "");
const exportInformationApi: CustomsExportInformationApi =
  new CustomsExportInformationApi(undefined, "");
const customsCountriesApi: CustomsCountriesApi = new CustomsCountriesApi(
  undefined,
  "",
);
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);
const headers = ref([
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
  { text: "Name", value: "name", width: "200px" },
  {
    text: "CONSO file country",
    align: "start",
    value: "consoFileCountry",
  },
  { text: "Customer", align: "start", value: "customerId" },
  { text: "Country Origin", value: "countryOfOrigin" },
  { text: "Country Destination", value: "countryDestination" },
  { text: "Country Route", value: "customsExportInformationRouteIds" },
  { text: "Manifest Name", value: "manifestName" },
  { text: "Number of days", value: "numOfdays" },
  { text: "Anmelde Art Ausfuhr Code", value: "anmeldeArtAusfuhrCode" },
  {
    text: "Anmelde Art Ueberfuehrung Code",
    value: "anmeldeArtUeberfuehrungCode",
  },
  { text: "Verkehrszweig Grenze", value: "verkehrszweigGrenze" },
  {
    text: "Befoerderungsmittel Grenze Art Code",
    value: "befoerderungsmittelGrenzeArtCode",
  },
  { text: "Verkehrszweig Inland", value: "verkehrszweigInland" },
  { text: "Ausfuhr Zollstelle Id", value: "ausfuhrZollstelleID" },
  { text: "Ausgangs Zollstelle ID", value: "ausgangZollstelleId" },
  { text: "Verfahren Angemeldet Code", value: "verfahrenAngemeldetCode" },
  {
    text: "Verfahren Vorangegangen Code",
    value: "verfahrenVorangegangenCode",
  },
  { text: "Versendungsland Code", value: "versendungslandCode" },
  { text: "Bestimmungsland Code", value: "bestimmungslandCode" },
  { text: "Art Anmeldung Kopf Code", value: "artAnmeldungKopfCode" },
  { text: "Beladungs Ort", value: "beladungsOrt" },
  {
    text: "Bewilligungsnummer Versender",
    value: "bewilligungsnummerVersender",
  },
  {
    text: "Durchgangszollstelle Dienststellnummer",
    value: "durchgangszollstelleDienststellnummer",
  },
  {
    text: "Durchgangszollstelle Dienststellnummer 2",
    value: "durchgangszollstelleDienststellnummer2",
  },
  {
    text: "Bestimmungsstelle Dienststellnummer",
    value: "bestimmungsstelleDienststellnummer",
  },
  {
    text: "Abgangsstelle Dienststellnummer",
    value: "abgangsstelleDienststellnummer",
  },
  { text: "Zusatz", value: "zusatz" },
  { text: "Versender Name", value: "versenderName" },
  {
    text: "Versender Strasse Hausnummer",
    value: "versenderStrasseHausnummer",
  },
  { text: "Versender Ort", value: "versenderOrt" },
  { text: "Versender PLZ", value: "versenderPLZ" },
  { text: "Versender Land", value: "versenderLand" },
  { text: "Empfaenger Name", value: "empfaengerName" },
  {
    text: "Empfaenger Strasse Hausnummer",
    value: "empfaengerStrasseHausnummer",
  },
  { text: "Empfaenger Ort", value: "empfaengerOrt" },
  { text: "Empfaenger PLZ", value: "empfaengerPLZ" },
  { text: "Empfaenger Land", value: "empfaengerLand" },
  {
    text: "Ansprechpartner Email Adresse",
    value: "ansprechpartnerEmailAdresse",
  },
  {
    text: "Ansprechpartner Telefonnummer",
    value: "ansprechpartnerTelefonnummer",
  },
  {
    text: "Art Anmeldung Position Code",
    value: "artAnmeldungPositionCode",
  },
  {
    text: "Sicherheitsangaben Art Code",
    value: "sicherheitsangabenArtCode",
  },
  {
    text: "Sicherheitsangaben Bezeichnung Bescheinigung",
    value: "sicherheitsangabenBezeichnungBescheinigung",
  },
  { text: "Sicherheitsangaben GRN", value: "sicherheitsangabenGRN" },
  {
    text: "Sicherheitsangaben Zugriffscode",
    value: "sicherheitsangabenZugriffscode",
  },
  { text: "Sicherheitsangaben TIN", value: "sicherheitsangabenTIN" },
  {
    text: "Import UeberlassungsmeldungNCTSDE*",
    value: "importUeberlassungsmeldungNCTSDE",
  },
  {
    text: "Import UeberlassungAusfuhr*",
    value: "importUeberlassungAusfuhr",
  },
  {
    text: "Export VollstaendigeAusfuhrAnmeldung*",
    value: "exportVollstaendigeAusfuhrAnmeldung",
  },
  {
    text: "Export VersandanmeldungNCTSDE*",
    value: "exportVersandanmeldungNCTSDE",
  },
  { text: "Teilnehmer EORI*", value: "teilnehmerEORI" },
  { text: "Teilnehmer NLNR*", value: "teilnehmerNLNR" },
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
]);
const isLoading = ref(false);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const items = ref<CustomsExportInformationViewModel[]>([]);
const totalItems = ref(0);
const disableNewItemButton = ref(false);
const customers = ref<CustomerViewModel[]>([]);
const customsCountries = ref<CustomsCountryViewModel[]>([]);
const consoFileCountries = ref<KeyValueItem[]>([]);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits(
    "PageInfoReceived",
    "Dakosy definitions for Export shipments",
    toolbarButtons,
  );

  getCustomsCountries();
  getConsoFileCountries();

  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
  });
});

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(
        newVal.page,
        newVal.itemsPerPage,
        getSort(newVal.sortDesc),
        getSortField(newVal.sortBy),
      );
    }, 250);
  },
);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  exportInformationApi
    .getCustomsExportInformation(itemsPerPage, page, orderBy, orderByField)
    .then((result) => {
      items.value = result.data.items as CustomsExportInformationViewModel[];
      totalItems.value = result.data.totalItems as number;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const getCustomsCountries = () => {
  customsCountries.value = [];

  customsCountriesApi
    .getCustomsCountries()
    .then((response) => {
      customsCountries.value = response.data;
    })
    .catch((error) => {});
};

const getConsoFileCountries = async () => {
  try {
    var response = await exportInformationApi.getConsoFileCountries();
    consoFileCountries.value = response.data;
  } catch {
    emits("errorOccured", [
      "Something went wrong while retrieving the ConsoFile countries.",
    ]);
  }
};

const saveItem = async (item: CustomsExportInformationViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }
  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(
          item[propName as keyof CustomsExportInformationViewModel],
        ),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    let errorsArray: { id: string; errors: string[] }[] = [];
    Object.keys(validationObserver.errors).map((key) => {
      if (validationObserver.errors[key].length > 0) {
        errorsArray.push({
          id: key,
          errors: validationObserver.errors[key],
        });
      }
    });
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        var errorObject = errorsArray.find(
          (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
        );
        if (errorObject) {
          var errorElementId = errorObject?.id;
          document
            .getElementById(errorElementId)
            ?.scrollIntoView({ inline: "start" });
        }

        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);

    exportInformationApi
      .saveCustomsExportInformation(item)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }

        validationObserver.reset();
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((cdec) => cdec.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  exportInformationApi
    .deleteCustomsExportInformation(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems(
        options.value.page,
        options.value.itemsPerPage,
        getSort(options.value.sortDesc),
        getSortField(options.value.sortBy),
      );
    });
};

const addNewItem = () => {
  disableNewItemButton.value = true;
  items.value.unshift({ id: 0 } as CustomsExportInformationViewModel);
};

const isNewItem = (item: CustomsExportInformationViewModel): boolean => {
  return item?.id === 0;
};

const getSortField = (sortFields: string[]) => {
  return sortFields[0] ?? "";
};

const getSort = (sortDesc: boolean[]) => {
  let isDesc = sortDesc[0] ?? null;
  if (!(isDesc === false || isDesc === true)) {
    return "";
  }
  return isDesc ? "DESC" : "ASC";
};

const mapItem = (oldItem: any, newItem: any) => {
  for (const key in oldItem) {
    oldItem[key] = newItem[key];
  }
};

const refresh = () => {
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    getSort(options.value.sortDesc),
    getSortField(options.value.sortBy),
  );
  disableNewItemButton.value = false;
};

const getCustomerName = (item: CustomsExportInformationViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }

  return customer.shortName;
};

const getCountryName = (id: number) => {
  var country = customsCountries.value.find((c) => c.id === id);
  if (!country) {
    return "";
  }

  return country.description;
};

const getCountryNames = (ids: number[]) => {
  if (!ids || ids.length == 0) return;

  var countryNames = getCountryName(ids[0]);
  for (var i = 1; i < ids.length; i++) {
    countryNames += " ➔ " + getCountryName(ids[i]);
  }

  return countryNames;
};

const getConsoFileCountryName = (key: number) => {
  return consoFileCountries.value.find((c) => c.key === key)?.value;
};
</script>
