<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs
          v-model="currentTab"
          background-color="grey darken-3"
          center-active
          dark
        >
          <v-tab>MAWB</v-tab>
          <v-tab>History</v-tab>
          <v-tab>HAWB overview</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <v-row align="center">
              <v-col cols="auto"
                ><v-btn
                  class="my-4"
                  :disabled="containsNewMawb"
                  @click="createMawb"
                  >Create MAWB</v-btn
                ></v-col
              >
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  class="my-4"
                  outlined
                  hide-details
                  placeholder="Search for MAWB, customer or handling partner"
                  clearable
                  dense
                  @keyup.enter="getMawbOverviewItems"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :footer-props="footerOptions"
              :server-items-length="totalAmountOfItems"
              :single-expand="true"
              :expanded.sync="expanded"
              item-key="id"
              height="calc(100vh - 123px)"
              show-expand
              class="elevation-1"
              :loading="loading"
            >
              <template #item.customerId="{ item }">
                {{ getCustomerName(item.customerId) }}
              </template>

              <template #item.eta="{ item }">
                {{ item.eta ? moment(item.eta).format("YYYY-MM-DD") : "" }}
              </template>

              <template #expanded-item="{ headers, item }">
                <td v-if="currentDetailItem" :colspan="headers.length">
                  <CustomsMawb
                    :item="currentDetailItem"
                    :general-customers="generalCustomers"
                    @updated="onUpdated"
                    @created="onCreated"
                    @canceled="onCanceled"
                  ></CustomsMawb>
                  <v-card v-if="item.id !== 0" color="lighten-3" class="ma-6">
                    <v-card-text>
                      <CustomsConsolidations
                        :mawb-id="item.id"
                      ></CustomsConsolidations>
                    </v-card-text>
                  </v-card>
                </td>
              </template>

              <template #item.data-table-expand="{ item, expand, isExpanded }">
                <td class="text-start">
                  <v-btn
                    icon
                    class="v-data-table__expand-icon"
                    :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                    :disabled="containsNewMawb"
                    @click="expand(!isExpanded)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            ><CustomsConsolidationHistory
              v-if="currentTab === 1"
            ></CustomsConsolidationHistory>
          </v-tab-item>
          <v-tab-item
            ><CustomsConsolidationHawbOverview
              v-if="currentTab === 2"
            ></CustomsConsolidationHawbOverview
          ></v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import {
  CustomerViewModel,
  CustomsFlightHandlingApi,
  DataApi,
  MAWBDetailItem,
  MAWBOverviewItem,
} from "@/openapi/api";
import CustomsMawb from "./components/CustomsMawb.vue";
import CustomsConsolidations from "./components/CustomsConsolidations.vue";
import CustomsConsolidationHawbOverview from "./components/CustomsConsolidationHawbOverview.vue";
import CustomsConsolidationHistory from "./components/CustomsConsolidationHistory.vue";

import { emitError } from "@/event-bus";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";
import { computed, ref, watch } from "vue";
import moment from "moment";

const consolidationApi = new CustomsFlightHandlingApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const currentTab = ref(0);

const headers = ref<DataTableHeader[]>([
  {
    text: "MAWB",
    value: "mawb",
    groupable: false,
  },
  { text: "Kunde", value: "customerId", groupable: false },
  { text: "ETA", value: "eta", groupable: false },
  {
    text: "Packstücke",
    value: "packages",
    groupable: false,
  },
  { text: "Gewicht (real)", value: "netWeight", groupable: false },
  {
    text: "Handlingpartner",
    value: "handlingPartner",
    groupable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);
const items = ref<MAWBOverviewItem[]>([]);
const currentOverviewItem = ref<MAWBOverviewItem | null>(null);
const currentDetailItem = ref<MAWBDetailItem | null>(null);

const expanded = ref<MAWBOverviewItem[]>([]);
const loading = ref(false);
const search = ref("");

const generalCustomers = ref<CustomerViewModel[]>([]);

watch(
  () => expanded.value,
  (newVal, oldVal) => {
    if (newVal.length === 0) {
      currentOverviewItem.value = null;
      currentDetailItem.value = null;
    } else {
      currentOverviewItem.value = newVal[0];
      if (currentOverviewItem.value.id !== 0) {
        currentDetailItem.value = null;
        getMawbDetail(currentOverviewItem.value.id as number);
      }
    }
  },
);

watch(
  () => options.value,
  (newVal, oldVal) => {
    getMawbOverviewItems();
  },
);

const getGeneralCustomers = async () => {
  try {
    const response = await dataApi.getCustomers(true);
    generalCustomers.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the general customers");
  }
};

const createMawb = () => {
  const newMawbOverviewItem = createEmptyMawbOverviewItem();
  const newMawbDetailItem = createEmptyMawbDetailItem();
  items.value.unshift(newMawbOverviewItem);
  currentOverviewItem.value = newMawbOverviewItem;
  expanded.value = [newMawbOverviewItem];
  currentDetailItem.value = newMawbDetailItem;
  totalAmountOfItems.value += 1;
};

const createEmptyMawbOverviewItem = (): MAWBOverviewItem => {
  return {
    id: 0,
    customerId: null,
    eta: "",
    handlingPartner: "",
    mawb: "",
    netWeight: 0,
    packages: 0,
  };
};

const createEmptyMawbDetailItem = (): MAWBDetailItem => {
  return {
    id: 0,
    customerId: null,
    mawb: "",
    eta: "",
    packages: 0,
    netWeight: 0,
    grossWeight: 0,
    handlingPartner: "",
  };
};

const getMawbOverviewItems = async () => {
  loading.value = true;
  try {
    const mawbResponse = await consolidationApi.getMawbs(
      options.value.page,
      options.value.itemsPerPage,
      search.value ?? "",
    );
    items.value = mawbResponse.data.items ?? [];
    options.value.page = mawbResponse.data.page!;
    totalAmountOfItems.value = mawbResponse.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went wrong while retrieving the MAWBs");
  }
  loading.value = false;
};

const getMawbDetail = async (mawbId: number) => {
  loading.value = true;
  try {
    const response = await consolidationApi.getMawbDetail(mawbId);
    currentDetailItem.value = response.data;
  } catch {
    emitError(
      "Something went wrong while trying to retrieve the MAWB information",
    );
  }

  loading.value = false;
};

const onCreated = async () => {
  await getMawbOverviewItems();
};

const onUpdated = async () => {
  await getMawbOverviewItems();
};

const onCanceled = async ({ entityId }: { entityId: number | null }) => {
  const index = items.value.findIndex((c) => c.id === entityId);

  if (index < 0) {
    return;
  }

  items.value.splice(index, 1);
  expanded.value = [];
  currentOverviewItem.value = null;
  currentDetailItem.value = null;
  totalAmountOfItems.value -= 1;
};

const getCustomerName = (customerId: number | null) => {
  return (
    generalCustomers.value.find((c) => c.id === customerId)?.shortName ?? ""
  );
};

const containsNewMawb = computed(() => {
  return items.value.some((c) => !c.id);
});

getGeneralCustomers();
</script>
