<template>
  <div class="paperwork-group">
    <div
      v-for="document in paperworkDocuments"
      :key="document.documentId"
      class="d-flex flex-column"
    >
      <ButtonGroup
        class="paperwork-document-group"
        :loading="document.loading"
        dense
      >
        <v-btn
          color="error"
          :active="false"
          :href="`/CustomsPaperworkHandling/DownloadShipmentPaperworkDocument?docId=${document.documentId}`"
          >{{ document.documentType }} XXX</v-btn
        >
        <v-btn
          color="error"
          :active="false"
          class="paperwork-document-group__change"
          :loading="document.loading"
          @click="makeEditable(document)"
          ><v-icon small>mdi-pencil</v-icon></v-btn
        >
      </ButtonGroup>
      <v-select
        v-if="document.editable"
        v-model="selectedDocumentType"
        :items="documentTypes"
        item-value="key"
        item-text="value"
        :disabled="document.loading"
        @change="changeDocumentType($event, document)"
      ></v-select>
    </div>
  </div>
</template>
<script setup lang="ts">
interface IProps {
  shipmentId: number;
}

interface EditableCategory {
  editable: boolean;
  loading: boolean;
}
import {
  CustomsHandlingPaperworkViewModel,
  CustomsPaperworkHandlingApi,
  DataApi,
  KeyValueItem,
} from "@/openapi";
import { ref, onBeforeMount } from "vue";

import ButtonGroup from "./buttons/ButtonGroup.vue";

const emits = defineEmits(["errorOccured"]);

const customsPaperworkHandlingApi = new CustomsPaperworkHandlingApi(
  undefined,
  "",
);
const dataApi = new DataApi(undefined, "");

type EnrichedPaperwork = CustomsHandlingPaperworkViewModel & EditableCategory;

const props = defineProps<IProps>();

const paperworkDocuments = ref<EnrichedPaperwork[]>([]);

const documentTypes = ref<KeyValueItem[]>([]);
const selectedDocumentType = ref<number | null>(null);

onBeforeMount(async () => {
  getPaperworkDocuments();
  getDocumentTypes();
});

const getPaperworkDocuments = () => {
  customsPaperworkHandlingApi
    .getPaperworkForShipment(props.shipmentId)
    .then((result) => {
      paperworkDocuments.value = result.data.map((c) => {
        return {
          ...c,
          editable: false,
          loading: false,
        };
      });
    })
    .catch((error) => {
      displayError(error.message);
    });
};

const getDocumentTypes = async () => {
  try {
    const response =
      await dataApi.getDocumentTypesRelatedToPaperworkCategories();
    documentTypes.value = response.data;
  } catch {
    displayError("Failed to retrieve the paperwork categories");
  }
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const makeEditable = (document: EnrichedPaperwork) => {
  if (document.editable) {
    selectedDocumentType.value = null;
  } else {
    selectedDocumentType.value = document.documentTypeId!;
  }
  document.editable = !document.editable;
};

const changeDocumentType = async (
  documentTypeId: number,
  document: EnrichedPaperwork,
) => {
  try {
    document.loading = true;
    await customsPaperworkHandlingApi.changeDocumentType(
      document.documentId,
      documentTypeId,
    );
    document.documentType = documentTypes.value.find(
      (dt) => dt.key === documentTypeId,
    )?.value;
    document.documentTypeId = documentTypeId;
    document.editable = false;
  } catch {
    displayError("Something went wrong while changing the category");
  }

  document.loading = false;
};
</script>
