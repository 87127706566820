<template>
  <Fragment>
    <ConfirmDialog
      v-model="localValue"
      title="Release | EORI number invalid or missing"
      :is-confirm-disabled="isRetrieving"
      :is-loading="isProcessing"
      @confirm="onConfirm"
    >
      <v-text-field
        v-model="formData.eoriNumber"
        dense
        label="EORI-number"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="formData.niederlassungsnummer"
        dense
        label="Niederlassungsnummer"
        outlined
      ></v-text-field>
    </ConfirmDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ProblemShipmentsApi } from "@/openapi";
import { computed, onBeforeMount, ref, watch } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export interface EoriNumberInvalidFormData {
  eoriNumber: string;
  niederlassungsnummer: string;
}

interface EoriNumberInvalidDialogProps {
  value: boolean;
  blockingMilestoneId: number;
}

const api = new ProblemShipmentsApi(undefined, "");
const props = defineProps<EoriNumberInvalidDialogProps>();
const emits = defineEmits(["input", "confirm"]);

const defaultFormValues: EoriNumberInvalidFormData = {
  eoriNumber: "",
  niederlassungsnummer: "",
};

const formData = ref<EoriNumberInvalidFormData>({ ...defaultFormValues });
const isRetrieving = ref(false);
const isProcessing = ref(false);

const getEoriNumberInvalidData = async () => {
  isRetrieving.value = true;
  try {
    const response = await api.getEoriNumberInvalidOrMissingCategoryData(
      props.blockingMilestoneId,
    );
    formData.value.eoriNumber = response.data.eoriNumber!;
    formData.value.niederlassungsnummer = response.data.niederlassungsnummer!;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the necessary data",
    );
  }
  isRetrieving.value = false;
};

const onConfirm = async () => {
  isProcessing.value = true;
  try {
    await api.setEoriNumberInvalidOrMissingCategoryData({
      blockingMilestoneId: props.blockingMilestoneId,
      eoriNumber: formData.value.eoriNumber,
      niederlassungsnummer: formData.value.niederlassungsnummer,
    });
    localValue.value = false;
    emitSuccess("Release successful");
    emits("confirm");
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while saving the changes");
  } finally {
    isProcessing.value = false;
  }
};

const localValue = computed({
  get() {
    return props.value;
  },
  set(newValue: boolean) {
    emits("input", newValue);
  },
});

watch(
  () => props.value,
  (newValue: boolean, oldValue: boolean) => {
    if (!newValue) {
      formData.value = { ...defaultFormValues };
    }
  },
);

onBeforeMount(async () => {
  await getEoriNumberInvalidData();
});
</script>
