<template>
  <Fragment>
    <slot
      name="all"
      v-bind="{ createNew: constructorFn, remove: remove, items: items }"
    >
      <div class="multiple-items-editor" v-for="(item, index) in items">
        <slot name="editor" v-bind="{ item: item, index: index }"></slot>
        <slot v-if="canDelete" name="remove" v-bind="{ remove: remove }">
          <v-icon color="error" :disabled="index === 0" @click="remove(index)"
            >mdi-delete</v-icon
          >
        </slot>
      </div>
      <slot v-if="constructorFn" name="add" v-bind="{ add: add }">
        <v-btn color="success" @click="add">Add</v-btn>
      </slot>
    </slot>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import { computed } from "vue";
const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  constructorFn: {
    type: Function,
    default: null,
  },
  canDelete: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(["input"]);

const remove = (index: number) => {
  if (index < 0) {
    emitError("Failed to remove the item");
  }

  const deepCopy = JSON.parse(JSON.stringify(props.value)) as unknown[];
  const removedElements = deepCopy.splice(index, 1);

  if (!removedElements?.length) {
    emitError("Failed to remove the item");
  }

  items.value = deepCopy;
};

const add = () => {
  items.value.push(props.constructorFn!());
};

const items = computed({
  get() {
    return props.value;
  },
  set(newValue: unknown[]) {
    emits("input", newValue);
  },
});
</script>
