<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="light-blue darken-1" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="text-caption">Please enter the LTS number</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :autofocus="true"
                  v-model="ltsNumber"
                  label="LTS number"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="!valid"
          @click="emits('confirm', { ltsNumber: ltsNumber })"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
interface IProps {
  value?: boolean;
  isLoading?: boolean;
  title?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Add LTS number",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});
const emits = defineEmits(["input", "close", "confirm"]);
const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
};

const valid = ref(true);
const ltsNumber = ref("");
</script>
