<template>
  <div>
    <div class="d-flex justify-space-between">
      <h1>Single positions</h1>
      <v-btn color="success" elevation="2" @click="openDialogNewRatecard"
        ><v-icon color="white">mdi-plus</v-icon> Create ratecard</v-btn
      >
    </div>
    <p>Select existing ratecard or create a new one.</p>
    <v-select label="Select ratecard" item-value="id" solo></v-select>
    <v-dialog v-model="showDialogNewRatecard" persistent max-width="600px">
      <ValidationObserver v-slot="{ invalid }" ref="observerNew">
        <v-card>
          <v-card-title>
            <span class="text-h5">New import ratecard</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Ratecard"
                    rules="required"
                  >
                    <v-autocomplete
                      item-value="id"
                      item-text="name"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Ratecard name"
                    rules="required"
                  >
                    <v-text-field
                      label="Ratecard name*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="showDialogNewRatecard = false"
            >
              Close
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="blue darken-1"
              text
              @click="createRatecard"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <v-dialog v-model="showDialogDeleteRatecard" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete ratecard</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>Are you sure you want to delete this ratecard?</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showDialogDeleteRatecard = false"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteRatecard">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="currentTab" background-color="primary">
      <v-tab>Fixed values</v-tab>
      <v-tab>System values</v-tab>
      <v-tab>Flexible values</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab" class="pa-3">
      <v-tab-item>
        <RatecardValues name="Single Positions"></RatecardValues>
      </v-tab-item>
      <v-tab-item>
        <RatecardValues name="Single Positions"></RatecardValues>
      </v-tab-item>
      <v-tab-item>
        <RatecardValues name="Single Positions"></RatecardValues>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import RatecardValues from "./RatecardValues.vue";

const currentTab = ref<number | null>(0);

const showDialogNewRatecard = ref(false);

const showDialogDeleteRatecard = ref(false);
const isLoading = ref(false);
const selectedTemplate = ref<number | null>(0);
const priceIsManually = ref(false);

const canEditRatecardName = ref(false);

const subCategories = ref(["A1 service", "A2 service", "A3 service"]);
const selectedSubCategories = ref([]);

const deleteRatecard = () => {
  // Logic to be added at a later date (SOVC-669)
};

const createRatecard = () => {
  // Logic to be added at a later date (SOVC-669)
};

const openDialogNewRatecard = () => {
  showDialogNewRatecard.value = true;
};

const editRatecardName = () => {
  canEditRatecardName.value = true;
};

const openDialogDeleteRatecard = () => {
  showDialogDeleteRatecard.value = true;
};

const saveRatecard = () => {
  // Logic to be added at a later date (SOVC-669)
};
</script>
