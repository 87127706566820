<template>
  <v-card>
    <validation-observer v-slot="{ invalid }">
      <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>
      <v-col>
        <v-select
          v-model="currentDocument.documentCode"
          :items="dataStore.acDocumentTypes"
          item-text="code"
          item-value="code"
          hide-details
          dense
          label="Document code"
          outlined
          @input="updateDescription"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentDocument.description"
          hide-details
          dense
          label="Description"
          outlined
        ></v-text-field>
      </v-col>
      <v-col
        ><v-text-field
          v-model="currentDocument.number"
          hide-details
          dense
          label="Number"
          outlined
        ></v-text-field
      ></v-col>
      <v-col
        ><DatePicker v-model="localDateValue" label="Date"></DatePicker
      ></v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="saving" text @click="closeDialog()">
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving || invalid"
          :loading="saving"
          text
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script setup lang="ts">
import { computed, ref, watch, onBeforeMount } from "vue";
import { useDataStore } from "@/stores/data-store";
import { ShipmentDetailAcDocumentModel } from "@/openapi";
import DatePicker from "@/components/editors/DatePicker.vue";
import moment from "moment";

const dataStore = useDataStore();

interface IProps {
  document: ShipmentDetailAcDocumentModel;
  index: number | null;
  saving: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["close", "save"]);
const saving = ref<boolean>(false);
const currentDocument = ref<ShipmentDetailAcDocumentModel>({ id: 0 });
const localDateValue = computed({
  get() {
    if (!currentDocument.value?.date) {
      return "";
    }
    return currentDocument.value?.date?.split("T")?.at(0) ?? "";
  },
  set(value: string | null) {
    if (currentDocument.value) {
      currentDocument.value.date = value;
    }
  },
});

const updateDescription = (code: string) => {
  const found = dataStore.acDocumentTypes.find((item) => item.code == code);
  if (found) {
    currentDocument.value.description = found.description;
  }
};
const closeDialog = () => {
  emit("close", false);
};

const save = () => {
  emit("save", currentDocument.value, props.index);
};

onBeforeMount(() => {
  currentDocument.value = structuredClone(props.document);
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
</style>
