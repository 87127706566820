<template>
  <v-btn-toggle v-model="btnToggleState" dense>
    <slot></slot>
  </v-btn-toggle>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const btnToggleState = ref([]);

watch(
  () => btnToggleState.value,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) == JSON.stringify(oldVal)) {
      return;
    }
    btnToggleState.value = [];
  },
);
</script>
