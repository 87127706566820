<template>
  <v-btn
    v-if="[20, 21, 22].includes(exportFlowStatus ?? 0)"
    color="error"
    :loading="loading"
    @click="changeShipmentStatus(), (loading = true)"
    class="ml-5 mb-5"
  >
    Release shipment
  </v-btn>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { emitError } from "@/event-bus";
import { ShipmentStatusApi } from "@/openapi";
interface IProps {
  shipmentId?: number;
  exportFlowStatus?: number;
}
const props = defineProps<IProps>();
const emits = defineEmits(["shipmentStatusChanged"]);
const shipmentStatusApi = new ShipmentStatusApi(undefined, "");

interface Successful {
  success: boolean;
}

const loading = ref(false);

const changeShipmentStatus = async () => {
  loading.value = true;
  let success = false;
  try {
    await shipmentStatusApi.setReleaseStatus(props.shipmentId);
    success = true;
  } catch {
    emitError("Something went wrong while releasing this shipment.");
  }

  loading.value = false;
  emits("shipmentStatusChanged", { success } as Successful);
};
</script>
