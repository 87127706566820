<template>
  <UploadFileDialog
    v-model="localValue"
    :multiple="true"
    title="Release | Invoice required"
    :is-loading="isProcessing"
    @confirm="onConfirm"
  ></UploadFileDialog>
</template>

<script setup lang="ts">
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ProblemShipmentsApi } from "@/openapi";
import { serialize } from "object-to-formdata";
import { computed, ref } from "vue";
import UploadFileDialog, {
  UploadFileForm,
} from "@/components/dialogs/UploadFileDialog.vue";

interface InvoiceRequiredDialogProps {
  value: boolean;
  blockingMilestoneId: number;
}

const api = new ProblemShipmentsApi(undefined, "");
const props = defineProps<InvoiceRequiredDialogProps>();
const emits = defineEmits(["input", "confirm"]);

const isProcessing = ref(false);

const onConfirm = async (eventData: UploadFileForm) => {
  isProcessing.value = true;
  try {
    await api.setInvoiceRequiredCategoryData(
      props.blockingMilestoneId,
      eventData.files!,
    );
    localValue.value = false;
    emitSuccess("Release successful");
    emits("confirm");
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while saving the changes");
  } finally {
    isProcessing.value = false;
  }
};

const localValue = computed({
  get() {
    return props.value;
  },
  set(newValue: boolean) {
    emits("input", newValue);
  },
});
</script>
