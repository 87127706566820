<template>
  <v-dialog
    :value="value"
    @input="emits('input', $event)"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="light-blue darken-1" dark>
        <span class="ml-3 text-button">{{ title }}</span>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onEnter">
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="text-caption">Please enter the HAWB</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :autofocus="true"
                  v-model="formData.hawbNumber"
                  label="HAWB"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-1"
          text
          :disabled="isLoading"
          @click="emits('input', false), emits('close')"
        >
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          :disabled="!valid"
          @click="emitValues"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

export interface SetHawbNumberForm {
  hawbNumber: string;
}

interface IProps {
  value?: boolean;
  isLoading?: boolean;
  title?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  value: false,
  isLoading: false,
  title: "Set HAWB",
  confirmButtonText: "Confirm",
  closeButtonText: "Close",
});

const emits = defineEmits(["confirm", "input", "close"]);
const form = ref(null);
const rules = {
  required: (value: string) => (value && !!value.toString()) || "Required",
};

const valid = ref(true);
const formData = ref<SetHawbNumberForm>({ hawbNumber: "" });

watch(
  () => props.value,
  (newVal) => {
    if (!newVal) {
      (
        form.value! as Vue & {
          resetValidation: () => boolean;
        }
      ).resetValidation();

      formData.value.hawbNumber = "";
    }
  },
);

const onEnter = () => {
  if (!valid.value) {
    return;
  }
  emitValues();
};

const emitValues = () => {
  emits("confirm", formData);
};
</script>
