<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :disabled="disabled"
    cache-items
    clearable
    label="Senders"
    @input="emits('input', $event)"
    @change="emits('change', $event)"
  >
  </v-autocomplete>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { CustomsArticleMaintenanceApi } from "@/openapi";
import { emitError } from "@/event-bus";

const api = new CustomsArticleMaintenanceApi(undefined, "");
const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  debounce: {
    type: Number,
    default: 500,
  },
  isExport: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    validator: (value: string) => ["open", "fulfilled"].includes(value),
  },
});
const emits = defineEmits(["input", "change"]);
const loading = ref(false);
const search = ref("");
const items = ref<string[]>([]);

let timeout = 0;
watch(
  () => search.value,
  (newVal) => {
    clearTimeout(timeout);
    if (!newVal) {
      return;
    }

    timeout = setTimeout(getSenders, props.debounce);
  },
);

const getSenders = async () => {
  loading.value = true;
  try {
    const response =
      props.type === "open"
        ? await api.getOpenSenders(search.value, props.isExport)
        : await api.getFulfilledSenders(search.value, props.isExport);
    items.value = response.data;
  } catch {
    emitError(
      "Something went wrong while retrieving the selectable sender options.",
    );
  }
  loading.value = false;
};
</script>
