<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="tab" dark background-color="grey darken-3">
          <v-tab>Dakosy (shipment)</v-tab>
          <v-tab>Dakosy (MRN)</v-tab>
          <v-tab>Shipment importer</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-3">
          <v-tab-item>
            <DakosyShipment v-if="tab === 0"></DakosyShipment>
          </v-tab-item>
          <v-tab-item>
            <DakosyMRN v-if="tab === 1"></DakosyMRN>
          </v-tab-item>
          <v-tab-item>
            <ShipmentImporter v-if="tab === 2"></ShipmentImporter>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import DakosyShipment from "./components/DakosyShipment.vue";
import DakosyMRN from "./components/DakosyMRN.vue";
import ShipmentImporter from "./components/ShipmentImporter.vue";

const tab = ref(0);
</script>
<style lang="scss"></style>
