<template>
  <!-- ToDo SOVC-758: Add models to inputs -->
  <v-card class="pa-3 references-card mb-1">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>References</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.mawbNumber"
          label="MAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.hawbNumber"
          label="HAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.placeholder.connectedSchemeId"
          :items="dataStore.customsSchemes"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Scheme"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.orderDetails.customerId"
          :items="dataStore.generalCustomers"
          item-text="shortName"
          item-value="id"
          hide-details
          dense
          label="General customer"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="invoiceNumbers"
          label="Invoice numbers"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" @click="openParcels()">Parcels</v-btn>
      </v-col>
      <v-dialog v-model="parcelsDialog" width="1200">
        <ShipmentInfoParcelsDialog
          :key="timeStampKey"
          :detail.sync="detail"
          :save="detail"
          :open-dialog.sync="parcelsDialog"
        ></ShipmentInfoParcelsDialog>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { getInvoiceNumbers } from "@/helpers/shipmentDetailHelper";
import { useDataStore } from "@/stores/data-store";
import ShipmentInfoParcelsDialog from "./dialogs/ShipmentInfoParcelsDialog.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();

const timeStampKey = ref(Date.now());

const parcelsDialog = ref<boolean>(false);

const openParcels = () => {
  timeStampKey.value = Date.now();
  parcelsDialog.value = true;
};

const invoiceNumbers = computed(() => {
  return getInvoiceNumbers(props.detail.invoices);
});
</script>
