<template>
  <Fragment>
    <v-btn
      v-if="exportFlowStatus != 20"
      color="error"
      :class="cssClasses"
      @click="showDialog = true"
    >
      <span class="white--text"> Hold shipment </span>
    </v-btn>

    <SetHoldReasonDialog
      v-if="showDialog"
      v-model="showDialog"
      :is-loading="loading"
      @confirm="onConfirmedHoldReason"
    ></SetHoldReasonDialog>
  </Fragment>
</template>

<script setup lang="ts">
interface IProps {
  shipmentId: number;
  exportFlowStatus: number;
}
import { ref, computed } from "vue";
import { ShipmentStatusApi } from "@/openapi";
import SetHoldReasonDialog, {
  SetHoldReasonForm,
} from "../dialogs/SetHoldReasonDialog.vue";
import { emitError } from "@/event-bus";
import { getCurrentInstance } from "vue";
const instance = getCurrentInstance();
const shipmentStatusApi = new ShipmentStatusApi(undefined, "");
const emits = defineEmits(["shipmentStatusChanged"]);
const props = defineProps<IProps>();

interface Successful {
  success: boolean;
}

const loading = ref(false);
const showDialog = ref(false);

const onConfirmedHoldReason = async (value: SetHoldReasonForm) => {
  loading.value = true;
  let success = false;
  try {
    await shipmentStatusApi.setHoldStatus({
      shipmentId: props.shipmentId,
      reason: value.reason,
    });

    success = true;
    showDialog.value = false;
  } catch {
    emitError("Something went wrong while setting the Hold status.");
  }

  loading.value = false;
  emits("shipmentStatusChanged", { success } as Successful);
};

const cssClasses = computed(() => {
  return instance?.proxy?.$vnode?.data?.staticClass ?? "";
});
</script>
