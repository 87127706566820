<template>
  <v-stepper alt-labels :value="activeStatus" class="shipment-status-flow">
    <v-stepper-header>
      <template v-for="step in fullfilledStep">
        <v-stepper-step
          :step="step"
          :color="flowSteps[step][3]"
          :complete="step < activeStatus && !(activeStatus >= 20)"
          class="d-flex"
        >
          <div
            class="shipment-status-flow__step-info d-flex align-center flex-column"
          >
            <span class="text-caption text-center">{{ step }}</span>
            <span class="text-caption text-center">{{
              flowSteps[step][4]
            }}</span>
          </div>
          <template v-if="groupedHistoryItems.get(step)">
            <div
              v-for="historyItem in groupedHistoryItems.get(step)"
              class="shipment-status-flow__step-history d-flex align-center flex-column"
            >
              <span class="text-caption text-center font-italic">{{
                !!historyItem.changedBy ? historyItem.changedBy : "Unknown"
              }}</span>
              <span class="text-caption text-center font-italic">{{
                !!historyItem.changedDate
                  ? moment(historyItem.changedDate).format(
                      "DD-MM-YYYY HH:mm:ss",
                    )
                  : ""
              }}</span>
            </div>
          </template>
        </v-stepper-step>
        <v-divider v-if="step < fullfilledStep"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script setup lang="ts">
interface IProps {
  shipmentId: number | null;
  activeStatus: number;
}

import { ref, watch, computed, onBeforeMount } from "vue";
import moment from "moment";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { ExportFlowStatusHistoryItem, CustomsExportApi } from "@/openapi";

const customsExportApi = new CustomsExportApi(undefined, "");
const props = withDefaults(defineProps<IProps>(), {
  shipmentId: 0,
  activeStatus: 0,
});
const emits = defineEmits(["errorOccured"]);
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();

const historyItems = ref<ExportFlowStatusHistoryItem[]>([]);
const fullfilledStep = ref(14);

watch(
  () => props.shipmentId,
  (newVal) => {
    if (!newVal) {
      return;
    }

    getHistoryItems(newVal);
  },
);

watch(
  () => props.activeStatus,
  (newVal) => {
    if (!newVal || props.shipmentId == null) {
      return;
    }

    getHistoryItems(props.shipmentId);
  },
);
const groupedHistoryItems = computed(() => {
  const map = new Map();
  historyItems.value.forEach((item) => {
    const key = item.status;
    const values = map.get(key);
    if (!values) {
      map.set(key, [item]);
    } else {
      values.push(item);
    }
  });
  return map;
});

const getHistoryItems = async (shipmentId: number) => {
  try {
    var response =
      await customsExportApi.getExportFlowStatusHistory(shipmentId);
    historyItems.value = response.data;
  } catch {
    displayError(
      "Something went wrong while retrieving the history of the shipment statuses.",
    );
  }
};
const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

onBeforeMount(() => {
  customsFlowStepsOnBeforeMount();
});
</script>
