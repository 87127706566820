<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1 stickyColumn"
          :loading="isLoading"
        >
          <template #header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              :disabled="disableNewItemButton"
              @click="addNewItem"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <template #body="{ items }">
            <tbody>
              <ValidationObserver
                v-for="item in items"
                :key="item.id"
                v-slot="{ invalid, dirty }"
                ref="observers"
                :item-id="item.id"
                slim
              >
                <tr>
                  <td valign="top">
                    <v-icon
                      :disabled="!dirty"
                      small
                      class="mr-2 warning--text"
                      @click="saveItem(item)"
                    >
                      mdi-content-save
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>

                  <EditableField
                    :id="'description-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="description"
                      :vid="'description-' + item.id"
                      item-name="description"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">{{ item.description }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.description"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>

                  <EditableField
                    :id="'code-' + item.id"
                    v-slot="{ makeEditable, removeEditable, isEditable }"
                  >
                    <ValidationProvider
                      ref="providers"
                      v-slot="{ errors, failed, validate }"
                      name="code"
                      :vid="'code-' + item.id"
                      item-name="code"
                      :item-id="item.id"
                      rules="required"
                      slim
                    >
                      <div
                        :class="{ red: failed && !isEditable }"
                        @click="makeEditable"
                      >
                        <div v-if="!isEditable">{{ item.code }}</div>
                        <v-text-field
                          v-if="isEditable"
                          v-model="item.code"
                          :class="{ 'editable-field': isEditable }"
                          label="Edit"
                          :error-messages="errors"
                          single-line
                          autofocus
                          @blur="removeEditable"
                        ></v-text-field>
                      </div>
                    </ValidationProvider>
                  </EditableField>
                </tr>
              </ValidationObserver>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { CustomsCountriesApi, CustomsCountryViewModel } from "@/openapi";
import { useCrudPage } from "@/composables/crudPage";

const { disableNewItemButton, items, addNewItem, isNewItem, mapItem } =
  useCrudPage<CustomsCountryViewModel>({ id: 0 }, "id", 0);
const emits = defineEmits(["PageInfoReceived", "errorOccured"]);
const observers = ref<InstanceType<typeof ValidationObserver>[]>([]);
const providers = ref<InstanceType<typeof ValidationProvider>[]>([]);

const api: CustomsCountriesApi = new CustomsCountriesApi(undefined, "");
const headers = ref([
  { text: " ", value: "actions", sortable: false, width: "6em" },
  { text: "Country EU", value: "description", width: "30%" },
  { text: "Code", value: "code" },
]);

const isLoading = ref(false);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Countries EU", toolbarButtons);

  loadItems();
});

const loadItems = () => {
  isLoading.value = true;

  api
    .getCustomsCountriesEU()
    .then((result) => {
      items.value = result.data;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const saveItem = async (item: CustomsCountryViewModel) => {
  if (isLoading.value) {
    return;
  }

  const id = item.id?.toString() ?? "0";
  const validationObserver = observers.value.find(
    (el) => el.$attrs["item-id"] == id,
  );
  if (!validationObserver) {
    return;
  }

  const promises: Promise<ValidationResult>[] = [];
  providers.value
    .filter((el) => el.$attrs["item-id"] == id)
    .forEach((provider) => {
      const propName = provider.$attrs["item-name"];
      promises.push(
        provider.validate(item[propName as keyof CustomsCountryViewModel]),
      );
    });

  validationObserver.validate().then(async (isValid) => {
    const validationResults = await Promise.all(promises);
    let errorsArray: { id: string; errors: string[] }[] = [];
    Object.keys(validationObserver.errors).map((key) => {
      if (validationObserver.errors[key].length > 0) {
        errorsArray.push({ id: key, errors: validationObserver.errors[key] });
      }
    });
    for (var validationResult of validationResults) {
      if (!validationResult.valid) {
        var errorObject = errorsArray.find(
          (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
        );
        if (errorObject) {
          var errorElementId = errorObject?.id;
          document
            .getElementById(errorElementId)
            ?.scrollIntoView({ inline: "start" });
        }

        return;
      }
    }

    isLoading.value = true;
    const isNew = isNewItem(item);
    const postData = JSON.parse(JSON.stringify(item));

    api
      .saveCustomsCountryEU(postData as CustomsCountryViewModel)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        mapItem(item, result.data);
        if (isNew) {
          disableNewItemButton.value = false;
        }
      })
      .catch((error) => {
        emits("errorOccured", [error.message]);
      })
      .finally(() => {
        isLoading.value = false;
      });
  });
};

const deleteItem = (item: any) => {
  isLoading.value = true;
  const index = items.value.findIndex((x) => x.id === item.id);
  if (isNewItem(item)) {
    items.value.splice(index, 1);
    isLoading.value = false;
    disableNewItemButton.value = false;
    return;
  }

  api
    .deleteCustomsCountryEU(item.id)
    .then((c) => {
      items.value.splice(index, 1);
    })
    .catch(() => {})
    .finally(() => {
      loadItems();
    });
};

const refresh = () => {
  loadItems();
  disableNewItemButton.value = false;
};
</script>
