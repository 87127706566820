<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="-1"
      height="500"
      hide-default-footer
      fixed-header
    >
      <template #body.prepend>
        <tr>
          <td>
            <v-text-field
              v-model="filters.hawb"
              clearable
              placeholder="Filter on paketscheinnummer"
              dense
              single-line
              @keyup.enter="onFilterSelected"
              @click:clear="(filters.hawb = ''), onFilterSelected()"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          small
          color="error"
          :disabled="!!item.authority"
          @click="(currentItem = item), (showAuthorityFormDialog = true)"
        >
          Customs inspection
        </v-btn>
      </template>
    </v-data-table>

    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <DefaultDialog v-model="showAuthorityFormDialog">
        <template #header> Set authority </template>
        <template #content>
          <v-form>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Authority"
            >
              <v-text-field
                v-model="formData.authority"
                label="Authority"
                :error-messages="errors"
              >
              </v-text-field>
            </ValidationProvider>
          </v-form>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="authorityFormLoading"
            @click="showAuthorityFormDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            :loading="authorityFormLoading"
            @click="setAuthority"
          >
            Ok
          </v-btn>
        </template>
      </DefaultDialog>
    </ValidationObserver>
  </Fragment>
</template>
<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  ConsolidationCustomsInspectionShipment,
  CustomsFlightHandlingApi,
} from "@/openapi";
import { ValidationObserver } from "vee-validate";
import { ref, watch, onBeforeMount } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";

interface Filters {
  hawb?: string;
}

interface AuthorityForm {
  authority: string;
}

const api = new CustomsFlightHandlingApi(undefined, "");
const props = defineProps({
  consolidationId: {
    type: Number,
    required: true,
  },
});

const initializeForm = () => {
  return {
    authority: "",
  };
};
const observer = ref<InstanceType<typeof ValidationObserver>>();

const loading = ref(false);
const items = ref<ConsolidationCustomsInspectionShipment[]>([]);
const currentItem = ref<ConsolidationCustomsInspectionShipment | null>(null);

const filters = ref<Filters>({
  hawb: "",
});

const showAuthorityFormDialog = ref(false);
const authorityFormLoading = ref(false);

const formData = ref<AuthorityForm>(initializeForm());

const headers = ref([
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
  },
  {
    text: "Gewicht ",
    value: "weight",
    sortable: false,
  },
  {
    text: "Customs inspection authority",
    value: "authority",
    sortable: false,
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
  },
]);

watch(
  () => showAuthorityFormDialog.value,
  (newVal) => {
    formData.value = initializeForm();
    currentItem.value = null;
    observer.value!.reset();
  },
);

onBeforeMount(() => {
  getShipments();
});

const getShipments = async () => {
  loading.value = true;
  try {
    const response = await api.getConsolidationCustomsInspectionShipments(
      props.consolidationId,
      filters.value.hawb ?? "",
    );
    items.value = response.data;
  } catch {
    emitError(
      "Something went wrong while retrieving the customs inspection shipments.",
    );
  }
  loading.value = false;
};

let timeout = 0;
const onFilterSelected = async () => {
  clearTimeout(timeout);
  timeout = setTimeout(async () => {
    await getShipments();
  }, 250);
};

const setAuthority = async () => {
  authorityFormLoading.value = true;
  if (!currentItem.value) {
    return;
  }

  try {
    await api.inspectConsolidationShipment({
      id: currentItem.value!.id,
      authority: formData.value.authority,
    });
    authorityFormLoading.value = false;
    showAuthorityFormDialog.value = false;

    await getShipments();
  } catch {
    authorityFormLoading.value = false;
    emitError("Something went wrong while saving the authority");
  }
};
</script>
