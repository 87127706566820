<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <ValidationObserver v-slot="{ invalid }" ref="observer">
          <form>
            <v-data-table
              :headers="headers"
              :items="customsOfficeExportFlowItems"
              :single-expand="true"
              :expanded.sync="expanded"
              item-key="id"
              height="calc(100vh - 123px)"
              fixed-header
              fixed-footer
              show-expand
              class="elevation-1"
              :loading="isLoading"
            >
              <template #expanded-item="{ headers, item }">
                <td
                  v-if="currentCustomsOfficeExportFlowItem"
                  :colspan="headers.length"
                >
                  <v-flex class="pa-3 pb-5">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title>Export flows</v-card-title>

                            <div
                              v-for="flow in currentCustomsOfficeExportFlowItem"
                              class="flow-stepper"
                            >
                              <v-stepper
                                alt-labels
                                :value="confirmDocumentOrMRN"
                              >
                                <v-stepper-header>
                                  <template
                                    v-for="step in range(
                                      valueBreaksStep,
                                      confirmManifestStep,
                                    )"
                                  >
                                    <v-stepper-step
                                      v-if="step !== confirmDocumentOrMRN"
                                      :step="step"
                                    >
                                      {{ flowSteps[step][flow.flowIndex] }}
                                    </v-stepper-step>

                                    <template
                                      v-if="step === confirmDocumentOrMRN"
                                    >
                                      <v-stepper-step
                                        v-if="flow.flowIndex == 0"
                                        :step="confirmDocumentOrMRN"
                                      >
                                        <v-select
                                          v-model="selectedFlowValueFlow0"
                                          :items="flowItemsFlow0"
                                          item-text="flowText"
                                          item-value="flowValue"
                                          single-line
                                          class="flow-step2"
                                          @change="changeFlow0"
                                        >
                                        </v-select>
                                      </v-stepper-step>
                                      <v-stepper-step
                                        v-if="flow.flowIndex == 1"
                                        :step="confirmDocumentOrMRN"
                                      >
                                        <v-select
                                          v-model="selectedFlowValueFlow1"
                                          :items="flowItemsFlow1"
                                          item-text="flowText"
                                          item-value="flowValue"
                                          single-line
                                          class="flow-step2"
                                          @change="changeFlow1"
                                        >
                                        </v-select>
                                      </v-stepper-step>
                                      <v-stepper-step
                                        v-if="flow.flowIndex == 2"
                                        :step="confirmDocumentOrMRN"
                                      >
                                        <div class="flow3-step2">
                                          {{
                                            flowSteps[confirmDocumentOrMRN][
                                              flow.flowPathIndex
                                            ]
                                          }}
                                        </div>
                                      </v-stepper-step>
                                    </template>

                                    <v-divider
                                      v-if="step < confirmManifestStep"
                                    ></v-divider>
                                  </template>
                                </v-stepper-header>
                              </v-stepper>
                            </div>

                            <v-row>
                              <v-col cols="4">
                                <br />
                                <v-btn
                                  color="primary"
                                  class="ml-3"
                                  @click="save"
                                  >Save</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </td>
              </template>
            </v-data-table>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { ValidationObserver } from "vee-validate";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  CustomsOfficeExportFlowApi,
  CustomsOfficeExportFlowViewModel,
} from "@/openapi";

const api = new CustomsOfficeExportFlowApi(undefined, "");
const emits = defineEmits(["PageInfoReceived"]);
const headers = ref([
  { text: "Customer", value: "customerName" },
  { text: "Custom Office", value: "customsOfficeName" },
]);

const saveSuccessful = ref(false);
const expanded = ref([]);

const observer = ref<InstanceType<typeof ValidationObserver>>();

const isLoading = ref(false);

const currentCustomsOfficeExportFlowItem = ref<
  CustomsOfficeExportFlowViewModel[]
>([]);
const customsOfficeExportFlowItems = ref<CustomsOfficeExportFlowViewModel[]>(
  [],
);

const flowSteps = ref([
  // CHECK MODUS per Shipment
  ["Start", "Start", "Start", "yellow darken-3", "Start"],
  [
    "Shipment data received",
    "Shipment data received",
    "Shipment data received",
    "yellow darken-3",
    "Shipment data received",
  ],
  [
    "Check shipment",
    "Check shipment",
    "Check shipment",
    "yellow darken-3",
    "Check shipment",
  ],

  // EXPORT MODUS per WAB
  [
    "< 1.000",
    "1.000 - 3.000",
    "> 3.000",
    "light-blue darken-1",
    "Value breaks",
  ],
  [
    "Confirm loading",
    "Confirm loading",
    "Confirm loading",
    "light-blue darken-1",
    "Confirm loading",
  ],
  [
    "Excel sheet created",
    "Declaration by Dakosy",
    "Movement Reference Number (MRN) entered",
    "light-blue darken-1",
    "Files/MRN created",
  ],
  [
    "Pending customs release",
    "Pending customs release",
    "Movement Reference Number (MRN) entered",
    "light-blue darken-1",
    "Pending customs release",
  ],
  [
    "Confirm reply from customs",
    "Confirm reply from Dakosy",
    "Movement Reference Number (MRN) entered",
    "light-blue darken-1",
    "Customs EXIT released",
  ],

  // TRANSIT MODUS per WAB
  [
    "Confirm WAB ready to depart",
    "Confirm WAB ready to depart",
    "Confirm WAB ready to depart",
    "purple",
    "Confirm WAB ready to depart",
  ],
  ["T1 creation", "T1 creation", "T1 creation", "purple", "T1 creation"],
  ["T2 released", "T2 released", "T2 released", "purple", "T2 released"],
  [
    "CONSO file created",
    "CONSO file created",
    "CONSO file created",
    "purple",
    "CONSO file created",
  ],
  [
    "T2 discharged",
    "T2 discharged",
    "T2 discharged",
    "purple",
    "T2 discharged",
  ],

  // FULLFILLED
  [
    "Fullfilled",
    "Fullfilled",
    "Fullfilled",
    "light-green darken-2",
    "Fullfilled",
  ],
]);

const dataReceivedStep = ref(1);
const checkShipmentStep = ref(2);

const valueBreaksStep = ref(3);
const confirmLoadingStep = ref(4);
const confirmDocumentOrMRN = ref(5);
const pendingCustomsStep = ref(6);
const confirmManifestStep = ref(7);

const confirmWABStep = ref(8);
const t2DischargedStep = ref(13);

const fullfilledStep = ref(14);

const flowItemsFlow0 = ref([
  { flowText: "Create Excel sheet", flowValue: "0" },
  { flowText: "Declaration by Dakosy", flowValue: "1" },
  { flowText: "Declaration by customer", flowValue: "2" },
]);
const selectedFlowValueFlow0 = ref("");

const flowItemsFlow1 = ref([
  { flowText: "Declaration by Dakosy", flowValue: "1" },
  { flowText: "Declaration by customer", flowValue: "2" },
]);
const selectedFlowValueFlow1 = ref("");

watch(
  () => expanded.value,
  (
    newVal: CustomsOfficeExportFlowViewModel[],
    oldVal: CustomsOfficeExportFlowViewModel[],
  ) => {
    var customsOfficeExportFlowId = newVal[0].id;
    isLoading.value = true;
    api
      .getCustomsOfficeExportFlowItemById(customsOfficeExportFlowId)
      .then((response) => {
        currentCustomsOfficeExportFlowItem.value = response.data ?? [];
        selectedFlowValueFlow0.value =
          currentCustomsOfficeExportFlowItem.value[0].flowPathIndex.toString();
        selectedFlowValueFlow1.value =
          currentCustomsOfficeExportFlowItem.value[1].flowPathIndex.toString();
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
      });
  },
);

const changeFlow0 = (flowIndex: number) => {
  currentCustomsOfficeExportFlowItem.value[0].flowPathIndex = flowIndex;
};

const changeFlow1 = (flowIndex: number) => {
  currentCustomsOfficeExportFlowItem.value[1].flowPathIndex = flowIndex;
};

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Office Export Flows", toolbarButtons);

  loadData();
});

const refresh = () => {
  loadData();
};

const loadData = () => {
  isLoading.value = true;
  api
    .getCustomsOfficeExportFlowItems()
    .then((response) => {
      customsOfficeExportFlowItems.value = response.data;
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
    });
};

const save = (saveSuccessfulCallback: Function) => {
  for (let flow = 0; flow < 2; flow++) {
    api
      .saveCustomsOfficeExportFlowItem(
        currentCustomsOfficeExportFlowItem.value![flow],
      )
      .then((result) => {
        currentCustomsOfficeExportFlowItem.value[flow] = result.data;
        let item = customsOfficeExportFlowItems.value!.find(
          (x) => x.id === currentCustomsOfficeExportFlowItem.value![flow].id,
        );
        item!.id = currentCustomsOfficeExportFlowItem.value[flow].id;
        item!.customerId =
          currentCustomsOfficeExportFlowItem.value[flow].customerId;
        item!.customsOfficeId =
          currentCustomsOfficeExportFlowItem.value[flow].customsOfficeId;
        item!.flowIndex =
          currentCustomsOfficeExportFlowItem.value[flow].flowIndex;
        item!.flowPathIndex =
          currentCustomsOfficeExportFlowItem.value[flow].flowPathIndex;

        saveSuccessful.value = true;
        if (saveSuccessfulCallback) {
          saveSuccessfulCallback();
        }
      })
      .catch((error) => {});
  }
};

const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx);
};
</script>
