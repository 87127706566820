<template>
  <Fragment>
    <div class="d-flex">
      <v-btn
        class="my-4"
        color="primary"
        :disabled="!selectedItems.length"
        @click="showCreateDeclarationDialog = true"
        >Move selected shipments
        <span v-if="!!selectedItems.length">
          ({{ selectedItems.length }})
        </span>
      </v-btn>

      <v-checkbox
        v-model="filters.includeCustomsInspection"
        class="ml-8"
        @change="onFilterSelected"
      >
        <span slot="label" class="primary--text"
          >Include customs inspection</span
        >
      </v-checkbox>
    </div>

    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      item-key="id"
      fixed-header
      fixed-footer
      show-select
      class="elevation-1"
      :loading="loading"
    >
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="!item.isSelectable"
          @input="select"
        ></v-simple-checkbox>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          small
          color="primary"
          :disabled="!!item.atbNumber || !!item.atbPosition"
          @click="(showDialog = true), (currentItem = item)"
          >Release</v-btn
        >
      </template>
    </v-data-table>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <DefaultDialog v-model="showDialog">
        <template #header>ATB</template>
        <template #content>
          <AtbNumberEditor v-model="atb"></AtbNumberEditor>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn :loading="dialogLoading" text small @click="showDialog = false"
            >Close</v-btn
          >
          <v-btn
            :disabled="invalid"
            :loading="dialogLoading"
            text
            small
            @click="release"
            >Confirm</v-btn
          >
        </template>
      </DefaultDialog>
    </ValidationObserver>

    <CreateTransitDeclarationDialog
      v-if="showCreateDeclarationDialog"
      v-model="showCreateDeclarationDialog"
      :create-transit-declaration-loading="createDeclarationDialogLoading"
      @confirm="moveShipments"
    ></CreateTransitDeclarationDialog>
  </Fragment>
</template>

<script setup lang="ts">
import {
  CustomsFlightHandlingApi,
  ExcludedFromFlightShipment,
} from "@/openapi";
import { ref, watch } from "vue";
import { ValidationObserver } from "vee-validate";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue";
import AtbNumberEditor, { AtbInfoSingle } from "./editors/AtbNumberEditor.vue";
import CreateTransitDeclarationDialog, {
  CreateTransitDeclarationForm,
} from "@/components/dialogs/CreateTransitDeclarationDialog.vue";
import { emitError } from "@/event-bus";

interface Selectable {
  isSelectable: boolean;
}

type EnrichedExcludedFromFlightShipment = ExcludedFromFlightShipment &
  Selectable;

const api = new CustomsFlightHandlingApi(undefined, "");
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const loading = ref(false);

const headers = ref<DataTableHeader[]>([
  {
    text: "MAWB",
    value: "mawb",
    sortable: false,
  },
  {
    text: "Consolidation number",
    value: "consolidation",
    sortable: false,
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
  },
  {
    text: "Gewicht ",
    value: "weight",
    sortable: false,
  },
  {
    text: "Not moved reason ",
    value: "notMovedReason",
    sortable: false,
  },
  {
    text: "Customs inspection authority",
    value: "customsInspectionAuthority",
    sortable: false,
  },
  {
    text: "ATB number",
    value: "atbNumber",
    sortable: false,
  },
  {
    text: "ATB position",
    value: "atbPosition",
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    sortable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const filters = ref({
  includeCustomsInspection: false,
});

const items = ref<EnrichedExcludedFromFlightShipment[]>([]);
const selectedItems = ref<EnrichedExcludedFromFlightShipment[]>([]);
const totalAmountOfItems = ref(0);
const currentItem = ref<EnrichedExcludedFromFlightShipment | null>(null);

const initializeAtb = () => {
  return {
    number: "",
    position: "",
  };
};

const showDialog = ref(false);
const dialogLoading = ref(false);
const atb = ref<AtbInfoSingle | null>(initializeAtb());

const showCreateDeclarationDialog = ref(false);
const createDeclarationDialogLoading = ref(false);

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal, oldVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = window.setTimeout(() => {
      getExcludedFromFlightShipments();
    }, 250);
  },
);

watch(
  () => showDialog.value,
  (newVal, oldVal) => {
    if (!newVal) {
      atb.value = initializeAtb();
      observer.value?.reset();
      currentItem.value = null;
    }
  },
);

const onFilterSelected = () => {
  getExcludedFromFlightShipments();
};

const getExcludedFromFlightShipments = async () => {
  loading.value = true;
  try {
    const response = await api.getShipmentsExcludedFromFlight(
      filters.value.includeCustomsInspection,
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value = response.data
      .items!.map((item) => ({
        ...item,
        isSelectable: !!item.atbNumber && !!item.atbPosition,
      }))
      .sort((a, b) => (a.isSelectable ? -1 : 1));
    totalAmountOfItems.value = response.data.totalAmountOfItems!;
  } catch {
    emitError("Something went wrong while retrieving the shipments.");
  }

  loading.value = false;
};

const release = async () => {
  dialogLoading.value = true;
  try {
    await api.setAtb({
      atbNumber: atb.value?.number,
      atbPosition: atb.value?.position,
      shipmentId: currentItem.value!.id,
    });
    currentItem.value!.atbNumber = atb.value?.number;
    currentItem.value!.atbPosition = atb.value?.position;
    currentItem.value!.isSelectable = true;
    showDialog.value = false;
  } catch {
    emitError("Something went wrong while setting the ATB.");
  }

  dialogLoading.value = false;
};

const moveShipments = async (eventData: CreateTransitDeclarationForm) => {
  createDeclarationDialogLoading.value = true;
  try {
    await api.moveShipmentsFromAirport({
      amount: eventData.amount,
      country: eventData.country,
      ids: selectedItems.value.map((item) => item.id!),
      registration: eventData.registration,
      signs: eventData.signs.map((c) => c.value).filter((c) => !!c),
      routeId: eventData.routeId ?? undefined,
      transportAcrossEuBorder: eventData.transportAcrossEuBorder,
    });
    showCreateDeclarationDialog.value = false;
    selectedItems.value = [];
    await getExcludedFromFlightShipments();
  } catch {
    emitError("Something went wrong while moving the shipments");
  }

  createDeclarationDialogLoading.value = false;
};

getExcludedFromFlightShipments();
</script>
