<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-dialog
      :value="value"
      @input="emits('input', $event)"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="ml-3 text-button">{{ title }}</span>
        </v-toolbar>
        <v-card-text class="justify-center">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col>
                  <ValidationProvider
                    :rules="fileValidationRules"
                    v-slot="{ errors }"
                    name="File(s)"
                  >
                    <v-file-input
                      :value="formData.files"
                      outlined
                      dense
                      label="Upload file(s)"
                      :multiple="multiple"
                      placeholder="Please upload file(s)"
                      @change="onFileUpload"
                      :error-messages="errors"
                    ></v-file-input>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="isLoading"
            @click="emits('input', false), emits('close')"
            >{{ closeButtonText }}</v-btn
          >
          <v-btn
            color="primary"
            text
            :loading="isLoading"
            :disabled="!hasSelectedFiles || invalid"
            @click="onConfirm"
          >
            {{ confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";

export interface UploadFileForm {
  files: File[] | null;
}
const props = defineProps({
  value: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  title: { type: String, default: "Upload file(s)" },
  confirmButtonText: { type: String, default: "Confirm" },
  closeButtonText: { type: String, default: "Close" },
  multiple: { type: Boolean, default: false },
  rules: { type: Object, default: () => ({ required: true }) },
});

const getDefaultFormValues = (): UploadFileForm => {
  return {
    files: null,
  };
};
const emits = defineEmits(["input", "close", "confirm", "errorOccured"]);
const observer = ref(null);
const valid = ref(true);
const formData = ref<UploadFileForm>(getDefaultFormValues());

watch(
  () => props.value,
  (newVal) => {
    if (newVal == false) {
      (observer.value! as Vue & { reset: () => boolean }).reset();
      formData.value = getDefaultFormValues();
    }
  },
);

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const onConfirm = () => {
  emits("confirm", {
    files: formData.value.files,
  } as UploadFileForm);
};

const onFileUpload = (eventData: File | File[]) => {
  formData.value.files = eventData
    ? Array.isArray(eventData)
      ? eventData
      : [eventData]
    : [];
};

const hasSelectedFiles = computed((): boolean => {
  return !!formData.value.files?.length;
});

const fileValidationRules = computed(() => {
  return props.rules ? { required: true, ...props.rules } : { required: true };
});
</script>
