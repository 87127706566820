<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :server-items-length="totalAmountOfItems"
    :options.sync="options"
    :footer-props="footerOptions"
    :single-expand="true"
    :loading="isLoading"
    height="calc(100vh - 325px)"
    fixed-header
    fixed-footer
    class="elevation-1"
  >
  </v-data-table>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeUnmount, onUnmounted } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";

import {
  CustomsDiscrepanciesApi,
  IncompleteMultipieceShipment,
  IncompleteMultipieceShipmentPagedResult,
} from "@/openapi";

const emits = defineEmits(["errorOccured", "PageInfoReceived"]);

const api = new CustomsDiscrepanciesApi(undefined, "");

const isLoading = ref(false);

const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const items = ref<IncompleteMultipieceShipment[]>([]);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const totalAmountOfItems = ref(0);

const headers = ref([
  {
    text: "HAWB",
    value: "hawbNumber",
    align: "center",
    sortable: false,
  },
  {
    text: "parcelNumber",
    value: "parcelNumber",
    align: "center",
    sortable: false,
  },
  {
    text: "",
    value: "",
    align: "end",
    sortable: false,
  },
]);

const incompleteMultipieceShipmentInterval = ref(0);

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeUnmount(() => {
  clearInterval(incompleteMultipieceShipmentInterval.value);
  incompleteMultipieceShipmentInterval.value = setInterval(
    () => getIncompleteMultipieceShipments(options.value.page),
    60000,
  );

  addRefreshToolbarOption(reloadIncompleteMultiepieceShipments);
});

onUnmounted(() => {
  clearInterval(incompleteMultipieceShipmentInterval.value);
});

watch(
  () => options.value,
  (newVal) => {
    getIncompleteMultipieceShipments(options.value.page);
  },
);

const getIncompleteMultipieceShipments = async (page: number) => {
  isLoading.value = true;
  items.value = [];
  try {
    const response = await api.getIncompleteMultipieceShipments(
      page,
      options.value.itemsPerPage,
    );
    const data: IncompleteMultipieceShipmentPagedResult = response.data;
    items.value = data.items ?? [];
    totalAmountOfItems.value = data.totalAmountOfItems ?? 0;
  } catch {
    displayError(
      "Something went wrong while retrieving the incomplete multipiece shipments",
    );
  }
  isLoading.value = false;
};

const reloadIncompleteMultiepieceShipments = async () => {
  options.value.page == 1
    ? await getIncompleteMultipieceShipments(options.value.page)
    : (options.value.page = 1);
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};
</script>
