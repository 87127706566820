<template>
  <div>
    <v-card class="mx-3" outlined :color="color" elevation="2">
      <v-flex class="pa-5"></v-flex>
      <v-flex class="px-3" white>
        <v-container fluid>
          <v-row>
            <v-col cols="2">
              <div class="ml-4">
                <v-checkbox
                  v-model="filter.showReturn"
                  class="mt-0 d-inline-block"
                  :disabled="filter.showHoldOnly"
                  @change="
                    (showDateRangeMenu = false),
                      (filter.returnShipmentsDateRange = []),
                      onFilterSelected()
                  "
                >
                  <span slot="label" class="primary--text"
                    >Show also Return shipments</span
                  >
                </v-checkbox>

                <v-menu
                  v-if="filter.showReturn"
                  ref="dateRangeMenu"
                  v-model="showDateRangeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="ml-4 d-block"
                >
                  <template #activator="menuSlotProps">
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-badge color="primary">
                          <template #badge>
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-information-variant</v-icon
                            >
                          </template>
                          <v-text-field
                            v-model="filter.returnShipmentsDateRange"
                            label="Shipment return date"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="menuSlotProps.attrs"
                            v-on="menuSlotProps.on"
                            @input="onFilterSelected"
                          ></v-text-field>
                        </v-badge>
                      </template>
                      <span
                        >Select 2 dates to select a range of dates. Select the
                        same date twice to filter on a single date.</span
                      >
                    </v-tooltip>
                  </template>

                  <v-date-picker
                    v-model="filter.returnShipmentsDateRange"
                    :range="true"
                    @change="onFilterSelected"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                v-model="filter.showHoldOnly"
                class="mt-0 d-inline-block"
                @change="onFilterSelected()"
              >
                <span slot="label" class="primary--text"
                  >Show only Hold shipments</span
                >
              </v-checkbox>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                dark
                @click="showHoldReturnExportDialog = true"
              >
                Export Excel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
      <v-data-table
        v-model="selectedHandlingItems"
        :headers="headers"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        :footer-props="footerOptions"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        height="calc(100vh - 310px)"
        dense
        fixed-header
        fixed-footer
        show-expand
        class="elevation-1"
        :loading="isLoading"
      >
        <template #body.prepend>
          <tr>
            <td>
              <v-icon small>mdi-filter</v-icon>
            </td>
            <td>
              <v-select
                v-model="filter.step"
                :items="statusFilter"
                clearable
                chips
                dense
                single-line
                @change="onFilterSelected"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentLoaded"
                :items="booleanFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              >
              </v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentConfirmed"
                :items="booleanFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              >
              </v-select>
            </td>
            <td>
              <v-select
                v-model="filter.shipmentStatusDescription"
                :items="shipmentStatusFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-select>
            </td>
            <td>
              <FilterParcels
                v-model="filter.parcels"
                @input="onFilterSelected"
              ></FilterParcels>
            </td>
            <td>
              <v-text-field
                v-model="filter.exportMrn"
                clearable
                dense
                @keyup.enter="onFilterSelected"
                @click:clear="(filter.exportMrn = ''), onFilterSelected()"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="filter.returnReasonSecond"
                :items="returnReasonsFilter"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-select>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <v-autocomplete
                v-model="filter.shipperCountry"
                :items="dataStore.originCountries"
                item-key="code"
                item-text="code"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                v-model="filter.consigneeCountry"
                :items="dataStore.originCountries"
                item-key="code"
                item-text="code"
                clearable
                dense
                single-line
                @change="onFilterSelected"
              ></v-autocomplete>
            </td>
          </tr>
        </template>

        <template #item.exportFlowStatus="{ item }">
          <v-avatar
            class="flow-status-avatar"
            size="22"
            :color="flowSteps[item.exportFlowStatus][3]"
          >
            {{ item.exportFlowStatus }}
          </v-avatar>
        </template>

        <template #item.isLoaded="{ item }">
          <v-row justify="center">
            <v-col>
              <v-icon v-if="item.isLoaded"> mdi-check-bold </v-icon>
            </v-col>
          </v-row>
        </template>

        <template #item.isConfirmed="{ item }">
          <v-row justify="center">
            <v-col>
              <v-icon v-if="item.isConfirmed"> mdi-check-bold </v-icon>
            </v-col>
          </v-row>
        </template>

        <template #item.exportMrn="{ item }">
          <v-row justify="center">
            <v-col>
              {{ item.isMaster ? item.exportMrns.join(", ") : item.exportMrn }}
            </v-col>
          </v-row>
        </template>

        <template #item.shipper="{ item }">
          <v-row justify="center">
            <v-col>
              {{ item.shipper.name }}<br />
              {{ item.shipper.address }}<br />
              {{ item.shipper.zipcode }} {{ item.shipper.city }}<br />
              {{ item.shipper.country }}
            </v-col>
          </v-row>
        </template>

        <template #item.consignee="{ item }">
          <v-row justify="center">
            <v-col>
              {{ item.consignee.name }}<br />
              {{ item.consignee.address }}<br />
              {{ item.consignee.zipcode }}
              {{ item.consignee.city }}<br />
              {{ item.consignee.country }}
            </v-col>
          </v-row>
        </template>

        <template #item.value="{ item }">
          <v-row>
            <v-col>
              {{ item.value.toFixed(2) }}
            </v-col>
          </v-row>
        </template>

        <template #item.articles="{ item }">
          <v-row v-for="article in item.articles.slice(0, 4)" no-gutters>
            <v-col cols="9">
              <span class="articleDescription">{{ article.description }}</span>
            </v-col>
            <v-col>
              <span class="articleDescription pl-4">{{ article.rcTarif }}</span>
            </v-col>
          </v-row>
          <v-row v-if="item.articles.length > 4" no-gutters>
            <v-col cols="9">
              <span class="articleDescription">more...</span>
            </v-col>
          </v-row>
        </template>

        <template #expanded-item="{ headers, item }">
          <td v-if="currentShipmentDetail" :colspan="headers.length">
            <v-flex class="pa-3 pb-5">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title>Shipment status</v-card-title>
                      <ShipmentPaperwork
                        class="shipment-paperwork"
                        :shipment-id="currentShipmentDetail.id"
                      ></ShipmentPaperwork>
                      <!-- MISSING: @updated-details="onShipmentDetailsUpdated" -->
                      <CustomsExportShipmentDetailViewNew
                        v-if="currentShipmentDetail"
                        :current-handling-item="currentShipmentDetail"
                      />
                      <div class="ms-6">
                        <ReleaseShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          :export-flow-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        ></ReleaseShipmentButton>

                        <HoldShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          :export-flow-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </HoldShipmentButton>

                        <ReturnShipmentButton
                          :shipment-id="currentShipmentDetail.id"
                          :export-flow-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </ReturnShipmentButton>

                        <CustomsInspectionButton
                          :shipment-id="currentShipmentDetail.id"
                          :export-flow-status="
                            currentShipmentDetail.exportFlowStatus
                          "
                          class="ml-3 mb-5"
                          @shipmentStatusChanged="onShipmentStatusChanged"
                        >
                        </CustomsInspectionButton>
                      </div>

                      <ShipmentStatusFlow
                        :shipment-id="currentShipmentDetail.id"
                        :active-status="currentShipmentDetail.exportFlowStatus"
                      ></ShipmentStatusFlow>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <HoldReturnExportDialog
      v-model="showHoldReturnExportDialog"
      :include-return="filter.showReturn"
      :shipment-ids="customsHandlingItemIds"
      :return-date-filter="holdReturnExportDateFilter ?? undefined"
    ></HoldReturnExportDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onBeforeMount, nextTick } from "vue";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue";
import ConfirmShipmentReasonDialog from "@/components/dialogs/ConfirmShipmentReasonDialog.vue";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue";
import HoldReturnExportDialog, {
  HoldReturnExportDialogDateFilter,
} from "./dialogs/HoldReturnExportDialog.vue";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsReturnApi,
  CustomsExportApi,
  CustomsShipmentItemViewModel,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
  CustomsShipmentDetailViewModel,
} from "@/openapi";
import ShipmentPaperwork from "./ShipmentPaperwork.vue";
import FilterParcels from "@/pages/shipment-overview/components/FilterParcels.vue";

import ReleaseShipmentButton from "./buttons/ReleaseShipmentButton.vue";
import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue";
import { useCustomsFlowSteps } from "@/composables/customsFlowSteps";
import { useDataStore } from "@/stores/data-store";
import { ToolbarItem } from "@/models/ToolbarItem";

const customsReturnApi = new CustomsReturnApi(undefined, "");
const cstomsExportApi = new CustomsExportApi(undefined, "");
const { flowSteps, customsFlowStepsOnBeforeMount } = useCustomsFlowSteps();
const dataStore = useDataStore();
const emits = defineEmits(["errorOccured", "PageInfoReceived"]);
const props = defineProps({
  color: { type: String, default: "" },
});

const isLoading = ref(false);
const showDateRangeMenu = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});
const filter = ref({
  step: null,
  shipmentStatusDescription: "",
  parcels: [],
  exportMrn: "",
  showReturn: false,
  returnShipmentsDateRange: [],
  shipmentConfirmed: null,
  shipmentLoaded: null,
  showHoldOnly: false,
  returnReasonSecond: null,
  shipperCountry: null,
  consigneeCountry: null,
});
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});
const totalItems = ref(0);
const expanded = ref<CustomsShipmentItemViewModel[]>([]);

const statusFilter = ref<number[]>([]);
const shipmentStatusFilter = ref([
  "Hold",
  "Return",
  "Customs inspection",
  "Loading confirmed",
  "Waiting for customs release",
  "Customs EXIT released",
]);
const booleanFilter = ref(["Yes", "No"]);
const returnReasonsFirst = ref<ReturnReasonFirstViewModel[]>([]);
const returnReasonsSecond = ref<ReturnReasonSecondViewModel[]>([]);

const items = ref<CustomsShipmentItemViewModel[]>([]);
const currentHandlingItem = ref<CustomsShipmentItemViewModel | null>(null);
const currentShipmentDetail = ref<CustomsShipmentDetailViewModel | null>(null);
const selectedHandlingItems = ref<CustomsShipmentItemViewModel[]>([]);

const showHoldReturnExportDialog = ref(false);

const headers = ref([
  {
    text: "Step",
    value: "exportFlowStatus",
    align: "center",
  },
  {
    text: "Loaded",
    value: "isLoaded",
    align: "center",
  },
  {
    text: "Confirmed",
    value: "isConfirmed",
    align: "center",
  },
  {
    text: "Shipment status",
    value: "shipmentStatusDescription",
  },
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: false,
    width: "15em",
  },
  {
    text: "MRN ",
    value: "exportMrn",
    width: "18em",
  },
  {
    text: "Return reason ",
    value: "shipmentReason",
    width: "18em",
  },
  { text: "Anzahl ", value: "pieces", sortable: false, align: "end" },
  {
    text: "Gewicht ",
    value: "grossWeight",
    sortable: false,
    align: "end",
  },
  { text: "EORI", value: "eori", sortable: false },
  {
    text: "Versender",
    value: "shipper",
    sortable: false,
    width: "15em",
  },
  {
    text: "Empfänger",
    value: "consignee",
    sortable: false,
    width: "15em",
  },
  { text: "Warenwert", value: "value", sortable: false, align: "end" },
  { text: "Währung", value: "valueCurrency", sortable: false },
  {
    text: "Warenbeschreibung / tarifnummer",
    value: "articles",
    sortable: false,
    width: "25em",
  },
]);

const addRefreshToolbarOption = (callback: Function) => {
  emits("PageInfoReceived", [
    {
      callback: callback,
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ] as ToolbarItem[]);
};

onBeforeMount(() => {
  customsFlowStepsOnBeforeMount();
  dataStore.fetchOriginCountries();
  getReturnReasons();
  statusFilter.value = getStatuses(false, true, true, true, false);

  addRefreshToolbarOption(reloadShipments);
});

const returnReasonsFilter = computed(() => {
  if (!returnReasonsFirst.value?.length) {
    return [];
  }

  var filters = [];
  for (const reason of returnReasonsFirst.value) {
    if (!reason.returnReasonsSecond?.length) {
      continue;
    }

    filters.push({
      divider: true,
      header: reason.reason,
    });

    for (const secondReason of reason.returnReasonsSecond) {
      filters.push({
        value: secondReason.id,
        text: secondReason.reason,
      });
    }
  }

  return filters;
});

const customsHandlingItemIds = computed(() => {
  return items.value?.map((c) => c.id) ?? [];
});

const holdReturnExportDateFilter = computed(
  (): HoldReturnExportDialogDateFilter | null => {
    return filter.value.returnShipmentsDateRange?.length === 2
      ? {
          fromDate: filter.value.returnShipmentsDateRange[0],
          tillDate: filter.value.returnShipmentsDateRange[1],
        }
      : null;
  },
);

const loadShipments = async (page: number) => {
  isLoading.value = true;
  try {
    let startDateString: string = "";
    let endDateString: string = "";
    if (filter.value.returnShipmentsDateRange?.length === 2) {
      startDateString = filter.value.returnShipmentsDateRange[0];
      endDateString = filter.value.returnShipmentsDateRange[1];
    }

    const response = await customsReturnApi.getHoldReturnShipments({
      page,
      itemsPerPage: options.value.itemsPerPage,
      step: filter.value.step ?? undefined,
      shipmentStatusDescription:
        filter.value.shipmentStatusDescription ?? undefined,
      parcels: filter.value.parcels,
      exportMrn: filter.value.exportMrn ?? undefined,
      shipmentLoaded: filter.value.shipmentLoaded ?? undefined,
      shipmentConfirmed: filter.value.shipmentConfirmed ?? undefined,
      showReturn: filter.value.showReturn ?? undefined,
      returnReasonSecond: filter.value.returnReasonSecond ?? undefined,
      returnStartDate: startDateString,
      returnEndDate: endDateString,
      shipperCountry: filter.value.shipperCountry ?? undefined,
      consigneeCountry: filter.value.consigneeCountry ?? undefined,
      showHoldOnly: filter.value.showHoldOnly ?? undefined,
    });

    items.value = response.data.shipments ?? [];
    totalItems.value = response.data.totalAmount ?? 0;
  } catch (e) {
    displayError("Something went wrong while retrieving the shipments");
  }
  isLoading.value = false;
};

const reloadShipments = async () => {
  options.value.page == 1 ? await loadShipments(1) : (options.value.page = 1);
};

let timeoutDelay = 0;
watch(
  () => options.value,
  (newVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      loadShipments(options.value.page);
    }, 250);
  },
);

watch(
  () => expanded.value,
  (newVal) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
      currentShipmentDetail.value = null;
    } else {
      currentHandlingItem.value = newVal[0];

      isLoading.value = true;
      currentShipmentDetail.value = null;
      cstomsExportApi
        .getCustomsShipmentDetail(currentHandlingItem.value?.id ?? 0)
        .then((response) => (currentShipmentDetail.value = response.data))
        .catch((error) =>
          displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (isLoading.value = false));
    }
  },
);

const onFilterSelected = () => {
  nextTick(() => {
    reloadShipments();
  });
};

const displayError = (errorMessage: string) => {
  emits("errorOccured", errorMessage);
};

const getReturnReasons = async () => {
  returnReasonsFirst.value = [];
  try {
    var response = await cstomsExportApi.getReturnReasons();
    returnReasonsFirst.value = response.data;
  } catch {
    displayError(
      "Something went wrong while retrieving the return reason filters.",
    );
  }
};

const onShipmentStatusChanged = ({ success }: any) => {
  if (success) {
    selectedHandlingItems.value = [];
    loadShipments(options.value.page);
  }
};
</script>
