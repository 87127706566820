<template>
  <v-container fluid>
    <v-row v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-row>
    <v-row class="flex-wrap">
      <v-col
        v-for="(warehouse, index) in warehouses"
        :key="`${index}-${warehouse.id}`"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card color="grey">
          <v-toolbar dark color="grey darken-3" dense elevation="0">
            <v-toolbar-title>{{ warehouse.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-edit-dialog :return-value.sync="warehouse.name">
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <template #input>
                  <v-text-field
                    v-model="warehouse.name"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
              <v-btn icon @click="saveWarehouse(warehouse)">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn
                icon
                :disabled="$store.getters.disableByUserRights(false, 'Delete')"
                @click="deleteWarehouse(warehouse)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list class="draglist pb-0">
            <draggable
              v-model="warehouse.warehouseLocations"
              group="warehouse.warehouseLocations"
            >
              <v-list-item
                v-for="(location, index) in warehouse.warehouseLocations"
                :key="`${index}-${location.id}`"
              >
                <v-list-item-content>
                  <v-edit-dialog :return-value.sync="location.name">
                    {{ location.name }}
                    <v-icon small class="mb-1"> mdi-pencil </v-icon>
                    <template #input>
                      <v-text-field
                        v-model="location.name"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    :disabled="
                      $store.getters.disableByUserRights(false, 'Delete')
                    "
                    @click="deleteLocation(warehouse, location)"
                  >
                    mdi-delete
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </draggable>
            <v-list-item @click="addLocation(warehouse)">
              <v-list-item-icon>
                <v-icon> mdi-plus </v-icon>
              </v-list-item-icon>
              <v-list-item-content> Add location </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <v-card class="text-center pb-3 pt-5" @click="addWarehouse">
          <v-card-text>
            <h5 class="text-h5 mb-3">Add warehouse</h5>
            <v-icon class="text-h3">mdi-plus</v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template #action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorSnackbar" :timeout="3000" color="danger">
      An error occurred. Please try again.
      <template #action="{ attrs }">
        <v-btn v-bind="attrs" text @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { ToolbarItem } from "@/models/ToolbarItem";
import draggable from "vuedraggable";
import {
  WarehouseViewModel,
  WarehouseLocationViewModel,
  WarehouseLocationApi,
} from "@/openapi";
const emits = defineEmits(["PageInfoReceived"]);
const warehouseLocationApi = new WarehouseLocationApi(undefined, "");
const snack = ref(false);
const snackColor = ref("");
const snackText = ref("");
const errorSnackbar = ref(false);
const loading = ref(false);

const warehouses = ref<WarehouseViewModel[]>([]);

onBeforeMount(() => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refreshData(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Warehouse locations", toolbarButtons);

  refreshData();
});

const addWarehouse = () => {
  var newWarehouse: WarehouseViewModel = {
    id: 0,
    name: "Warehouse " + (warehouses.value.length + 1),
    warehouseLocations: [],
  };

  warehouses.value.push(newWarehouse);
};

const saveWarehouse = (warehouse: WarehouseViewModel) => {
  loading.value = true;
  warehouseLocationApi
    .saveWarehouse(warehouse)
    .then((response) => {
      snack.value = true;
      snackColor.value = "success";
      snackText.value = "Data saved";
      refreshData();
    })
    .catch((error) => {
      errorSnackbar.value = true;
      loading.value = false;
    });
};

const deleteWarehouse = (warehouse: WarehouseViewModel) => {
  loading.value = true;
  warehouseLocationApi
    .deleteWarehouse(warehouse.id)
    .then((response) => {
      snack.value = true;
      snackColor.value = "success";
      snackText.value = "Entity removed";
      refreshData();
    })
    .catch((error) => {
      errorSnackbar.value = true;
      loading.value = false;
    });
};

const addLocation = (warehouse: WarehouseViewModel) => {
  var newLocation: WarehouseLocationViewModel = {
    id: 0,
    warehouseId: warehouse.id,
    name: "Location " + (warehouse.warehouseLocations!.length + 1),
  };
  warehouse.warehouseLocations!.push(newLocation);
};

const deleteLocation = (
  warehouse: WarehouseViewModel,
  location: WarehouseLocationViewModel,
) => {
  var arrWarehouse = warehouses.value.find(
    (value) => value.id === warehouse.id,
  );
  if (arrWarehouse) {
    var existingLocationId = arrWarehouse.warehouseLocations!.findIndex(
      (value) => value.id === location.id,
    );
    if (existingLocationId !== -1) {
      arrWarehouse.warehouseLocations!.splice(existingLocationId, 1);
    }
  }
};

const refreshData = () => {
  loading.value = true;
  warehouseLocationApi
    .getWarehouses()
    .then((response) => {
      warehouses.value = response.data;
    })
    .catch((error) => {
      errorSnackbar.value = true;
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
